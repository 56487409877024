let previous_page;
const PAGE_HOME = 'home';
const PAGE_SETTINGS_PERSONAL_INVITATIONS = 'settings_personal_invitations';

/**
 * goHome switches to the main index page.
 */
function goHome() {
  previous_page_lock = false;
  if (is_app < 0) {
    $(':mobile-pagecontainer').pagecontainer('change',
      window.location.href.slice(0, window.location.href.indexOf('#')), {
      // home_dir+'index.html', {
        transition: 'slide',
        reverse: true,
      });
  } else {
    if (home_dir.indexOf('android_asset') === false) {
      $(':mobile-pagecontainer').pagecontainer('change',
        home_dir + 'index.html', {
          transition: 'slide',
          reverse: true,
        });
    } else {
      $(':mobile-pagecontainer').pagecontainer('change',
        source_index, {
          transition: 'slide',
          reverse: true,
        });
    }
    // remove hash:
    setTimeout(function() {
      history.pushState('', document.title,
        window.location.pathname+
                 window.location.search);
    }, 10);
  }
  // if solo mode check whether date changed
  if (utilization===4) {
    checkDateChange();
  }
}

/**
 * old goback function which uses the native window.history.back.
 * @returns {boolean} always true is returned.
 */
function goback_old() {
  window.history.back();
  return true;
}

/**
 * goback switchs to the last active page which is stored.
 * @returns {boolean}always true is returned.
 */
function goback() {
  if (v.d) console.log('goin back ' + jqm_activePage);

  switch (jqm_activePage) {
    case 'message':
    case 'videochat':
      /*
              if ( previous_page_id === 'home' || previous_page_id === '' ) {
                goHome();
              } else {
                page.back_to( previous_page_id + '.html' );
            }*/
      if (state.pswipe.gallery_open) {
        goHome();
      } else {
        Fotos.show_last_unseen();
        window.history.back();
      }
      previous_page_lock = false;
      break;
    case 'solo_fotos':
      goHome();
      break;

    case 'youtube':
    case 'skype':
    case 'user_media':
      page.back_to('web.html');
      break;

    case 'google':
      googleframe_back();
      break;

    case 'settings_pw':
    case 'settings_update':
    case 'settings_screen':
    case 'settings_profile':
    case 'settings_wlan':
    case 'settings_shut_down_device':
    case 'settings_languages':
      page.back_to('settings.html');
      break;

    case 'hilfe':
    case 'help':
      previous_page_lock = false;
      if (previous_page_id==='sublist' &&
        (sublist_mother_id === 1963 ||
          sublist_mother_id === 1964 ||
          sublist_mother_id === 1965)) {
        sublist_mother_id = old_sublist_mother_id;
      }
      // go from all settings_xyz pages to the the settings page back:
      if (previous_page_id.substr(0, 9)==='settings_') previous_page_id = 'settings';
      if (previous_page_id==='home') {
        goHome();
      } else {
        page.back_to(previous_page_id+'.html');
      }
      break;

    case 'settings_page':
      // go to page before settings_page called - header_btns in home.js save changes to it:
      if (page_id_before_settings === 'home') {
        goHome();
      } else if (page_id_before_settings === 'profiles_list') {
        page.back_to('profile.html');
      } else {
        page.back_to(page_id_before_settings+'.html');
      }
      break;

    case 'profile_page':
    case 'profile_delete':
    case 'profile_settings':
    case 'profile_create':
      page.back_to('profile.html');
      break;

    case 'profile_entry_delete':
    case 'imagecontainer':
      page.back_to('profile_content.html');
      break;

    case 'guide':
      goHome();
      break;
    case 'relatives_list':
      goHome();
      break;
    case 'list':
      if ( utilization === 24 &&
            (list_media_type_id === 77 ||
            list_media_type_id === 4)) {
        page.back_to('guide.html');
      } else if (userid === 1 ||
                    utilization === 4 ||
                    utilization === 5 ) {
        page.back_to('typelist.html');
      } else if ( utilization === 8 ) {
        if ( list_media_type_id === 61 || list_media_type_id === 62 ) {
          page.back_to('entertain.html');
        } else {
          page.back_to('typelist.html');
        }
      } else {
        page.back_to('profile_content.html');
      }
      break;

    case 'sublist':
      // using sessionStorage for differ (set in typelist/categories)
      // if mother media was opened in categories:
      if (parseInt(sessionStorage.wascat)||0 ) {
        page.back_to('categories_sublist1.html');
      } else if (utilization===24 && ( sublist_mother_id === 1813)) {
        goHome();
      } else if (
        utilization===24 &&
                    (sublist_mother_id === 1963 ||
                     sublist_mother_id === 1964 ||
                     sublist_mother_id === 1960 ||
                     sublist_mother_id === 1988)
      ) {
        page.back_to(home_dir + 'guide.html');
      } else if (sublist_mother_id === 1963 || sublist_mother_id === 1964) {
        page.back_to('help.html');
      } else {
        page.back_to('list.html');
      }
      // window.history.back();
      break;

    case 'gamemaulwurf':
    case 'gamesolitaire':
    case 'gameballoon':
      if (utilization === 4 || utilization === 5) {
        curr_sort_id = parseInt(curr_sort_id)-1;
        sessionStorage.mediaid = daily_media_ids[curr_sort_id];
        let temp_home_dir = home_dir;
        if (is_app<0) temp_home_dir = '../';
        page.back_to('motivation.html');
        unbind_headerbuttons();
      } else {
        if (userid === 1 || utilization === 8) {
          $(':mobile-pagecontainer').pagecontainer('change',
            '../list.html', {
              transition: 'slide',
              reverse: true,
            });
        } else {
          // in categories/themen:if(userid !== 1){
          ask_for_rating(
            'gameframe_wrap',
            '.',
            function() {
              window.history.back();
            }
          );
        }
      }

      if (is_app>0) {
        home_dir = 'file:///android_asset/www/';
      }

      break;

    case 'entertain':
      goHome();
      break;

    case 'typelist':
      if ( userid === 1 || utilization === 4 || utilization === 5 ||
              utilization === 8 ) {
        if (utilization === 24 || utilization === 8) {
          page.back_to('entertain.html');
        } else {
          goHome();
        }
        return true;
      } else {
        page.back_to('profile_content.html');
        return true;
      }
      break;

    case 'videocontainer':
      if (utilization === 6) {
        goHome();
      } else if
      (
        userid === 1 ||
                utilization === 4 ||
                utilization === 5 ||
                utilization === 8
      ) {
        vid.pause();
        $('#video1').html('');
        $('#video1').attr('src', '');
        $('#video1').find('source').remove();
        vid.remove();
        let previous_page_array = previous_page.split('#');
        if (utilization === 4 || utilization === 5) {
            				curr_sort_id = parseInt(curr_sort_id)-1;
            				sessionStorage.mediaid = daily_media_ids[curr_sort_id];
          unbind_headerbuttons();
          page.back_to('motivation.html');
        } else {
          window.history.back();
        }
      } else {
        vid.pause();
        $('#video1').html('');
        $('#video1').attr('src', '');
        $('#video1').find('source').remove();
        vid.remove();
        if (video_personal === true && pm_type === 'overview') {
          page.back_to('profile_content.html');
        } else if (video_personal === true && pm_type !== 'overview') {
          page.back_to('profile_family_pictures.html');
        } else if (rating_video) {
          rating_video = false;
          ask_for_rating(
            'video_content',
            '',
            function() {
              if (pm_type==3) {
                page.back_to('category_view.html');
              } else {
                page.back_to('profile_content.html');
              }
            }
          );
        } else {
          // in categories/themen:
          if (pm_type==3) {
            page.back_to('category_view.html');
          } else {
            page.back_to('profile_content.html');
          }
        }
      }
      break;

    case 'textplayer':
    case 'pdfplayer':
      window.history.back();
      break;

    case 'quiz':
      if (utilization === 6) {
        goHome();
      } else if (userid === 1 || utilization === 8) {
        getLanguageArray(
          lang,
          null,
          null,
          function() {
            window.history.back();
          }
        );
        // SOLO MODUS TEST START 2016-10-13 MHe:
      } else if (utilization === 4 || utilization === 5) {
        getLanguageArray(
          lang,
          null,
          null,
          function() {
                				curr_sort_id = parseInt(curr_sort_id)-1;
                				sessionStorage.mediaid = daily_media_ids[curr_sort_id];
            unbind_headerbuttons();
            page.back_to('motivation.html');
          }
        );
      }
      // SOLO MODUS TEST END
      else {
        if (quiz_rating) {
          quiz_rating = false;
          ask_for_rating(
            'raetsel',
            '',
            function() {
              getLanguageArray(
                lang,
                null,
                null,
                function() {
                  if (pm_type==3) {
                    page.back_to('category_view.html');
                  } else {
                    page.back_to('profile_content.html');
                  }
                }
              );
            }
          );
        } else {
          if (pm_type==3) {
            page.back_to('category_view.html');
          } else {
            page.back_to('profile_content.html');
          }
        }
      }
      break;

    case 'book':
    case 'quote':
      if (userid === 1 || utilization === 8) {
        window.history.back();
      } else if (utilization === 4 || utilization === 5) {
        				curr_sort_id = parseInt(curr_sort_id)-1;
        				sessionStorage.mediaid = daily_media_ids[curr_sort_id];
        page.back_to('motivation.html');
        unbind_headerbuttons();
      } else {
        if (book_rating) {
          book_ask_for_rating();
        } else {
          page.back_to('profile_content.html');
        }
      }
      break;

    case 'profile_checkpassword':
      window.history.back();
      break;

    case 'check_pin':
      window.history.back();
      break;

    case 'category_view':
      if (userid === 1 || utilization === 8) {
        if (has_mother) {
          page.back_to('categories_sublist2.html');
        } else {
          page.back_to('categories_sublist1.html');
        }
      } else {
        page.back_to('profile_content.html');
      }
      break;

    case 'categories_sublist1':
      if (utilization === 24) {
        if ( previous_page_id !== 'entertain' ) {
          goHome();
        } else {
          page.back_to('entertain.html');
        }
      } else if (userid === 1 || utilization === 8) {
        page.back_to('categories.html');
      } else {
        page.back_to('profile_content.html');
      }
      break;

    case 'categories_sublist2':
      page.back_to('categories_sublist1.html');
      break;

    case 'categories':
      if (
        userid === 1 ||
              utilization === 4 ||
              utilization === 5 ||
              utilization === 8
      ) {
        goHome();
      } else {
        page.back_to('profile_content.html');
      }
      break;
    case 'imprint':
      // when on impressum page from help page page_lock is set
      if (previous_page_lock) {
        page.back_to('help.html');
      } else {
        goHome();
      }
      break;
    case 'data_protection':
      // when on data_protection page from help page page_lock is set
      if (previous_page_lock) {
        page.back_to('help.html');
      } else {
        goHome();
      }
      break;
    case 'web':
    case 'profiles_list':
      if (previous_page_id === 'settings_utilization') {
        page.back_to(previous_page_id+'.html');
        delete sessionStorage.temp_utilization;
      } else {
        goHome();
      }
      break;

    case 'profile_content':
    case 'profile_family':
      page.back_to('profile_page.html');
      break;

    case 'profile_biography':
    case 'profile_family_messages':
    case 'profile_family_pictures':
    case 'profile_family_bio':
    case 'profile_biography':
      page.back_to('profile_family.html');
      break;

    case 'profile_family_messages_new':
    case 'profile_family_messages_show':
    case 'profile_family_messages_delete':
      page.back_to('profile_family_messages.html');
      break;

    case 'profile_family_pictures_delete':
      page.back_to('profile_family_pictures.html');
      break;

    case 'game_piano':
      if (userid === 1 || utilization === 8) {
        window.history.back();
      } else if (utilization === 4 || utilization === 5) {
        				curr_sort_id = parseInt(curr_sort_id)-1;
        				sessionStorage.mediaid = daily_media_ids[curr_sort_id];
        if (is_app<0) {
          home_dir='../';
        }
        page.back_to('motivation.html');
        if (is_app<0) {
          home_dir='';
        }
        unbind_headerbuttons();
      } else {
        if (rating_game_piano) {
          ask_for_rating(
            'game_piano .ui-content',
            '.',
            function() {
              window.history.back();
            }
          );
          rating_game_piano = false;
        } else {
          window.history.back();
        }
      }
      break;

    case 'game_paint':
      if (userid === 1 || utilization === 8) {
        window.history.back();
      } else if (utilization === 4 || utilization === 5) {
          				curr_sort_id = parseInt(curr_sort_id)-1;
          				sessionStorage.mediaid = daily_media_ids[curr_sort_id];
        if (is_app<0) {
          home_dir='../';
        }
        page.back_to('motivation.html');
        if (is_app<0) {
          home_dir='';
        }
        unbind_headerbuttons();
      } else {
        if (rating_game_paint) {
          ask_for_rating(
            'game_paint .ui-content',
            '.',
            function() {
              window.history.back();
            }
          );
          rating_game_paint = false;
        } else {
          window.history.back();
        }
      }
      break;

    case 'game_soundgame':
      sound_game.stopAll();
      if (userid === 1 || utilization === 8) {
        window.history.back();
      } else if (utilization === 4 || utilization === 5) {
        curr_sort_id = parseInt(curr_sort_id)-1;
        sessionStorage.mediaid = daily_media_ids[curr_sort_id];
        let tmp_home_dir = home_dir;
        if (is_app<0) tmp_home_dir = '../';
        page.back_to('motivation.html');
        unbind_headerbuttons();
      } else {
        ask_for_rating(
          'game_soundgame .ui-content',
          '.',
          function() {
            window.history.back();
          }
        );
      }
      break;

    case 'game_memory':
      if (userid === 1 || utilization === 8) {
        window.history.back();
      } else if (utilization === 4 || utilization === 5) {
        curr_sort_id = parseInt(curr_sort_id)-1;
        sessionStorage.mediaid = daily_media_ids[curr_sort_id];
        let tmphome_dir = home_dir;
        if (is_app<0) tmphome_dir = '../';
        page.back_to('motivation.html');
        unbind_headerbuttons();
      } else {
        ask_for_rating(
          'game_memory .ui-content',
          '.',
          function() {
            window.history.back();
          }
        );
      }
      break;

    case 'game_dice':
      if (userid === 1 || utilization === 8) {
        window.history.back();
      } else if (utilization === 4 || utilization === 5) {
        goHome();
      } else {
        ask_for_rating(
          'game_dice .ui-content',
          '.',
          function() {
            window.history.back();
          }
        );
      }
      break;

    case 'text':
      window.history.back();
      break;

    case 'wikipedia':
      var wikiframe = document.getElementById('wikiframe');

      if (wikiframe === null) {
        page.back_to('web.html');
      } else if
      (
        wikiframe.contentWindow.location.href !==
                    'http://de.m.wikipedia.org/wiki/Wikipedia:Hauptseite' &&
                wikiframe.contentWindow.location.href !==
                    'https://de.m.wikipedia.org/wiki/Wikipedia:Hauptseite'
      ) {
        wikiframe.contentWindow.history.back();
      } else {
        page.back_to('web.html');
      }
      break;

    case 'tagesschau':
      var tagesschau = document.getElementById('tagesschau_frame');

      if (tagesschau === null) {
        page.back_to('web.html');
      } else if
      (
        tagesschau.contentWindow.location.href !==
                    'http://www.tagesschau.de/' &&
                tagesschau.contentWindow.location.href !==
                    'https://www.tagesschau.de/'
      ) {
        tagesschau.contentWindow.history.back();
      } else {
        page.back_to('web.html');
      }
      break;

    case 'find_the_difference':
      if (userid === 1 || utilization === 8) {
        window.history.back();
      } else if (utilization === 4 || utilization === 5) {
        curr_sort_id = parseInt(curr_sort_id)-1;
        sessionStorage.mediaid = daily_media_ids[curr_sort_id];
        let temphome_dir = home_dir;
        if (is_app<0) temphome_dir = '../';
        page.back_to('motivation.html');
        unbind_headerbuttons();
      } else {
        ask_for_rating(
          'find_the_difference .ui-content',
          '.',
          function() {
            window.history.back();
          }
        );
      }
      break;

    case 'motivation':
      if (utilization===4) {
        window.history.back();
      }
      break;

    default:
      window.history.back();
  }
}

if (is_app >= 0) {
  document.addEventListener('backbutton', goback, false);
}
