window.contentBackendDb = () => {};
const createContentBackendDb = () => {
  return new Promise(
    (resolve, reject) => {
      window.contentBackendDb = new function() {
        this.imports = () => {
          return Promise.all(
            // you can not directly execute those functions
            // as they are part of the this.readFiles and not available
            // on execution of createContentBackendDb() yet
            // therefore i put them into nameless functions - mahe
            [
              (
                () => {
                  return importMediaBeData();
                }
              )(),
              (
                () => {
                  return importFilesBeData();
                }
              )(),
              (
                () => {
                  return importTranslationsBeData();
                }
              )(),
            ]
          )
            .catch(
              (r) => {
                console.error(r);
                reject(r);
              }
            );
        };
        this.readFiles = () => {
          return Promise.all(
            [
              (
                () => {
                  return core.load_js('./js/bedb.js', 'js_bedb');
                }
              )(),
              (
                () => {
                  return core.load_js(
                    './js/translations.js',
                    'js_translations'
                  );
                }
              )(),
              (
                () => {
                  return core.load_js('./js/files.js', 'js_files');
                }
              )(),
            ]
          )
            .catch(
              (r) => {
                console.error(r);
                reject(r);
              }
            );
        };
      };
      resolve();
    }
  );
};
