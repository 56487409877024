// this file handles updating of media files from contentBackend
// to download them on client
var files_checklist = [];
var file_check_error = 0;
// entry point for updating files:
//generate to download list from files table 2016_10_19 MHe
function sync_files(access_token) {
  return new Promise(
    (resolve) => {
      db.transaction(
        function(tx) {
          tx.executeSql(
            'select * from files_v3 where status = ? order by path asc',
            [1],
            function(transaction, result) {
              if (result !== null && result.rows !== null) {
                if (v.d) console.log('length ' + result.rows.length);
                if (result.rows.length === 0) {
                  // start_app();
                  Settings.update('updateMediaFiles', 0, resolve);
                }

                for (let i = 0; i < result.rows.length; i++) {
                  let row = result.rows.item(i);

                  files_checklist[i] = [];
                  if (row.path.indexOf('videos/') >= 0) {
                    files_checklist[i].abs_path = local_dir + row.path;
                    files_checklist[i].abs_path_2 =
                      sd_card_url + 'Android/data/com.media4care.dementia/files/' + row.path;
                  } else {
                    files_checklist[i].abs_path = local_dir + row.path;
                    // to not cause any damn errors:
                    files_checklist[i].abs_path_2 = local_dir + row.path;
                  }
                  files_checklist[i].id = row.id;
                  files_checklist[i].md5 = row.md5;
                  files_checklist[i].md5_old_0 = row.md5_old_0;
                  files_checklist[i].md5_old_1 = row.md5_old_1;

                  files_checklist[i].rel_path = row.path;
                  files_checklist[i].size = row.size;
                  files_checklist[i].copy_errors = 0;
                }

                //start checking file here:
                if (v.d) console.log('starting local files check');
                check_file_locally(0, access_token, resolve);
              } else {
                Settings.update('updateMediaFiles', 0, resolve);
              }
            },
            errorHandler
          );
        }
      );
    }
  );
}

// check if file is locally available with the same md5 checksum on device:
function check_file_locally(position, access_token, resolve) {
  let file_path = files_checklist[position].abs_path;
  // give some ui feedback:
  let download_progress = (position / files_checklist.length) * 100;
  let bar_color = '#aaa';
  let file_display = files_checklist[position].rel_path;
  controllers.home.bootscreen.render(
    'wird aktualisiert',
    {
      show: true,
      overallProgress: download_progress,
      barColor: bar_color,
      timeLeft: '',
      fileDisplay: file_display,
    }
  );
  if (file_path.indexOf('.project') < 0) {
    window.resolveLocalFileSystemURL(
      files_checklist[position].abs_path,
      (fileEntry) => {
        // check md5:
        md5chksum.file(
          fileEntry,
          (md5_lokal) => {
            if (
              files_checklist[position].md5 === md5_lokal
              || files_checklist[position].md5_old_0 === md5_lokal
              || files_checklist[position].md5_old_1 === md5_lokal
              // file_md5 === 'd41d8cd98f00b204e9800998ecf8427e' ||
              // all js files in app now, therefore:
              || file_path.indexOf('.js') > -1
            ) {
              // Update Files Table Entry:
              files.set_status(
                nullHandler,
                files.constants().STATUS.LOCALLY_AVAILABLE,
                files_checklist[position].id
              );

              // finish sd card INSTALLATION:
              if (
                (position + 1) === files_checklist.length
                && file_check_error < 1
              ) {
                // start downloading missing files:
                prepare_download(access_token, resolve);
              } else {
                position++;
                check_file_locally(position, access_token, resolve);
              }
            } else {
              if ((position + 1) === files_checklist.length) {
                // we need to get a new version of the file:
                filestocopy.push(files_checklist[position].rel_path);
                filestocopy_size.push(files_checklist[position].size);
                filestocopy_id.push(files_checklist[position].id);
                if (files_checklist[position].rel_path.indexOf('videos/') < 0) {
                  update_size_internal =
                    update_size_internal + parseInt(files_checklist[position].size);
                }
                update_size = update_size + parseInt(files_checklist[position].size);
                // start downloading missing files:
                prepare_download(access_token, resolve);
              } else {
                // check if video is internally saved since default abs_path of videos is sd_card
                // though some P1050 have been installed with videos saved internally
                if (
                  files_checklist[position].abs_path.indexOf('videos/') >= 0 &&
                  files_checklist[position].abs_path.indexOf(sd_card_url) <= 0 &&
                  files_checklist[position].abs_path !== files_checklist[position].abs_path_2
                ) {
                  files_checklist[position].abs_path = files_checklist[position].abs_path_2;
                  check_file_locally(position, access_token, resolve);
                } else {
                  // we need to get a new version of the file:
                  filestocopy.push(files_checklist[position].rel_path);
                  filestocopy_size.push(files_checklist[position].size);
                  filestocopy_id.push(files_checklist[position].id);
                  if (
                    files_checklist[position].rel_path.indexOf('videos/') < 0
                  ) {
                    update_size_internal =
                      update_size_internal+parseInt(files_checklist[position].size);
                  }
                  update_size =
                    update_size + parseInt(files_checklist[position].size);
                  position++;
                  check_file_locally(position, access_token, resolve);
                }
              }
            }
          }
        );
      },
      (err) => {
        if ((position + 1) === files_checklist.length) {
          filestocopy.push(files_checklist[position].rel_path);
          filestocopy_size.push(files_checklist[position].size);
          filestocopy_id.push(files_checklist[position].id);
          if (files_checklist[position].rel_path.indexOf('videos/') < 0) {
            update_size_internal =
              update_size_internal + parseInt(files_checklist[position].size);
          }
          update_size = update_size + parseInt(files_checklist[position].size);
          // start downloading missing files:
          prepare_download(access_token, resolve);
        } else {
          // check if video is internally saved since default abs_path of videos is sd_card
          // though some P1050 have been installed with videos saved internally
          if (
            files_checklist[position].abs_path.indexOf('videos/') >= 0 &&
            files_checklist[position].abs_path.indexOf(sd_card_url) <= 0 &&
            files_checklist[position].abs_path !== files_checklist[position].abs_path_2
          ) {
            files_checklist[position].abs_path = files_checklist[position].abs_path_2;
            check_file_locally(position, access_token, resolve);
          } else {
            filestocopy.push(files_checklist[position].rel_path);
            filestocopy_size.push(files_checklist[position].size);
            filestocopy_id.push(files_checklist[position].id);
            if (files_checklist[position].rel_path.indexOf('videos/') < 0)
              update_size_internal = update_size_internal + parseInt(files_checklist[position].size);

            update_size = update_size + parseInt(files_checklist[position].size);
            position++;
            check_file_locally(position, access_token, resolve);
          }
        }
      }
    );
  } else if (files_checklist.length === 1 && file_path.indexOf('.project') > -1) {
    Settings.update('updateMediaFiles', 0, resolve);
  } else {
    position++;
    check_file_locally(position, access_token, resolve);
  }
}

function prepare_download(access_token, resolve) {
  cordova.exec(
    (result) => {
      console.log('Free Disk Space: ' + result);
      available_space = result * 1000;

      if (available_space >= update_size_internal) {
        if (filestocopy.length<3) {
          downloadAssets(0, state.content_back_end.token, resolve, 1);
        } else {
          // start as many instances as parallel download counter
          downloadAssets(0, state.content_back_end.token, resolve, 4);
          downloadAssets(1, state.content_back_end.token, resolve, 4);
          downloadAssets(2, state.content_back_end.token, resolve, 4);
          downloadAssets(3, state.content_back_end.token, resolve, 4);
        }
      } else {
        controllers.home.bootscreen.render(
          ` F&uuml;r die Aktualisierung ist nicht genug
                      freier Speicher auf Ihrem Gerät vorhanden.<br>
                      Die Anwendung startet in 20s ohne Aktualisierung.<br>
                      Bitte wenden Sie sich an uns:<br>
                      +49 (0)800 – 5 750 750`
        );
        /* Timeout so one can read 'not enough memory message' */
        setTimeout(
          start_app,
          20000
        );
      }
    },
    (error) => {
      console.log('Error: ' + error);
    },
    'File',
    'getFreeDiskSpace',
    []
  );
}

var try_sync = 0;

function downloadAssets(cnter, access_token, resolve, parallel_downloads) {
  let filename = '';
  let datalength = filestocopy.length;
  if (v.d) {
    console.log(cnter);
    console.log('parallel: '+parallel_downloads);
    console.log(datalength);
  }

  let transfered_prev = transfered_size;
  transfered_size = transfered_size+(parseInt(filestocopy_size[cnter])||0);
  let download_progress_o = 100 * transfered_size / update_size;
  let download_progress = download_progress_o.toFixed(2);

  // set sleep till next try depending on 'download quality factor':
  let sleep_next_try = 500;

  if (download_errors >= 0 && download_errors <= 0.5) {
    sleep_next_try = 2500;
  } else if (download_errors > 0.5) {
    sleep_next_try = 10000;
  }

  // create display download progress:
  if (download_progress <= 100) {
    let bar_color = '#117cc2';
    if (download_errors > 0.5) {
      bar_color = 'red';
    }
    if (download_errors <= 0.5 && download_errors > -0.5) {
      bar_color = 'yellow';
    }

    // let progress_width = download_progress * 6.8;
    let progress_width = (download_progress*0.6).toString() + '%';

    let file_display = '';
    if (filestocopy[cnter]) {
      file_display = filestocopy[cnter].replace('../../mddev/www/', '');
    }

    let istime = new Date().getTime();
    let dtime = istime-download_start_time;

    // use transfered_prev: no use of current download size,
    // since it is GOING tO be downloaded:
    // 1877976 bytes => current avg file size
    if (transfered_prev < 187797) {
      transfered_prev = 187797;
    }
    let download_progress_prev_o = 100 * transfered_prev / update_size;
    let download_progress_prev = download_progress_prev_o.toFixed(2);

    let time_needed = Math.floor(
      (100-download_progress_prev)*dtime/(download_progress_prev*1000)
    );

    let time_n_min = Math.floor(time_needed/60);
    let time_n_sec = Math.floor(time_needed%60);
    if (time_n_sec < 10) {
      time_n_sec = '0'+time_n_sec;
    }

    let time_left = time_n_min+':'+time_n_sec;
    let error_msg = '';
    if (download_errors > 0.8) {
      error_msg =
        `<div class='updating' style='width:800px; margin:0px auto; font-size:28px;'>
          <span style='color:#6e6e6e;font-size:23px;'>Es werden aktuell Fehler registriert,<br>
            lassen Sie die Aktualisierung weiterlaufen und:
            <ul>
              <li>Bitte versuchen Sie, den Abstand zu Ihrem WLAN Router zu verringern</li>
              <li>Bitte &uuml;berpr&uuml;fen Sie Ihre Internetverbindung</li>
              <li>Kontaktieren Sie unsere gerne unter: +49 (0)800 - 5 750 750 (Ortstarif)</li>
            </ul>
          </span>
        </div>`;
    }
    controllers.home.bootscreen.render(
      'wird aktualisiert',
      {
        show: true,
        overallProgress: download_progress,
        barColor: bar_color,
        timeLeft: time_left,
        fileDisplay: file_display+error_msg,
      }
    );
  }

  let ft = new FileTransfer();

  // if download stream is ready:
  if (cnter >= datalength) {
    finished_streams++;
    console.log('STREAM FINISHED'+finished_streams);
    // if all download streams are ready:
    if (finished_streams === parallel_downloads) {
      if (typeof cordova.plugins.brightness !== 'undefined') {
        brightness.setKeepScreenOn(false);
      }

      updatesync();
      Settings.update('updateMediaFiles', 0, resolve);
    } else {
      // if not all download streams are ready
      console.log(
        'cnter indicates finsihed but',
        finished_streams,
        parallel_downloads
      );
    }
  }

  /*get file URL**/
  if (stage==='dev' || stage==='demo') {
    assetURL = url.content_be+'/get.php';
    filename = filestocopy[cnter];
    if(v.d)console.log(assetURL);
    filename = filename.replace('../../mddev/www/', '');
    filename = filename.replace('../../mddev/root/', '');
  } else {
    assetURL = url.content_be+'/get.php';
    filename = filestocopy[cnter];
    if(v.d)console.log(assetURL);
    filename = filename.replace('../../md210/www/', '');
    filename = filename.replace('../../md210/root/', '');
  }

  // if file is video and sd card available, change store to sdcard
  /*
  changed from :

  to follwing for china tab testing with only 1gb internal memory:
  store = sd_card_url+'Android/data/com.media4care.dementia/files/';
  */
  if (
    filename.indexOf('videos/') >= 0 &&
    sd_card_videores === true &&
    available_space < 16000111000
  ) {
    videofile = true;
    if (v.d) console.log('VIDEO');
    store = sd_card_url + 'Android/data/com.media4care.dementia/files/';
  } else {
    store = local_dir;
  }

  var options = new FileUploadOptions();

  // GET MIME TYPE OF FILE:
  const re = /(?:\.([^.]+))?$/;
  const file_ext = re.exec(filename);
  if(v.d)console.log(file_ext);
  switch (file_ext[1]) {
    case 'ogg':
      options.mimeType = 'audio/ogg';
      break;
    case 'mp4':
      options.mimeType = 'video/mp4';
      break;
    case 'mp3':
      options.mimeType = 'audio/mpeg';
      break;
    default:
      options.mimeType = 'text/plain';
      break;
  }

  if(v.d) {
    console.log('mimetype:' + options.mimeType);
  }
  let md5_url = url.content_be+'/md5.php';
  let headers = {
    'token': access_token,
    Connection: 'close',
    'filename': filename,
  };
  console.log('downloading ', assetURL);
  options.headers = headers;
  if (filestocopy_id[cnter]) {
    ft.download(
      assetURL,
      store + filename,
      (entry) => {
        if (v.d) console.log(entry);

        // get MD5 of file from server:
        $.ajax(
          {
            type: 'POST',
            data:
              {
                token: access_token,
                filename: filename,
              },
            url: md5_url,
            xhrFields:
              {
                withCredentials: false,
              },
            timeout: 30000, // conf.get_timeout,
            headers: {},
            cache: false,
            success: (md5_source) => {
              if (v.d) console.log('Success!' + filename + ' MD5:' + md5_source);

              // get local md5 of transfered file:
              md5chksum.file(
                entry,
                function (md5_lokal) {
                  if (v.d) console.log(entry);
                  // process next file
                  // if file is not available anymore on cloud storage:
                  // TODO delete file if BE source = empty => d41d8...
                  if (
                    md5_lokal === md5_source ||
                    md5_source === 'd41d8cd98f00b204e9800998ecf8427e'
                  ) {
                    if (v.d) console.log('download success for ' + filename);

                    if (download_errors > -1) {
                      download_errors = download_errors - 0.05;
                    }

                    // update file status:
                    files.set_status(
                      nullHandler,
                      files.constants().STATUS.LOCALLY_AVAILABLE,
                      filestocopy_id[cnter]
                    );

                    downloadAssets(
                      (cnter + parallel_downloads),
                      access_token,
                      resolve,
                      parallel_downloads
                    );
                  } else {
                    if (v.d) {
                      console.log(entry.fullPath);
                      console.log(filename);
                      console.log(md5_lokal);
                      console.log(md5_source);
                      console.log('Error in MD5 checking for ' + filename);
                    }
                    // count error:
                    if (download_errors < 1) {
                      download_errors = download_errors + 0.25;
                    }
                    // change: try the same again:
                    transfered_size =
                      parseInt(transfered_size) - (parseInt(filestocopy_size[cnter]) || 0);

                    setTimeout(function () {
                      downloadAssets(cnter, access_token, resolve,
                        parallel_downloads);
                    }, sleep_next_try);
                  }
                },
                function () {
                  if (v.d) console.log('error getting lokal md5');
                  // count error:
                  if (download_errors < 1) {
                    download_errors = download_errors + 0.25;
                  }

                  transfered_size =
                    parseInt(transfered_size) - (parseInt(filestocopy_size[cnter]) || 0);

                  setTimeout(
                    function () {
                      downloadAssets(
                        cnter,
                        access_token,
                        resolve,
                        parallel_downloads
                      );
                    },
                    sleep_next_try
                  );
                }
              );
            },
            error: function() {
              if (v.d) console.log('error receiving md5');
              // count error:
              if (download_errors < 1) {
                download_errors = download_errors + 0.25;
              }

              transfered_size =
                parseInt(transfered_size) - (parseInt(filestocopy_size[cnter]) || 0);

              setTimeout(
                function() {
                  downloadAssets(
                    cnter,
                    access_token,
                    resolve,
                    parallel_downloads
                  );
                },
                sleep_next_try
              );
            },
          }
        );
      },
      function(err) {
        if (v.d) console.log('Error');
        if (v.d) console.dir(err);
        // count error:
        if (download_errors < 1) {
          download_errors = download_errors + 0.25;
        }
        transfered_size =
          parseInt(transfered_size) - (parseInt(filestocopy_size[cnter]) || 0);

        setTimeout(
          function () {
            downloadAssets(
              cnter,
              access_token,
              resolve,
              parallel_downloads
            );
          },
          sleep_next_try
        );
      },
      true,
      options
    );
  }
}

function show_nosd_nostorage() {
  controllers.home.bootscreen.render(
    `Ohne eingelegte SD Karte mit Videos oder mehr Speicher
    findet keine Aktualisierung statt!<br>
    Die Anwendung wird nun ohne Aktualisierung gestartet.`
  );
}
