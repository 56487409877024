window.thisapp = {};
const createThisapp = () => {
  return new Promise(
    (resolve, reject) => {
      thisapp.state = {};
      thisapp.onpagebeforeshow = function() {
        unbind_headerbuttons();
        bind_headerbuttons();
        // no go_back in solo app:
        if ((utilization === 4 || utilization === 5) &&
          $.mobile.activePage.attr('id').indexOf('settings') < 0 &&
          $.mobile.activePage.attr('id').indexOf('profile_checkpassword') < 0 &&
          $.mobile.activePage.attr('id').indexOf('check_pin') < 0 &&
          $.mobile.activePage.attr('id').indexOf('profiles_list') < 0 &&
          $.mobile.activePage.attr('id').indexOf('profile_settings') < 0) {
          $('.backbtn').hide();
        }

        if (
          utilization === 4
          && $.mobile.activePage.attr('id').indexOf('settings') > -1
        ) {
          $('#btn_pause_solo').remove();
        }

        if (utilization === 24) {
          if (typeof fast_lang_switch !== 'undefined') {
            fast_lang_switch.show_btns();
            fast_lang_switch.bind_fast_switch_lang_btns();
          }
        }

        if (!previous_page_lock && jqm_activePage !== 'videochat') {
          previous_page_id = jqm_activePage;
        }

        jqm_activePage = $.mobile.activePage.attr('id');
        if (typeof view[jqm_activePage] !== 'undefined') {
          if ($.isFunction(view[jqm_activePage].render)) {
            view[jqm_activePage].render();
          }
          if (is_app > -1) {
            $('.battery').html(sessionStorage.battery);
          }

          if (typeof view[jqm_activePage].el !== 'undefined') {
            let el_keys = Object.keys(view[jqm_activePage].el);
            let elements_cnt = el_keys.length;

            for (let i = 0; i < elements_cnt; i++) {
              let el = view[jqm_activePage].el[el_keys[i]];
              $(`#${el_keys[i]}`).on(
                el.run,
                function (event) {
                  event.preventDefault();
                  view[jqm_activePage]
                    .el[$(this).context.id][view[jqm_activePage]
                    .el[$(this).context.id].run](event);
                });
            }
          }
        } else {
          if (is_app > -1) {
            $('.battery').html(sessionStorage.battery);
          }
        }
      };

      thisapp.onpagebeforehide = function () {
        if (typeof view[jqm_activePage] !== 'undefined') {
          if ($.isFunction(view[jqm_activePage].leave))
            view[jqm_activePage].leave();

          if (typeof view[jqm_activePage].el !== 'undefined') {
            const el_keys = Object.keys(view[jqm_activePage].el);
            const elements_cnt = el_keys.length;
            // console.log(elements_cnt);
            for (let i = 0; i < elements_cnt; i++) {
              // disable elements
              $(`#${el_keys[i]}`).off();
            }
          }
        }
      };

      thisapp.enable_family_sync = function() {
        if (!thisapp_online) {
          return 'app offline, no request will be executed';
        }

        Auth.checkPromise()
          .then(Auth.verifyPromise)
          .then(Usync.sendUsers)
          .then(api_device_user.getProfIds)
          .then(Invite.send_disabled_users)
          .then(Family_relations.pull)
          .then(api_msg.start)
          .then(Invite.sendPromise);

        if (interval_new_fotos === '') {
          timelineService.getUserMedia()
            .then(Fotos.show_last_unseen);

          interval_new_fotos = setInterval(
            function() {
              if (thisapp_online) {
                timelineService.getUserMedia()
                  .then(Fotos.show_last_unseen);
              }
            },
            35 * 1000
          );
        }
      };

      thisapp.homebeforeshow = function() {
        if (utilization === 5) {
          thisapp.enable_family_sync();
        } else if (utilization === 6) {
          util6.build_header('home');
          thisapp.enable_family_sync();
        } else if (utilization === 8) {
          header.buildDefault();
          thisapp.enable_family_sync();
        }
        else {
          clearInterval(interval_new_fotos);
          interval_new_fotos = '';
        }

        /* START
         * fast switching language button
         * insteead of help in header 2017_02_10 Mhe:
         * */
        if (utilization !== 24) {
          $('#lang_btns_wrap').attr('style', 'display:none;');
          $('.hilfe_home').attr('style', '');
        }

        /* request file system for
         * making photo and video saving work on the first run:
         * */
        if (is_app > 0) {
          // look for new fotos for currently active profile:

          if (utilization === 4) {
            Fotos.show_last_unseen_alert();
          }
        }
        create_hauptmenu();

        if (is_app >= 0) {
          refresh_battery_service();
        }
        translate();
        if (userid === null) userid = 1;
        bind_start_buttons_nav();
        bind_start_buttons();
        video_personal = false;

        if (test_aktiviert) {
          btn_autotest_text = 'Autotest läuft';
          $('#autotest')
            .html(
              '<div onclick="if(test_aktiviert){test_aktiviert=false;clearInterval(testing_interval);$(\'#autotest\').html();}" id="btn_autotest" style="color:red;">' +
              'AUTOTEST beenden</a>'
            );
        }

        if (typeof check_date_interval !== 'undefined' && utilization !== 4){
          clearInterval(check_date_interval);
        }
        if (solo_sound_assi && typeof intervalplayer !== 'undefined') {
          clearInterval(intervalplayer);
        }
      };

      thisapp.homebeforehide = () => {

      };

      thisapp.ready = function() {
        return new Promise(
          (resolve) => {
            add_webpasswort();

            // Check if custom brightness/contrast settings are set:
            Settings.select('settings_brightness', (set) => {
              if (!set) {
                console.log('no brightness level in settings set, OK.');
              } else {
                setBrightness(parseFloat(set));
              }
            });
            Settings.select('settings_contrast', (set) => {
              if (!set) {
                console.log('no contrast level in settings set, OK.');
              } else {
                setContrast(parseFloat(set));
              }
            });
            // set image path of pictures in booktexts properly:
            if (is_app > 0) {
              db.executeSql(
                `UPDATE texts_trans
                  SET content = 
                  REPLACE(content, "<img src=", "<img src=${local_dir}")
                WHERE content
                LIKE "<img src=img%"`,
                [],
                nullHandler,
                (error) => {
                  console.error('SELECT SQL statement ERROR:', error.message);
                }
              );
            }
            console.warn(
              'media menu wrapper cnt',
              $('#media_menu_wrapper').length
            );
            $('#media_menu_wrapper')
              .append(
                '<div class="startoverlay">' +
                /*loading program*/
                (strings[113] || 'Das Programm wird gestartet') +
                '</div>');
            checkConnection();
            refresh_onlinestates();
            resolve();
          }
        );
      };

      thisapp.loading_ts = (now) => {
        now = now
          .replace('-', '')
          .replace('-', '')
          .replace(' ', '')
          .replace(' ', '')
          .replace(':', '')
          .replace(':', '');
        return now;
      };


      thisapp.popup = function(popup_content, popup_btn_text, cb) {
        // only append if not already there
        // (relevantn i.e. triggering "hardware-home-btn") 2017_06_07 mhe
        if ($('#paused_screen').length === 0) {
          $('body')
            .append(`
              <div id="paused_screen">
                <div style="height:60%;">
                  <div style="margin: 5%;text-transform: none;font-size: 2em;">
                    ${popup_content}
                  </div>
                </div>
                <div style="height:40%;">
                  <div id="show_last_msg" style="text-transform:uppercase;">
                    ${popup_btn_text}
                  </div>
                </div>
              </div>`);
        }

        $('#paused_screen').off().on(clickhandler, function(e) {
          e.preventDefault();
          e.stopPropagation();
          $('#paused_screen').off().remove();
          util6.bind_ch_generate_proposal();
          if ($.isFunction(cb)) cb();
        });
      };

      thisapp.popupBetter = (
        popup_content,
        popup_btn_text1 = '',
        popup_btn_text2 = '',
        cb1,
        cb2
      ) => {
        // check if popup_btns are set:
        let popup_btn_1 = '';
        let popup_btn_2 = '';

        if (popup_btn_text1.length > 0) {
          popup_btn_1 = `
            <div id="show_last_msg1" style="text-transform:uppercase;">
              ${popup_btn_text1}
            </div>`;
        }
        if (popup_btn_text2.length > 0) {
          popup_btn_2 = `
            <div id="show_last_msg2" style="text-transform:uppercase;">
              ${popup_btn_text2}
            </div>`;
        }

        if ($('#paused_screen').length === 0) {
          $('body').append(
            `<div id="paused_screen">
              <div style="height:40%;">
                <div style="margin: 5%;text-transform: none;font-size: 2em;">
                  ${popup_content}
                </div>
              </div>
              <div style="height:60%;">
                ${popup_btn_1}
                ${popup_btn_2}
              </div>
            </div>`);
        }

        $('#show_last_msg1').off().on(clickhandler, function(e) {
          e.preventDefault();
          e.stopPropagation();
          $('#paused_screen').off().remove();
          if ($.isFunction(cb1)) cb1();
        });

        $('#show_last_msg2').off().on(clickhandler, function(e) {
          e.preventDefault();
          e.stopPropagation();
          $('#paused_screen').off().remove();
          if ($.isFunction(cb2)) cb2();
        });
      };

      thisapp.system_popup = function(
        popup_content,
        popup_btn_text1 = '',
        cb1,
        popup_btn_text2 = '',
        cb2
        ) {
        // check if popup_btns are set:
        let popup_btn_1 = '';
        let popup_btn_2 = '';

        if (popup_btn_text1.length > 0) {
          popup_btn_1 =
            `<div
              id="popup_option1"
              class="popup_button"
              style="text-transform:uppercase;">
              ${popup_btn_text1}
            </div>`;
        }
        if (popup_btn_text2.length > 0) {
          popup_btn_2 =
            `<div
            id="popup_option2"
            class="popup_button"
            style="text-transform:uppercase;">
            ${popup_btn_text2}
          </div>`;
        }

        let popup_dom =
          `<div id="system_popup">
          <div class="popup_content">
            ${popup_content}
          </div>
          <div class="popup_ui">
            ${popup_btn_1}
            ${popup_btn_2}
          </div>
        </div>`;

        if ($('#system_popup').length === 0) {
          $('body').append(popup_dom);
        }

        $('#popup_option1').off().on(clickhandler, function(e) {
          e.preventDefault();
          e.stopPropagation();
          $('#system_popup').off().remove();
          if ($.isFunction(cb1)) cb1();
        });

        $('#popup_option2').off().on(clickhandler, function(e) {
          e.preventDefault();
          e.stopPropagation();
          $('#system_popup').off().remove();
          if ($.isFunction(cb2)) cb2();
        });
      };
      thisapp.popup_simple = function (popup_content, callbacks, resume) {
        if (typeof resume === 'undefined') resume = false;

        if (resume) {
          $('#paused_screen')
            .html(
              `<div style="height:100%;
                background-image:url('${home_dir}./img/promo/female_senior_and_young_women_with_tablet.jpg');
                background-position: 0px 0px;
                text-transform: none;
                padding-top:90px;
                font-size: 1em;">
              ${popup_content}
              </div>`
            );
        }
        else {
          $('#paused_screen').off().remove();
          if ($('#paused_screen').length === 0) {
            $('body')
              .append(
                `<div id="paused_screen">
                  <div style="height:100%;
                    background-image:url('${home_dir}./img/promo/female_senior_and_young_women_with_tablet.jpg');
                    background-position: 0px 0px;
                    text-transform: none;
                    padding-top:90px;
                    font-size: 1em;">
                    ${popup_content}
                  </div>
                </div>`
              );
          }
        }
        if ($.isFunction(callbacks)) callbacks();
      };

      resolve();
    }
  );
};
