/*jshint esversion: 6 */
/*START STAGING OPTIONS*/
var testing;
var developer;
var logging;
/* USE different verbose logging options:*/
var v = {};
v.usync = false;
v.d = false;
/*staging variable for dev,demo, demo4g or prod*/
var stage = '';
var url = {};
url.content_be = '';
url.relatives_api = '';
url.device_user_api = '';
var api_endpoints = {};
var view = {};
var use_medialist_cache = false;
var location_url = window.location.pathname;
var location_href = window.location.href;

const createCore = () => {
  return new Promise(
    (resolve, reject) => {
      // TODO: move to home:
      core.db_broken_msg = function() {
        controllers.home.bootscreen.render(`
          <span class="akt">wird überprüft.</span><br />
          <h3>Hinweis</h3>
          <div
            style="
                font-size:23px;
                line-height:38px;
                color:#030303;
                text-align:justify;
                width:80%;
                margin:0 auto;">
            Sollte sich das Programm nicht innerhalb
            von 2 Minuten automatisch öffnen, bitten wir Sie,<br>
            das Tablet noch einmal neu zu starten.<br>
            Dafür müssen Sie die Power-Taste des Tablets
            einfach 10 Sekunden gedrückt halten.
          </div>`
        );
      };

      //models:
      core.ajax_error = function (error, err_cb) {
        if (v.d) console.log(error);
        // handle Unauthorized Request:
        if (error.status === 401) {
          console.warn('Token expired');
          Auth.refreshed = 0;
          Auth.authenticate(
            // successHandling:
            function () {
              if (v.d) console.log('successfully refreshed Token');
              if ($.isFunction(err_cb)) err_cb();
            },
            // errorHandling:
            function () {
              if (v.d) console.log('error requesting new Token');
              if ($.isFunction(err_cb)) err_cb();
            }
          );
        } else {
          if (v.usync) console.error(error);
          // Usync.running = false;
          if ($.isFunction(err_cb)) err_cb();
        }
      };

      core.load_models = function() {
        if (is_app < 0) {
          Settings.insert_replace(
            'secret',
            'AkzSUWkk9YTwB5BeugGpqeYg9IEI2ZTu',
            nullHandler
          );
        } else {
          //check selected userid::::::::
          Settings.select('utilization', function (set) {
            if (!set) {
              //write settings:
              Settings.insert('utilization', utilization, nullHandler);
            } else {
              utilization = parseInt(set) || 2;
            }
          });
          Settings.select('selected_userid', function (set) {
            if (!set) {
              Settings.insert('selected_userid', userid, nullHandler);
            } else {
              userid = parseInt(set) || 2;
            }
          });
        }

        let this_fprd = new Family_portal_register_direct();
        this_fprd.send();
        Fotos.check_settings();
        Auth.check(
          function () {
            if (online_media_sources) {
              if (is_app >= 0) {
                local_dir = url.media_base_path + Auth.token + '&filepath=';
              }
              if (!online_media_token_refresh) {
                online_media_token_refresh = setInterval(
                  function () {
                    Auth.check(
                      function () {
                        if (is_app >= 0) {
                          local_dir = url.media_base_path + Auth.token + '&filepath=';
                        }
                      }
                    );
                  },
                  3600 * 1000
                );
              }
            }
          }
        );
      };
      resolve();
    }
  );
};
/*
 * put in CONFIG?????
 * only set true for apk building for sd card install
 */
var conf = {};
conf.sd_install = false;
/*
proper util is selected when db connection is open 2016_12_02 MHe
*/
var utilization = 24;

// set standard userid
sessionStorage.userid = 2;
var userid = 2;
var cur_userid = 1;

/*END STAGING OPTIONS*/

var filestocopy = [];
var filestocopy_dl;

//app directory:
var home_dir = '';
//data directory:
var local_dir = '';
var pictureSource; // picture source
var destinationType; // sets the format of returned value
var new_image_url; // test camera
var users = 3;
var divs = 6;
var displayName = 'media4care_dementia';
var maxSize = 131070;
var cur_div = 1;


// if you like to use localStorage/sessionStorage as cache for db html creating
// requests... saves 8-120ms:
var use_ls_cache = false;

var userlock;
var userlock_id;
// The directory to store data
var store;
// Used for status updates
var $status;
var user_firstname = '';
var user_lastname = '';
var user_prof_img = './img/blank.jpg';
var batterydiv;
var batterystatus;

/*MHe 04.07.2016
switching app content release from just content to new app version + new content
==> changing paths from data directory for html, css and js files to app version specific directory android_asset:
every changed marked as RelChange072016
*/
var source_index = 'file:///android_asset/www/index.html';
//var source_index = 'file:///data/data/com.media4care.dementia/files/index.html';
var built_settings = 0;

// document file download errors to give user instructions about bad internet
// connection:
// negative value to street with the assumption of a good quality connection and
// having a green/positive progress bar:
var download_errors = -0.5;
var app_core_version = '2.0.12';



/*2.0.23 make progress bar globally file size dependend:*/
var update_size = 0;
var update_size_internal = 0;
var available_space = 0;
var transfered_size = 0;
var finished_streams = 0;


var filestocopy_size = [];
var filestocopy_id = [];
var install_errors = 0;
//prod var sdcard_timestamp = '2016-02-11 12:32:46';
var sdcard_timestamp = '2018-03-27 14:03:00';
var using_sdcard = false;

/*2016-03-02 MHe*/
var sd_card_videores = false;
/*2016-03-16 MHe update only on startup, array former in update.js */
var download_start_time = new Date().getTime();

/*
SOLO MODUS TEST END
*/
//is being checked with every stat upload request - controller/statistics.js
var thisapp_online = false;

function sqlquery(query) {
  let starttime = new Date().getTime();
  db.transaction(
    function(tx){
      tx.executeSql(
        query,
        [],
        function(tx, rs) {
          let table = [];
          for (let i = 0; i < rs.rows.length; i++) {
            let row = rs.rows.item(i);
            table.push(row);
          }
          console.table(table);
          let endtime = new Date().getTime();
          let took = endtime - starttime;
          console.log('executing + logging took ' + took + 'ms');
        },
        function(error) {
          console.error('SELECT SQL statement ERROR: ' + error.message);
        }
      );
    }
  );
};
const sql = sqlquery;
function sqlqueryn(query)
{
    let starttime = new Date().getTime();
    db.executeSql(
        query,
        [],
        function(tx, rs)
        {
            let table = []
               for (let i = 0; i < rs.rows.length; i++)
            {
                let row = rs.rows.item(i);
                table.push(row)
            }
            console.table(table);
            let endtime = new Date().getTime();
            let took = endtime - starttime;
            console.log('executing + logging took ' + took + 'ms');
          },
          function(error)
          {
            console.error('SELECT SQL statement ERROR: ' + error.message);
          }
      );
};
function reenable_wifi(rw_callback, gt_callback)
{
    if(device.model === 'LIFETAB_P1034X')
    {
        if(typeof WifiWizard !== 'undefined')
        {
            WifiWizard.setWifiEnabled
            (
                false,
                function(doff)
                {
                    WifiWizard.setWifiEnabled
                    (
                        true,
                        function(don)
                        {
                            if($.isFunction(rw_callback))
                                rw_callback(gt_callback);
                        },
                        errorHandler);
                },
                errorHandler
            );
        }
    }
    else
    {
        if($.isFunction(rw_callback)) rw_callback(gt_callback);
    }
}

function fail(error) {
  Sentry.captureException(error);
}

var try_conn = 0;




function get_token(load_home)
{
    /* just try update, if storage is not sufficient it will be checked in prepare_download()  */
    check_update_api(load_home, 0);
}


function updatesync()
{
  let datetime_now = get_datetime_now();
  db.transaction(
    function(tx) {
      tx.executeSql(
        `INSERT OR REPLACE INTO settings
          (name,setting,edit_datetime)
          values('sync_action_file_create', ?, ?)`,
        [
          lastsync_dl_cache,
          datetime_now,
        ],
        nullHandler,
        errorHandler
      );
    }
  );
}

function errorHandler(transaction, error) {
  Sentry.captureException(transaction);
  if (error) {
    Sentry.captureException(error);
  }
  return true;
}
function nullHandler() {
}
