var Files = {};

Files.remove = function(fileUrlToRemove) {
  window.resolveLocalFileSystemURL(
    fileUrlToRemove,
    function(entry) {
      entry.remove(
        function(suc) {
        },
        nullHandler
      );
    },
    () => {
      console.error('ERROR resolving file', fileUrlToRemove);
    }
  );
};

Files.resolve = function(urlToResolve, successCb, errorCb) {
  if ($.isFunction(errorCb) === false) {
    errorCb = (err) => {
      console.error(err);
    };
  }
  window.resolveLocalFileSystemURL(
    urlToResolve,
    function(fs) {
      if ($.isFunction(successCb)) successCb(fs);
    },
    errorCb
  );
};

Files.resolvePromise = (urlToResolve) => {
  return new Promise(
    (resolve, reject) => {
      Files.resolve(urlToResolve, resolve, reject);
    }
  );
};

Files.copy = Object.freeze(
  {
    CONSTANTS: Object.freeze({
      'STATUS': Object.freeze({
        'SUCCESS': 0,
        'SUCCESS_NO_COPY': 1,
        'ERROR_TARGET_RESOLVE': 2,
        'COPY_FAILED': 3,
      }),
    }),

    to: function(srcFile, destDir, destFilename) {
      return new Promise(
        (resolve, reject) => {
          const result = (results) => {
            return Object.assign(
              {
                'destDir': destDir,
                'destFilename': destFilename,
              },
              results
            );
          };
          console.log('in copy process', srcFile, destDir, destFilename);
          if (srcFile.nativeURL === (destDir + destFilename)) {
            resolve(
              result({
                'status': Files.copy.CONSTANTS.STATUS.SUCCESS_NO_COPY,
              })
            );
          }
          window.resolveLocalFileSystemURL(
            destDir,
            (destDirEntry) => {
              srcFile.copyTo(
                destDirEntry,
                destFilename,
                () => {
                  resolve(
                    result({
                      'status': Files.copy.CONSTANTS.STATUS.SUCCESS,
                    })
                  );
                },
                () => {
                  reject(
                    result({
                      'status': Files.copy.CONSTANTS.STATUS.COPY_FAILED,
                    })
                  );
                }
              );
            },
            () => {
              reject(
                result({
                  'status': Files.copy.CONSTANTS.STATUS.ERROR_TARGET_RESOLVE,
                })
              );
            }
          );
        }
      );
    },

  }
);

Files.getMd5 = (fileEntry) => {
  return new Promise(
    (resolve, reject) => {
      md5chksum.file(
        fileEntry,
        resolve,
        reject
      );
    }
  );
};

Files.get_md5 = function(fileSystem, successCb, errorCb, option) {
  md5chksum.file(
    fileSystem,
    function(md5Local) {
      if ($.isFunction(successCb)) {
        successCb(md5Local, option);
      }
    },
    (err) => {
      if ($.isFunction(errorCb)) errorCb(err);
    }
  );
};

Files.directory = Object.freeze({
  make: (baseDir = '', folderToCreate = '') => {
    return new Promise(
      (resolve, reject) => {
        Files.resolve(
          baseDir,
          (entry) => {
            return entry.getDirectory(
              folderToCreate + '/',
              {
                create: true,
                exclusive: false,
              },
              () => {
                resolve(entry.nativeURL + folderToCreate + '/');
              },
              (err) => {
                // no writing rights?
                if (err.code === 12) {
                  reject(baseDir + folderToCreate + '/');
                } else {
                  reject('error getDiretory');
                }
              }
            );
          },
          (err) => {
            console.debug('error in create Dir', baseDir, folderToCreate);
            console.debug(err);
          }
        );
      }
    );
  },
  // Creates a folder outside application directory
  create_external: (dir_name = '') => {
    return new Promise(
      (resolve, reject) => {
        window.requestFileSystem(
          LocalFileSystem.PERSISTENT, 0,
          function(fileSys) {
            fileSys.root.getDirectory(dir_name,
              {create:true, exclusive: false},
              resolve,
              (err) => {
                console.error(err);
                reject(err);
              }
            );
          }
        );
      }
    );
  },
  remove: (directoryPath = '') => {
    return new Promise(
      (resolve, reject) => {
        if (directoryPath === '') {
          resolve();
        }
        Files.resolvePromise(directoryPath)
          .then((fs) => {
            fs.removeRecursively(
              resolve,
              (err) => {
                console.error(err);
                reject(err);
              }
            );
          });
      }
    );
  },
  getContentList: (directoryPath = 'file:///') => {
    return new Promise(
      (resolve, reject) => {
        window.resolveLocalFileSystemURL(
          directoryPath,
          function(fs) {
            fs.createReader().readEntries(
              resolve,
              reject
            );
          },
          reject
        );
      }
    );
  },

});

Files.path = Object.freeze({
  make: (baseDir = '', pathToAppend = '') => {
    return pathToAppend.split('/').reduce(
      (p, x) => {
        return p
          .then(
            (res) => {
              return Files.directory.make(res, x);
            }
          )
          .catch(
            (err) => {
              console.error('error in Files.path.make:', err);
            }
          );
      },
      Promise.resolve(baseDir)
    );
  },
});

Files.getFilenameFromPath = (path = '') => {
  return path.slice(path.lastIndexOf('/') + 1);
};

Files.getFolderPathFromUrl = (path = '') => {
  return path.slice(0, path.lastIndexOf(('/')) + 1);
};


Files.getSingleFile = function(filePath) {
  return new Promise((resolve, reject) => {
    window.resolveLocalFileSystemURL(
      filePath,
      (entry) => {
        entry.file(resolve, reject);
      },
      reject
    );
  });
};

Files.convertToBlob = function(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      if (reader.error) {
        reject(reader.error);
      } else {
        const blob = new Blob([reader.result], {type: file.type});
        blob.lastModifiedDate = new Date();
        blob.name = file.name;
        resolve(blob);
      }
    };

    reader.readAsArrayBuffer(file);
  });
};

Files.save = function(path, blob) {
  return new Promise((resolve, reject) => {
    Files.resolve(cordova.file.externalRootDirectory, function(entry) {
      entry.getFile(path, {create: true, exclusive: false}, function(fileEntry) {
        fileEntry.createWriter(function(writer) {
          writer.onwriteend = function() {
            resolve(fileEntry);
          };

          writer.onerror = function(e) {
            reject(e);
          };

          writer.write(blob);
        }, reject);
      }, reject);
    });
  });
};

Files.copy_to_sdcard = function(fromFileEntry, toFileEntry) {
  return new Promise((resolve, reject) => {
    window.resolveLocalFileSystemURL(
      cordova.file.applicationDirectory + fromFileEntry,
      function(fileEntry) {
        window.requestFileSystem(
          LocalFileSystem.PERSISTENT,
          0,
          function(fileSys) {
            fileEntry.copyTo(fileSys.root, toFileEntry,
              function(res){
                console.log('copying was successful to: ' + res.nativeURL);
              },
              function(err){
                console.log('copying unsuccessful.');
              }
            );
          }
        );
      },
      reject);
  });
};
