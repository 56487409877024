const createApiChat = () => {
  return new Promise(
    (resolve, reject) => {
      window.api_chat =
        {
          host: api_endpoints.videochat,
          // host:   'http://127.0.0.1:8088',
          // chat_token: '',
          // room_token: '',
          token: '',
          identity: '',
          roomSid: '',
          ajax_set:
            {
              'async': true,
              'crossDomain': true,
              'method': 'POST',
              'headers':
                {
                  'Token': Auth.token,
                  'Content-Type': 'application/json',
                },
              'processData': false
            },
          get_token: function (identity) {
            console.log('executing api_chat get_token');
            api_chat.ajax_set.url = `${api_chat.host}v1/token/`;
            api_chat.ajax_set.data = JSON.stringify({identity: identity});
            $.ajax(api_chat.ajax_set).done
            (
              function (response) {
                if (v.d) console.log(response);
                api_chat.token = response.token;
                api_chat.identity = response.identity;
              }
            );
          },
          create_room: function (roomname) {
            api_chat.ajax_set.url = `${api_chat.host}v1/video/room/`;
            api_chat.ajax_set.data = JSON.stringify
            (
              {
                name: roomname,
                identity: api_chat.identity
              }
            );
            $.ajax(api_chat.ajax_set).done
            (
              function (response) {
                if (v.d) console.log(response);
                api_chat.roomSid = response.roomSid;
                api_chat.token = response.token;
              }
            );
          },
        };
      resolve();
    }
  );
};
// fix for chrome 48
// https://github.com/twilio/twilio-video.js/issues/112
// if (window.webkitRTCPeerConnection) {
//   ['createOffer', 'createAnswer'].forEach(function(method) {
//     var nativeMethod = window.webkitRTCPeerConnection.prototype[method];
//     window.webkitRTCPeerConnection.prototype[method] = function() {
//       var self = this;
//       if (arguments.length < 1 || (arguments.length === 1 &&
//         typeof arguments[0] === 'object')) {
//         var opts = arguments.length === 1 ? arguments[0] : undefined;
//         return new Promise(function(resolve, reject) {
//           nativeMethod.apply(self, [resolve, reject, opts]);
//         });
//       }
//       return nativeMethod.apply(this, arguments);
//     };
//   });
// }


// api_chat.get_token();
