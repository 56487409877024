class TimelineService {
  static get intervalTime() {
    return 35 * 1000;
  }

  getUserMedia() {
    const DEVICE_USER_API_URL = `${url.device_user_api}/ask_for_new_user_media`;

    return promisify(Auth.check)
      .then(Timeline.getLastInsertedDate)
      .then((lasted_edit) => axios.post(DEVICE_USER_API_URL, {}, {
        headers: {
          Token: Auth.token,
          Cloud_db_last_edit: lasted_edit,
        },
      }))
      .then(this.updateTimeline)
      .then(Timeline.getDownloadPending)
      .then(this.downloadMedia)
      .then(this.removeToDelete);
  }

  updateTimeline({data}) {
    const {shouldRemove, create, destroy} = Timeline;
    const promises = (data || [])
      .map((item) => shouldRemove(item) ? destroy(item) : create(item));

    return Promise.all(promises);
  }

  removeToDelete() {
    return Timeline.getToDelete()
      .then((items) => items
        .forEach((item) => {
          item.url && Files.remove(item.url);
          item.setStatus(TIMELINE_STATUS_DELETED);
        })
      );
  }

  downloadMedia(items) {
    const DOWNLOAD_URL = `${url.storage_api}/download`;

    const queue = items.map((item) => {
      const headers = {
        Token: Auth.token,
        Hash: item.hash,
      };

      return axios.get(DOWNLOAD_URL, {
        headers,
        responseType: 'arraybuffer',
        timeout: 30000,
      })
        .then((response) => {
          const spark = new SparkMD5.ArrayBuffer();
          const path = `media4carefotos/${(new Date()).getTime()}.jpg`;
          const file = new Blob([response.data]);

          spark.append(response.data);

          if (response.headers.md5 !== spark.end()) {
            throw Error('MD5 Checksum Fail');
          }

          return Files.save(path, file);
        })
        .then((fileEntry) => {
          item.setURL(fileEntry.toURL());
        })
        .catch(console.error);
    });

    return Promise.all(queue);
  }

  pull() {
    const PULL_URL = `${url.device_user_api}/get_user_fotostable`;

    return promisify(Auth.check)
      .then(() => axios.post(PULL_URL, {}, {
        headers: {
          Token: Auth.token,
        },
      }))
      .then((response) => {
        const data = (response.data || []).filter((item) => item.type === 5);
        return this.updateTimeline({data});
      })
      .then(Timeline.getDownloadPending)
      .then(this.downloadMedia);
  }

  run() {
    setInterval(TimelineService.intervalTime, () => {
      this.getUserMedia();
    });
  }
}

window.timelineService = new TimelineService();
