function checkTime(i) {
  if (i < 10) {
    i = "0" + i;
  }
  // add zero in front of numbers < 10
  return i;
}
// start screen clock:
function startTime() {
  let today = new Date();
  let ye = today.getUTCFullYear();
  let mo = today.getMonth();
  let d = today.getDay();
  let da = today.getDate();
  let h = today.getHours();
  let m = today.getMinutes();
  let s = today.getSeconds();
  let days = [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag'
  ];
  let months = ['1', '2', '3', '4', '5', '6', '7','8','9','10','11','12'];
  m = checkTime(m);
  s = checkTime(s);
  $('.uhrzeit').html(h + ":" + m);
  if( utilization === 6 )$( '.clockdate').html(h + ":" + m + " - " + days[d] );

  let t = setTimeout(function() {
    startTime();
  }, 500);
}