var onboarding = {};

onboarding.step = 1;
onboarding.pageCounter = 0;
onboarding.alreadyFocus = false;
onboarding.template = false;
let screenUpStatus = false;
// ONBOARDING Templates

// SENIOR Onboarding:
// This page is for the informations about the Senior
var onboarding_templ_1 =
  `<div class="grid-container">
    <div class="row overlay--main-col--greeting">
          <div class="col col-2">
            <img class="overlay--main-col--greeting-img" 
            src="./img/fam_senior.png" />
          </div>
          <div class="col col-10">
            <h2 class="overlay--main-col--greeting-heading-twolines">
                Bitte geben Sie hier den Namen des Seniors ein</br>
                für den das Tablet bestimmt ist
            </h2>
          </div>
    </div>
    <div class="row">
     <div class="keyboard-off-focusout" style="display: none;">&nbsp;</div>
        <div class="col col-6">
          <h3 class="col--heading-sm">Vorname Senior:</h3>
          <input type="text" 
          class="overlay--main-col--input input-large to_strech_upper" 
          id="sps_firstname" onchange="User.firstname_update( $(this).val() )" 
          placeholder="Vorname" value="" />
        </div>
        <div class="col col-6">
          <h3 class="col--heading-sm">Nachname Senior:</h3>
          <input type="text" 
          class="overlay--main-col--input input-large to_strech_upper" 
          id="sps_lastname" 
          onchange="User.lastname_update( $(this).val() )" 
          placeholder="Nachname" input-medium value="" />
        </div>
    </div>
    <div class="row">
      <div class="col col-12">
        <div class="message--error-info" id="onboarding_error_name"></div>
      </div>
    </div>
    <div class="row">
      <div class="col col-8">
      </div>
      <div class="col col-4">
        <div id="onboarding--next" class="overlay--main-col--next-screenup">
        Weiter</div>
      </div>
    </div>
  </div>`;

// RELATIVE Invitation:
// This page is for getting email address of relative
// then use it to send invitation to relative
var onboarding_templ_2 =
  `<div class="grid-container">
    <div class="row overlay--main-col--greeting">
          <div class="keyboard-off-focusout" style="display: none;">&nbsp;</div>
          <div class="col col-2">
            <img class="overlay--main-col--greeting-img" 
            src="./img/fam_portal.png" />
          </div>
          <div class="col col-10">
            <h2 class="overlay--main-col--greeting-heading-twolines">
            Bitte geben Sie zur Einladung zum Familien-Portals die E-Mail 
            Adresse eines Angehörigen ein
            </h2>
          </div>
    </div>
    <div class="row">
     <div class="keyboard-off-focusout" style="display: none;">&nbsp;</div>
        <div class="col col-6">
          <h3 class="col--heading-sm">Vorname Angehöriger:</h3>
          <input type="text" 
          class="overlay--main-col--input input-large to_strech_upper" 
          id="rel_firstname" placeholder="Vorname" value="" />
        </div>
        <div class="col col-6">
          <h3 class="col--heading-sm">Nachname Angehöriger:</h3>
          <input type="text" 
          class="overlay--main-col--input input-large to_strech_upper" 
          id="rel_lastname" placeholder="Nachname" input-medium value="" />
        </div>
    </div>
    <div class="row">
       <div class="col col-12">
         <div class="message--error-info" id="onboarding_error_upper"></div>
       </div>
    </div>
    <div class="row">
      <div class="col col-6 overlay--main-col--input-group" id="">
        <h3 class="col--heading-sm">E-Mail-Adresse eines Angehörigen:</h3>
        <div class="col col-12">
          <input type="email" 
          class="overlay--main-col--input input-large to_strech_lower" 
          id="onboarding_mail_invit" data-user-id="" 
          placeholder="ihre@email.de" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col col-12">
        <div class="message--error-info" id="onboarding_error_lower"></div>
      </div>
    </div>
    <div class="row">
      <div class="col col-8">
      </div>
      <div class="col col-4">
        <div id="onboarding--next" class="overlay--main-col--next-screenup">
            weiter
        </div>
      </div>
    </div>
      <div class="overlay_focus_up_next_box">
        <div class="overlay--main-col--focus-inner">
          <div id="onboarding-overlay-next-hidden" 
          class="overlay--main-col--next-focus">weiter</div>
        </div>
      </div>
  </div>`;

onboarding.onboardingTemplate = function( step ) {
  console.log('[onboarding] onboarding_template: ' + step);
  // make sure to reset paused_screen top position:
  $('#paused_screen').attr('style','');

  let content = '';
  let btn_text = 'weiter';

  onboarding_next = `<div id="onboarding--next" 
    class="overlay--main-col--next-right">${btn_text}</div>`;

  if ( step === 1 ) {
    content = onboarding_templ_1;
  } else if ( step === 2) {
    content = onboarding_templ_2;
  }

  if( $('#paused_screen').length <= 0 ) {
    $('body').append(`
    <div id="paused_screen">
        <div class="overlay--main-col">
            ${content}
        </div>`);
  } else {
    $('#paused_screen').html(`
        <div class="overlay--main-col">
          ${content}
        </div>`);
  }

  // Move the overlay up when screen keyboard/android keyboard is activated
  $('.to_strech_upper').focus(function(){
    onboarding.focus_screen_up(this.id,true);
    $(".overlay_focus_up_next_box").show();
  });
  $('.to_strech_upper').focusout(function(){
    onboarding.focus_screen_up(this.id,false);
    $(".overlay_focus_up_next_box").hide();
  });
  $('.to_strech_lower').focus(function(){
    onboarding.focus_screen_up(this.id,true);
    $(".overlay_focus_up_next_box").show();
  });
  $('.to_strech_lower').focusout(function(){
    onboarding.focus_screen_up(this.id,false);
    $(".overlay_focus_up_next_box").hide();
  });

  // ONBOARDING button bindings
  $('#onboarding--next').off()
    .on(clickhandler_prev, function(e){
      e.preventDefault();
      e.stopPropagation();
      $(this).attr('style', '-webkit-transform: scale3d(0.9, 0.9, 1);-webkit-transition-duration: 0.1s;transition-duration: 0.1s;');
    })
    .on(clickhandler, function(e){
      e.preventDefault();
      e.stopPropagation();
      $(this).attr('style', '');
      onboarding.onboarding_control();
    });
  $('#onboarding--next-hidden').off()
    .on(clickhandler_prev, function(e){
      e.preventDefault();
      e.stopPropagation();
      $(this).attr('style', '-webkit-transform: scale3d(0.9, 0.9, 1);-webkit-transition-duration: 0.1s;transition-duration: 0.1s;');
    })
    .on(clickhandler, function(e){
      e.preventDefault();
      e.stopPropagation();
      $(this).attr('style', '');
      onboarding.focus_screen_up(null,false);
      onboarding.onboarding_control();
    });
  $('#onboarding-overlay-next-hidden').off()
    .on(clickhandler_prev, function(e){
      e.preventDefault();
      e.stopPropagation();
      $(this).attr('style', '-webkit-transform: scale3d(0.9, 0.9, 1);-webkit-transition-duration: 0.1s; transition-duration: 0.1s;');
    })
    .on(clickhandler, function(e){
      e.preventDefault();
      e.stopPropagation();
      $(this).attr('style', '');
      onboarding.focus_screen_up(null, false);
      onboarding.onboarding_control();
    });
};

// ONBOARDING main control:
onboarding.onboarding_control = function(close_popup){
  var nextPageAllow = false;
  console.log('[onboarding] Onboarding control function');
  // Array for the error messages
  var onboarding_error_messages =
    [
      'Bitte geben sie einen Vornamen mit mindestens drei Buchstaben ein. ',
      'Bitte geben sie einen Nachnamen mit mindestens drei Buchstaben ein. ',
      'Bitte Ihre richtige Email eingeben. '
    ];
  console.log('[onboarding] Step before switch statement ' + onboarding.step );

  // Form checks and validation are done in this switch case
  // Validation is done when stepped to next step, so case 1 is for step 0
  // You check screen 0 of onboarding in case one of switch statement …
  switch ( onboarding.step ) {
    /* eslint-disable indent */
    case 1:
      // ONBOARDING senior screen, firstname
      // & lastname of senior will be set here
      lang = 'de';
      getLanguageArray(lang);
      console.log('[onboarding] Step: ' + onboarding.step
        + ' language changed to: ' + lang);
      Settings.insert_replace('language', lang);
      console.log('[onboarding] Step 2: ' + onboarding.step );
      let firstname = $('#sps_firstname').val();
      let lastname = $('#sps_lastname').val();
      let check_name = User.validate_name( firstname, lastname );
      console.log('[onboarding] This is the nameArray: ' + check_name );
      // Create Template for step
      console.log('[onboarding] +++ Before All checks +++ '
        + onboarding.pageCounter);
      if ( onboarding.pageCounter == 0 ) {
        onboarding.onboardingTemplate(onboarding.step);
        onboarding.template = true;
      }
      // Form check after clicking on next
      // Or after beeing here for one time at least
      if ( onboarding.pageCounter >= 1 ) {
        if ( !onboarding.alreadyFocus && firstname == '' && lastname == '') {
          $('#sps_firstname').trigger('focus');
          onboarding.alreadyFocus = true;
        } else {
          $('#onboarding_error_name').html('');
          let errorOutput = '';
          if ( !check_name[0] ) {
            $('#sps_firstname').addClass('form-field-error');
            errorOutput += onboarding_error_messages[0];
          } else {
            $('#sps_firstname').removeClass('form-field-error');
          }
          if ( !check_name[1] ) {
            $('#sps_lastname').addClass('form-field-error');
            errorOutput += onboarding_error_messages[1];
          } else {
            $('#sps_lastname').removeClass('form-field-error');
          }
          if ( check_name[0] && check_name[1] ) {
            console.log('[onboarding] All checks good in step '
              + onboarding.step + ' '+ check_name );
            // this function is used to update the RC name.
            User.name_update(firstname, lastname);
            api_msg.updateName(`${firstname} ${lastname}`);
            nextPageAllow = true;
            User.senior_address_update();
            errorOutput = '';
          }
          $('#onboarding_error_name').html( errorOutput );
        }
      }
      onboarding.pageCounter++;
      console.log('[onboarding] +++ After All checks +++ '
        + onboarding.pageCounter);
      // how many times have we clicked next on this page?
      // Next Page allowed? Yes, reset important vars and create next step
      if ( nextPageAllow ) {
        nextPageAllow = false;
        onboarding.pageCounter = 1;
        onboarding.alreadyFocus = false;
        onboarding.step++;
        User.name_update(firstname, lastname);
        api_msg.updateName(`${firstname} ${lastname}`);
        screenUpStatus = false;
        onboarding.onboardingTemplate( onboarding.step );
      }
      break;
    case 2:
      // Onboarding relative screen, email address for relative will be set here
      let relativeFirstname = $( '#rel_firstname').val();
      let firstnameCheck = Invite.validate_firstname(relativeFirstname);
      let relativeLastname = $( '#rel_lastname').val();
      let lastnameCheck = Invite.validate_lastname(relativeLastname);
      let relativeMail = $( '#onboarding_mail_invit').val();
      let mailCheck = Invite.validate_email( relativeMail );

      if ( onboarding.pageCounter >= 1 ) {
        console.log('[onboarding] we have been here '
          + onboarding.pageCounter + ' time' );
        if ( !onboarding.alreadyFocus && relativeFirstname == '' ) {
          $('#rel_firstname').trigger('focus');
          onboarding.alreadyFocus = true;
        } else {
          $('#onboarding_error_lower').html('');
          let errorOutputUpper = '';
          let errorOutputLower = '';
          if ( !firstnameCheck ) {
            $('#rel_firstname').addClass('form-field-error');
            errorOutputUpper += onboarding_error_messages[0];
          } else {
            $('#rel_firstname').removeClass('form-field-error');
          }
          if ( !lastnameCheck ) {
            $('#rel_lastname').addClass('form-field-error');
            errorOutputUpper += onboarding_error_messages[1];
          } else {
            $('#rel_lastname').removeClass('form-field-error');
          }
          if ( !mailCheck ) {
            $('#onboarding_mail_invit').addClass('form-field-error');
            errorOutputLower = onboarding_error_messages[2];
          } else {
            $('#onboarding_mail_invit').removeClass('form-field-error');
          }
          $('#onboarding_error_upper').html( errorOutputUpper );
          $('#onboarding_error_lower').html( errorOutputLower );
        }
        if ( firstnameCheck && lastnameCheck && mailCheck ) {
          nextPageAllow = true;
          Invite.new( relativeMail ,
            User.userid,
            nullHandler,
            nullHandler,
            nullHandler,
            '',
            '',
            '',
            '',
            '');
          console.log('[onboarding] +++ Invitation here +++ '
            + onboarding.pageCounter);
        }
      }
      onboarding.pageCounter++;
      console.log('[onboarding] +++ After All checks +++ '
        + onboarding.pageCounter);
      if ( nextPageAllow )
      {
        screenUpStatus = false;
        $('.to_strech_upper').blur();
        $('.to_strech_lower').blur();
        $('.overlay_focus_up_next_box').hide();
        Settings.insert('util6_onboarding' + userid.toString(),
          'done',
          nullHandler
        );
        onboarding.step = 1;
        onboarding.pageCounter = 0;
        $('#paused_screen').off().remove();
      }
      break;
    default:
      if ( close_popup ) $('#paused_screen').off().remove();
    /* eslint-enable indent */
  }
};

onboarding.focus_screen_up = function( item, up_down )
{
  if( up_down && !screenUpStatus )
  {
    screenUpStatus = true;
    var element_offset = 210;
    screen_up_last_item = item;
    if(v.d)console.log("-- this item -- " + screen_up_last_item +
      "Screen Status: " + screenUpStatus );
    $( "#paused_screen" ).offset({ top: - element_offset });
  }
  if( !up_down && screenUpStatus )
  {
    screenUpStatus = false;
    $( "#paused_screen" ).attr( "style", "" );
    $( '#' + screen_up_last_item ).blur();
    if(v.d)console.log("-- this item BLUR -- " + screen_up_last_item +
      "Screen Status: " + screenUpStatus );
    screen_up_last_item = "";
  }
};
