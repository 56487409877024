const createMessagesModel = () => {
  return new Promise(
    (resolve, reject) => {
      window.Messages = {
        /**
         * Initialize the Messages database.
         *
         * If the database already exists it checks the version of the Message
         * table and updates the table structure, depending
         * on the current version of the table.
         */
        init: function() {
          let msg_ver = 2;
          let creation_query = `CREATE
              TABLE
                IF NOT EXISTS Messages(
                  id INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
                  rocket_chat_id TEXT NOT NULL,
                  sender_account_id INT NOT NULL,
                  receipient_account_id INT NOT NULL,
                  prof_id INT NOT NULL,
                  cust_id INT NOT NULL,
                  content TEXT NOT NULL,
                  status INT NOT NULL,
                  receiving TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
                  last_edit TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
                  on_client_created TIMESTAMP NOT NULL DEFAULT '',
                  unique(
                    rocket_chat_id,
                    cust_id,
                    prof_id,
                    content,
                    on_client_created),
                  unique(
                    sender_account_id,
                    receipient_account_id,
                    content,
                    receiving
                  ),
                  FOREIGN KEY(prof_id) REFERENCES User(prof_id),
                  FOREIGN KEY(cust_id) REFERENCES Family_relations(cust_id)
                )`;
          /**
           * The Message how we store it with all the metadata.
           * @typedef {Object} Message
           * @property {number} id - Id of the message in the local Message db.
           * @property {string} content - The message body.
           * @property {number} cust_id - The id of the customer i.e. the "junior"
           * @property {string} last_edit - The last the time the message was updated.
           * @property {string} on_client_created - When the message was created on
           *  the client, this means either tablet or family portal.
           * @property {number} prof_id - The id of the tablet profile .i.e. "senior"
           * @property {number} receipient_account_id - The id of the recipient.
           * @property {string} receiving - When the message was received.
           *  @todo(peter): Receiving where? on server or client?
           * @property {string} rocket_chat_id - The id of the message in RocketChat,
           * if the message was not in sent to RocketChat than this value is "unsend".
           * @property {number} sender_account_id - The sender of the message.
           * @property {number} status -  1 = unsent,
           *                              2 = synced (has rocket_chat_id),
           *                              3 = message read
           */

          helper_db.get_table_version('Messages').then(
            function(version) {
              // Create table if not exists.
              if (version===0) {
                db.executeSql(
                  creation_query,
                  [],
                  function(tx, res) {
                    helper_db.set_table_version(
                      'Messages', msg_ver, 'Remove unique key constraint and add "on_client_created" col.');
                  },
                  errorHandler
                );
                // Modify table if version===1/(table exists but was never modified).
              } else if (version === 1) {
                // The following code is the creation stmt of the Version 1 Messages.
                //   `CREATE TABLE IF NOT EXISTS Messages
                // (
                //   id INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
                //   rocket_chat_id TEXT NOT NULL,
                //   sender_account_id INT NOT NULL,
                //   receipient_account_id INT NOT NULL,
                //   prof_id INT NOT NULL,
                //   cust_id INT NOT NULL,
                //   content TEXT NOT NULL,
                // ${''/* status:
                //   1 = unsend,
                //   2 = synced (has rocket_chat_id),
                //   3 = message read */}
                //   status INT NOT NULL,
                //   receiving TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
                //   last_edit TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
                //   unique(rocket_chat_id, cust_id, prof_id, content),
                //   unique(sender_account_id, receipient_account_id, content, receiving),
                //   FOREIGN KEY(prof_id) REFERENCES User(prof_id),
                //   FOREIGN KEY(cust_id) REFERENCES Family_relations(cust_id)
                // )`
                let columns_2_transfer = `rocket_chat_id,
                                    sender_account_id,
                                    receipient_account_id,
                                    prof_id,
                                    cust_id,
                                    content,
                                    status,
                                    receiving,
                                    last_edit`;
                if (db.hasOwnProperty('sqlBatch')) {
                  db.sqlBatch([
                    'ALTER TABLE Messages RENAME TO old_Messages',
                    creation_query,
                    `INSERT INTO
                      Messages(${columns_2_transfer})
                     SELECT ${columns_2_transfer} FROM old_Messages`,
                    'DROP TABLE old_Messages',
                  ],
                  function() {
                    helper_db.set_table_version(
                      'Messages',
                      msg_ver,
                      'Remove unique key constraint and add "on_client_created" col.',
                    );
                    console.log('Populated new version of Messages table.');
                  },
                  function(error) {
                    console.log('SQL batch ERROR: ' + error.message);
                  }
                  );
                }
              } else {
                // for newer version you can add more else if cases.
                // than please update the helper_db
              }
            }
          );
        },
        /**
         * Returns the number of unread messages of the profile with `prof_id`.
         * @param {number} prof_id - Id of a tablet profile.
         * @return {Promise<any>}
         */
        countUnreadMsg: function(prof_id) {
          return new Promise((resolve, reject) => {
            db.executeSql(
              `SELECT
          COUNT(m.id) amount
        FROM
          Messages m
        WHERE
          m.status != 3
        AND
          m.prof_id = ?
        AND
          m.receipient_account_id = ?
        AND
          m.content NOT LIKE "#TwVideo:%"
        AND m.sender_account_id NOT IN (
          SELECT fr.cust_id
            FROM 
              Family_relations fr 
            LEFT JOIN 
              invitations_v3 i 
            ON 
              i.inv_mail = fr.email
            WHERE 
              fr.prof_id = ? 
            AND
              i.inv_status=4
        )`,
              [prof_id, prof_id, prof_id],
              (res) => resolve(res.rows.item(0).amount),
              reject
            );
          });
        },
        /**
         * Returns the number of missed calls of the profile with `prof_id`.
         * @param {number} prof_id - Id of a tablet profile.
         * @return {Promise<any>}
         */
        countMissedVideoCalls: function(prof_id) {
          return new Promise((resolve, reject) => {
            db.executeSql(
              `SELECT
          COUNT(id) amount
        FROM
          Messages
        WHERE
          status != 3
        AND
          prof_id = ?
        AND
          receipient_account_id = ?
        AND
          content LIKE "#TwVideo:%"`,
              [prof_id, prof_id],
              (res) => resolve(res.rows.item(0).amount),
              reject
            );
          });
        },
        /**
         * Get all messages of the current user that have not yet been sent.
         * @return {Promise<Message[]>} An array of unsent messages.
         */
        selectAllUnsentCurrUser: function() {
          return new Promise((resolve, reject) => {
            db.executeSql(
              `SELECT * from Messages where 
          rocket_chat_id = 'unsend'
          AND prof_id = ?
          order by receiving desc`,
              [User.prof_id],
              (res) => {
                let data = [];
                for (let i = 0; i < res.rows.length; i++) {
                  data[i] = res.rows.item(i);
                }
                resolve(data);
              },
              reject
            );
          });
        },
        /**
         * Select max limit of messages, between current user and customer with
         * cust_id.
         * @param {number} limit - Max count of selected messages.
         * @param {number} cust_id - Id of a customer.
         * @return {Promise<any>}
         */
        selectByActiveUserCust: function(limit = 50, cust_id=0) {
          return new Promise((resolve, reject) => {
            db.executeSql(
              `SELECT M.*, Fr.* FROM Messages M
              LEFT JOIN
              Family_relations Fr
                  ON Fr.cust_id = M.cust_id
                  AND Fr.prof_id = M.prof_id
              WHERE M.prof_id = ?
              AND M.cust_id = ?
              AND M.content NOT LIKE "#TwVideo:%"
              ${/* GROUP BY M.sender_account_id*/''}
              ORDER BY M.receiving desc
              LIMIT ?`,
              [User.prof_id, cust_id, limit],
              (res) => {
                let data = [];
                for (let i = 0; i < res.rows.length; i++) {
                  data[i] = res.rows.item(i);
                  data[i].name = `${data[i].prename} ${data[i].lastname}`;
                }
                resolve(data);
              },
              reject
            );
          });
        },
        /**
         * Updates `status` and `rocket_chat_id` of the message
         * with the properties of `content`, `sender_account_id`
         * and `on_client_created`.
         * @param {string} content - The content of message which should be updated.
         * @param {number} sender_account_id - The id of the message sender.
         * @param {number} status - The status of the message:
         *                          1 = unsend,
         *                          2 = synced (has rocket_chat_id),
         *                          3 = message read
         * @param {string} rocket_chat_id - The rocket_chat_id which should be set.
         * @param {string} on_client_created - ISO8601 timestamp
         *                                     e.g.: "2018-06-14T14:22:12.421Z"
         * @return {Promise<any>}
         */
        updateStatusAndRcId: function(
          content = '',
          sender_account_id = 0,
          status = 1,
          rocket_chat_id = 'unsend',
          on_client_created = ''
        ) {
          return new Promise((resolve, reject) => {
            console.log('updating Messages status');
            if (on_client_created === undefined || on_client_created===null) {
              on_client_created = '';
            }
            db.executeSql(
              `UPDATE Messages
              set status = ?,
              rocket_chat_id = ?
              WHERE content = ? AND
              sender_account_id = ?
              AND on_client_created = ?
              `,
              [
                status,
                rocket_chat_id,
                content,
                sender_account_id,
                on_client_created,
              ],
              resolve,
              reject
            );
          });
        },
        /**
         * Delete all message connected to a specific customer.
         *
         * WARNING: This deletes ALL messages of a customer, so also all messages
         * which are not just connected to the current active profile.
         * @param {number} cust_id - Id of a customer.
         * @return {Promise<any>}
         */
        delete: function(cust_id) {
          return new Promise((resolve, reject) => {
            if (parseInt(cust_id) > 0) {
              db.executeSql(
                `DELETE
            FROM Messages
            WHERE cust_id = ?`,
                [cust_id],
                resolve,
                (error) => reject(error.message)
              );
            } else {
              reject('cust_id is not a positive Integer');
            }
          });
        },
        /**
         * Delete messages which are unsent, which have a special properties.
         *
         * The messages with specific 'content', 'sender_account_id' and
         * 'on_client_created'.
         * @param {string} content - Content of the messages to delete.
         * @param {number} sender_account_id - Account ID of the senders messages
         * to delete.
         * @param {string} on_client_created - Timestamp when the message was created
         * on client.
         * @return {Promise<any>}
         */
        deleteObsoleteUnsent: function(
          content,
          sender_account_id,
          on_client_created
        ) {
          return new Promise((resolve, reject) => {
            db.executeSql(
              `DELETE FROM 
          Messages 
         WHERE rocket_chat_id = 'unsend'
         AND content = ?
         AND sender_account_id = ?
         AND on_client_created = ?
         AND rocket_chat_id = 'unsend'
        `,
              [content, sender_account_id, on_client_created],
              resolve,
              reject
            );
          });
        },
        /**
         * Updates the status of all messages between a specific relation.
         *
         * All messages between the tablet user with the profile ID `prof_id` and
         * the family portal user `cust_id` will be updated to `status`.
         * @param {number} prof_id - Id of a profile.
         * @param {number} cust_id - Id of a customer.
         * @param {number} status - Target status {@link Message#status}
         * @return {Promise<any>}
         *
         * @see {@link Message#status}
         */
        updateStatusById: function(prof_id, cust_id, status) {
          return new Promise((resolve, reject) => {
            if (!prof_id || !cust_id || !status) {
              reject('One Parameter is empty');
            }
            db.executeSql(
              `UPDATE Messages
          SET
            status = ?
          WHERE prof_id = ?
            AND cust_id = ?`,
              [status, prof_id, cust_id],
              () => resolve(`updated Message to status ${status}`),
              (error) => reject(error.message)
            );
          });
        },
        /**
         * Set status of messages representing a missed call to "seen/read".
         * @param {string} prof_id - Id of a tablet profile.
         * @return {Promise<any>}
         */
        clearMissedCallsByProfId: function(prof_id=0) {
          return new Promise((resolve, reject) => {
            if (prof_id>0) {
              db.executeSql(
                `UPDATE Messages
            SET
              status = 3
            WHERE
              prof_id = ?
            AND
              receipient_account_id = ?
            AND
          content LIKE "#TwVideo:%"`,
                [prof_id, prof_id],
                resolve(`updated #TwVideo Messages from ${prof_id} to status 3`),
                (error) => reject(error.message)
              );
            } else {
              reject('prof_id is no a natural number');
            }
          });
        },
        /**
         * Set status of messages representing a missed call to "seen/read".
         * @param {string} room_name - Name of the Video room.
         */
        clearMissedCallByRoomName: function(room_name) {
          db.executeSql(
            `UPDATE Messages SET status = 3  WHERE content = "#TwVideo:${room_name}"`,
            null,
            () => {
              console.log(`updated #TwVideo '${room_name}' status 3`);
            },
            (err) => {
              console.error(err);
            }
          );
        },
        /**
         * Insert multiple messages into Messages table.
         * @param {Object[]} messages - An array of message data.
         * @param {string} messages[].rocket_chat_id
         * @param {number} messages[].sender_account_id
         * @param {number} messages[].receipient_account_id
         * @param {number} messages[].prof_id
         * @param {number} messages[].cust_id
         * @param {string} messages[].content
         * @param {number} messages[].status
         * @param {string} messages[].receiving
         * @param {string} messages[].on_client_created
         * @return {Promise<any>}
         *
         * For detailed description of properties see {@link Message}.
         * @see {@link Message}
         */
        insert: function(messages) {
          return new Promise(
            (resolve, reject) => {
              let length = messages.length;
              if (length <= 0) {
                reject('no (messages) to add found');
              }
              let sqlInsert = `INSERT OR IGNORE INTO Messages
                              (
                                  rocket_chat_id,
                                  sender_account_id,
                                  receipient_account_id,
                                  prof_id,
                                  cust_id,
                                  content,
                                  status,
                                  receiving,
                                  on_client_created
                              )
                              values`;
              let messageValuesTemplate = `( ?, ?, ?, ?, ?, ?, ?, ?, ? )`;

              let valuesString = '';
              let argsSqlStatement = [];
              for (let i = 0; i < length; i++) {
                let message = messages[i];

                // Adjust values
                // TODO(peter): Check if the parsing to int and OR-case is still needed.
                message.sender_account_id = parseInt(message.sender_account_id) || -1;
                message.prof_id = parseInt(message.prof_id) || -1;

                if (message.sender_account_id > 0 && message.prof_id > 0) {
                  valuesString += messageValuesTemplate + ((i < length -1 ) ? ',':'');

                  if (
                    message.on_client_created === undefined
                    || message.on_client_created === null
                  ) {
                    message['on_client_created'] = '';
                  }
                  let messageValuesArg = [
                    message.rocket_chat_id,
                    message.sender_account_id,
                    message.receipient_account_id,
                    message.prof_id,
                    message.cust_id,
                    message.content,
                    message.status,
                    message.receiving,
                    message.on_client_created,
                  ];
                  argsSqlStatement = argsSqlStatement.concat(messageValuesArg);
                }
              }
              let sqlStatement = sqlInsert + valuesString;

              console.log('[Messages.insert]', sqlStatement);
              console.log('[Messages.insert]', argsSqlStatement);

              // Execute the defined SQL query with
              // the defined arguments argsSqlStatment.
              db.executeSql(
                sqlStatement,
                argsSqlStatement,
                () => resolve(true),
                reject
              );
            }
          );
        },
        /**
         * Updates an element in DOM on the home screen and shows the amount
         * of unread messages.
         * @param {number} amount - number of unread messages
         */
        updateHomeScreenUnreadMessages: function(amount) {
          if (parseInt(amount) > 0 && jqm_activePage === PAGE_HOME) {
            $('#amount_unread_messages')
              .show()
              .html(amount);
          }
        },
      };
      // init table:
      Messages.init();
      resolve();
    }
  );
};
