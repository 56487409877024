/* jshint esversion: 6 */
const createUserModel = () => {
  return new Promise(
    (resolve, reject) => {
      window.User = {};

      User.userid = userid;
      User.senior_address = '';
      User.firstname = '';
      User.lastname = '';
      User.prof_img = '';
      User.gender = 'none';
      User.difficulty = 2;
      User.dateofbirth = '';
      User.prof_id = 0;

      User.select_all = function (cb) {
        db.transaction(function (tx) {
          tx.executeSql(
              `SELECT *
               FROM User;`,
            [],
            function (transaction, result) {
              if (result !== null && result.rows !== null) {
                let length = result.rows.length;
                if (v.d) console.log(result.rows);
                let User_arr = [];
                if (length < 1) return false;
                for (let i = 0; i < length; i++) {
                  let row = result.rows.item(i);
                  User_arr[i] = {
                    user_id: row.userid,
                    firstname: row.firstname,
                    lastname: row.lastname,
                    prof_img: row.prof_img,
                    gender: row.gender,
                    status: row.status,
                    creation_dt: row.creation_datetime,
                    dateofbirth: row.dateofbirth,
                    difficulty: row.difficulty,
                  };
                }
                if (v.d) console.log(User_arr);
                if ($.isFunction(cb)) cb(User_arr);
              }
            }, errorHandler
          );
        });
      };

      User.select_all_local_userids = function (cb_success, cb_error) {
        db.transaction(function (tx) {
          tx.executeSql(
              `SELECT userid
               FROM User;`,
            [],
            function (transaction, result) {
              if (result !== null && result.rows !== null) {
                let length = result.rows.length;
                let User_arr = [];
                if (length < 1) return this;
                for (let i = 0; i < length; i++) {
                  let row = result.rows.item(i);

                  User_arr[i] = {
                    l_user_id: row.userid,
                  };
                }
                if (v.usync) console.log(User_arr);
                if ($.isFunction(cb_success)) cb_success(User_arr);
              }
            }, errorHandler
          );
        });
      };

      User.update_profid_by_luserid = function (prof_id, luserid, cb_success) {
        if (luserid === userid) User.prof_id = prof_id;
        db.transaction(function (tx) {
          tx.executeSql(
              `UPDATE
                  User
               SET prof_id = ?
               WHERE userid = ? `,
            [prof_id, luserid],
            function (tx, result) {
              if (v.d) {
                console.log(
                  'updated User prof_id = ' + prof_id + ' for userid = ' + luserid
                );
              }
              // update family_portal_register_direct to new prof_id:
              let this_fprd = new Family_portal_register_direct();
              this_fprd.update_prof_id(prof_id, luserid);
              if ($.isFunction(cb_success)) {
                cb_success();
              }
            }
          );
        }, function (e) {
          if (v.d) console.log('ERROR: ' + e.message);
        });
      };

      User.select_all_to_backup = function (usa_callback) {
        db.transaction(function (tx) {
          tx.executeSql(
              `SELECT userid,
                      firstname,
                      lastname,
                      prof_img,
                      status,
                      CASE
                        WHEN dateofbirth IS NULL THEN ''
                        ELSE dateofbirth END AS dateofbirth,
                      gender,
                      difficulty,
                      creation_datetime
               FROM User;`,
            [],
            function (transaction, result) {
              if (result !== null && result.rows !== null) {
                let length = result.rows.length;
                if (v.usync) console.log(result.rows);
                let User_arr = [];
                if (length < 1) {
                  return this;
                }
                for (let i = 0; i < length; i++) {
                  let row = result.rows.item(i);
                  /*
                    console.log(row)
                    console.log(row.dateofbirth.length )
                    console.log(typeof(row.dateofbirth) )
                    */
                  // if empty or no data was set choose dummy date mhe 2017_05:
                  if (row.dateofbirth.length < 1) {
                    row.dateofbirth = '1900-01-01';
                  }

                  // clean prof_img from device_specific path:
                  row.prof_img = row.prof_img.replace(
                    'prev/', 'prev_'
                  );
                  row.prof_img = row.prof_img.substr(
                    row.prof_img.lastIndexOf('/') + 1
                  );

                  // ===> TODO: add device  specific path when restoring !!!!
                  User_arr[i] = {
                    user_id: row.userid,
                    firstname: row.firstname,
                    lastname: row.lastname,
                    prof_img: row.prof_img,
                    status: row.status,
                    dateofbirth: row.dateofbirth,
                    gender: row.gender,
                    difficulty: row.difficulty,
                    creation_dt: row.creation_datetime,
                  };
                }

                if (v.usync) console.log(User_arr);
                if (($.isFunction(usa_callback)) && (is_app >= 0)) {
                  usa_callback(User_arr, '/userbackup');
                }
              }
            }, errorHandler
          );
        });
      };

    // Validation of firstname and lastname
    // returns an array with boolean for firstname ceck
    // -result and lastname check-result
      User.validate_name = function (firstname = '', lastname = '') {
        if (firstname.length < 3 && lastname.length >= 3) {
          return [false, true];
        }
        if (firstname.length >= 3 && lastname.length < 3) {
          return [true, false];
        }
        if (firstname.length < 3 && lastname.length < 3) {
          return [false, false];
        }
        if (firstname.length >= 3 && lastname.length >= 3) {
          return [true, true];
        }
      };

      User.validate_birthday = function (bd_none, bd_year, bd_month, bd_day) {
        bd_year = parseInt(bd_year) || 0;
        bd_month = parseInt(bd_month) || 0;
        bd_day = parseInt(bd_day) || 0;

        if (bd_year > 0 && bd_month > 0 && bd_day > 0) {
          bd_none = false;
        }
        if (bd_none || (bd_year >= 1890 && bd_year <= 2017 &&
          bd_month >= 0 && bd_month <= 12 &&
          bd_day > 0 && bd_day <= 31)) {
          return true;
        } else {
          return false;
        }
      };

      User.name_update = function (firstname, lastname, cb) {
        User.firstname = firstname;
        User.lastname = lastname;
        db.transaction(function (transaction) {
          transaction.executeSql(
              `UPDATE
                  User
               SET firstname = ?,
                   lastname  = ?
               WHERE userid = ?;`,
            [firstname, lastname, userid],
            function (transaction, result) {
              if ($.isFunction(cb)) cb(true);
              else {
                return this;
              }
            }, errorHandler
          );
        });
      };

      User.senior_address_validate = function (address) {
        if (address === 'senior-address-none') {
          return false;
        } else {
          return true;
        }
      };

      User.senior_address_update = function (address, cb) {
        if (v.d) console.log('from User -> senior_address: ' + address);
      };

      User.firstname_update = function (firstname, cb) {
        User.firstname = firstname;
        db.transaction(function (transaction) {
          transaction.executeSql(
              `UPDATE
                  User
               SET firstname = ?
               WHERE userid = ?;`,
            [firstname, userid],
            function (transaction, result) {
              if ($.isFunction(cb)) cb(true);
              else {
                return this;
              }
            }, errorHandler
          );
        });
      };

      User.lastname_update = function (lastname, cb) {
        User.lastname = lastname;
        db.transaction(function (transaction) {
          transaction.executeSql(
              `UPDATE
                  User
               SET lastname = ?
               WHERE userid = ?;`,
            [lastname, userid],
            function (transaction, result) {
              if ($.isFunction(cb)) cb(true);
              else {
                return this;
              }
            }, errorHandler
          );
        });
      };

      User.senior_gender_validate = function (gender) {
        // better use a whitelisting approach, like:
        switch (gender) {
          case 'male':
          case 'female':
            return true;

          default:
            return false;
        }
      };

      User.gender_update = function (gender, cb) {
        User.gender = gender;
        db.transaction(function (transaction) {
          transaction.executeSql(
              `UPDATE
                  User
               SET gender = ?
               WHERE userid = ?;`,
            [gender, userid],
            function (transaction, result) {
              if ($.isFunction(cb)) cb(true);
              else {
                return this;
              }
            }, errorHandler
          );
        });
      };

      User.difficulty_update = function (difficulty, cb) {
        difficulty = parseInt(difficulty) || 0;
        User.difficulty = difficulty;
        db.transaction(function (transaction) {
          transaction.executeSql(
              `UPDATE
                  User
               SET difficulty = ?
               WHERE userid = ?;`,
            [difficulty, userid],
            function (transaction, result) {
              if ($.isFunction(cb)) cb(true);
              else {
                return this;
              }
            }, errorHandler
          );
        });
      };

      User.dateofbirth_update = function (
        bd_none, year,
        month, day,
        cb_success,
        cb_error
      ) {
        if (!bd_none) {
          if (User.validate_birthday(bd_none, year, month, day)) {
            let dateofbirth = year.toString() +
              '-' + month.toString() +
              '-' + day.toString();
            User.dateofbirth = dateofbirth;
            db.transaction(
              function (tx) {
                tx.executeSql(
                    `UPDATE
                        User
                     SET dateofbirth = ?
                     WHERE userid = ?;`,
                  [dateofbirth, userid],
                  function (transaction, result) {
                    if ($.isFunction(cb_success)) cb_success(true);
                    else return this;
                  },
                  errorHandler
                );
              }
            );
          } else {
            if (v.d) {
              console.error(`not able to construct date from input:
                                      ${year} - ${month} - ${day}`);
            }

            if ($.isFunction(cb_error)) cb_error(true);
          }
        } else {
          User.dateofbirth = null;
          db.transaction(
            function (tx) {
              tx.executeSql(
                  `UPDATE
                      User
                   SET dateofbirth = ?
                   WHERE userid = ?;`,
                [dateofbirth, userid],
                function (transaction, result) {
                  if ($.isFunction(cb_success)) cb_success(true);
                  else return this;
                },
                errorHandler
              );
            }
          );
        }
      };

    // firstName, lastName, bdYear, bdMonth, bdDay, bdData, diffData, gdData
    // Update on save and for all personal settings in sps
      User.settings_personal_update = function (
        firstname,
        lastname,
        year,
        month,
        day,
        nodate,
        difficulty,
        gender,
        cb_success,
        cb_error
      ) {
        let error = '';
        // setting the global variables:
        User.firstname = firstname;
        User.lastname = lastname;
        User.prof_img = '';
        User.dateofbirth = '';
        // Debugging
        if (v.d) {
          console.log(
            `+++ USER MODEL message From settings_personal_update +++
        Fname: ${firstname}
        Lname: ${lastname}
        bdYear: ${year}
        bdMonth: ${month}
        bdDay: ${day}
        bdData: ${nodate}
        diffData: ${difficulty}
        gdData: ${gender}
        +++ END USER MODEL message+++`
          );
        }

        if (!gender) {
          gender = null;
        }

        if (
          User.validate_birthday(nodate, year, month, day) &&
          User.validate_name(firstname, lastname)
        ) {
          let dateofbirth = year.toString() + '-' + month.toString() + '-' + day.toString();
          // Debugging
          if (v.d) {
            console.log('+++ USER MODEL SUCCESS: Checked Bday and Names +++ ');
          }
          // setting the global variables:
          User.firstname = firstname;
          User.lastname = lastname;
          User.dateofbirth = dateofbirth;
          User.gender = gender;
          User.difficulty = difficulty;

          db.transaction(
            function (tx) {
              tx.executeSql(
                  `UPDATE
                      User
                   SET firstname   = ?,
                       lastname    = ?,
                       dateofbirth = ?,
                       difficulty  = ?,
                       gender      = ?
                   WHERE userid = ?;`,
                [
                  firstname,
                  lastname,
                  dateofbirth,
                  difficulty,
                  gender,
                  userid,
                ],
                function (transaction, result) {
                  if ($.isFunction(cb_success)) {
                    cb_success(true);
                  } else {
                    return this;
                  }
                },
                errorHandler
              );
            }
          );
          api_msg.updateName(`${User.firstname} ${User.lastname}`);
        } else if (
          !User.validate_birthday(nodate, year, month, day) &&
          !User.validate_name(firstname, lastname)
        ) {
          error = 'both_error';
          if (v.d) {
            console.error();
          }
          if ($.isFunction(cb_error)) {
            cb_error(true, error);
          }
        } else if (!User.validate_birthday(nodate, year, month, day)) {
          error = 'birthday_error';
          if (v.d) console.error();
          if ($.isFunction(cb_error)) cb_error(true, error);
        } else if (!User.validate_name(firstname, lastname)) {
          error = 'name_error';
          if (v.d) {
            console.error(!User.validate_name(firstname, lastname));
          }
          if ($.isFunction(cb_error)) {
            cb_error(true, error);
          }
        }
      };

      /*
      inserts a new User with all given parameters
      */
      User.settings_personal_insert = function (
        firstname,
        lastname,
        year,
        month,
        day,
        difficulty,
        gender,
        cb_success,
        cb_error
      ) {
        let dateofbirth = year.toString() + '-' + month.toString() + '-' + day.toString();
        let now = get_datetime_now();
        let l_userid;
        if (v.d) {
          console.log(
            `+++ USER MODEL message From settings_personal_insert +++
          Fname: ${firstname}
          Lname: ${lastname}
          bdYear: ${year}
          bdMonth: ${month}
          bdDay: ${day}
          diffData: ${difficulty}
          gdData: ${gender}
          +++ END USER MODEL message+++`
          );
        }
        db.transaction(
          function (tx) {
            tx.executeSql(
                `SELECT max(userid) maxid
                 FROM User;`,
              [],
              function (transaction, result) {
                if (result !== null && result.rows !== null) {
                  l_userid = parseInt(result.rows.item(0).maxid) + 1;
                }
              }, errorHandler);
            tx.executeSql(
                `INSERT INTO User (firstname,
                                   lastname,
                                   dateofbirth,
                                   difficulty,
                                   gender,
                                   prof_img,
                                   status,
                                   creation_datetime)
                 VALUES (?, ?, ?, ?, ?, 'none', 1, ?);`,
              [
                firstname,
                lastname,
                dateofbirth,
                difficulty,
                gender,
                now,
              ],
              function (transaction, result) {
                if ($.isFunction(cb_success)) {
                  cb_success(l_userid);
                } else {
                  return this;
                }
              },
              errorHandler
            );
          }
        );
      };

      User.select_by_id = function (LUSERID, CB) {
        db.transaction(
          function(tx) {
            tx.executeSql(
              `SELECT *
                 FROM User
                 WHERE userid = ?;`,
              [LUSERID],
              function(transaction, result) {
                if (result !== null && result.rows !== null) {
                  let length = result.rows.length;
                  if (v.d) console.log(result.rows);
                  if (length < 1) return false;
                  User.firstname = result.rows.item(0).firstname;
                  User.lastname = result.rows.item(0).lastname;
                  User.prof_img = result.rows.item(0).prof_img;
                  User.gender = result.rows.item(0).gender;
                  User.dateofbirth = result.rows.item(0).dateofbirth;
                  User.difficulty = result.rows.item(0).difficulty;
                  User.prof_id = result.rows.item(0).prof_id;
                  if (v.d) console.log(User);
                  if ($.isFunction(CB)) {
                    console.log('executing User.select Callback', CB);
                    CB(User);
                  } else {
                    return this;
                  }
                }
              },
              errorHandler
            );
          }
        );
      };

      User.select_names_by_id = function (LUSERID, SUCCESS_CB) {
        db.transaction(
          function (tx) {
            tx.executeSql(
                `SELECT *
                 FROM User
                 WHERE userid = ?;`,
              [LUSERID],
              function (transaction, result) {
                if (result !== null && result.rows !== null) {
                  let length = result.rows.length;
                  if (v.d) console.log(result.rows);
                  if (length < 1) {
                    return false;
                  }
                  User.userid = LUSERID;
                  User.firstname = result.rows.item(0).firstname;
                  User.lastname = result.rows.item(0).lastname;
                  User.prof_img = result.rows.item(0).prof_img;
                  User.gender = result.rows.item(0).gender;
                  User.dateofbirth = result.rows.item(0).dateofbirth;
                  User.difficulty = result.rows.item(0).difficulty;
                  User.prof_id = result.rows.item(0).prof_id;
                  if (v.d) {
                    console.log(User);
                  }
                  if ($.isFunction(SUCCESS_CB)) {
                    SUCCESS_CB();
                  } else {
                    return this;
                  }
                }
              },
              errorHandler
            );
          }
        );
      };

      User.change = function (LUSERID, CB_SUCCESS) {
        userid = LUSERID;
        sessionStorage.setItem('userid', userid);
        User.select_names_by_id(LUSERID, () => {
          if (
            utilization === 4 ||
            utilization === 5 ||
            utilization === 6 ||
            utilization === 8
          ) {
            // updated selected user to settings tbl 2016_12_15 MHe:
            Settings.update('selected_userid', userid, nullHandler);
            // login to rocket.chat for this user:
            api_msg.rocketLogin();
          }
          if ($.isFunction(CB_SUCCESS)) {
            CB_SUCCESS();
          }
        });
      };

      User.set_gdpr_check = function (
        L_USERID = 0,
        CB_SUCCESS = helper_db.default_success
      ) {
        if (parseInt(L_USERID) > 0) {
          db.transaction(
            function (tx) {
              tx.executeSql(
                  `UPDATE
                      User
                   SET gdpr_check = "TRUE"
                   WHERE userid = ?;`,
                [
                  L_USERID,
                ],
                function (transaction, result) {
                  if ($.isFunction(CB_SUCCESS)) {
                    CB_SUCCESS(true);
                  } else {
                    return this;
                  }
                },
                errorHandler
              );
            }
          );
        } else {
          console.error('no valid L_USERID passed!');
        }
      };

      User.send = function () {
        User.select_all(User.upload);
      };

      User.insert_users = function (user_arr) {
        let datetime_now = get_datetime_now();
        let user_cnt = 0;
        // 1. check if User_arr data has enough data:
        db.transaction(
          function (tx) {
            tx.executeSql(
                `SELECT count(*) AS user_cnt
                 FROM User`,
              [],
              function (transaction, result) {
                if (v.usync) console.log(result.rows.item(0).user_cnt);
                user_cnt = result.rows.item(0).user_cnt;
                if (user_cnt <= user_arr.length) {
                  let q = '';
                  let val = '';
                  for (let i = 0; i < user_arr.length; i++) {
                    if (i === 0 || i % 500 === 0) {
                      q = `INSERT OR REPLACE INTO User
                      (
                        userid,
                        firstname,
                        lastname,
                        prof_img,
                        status,
                        creation_datetime,
                        dateofbirth,
                        gender,
                        difficulty
                      )`;
                      val = ` values`;
                    }
                    user_arr[i].creation_datetime = datetime_now;
                    if (user_arr[i].firstname.length < 1) {
                      user_arr[i].firstname = '';
                      if (v.usync) console.log('EMPTY firstname');
                    }
                    // if prof_img col does not contain device_specific path
                    //  ==> add it:
                    if (user_arr[i].prof_img.indexOf('file:///') < 0 &&
                      user_arr[i].prof_img !== 'none') {
                      user_arr[i].prof_img =
                        cordova.file.externalRootDirectory +
                        'media4carefotos/' +
                        user_arr[i].prof_img;
                    }


                    user_arr[i].prof_img = user_arr[i]
                      .prof_img.replace('prev_', 'prev/');

                    val += '(' +
                      user_arr[i].user_id + ',\'' +
                      user_arr[i].firstname + '\',\'' +
                      user_arr[i].lastname + '\',\'' +
                      user_arr[i].prof_img + '\',' +
                      user_arr[i].status + ',\'' +
                      datetime_now + '\',\'' +
                      user_arr[i].dateofbirth + '\',\'' +
                      user_arr[i].gender + '\',' +
                      user_arr[i].difficulty +
                      ')';
                    if ((i + 1) !== user_arr.length && (i + 1) % 500 !== 0) {
                      val += ',';
                    }
                    if ((i + 1) === user_arr.length || (i + 1) % 500 === 0) {
                      q += ' ' + val;
                    }

                    if ((i + 1) % 500 === 0 || (i + 1) >= user_arr.length) {
                      if (v.usync) console.log(q);
                      tx.executeSql(
                        q,
                        [],
                        function (transaction, result) {
                          if (v.usync) console.log(result.rows);
                          if (v.usync) console.log('yo alter0');
                        },
                        errorHandler
                      );
                    }
                  }
                }
              },
              errorHandler
            );
          }
        );
      };

      User.get_all_prof_ids = function() {
        return new Promise((resolve, reject) => {
          db.transaction(
            function (tx) {
              tx.executeSql(
                  `SELECT prof_id
                   FROM User`,
                [],
                function (tx, res) {
                  let prof_ids = [];
                  for (let i = 0; i < res.rows.length; i++) {
                    let curr_prof_id = parseInt(res.rows.item(i).prof_id) || 0;
                    if (curr_prof_id > 0) {
                      prof_ids.push(curr_prof_id);
                    }
                  }
                  resolve(prof_ids);
                },
                (err) => reject(err)
              );
            },
            (err) => reject(err),
            nullHandler
          );
        });
      };

      /* following alter statements are to alter table if it was not altered before
       * the information if already altered is stored in the settings table.
       */

      /* check if column gender and difficulty is already added: */
      User.check_settings = function () {
        Settings.select('user_personal_settings', function (setting) {
          console.log(setting);
          if (setting === 'done') {
            if (v.d) console.log('app is prepared for personal User settings');
          } else {
            User.alter_table_personalsettings();
          }
        });
        return this;
      };

      User.alter_table_personalsettings = function () {
        db.transaction(function (tx) {
          tx.executeSql(
              `ALTER TABLE
              User
                ADD
                gender TEXT DEFAULT 'none'`,
            [],
            function (tx, result) {
            }
          );

          tx.executeSql(
              `ALTER TABLE
              User
                ADD difficulty INTEGER DEFAULT 2`,
            [],
            function (tx, result) {
              Settings.insert('user_personal_settings', 'done', function () {
                if (v.d) console.log('set user_personal_settings = done');
                User.check_settings();
              });
            }
          );
        }, function (e) {
          if (v.d) console.log('ERROR: ' + e.message);
        });
      };

      /* check if column dateofbirth is already added: */
      User.check_settings_v3 = function () {
        Settings.select('user_personal_settings_v3', function (setting) {
          console.log(setting);
          if (setting === 'done') {
            if (v.d) console.log('app is prepared for personal User settings v3');
          } else {
            User.alter_table_personalsettings_v3();
          }
        });
        return this;
      };

      User.alter_table_personalsettings_v3 = function () {
        db.transaction(
          function (tx) {
            tx.executeSql(
                `ALTER TABLE
                User
                  ADD dateofbirth date DEFAULT NULL`,
              [],
              function (tx, result) {
                Settings.insert(
                  'user_personal_settings_v3', 'done',
                  () => {
                    if (v.d) {
                      console.log('set user_personal_settings = done');
                    }
                    User.check_settings_v3();
                  }
                );
              }
            );
          },
          function (e) {
            if (v.d) console.log('ERROR: ' + e.message);
          }
        );
      };



      /* check if column prof_id is already added: */
      User.check_settings_prof_id = function () {
        Settings.select('user_personal_settings_prof_id', function (setting) {
          console.log(setting);
          if (setting === 'done') {
            if (v.d) console.log('app is prepared for personal User settings v3');
          } else {
            User.alter_table_personal_prof_id();
          }
        });
        return this;
      };

      User.alter_table_personal_prof_id = function () {
        db.transaction(function (tx) {
          tx.executeSql(
              `ALTER TABLE
              User
                ADD prof_id INT DEFAULT NULL`,
            [],
            function (tx, result) {
              Settings.insert('user_personal_settings_prof_id', 'done', function () {
                if (v.d) console.log('set user_personal_settings_prof_id = done');
                User.check_settings_prof_id();
              });
            }
          );
        }, function (e) {
          if (v.d) console.log('ERROR: ' + e.message);
        });
      };



      /* check if column gdpr_check is already added: */
      User.check_settings_gdpr_check = function () {
        Settings.select('user_personal_settings_gdpr_check', function (setting) {
          console.log(setting);
          if (setting === 'done') {
            console.log('app is prepared for personal User settings with GDPR_check');
          } else {
            User.alter_table_personal_gdpr_check();
          }
        });
        return this;
      };

      User.alter_table_personal_gdpr_check = function () {
        db.transaction(function (tx) {
          tx.executeSql(
              `ALTER TABLE
              User
                ADD gdpr_check BOOLEAN NOT NULL DEFAULT FALSE;`,
            [],
            function (tx, result) {
              Settings.insert(
                'user_personal_settings_gdpr_check',
                'done',
                function () {
                  console.log('set user_personal_settings_gdpr_check = done');
                  User.check_settings_gdpr_check();
                }
              );
            }
          );
        }, function (e) {
          console.log('ERROR: ' + e.message);
        });
      };

      User.init = () => {
        // TODO make Promises:
        User.check_settings();
        User.check_settings_v3();
        User.check_settings_prof_id();
        User.check_settings_gdpr_check();
      };

      User.initUser = () => {
        Settings.select(
          'selected_userid',
          function(set) {
            if (!set) {
              Settings.insert('selected_userid', userid, nullHandler);
            } else {
              userid = parseInt(set)||2;
              // login on startup:
              User.change(userid, () => {
                Sentry.configureScope((scope) => {
                  scope.setUser({
                    id: User.prof_id,
                    username: User.firstname,
                  });
                });
              });
            }
          }
        );
      };
      User.init();
      User.initUser();
      resolve();
    }
  );
};
