let lang = '';
// 2016_12_16 use native_lang var as fallback for media lists:
const CONST_LANGUAGES = Object.freeze({
  GERMAN: 'de',
  ENGLISH: 'en',
});
const nat_lang = CONST_LANGUAGES.GERMAN;
let strings = {};

function getLanguageArray(
  gla_lang = CONST_LANGUAGES.GERMAN,
  id_string,
  lang_arr_index,
  gl_callback = nullHandler
) {
  lang = gla_lang;
  console.warn('getting language array now');
  db.transaction(
    function(tx) {
      tx.executeSql(
        `select id, ${lang}, en, ${nat_lang} from translations;`,
        [],
        function(transaction, result) {
          if (result !== null && result.rows !== null) {
            let this_id = 0;
            for (let i = 0; i < result.rows.length; i += 1) {
              this_id = parseInt(result.rows.item(i).id) - 1;
              // console.info(result.rows.item(i)[gla_lang]);

              // when String is empty take the fallback String:
              if (result.rows.item(i)[gla_lang].length === 0) {
                strings[this_id] = result.rows.item(i)[nat_lang];
              } else {
                strings[this_id] = result.rows.item(i)[gla_lang];
              }
              strings[result.rows.item(i)[CONST_LANGUAGES.ENGLISH]] = strings[this_id];
            }
            emptyStorageCache();
            /* END page/utilization specific actions 20176_02_09 MHe:*/
            /* TODO: better using a callback for the following:*/
            if (id_string !== null && lang_arr_index !== null) {
              changeNavTitles(id_string, lang_arr_index);
            }
            if(typeof gl_callback === 'function') gl_callback();
          }
        }
      );
    }
  );
}

function getLanguageArray_promise(gla_lang) {
  strings = {};
  if (gla_lang) gla_lang = CONST_LANGUAGES.GERMAN;
  console.error('new language array');
  console.error(gla_lang);

  return new Promise((resolve, reject) => {
    db.transaction(
      function(tx) {
        tx.executeSql(
          'select id, ? from translations;',
          [gla_lang],

          function(transaction, result) {
            if (result !== null && result.rows !== null) {
              for (let i = 0; i < result.rows.length; i += 1) {
                strings[parseInt(result.rows.item(i).id) - 1] =
                      result.rows.item(i)[gla_lang];
              }
              emptyStorageCache();
              resolve();
            }
          });
      });
  });
}

function changeNavTitles(id_string, lang_arr_index) {
  if (id_string) {
    for (let i = 0; i < id_string.length; i += 1) {
      if (v.d) console.log(id_string[i] + ' ' + lang_arr_index[i]);
      $(id_string[i]).html(strings[lang_arr_index[i]]);
    }
  }
}
