const boot_after = () => {
  return new Promise(
    () => {
      $(document)
        .on('pagebeforeshow',
          () => {
            console.log('a page before show');
            thisapp.onpagebeforeshow();
          }
        )
        .on('pagebeforehide',
          () => {
            console.log('a page before hide');
            thisapp.onpagebeforehide();
          }
        )
        .on(
          'pagebeforeshow',
          '#home',
          thisapp.homebeforeshow
        )
        .on('pagebeforehide',
          '#home',
          thisapp.homebeforehide);
    }
  );
};

const start_app = () => {
  return new Promise(
    (resolve) => {
      if (is_app > -1) {
        if (typeof cordova.plugins.brightness !== 'undefined') {
          brightness.setKeepScreenOn(false);
        }
      }

      $('#home').load(
        home_dir + 'home.html',
        function(response, status, xhr) {
          if (status === 'error') {
            console.error('error loading home.html', response);
          }
          resolve();
        }
      );

      Settings.select('callAutoAnswer').then(
        (value) => {
          state.callAutoAnswer = value;
        });
    }
  );
};

const boot = () => {
  init()
    .then(createHelperDb)
    .then(createOnBodyLoad)
    .then(createFiles)
    .then(() => {
      return onBodyLoad();
    })
    .then(createSettings)
    .then(
      () => {
        return Settings.init()
          .then(() => {
            return Settings.makeUnique();
          })
          .then(() => {
            return Settings.default();
          });
      }
    )
    .then(() => {
      return Settings.select('utilization');
    })
    .then((settingsUtilization) => {
      // in case of error set 2 = b2b Utilization Mode
      utilization = parseInt(settingsUtilization) || 2;
    })
    .then(Files.directory.create_external('media4carefotos'))
    .then(
      Files.copy_to_sdcard(
        "www/img/seerose_media4care.png",
        "media4carefotos/seerose_media4care.png"
      )
    )
    .then(getlastsync_dl)
    .then(createUpdateApp)
    .then(createContentBackendAuth)
    .then(createFiles)
    .then(createContentBackendDb)
    .then(
      () => {
        const execute = () => {
          return contentBackendDb.readFiles()
            .then(contentBackendDb.imports)
            .then(() => {
              Settings.insertReplaceWithPromise(
                'contentBackendDb',
                app_core_version
              );
            });
        };
        return Settings.select('contentBackendDb')
          .then(
            (setting) => {
              if (
                setting === false
                || setting === DEFAULT_SETTINGS.contentBackendDb
                || app_core_version > setting
                || is_app === ANDROID_ASSETS.NOT_FOUND
              ) {
                return execute();
              }
            }
          );
      }
    )
    .then(addNewLanguage)
    .then(createUserModel)
    .then(createFamily_portal_register_direct)
    .then(createAuthModel)
    .then(()=> {
      if (thisapp_online) {
        return new Promise(
          (resolve) => {
            if (is_app >= 0) {
              Auth.check(resolve, resolve);
            } else {
              resolve();
            }
          }
        );
      }
    })
    .then(
      () => {
        if (thisapp_online) {
          return contentBackendAuth
            .get_token(0);
        }
      }
    )
    .then(
      () => {
        if (thisapp_online) {
          return updateApp.check_version();
        }
      }
    )
    .then(checksdcard)
    .then(() => {
      return Settings.select('sdCardInstallation');
    })
    .then((sdCardInstallation) => {
      if (
        sdCardInstallation === SETTINGS_VALUES.TRUE
        && state.checksdcard.contentFolder
      ) {
        return new SdCardInstall().run()
          .then(() => {
            return Settings.update('sdCardInstallation', SETTINGS_VALUES.FALSE);
          })
          .catch((err) => {
            controllers.home.bootscreen.render(
              `Error at SD Card Installation:<br>
              ${err.toString()}<br>
              if online it will proceed with Online Installation Process.`
            );
            setTimeout(()=>{ return false; }, 5000);
          });
      }
    })
    .then(() => {
      return Settings.select('updateMediaFiles');
    })
    .then((updateMediaFiles) => {
      if (
        updateMediaFiles === SETTINGS_VALUES.DEPRECATED_TRUE
        && state.content_back_end.token.length > 0
        && thisapp_online
        && !online_media_sources
      ) {
        // start update here
        return sync_files(state.content_back_end.token)
          .then(() => {
            return Settings.update(
              'updateMediaFiles',
              SETTINGS_VALUES.DEPRECATED_FALSE
            );
          })
          .then(() => {
            return Settings.update('sdCardInstallation', SETTINGS_VALUES.FALSE);
          });
      }
    })
    .then(createUsyncModel)
    .then(() => {
      Usync.check_settings();
      return Settings.select('selected_userid');
    })
    .then((settingsUserid) => {
      return new Promise(
        (resolve) => {
          User.select_by_id(parseInt(settingsUserid), resolve);
        }
      );
    })
    .then(createCore)
    .then(createInvitationsModel)
    .then(createApiRelatives)
    .then(createFamilyRelationsModel)
    .then(createMessagesModel)
    .then(createFotosModel)
    .then(
      () => {
        return Fotos.init();
      })
    .then(Timeline.migrateFromPhotos)
    .then(createApiMsg)
    .then(createWsp)
    .then(createApiChat)
    .then(fast_lang_switch_creator)
    .then(createPage)
    .then(createApiDeviceUser)
    .then(createThisapp)
    .then(start_app)
    .then(() => {
      return thisapp.ready();
    })
    .then(load_after_trans)
    .then(bind_start_buttons)
    .then(createHelperSound)
    .then(
      () => {
        return check_sound_settings();
      }
    )
    .then(
      () => {
        core.load_js('controller.js', 'js_controller')
          .then(boot_after);
      }
    )
    .then(
      () => {
        return new CheckVideoUrls().init();
      }
    )
    .then(
      () => {
        $('.startoverlay').remove();
        console.info('BOOT FINISHED.:!');
      }
    )
    .catch(
      (r) => {
        console.error(r);
      }
    );
};

if (is_app < 0) {
  boot();
} else {
  document.addEventListener(
    'deviceready',
    () => {
      boot();
    }
  );
}
