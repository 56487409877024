const createApiMsg = () => {
  return new Promise(
    (resolve, reject) => {
      state.api_msg =
        {
          processing_sync: false,
          relations_to_sync: [],
          relations: [],
          auth_secret_password: false,
          allowed_account_ids: [],
          allowedAccountsRefresh: 0,
        };

      window.api_msg = new function() {
        var self = this;
        // defines after what time we pull Family_relations again to update
        // local Family_relations and with those allowed_account_ids:
        this.REFRESH_ALLOWED_ACCOUNTS_TIME = 1000*60;
        this.TW_VIDEO_TAG = '#TwVideo:';
        this.TW_ACTION_SEPARATOR = ';';
        this.TW_ACTION_STOP = 'BYE';
        this.TW_ACTION_DECLINE = 'DECLINE';
        this.TW_ACTION_CANCEL = 'CANCEL';
        this.username = `device_user_${User.prof_id}`;
        // this.passw = Auth.secret;
        this.passw = '';
        this.passw256 = '';
        this.userEmail = this.username+'@media4care.de';
        // rocket,chat user_id:
        this.user_id = 0;
        this.ws_url = `wss://${Auth.token}@${api_endpoints.rocketchat}/websocket`;
        this.rest_url = `https://${api_endpoints.rocketchat}`;
        this.updateUserinfo =
          `https://${api_endpoints.rocketchat}/api/v1/users.updateOwnBasicInfo`;

        this.clean_room_history = (user_data) => {
          let now = new Date();
          loginREST(self.username, self.passw)
            .catch(
              (err) => console.error(err)
            )
            .then(
              (result) => {
                let settings = {
                  'url': window.api_msg.rest_url + '/api/v1/rooms.cleanHistory',
                  'method': 'POST',
                  'headers': {
                    'Token': Auth.token,
                    'x-user-id': result.data.userId,
                    'x-auth-token': result.data.authToken,
                    'content-type': 'application/json',
                  },
                  'processData': false,
                  // timeout === 0 ==> no-timeout
                  'timeout': 0,
                  'data': JSON.stringify( {
                    // room_id is the concatenation of both user_ids:
                    'roomId': user_data.user._id+result.data.userId,
                    'latest': now,
                    'oldest': '2017-01-30T13:42:25.304Z',
                  }),
                };
                console.log(settings);
                return new Promise(
                  function(resolve, reject) {
                    $.ajax(settings).done(
                      () => {
                        console.log('room history successfully deleted!');
                        resolve('success');
                      }
                    ).fail(
                      (jqXHR, textStatus) => {
                        if (textStatus === 'error') {
                          let errorText = JSON.parse(jqXHR.responseText);
                          console.warn(errorText);
                          reject('failure');
                        } else {
                          reject('failure');
                        }
                      }
                    );
                  }
                );
              }
            );
        };

        this.get_userid_by_cust_id = (
          this_cust_id,
          cb_success = helper_db.default_success
        ) => {
          loginREST(self.username, self.passw)
            .catch(
              (err) => console.error(err)
            )
            .then(
              (result) => {
                console.log('get_userid_by_cust_id','x-user-id',result.data.userId,'x-auth-token',result.data.authToken);
                let settings = {
                  'url': window.api_msg.rest_url + '/api/v1/users.info',
                  'method': 'GET',
                  'headers': {
                    'x-user-id': result.data.userId,
                    'x-auth-token': result.data.authToken,
                  },
                  'data': {'username': 'customer_'+this_cust_id},
                };

                return new Promise(
                  function(resolve, reject) {
                    $.ajax(settings).done(
                      (data) => {
                        if ($.isFunction(cb_success)) {
                          // TODO(peter): This function is a mix of callback and Promise.
                          // It should either Callback or Promise. For Promise the following
                          // line should be exchanged by resolve(data);
                          cb_success(data);
                        }
                        resolve('success');
                      }
                    ).fail(
                      (jqXHR, textStatus) => {
                        if (textStatus === 'error') {
                          let errorText = JSON.parse(jqXHR.responseText);
                          console.warn(errorText);
                          reject('failure');
                        } else {
                          reject('failure');
                        }
                      }
                    );
                  }
                );
              }
            );
        };

        this.reset_creds = () => {
          self.username = `device_user_${User.prof_id}`;
          // self.passw = Auth.secret;
          // self.passw256 = sha256(self.passw);
          self.userEmail = this.username+'@media4care.de';
          self.ws_url = `wss://${Auth.token}@${api_endpoints.rocketchat}/websocket`;
          wsp._url = self.ws_url;
          window.api_msg.rest_url = `https://${api_endpoints.rocketchat}`;
          self.updateUserinfo =
            `https://${api_endpoints.rocketchat}/api/v1/users.updateOwnBasicInfo`;
        };
        // old password style fallback using Auth.secret:
        this.set_auth_secret_as_password = () => {
          self.passw = Auth.secret;
          self.passw256 = sha256(self.passw);
        };

        this.set_password = () => {
          return new Promise(
            (resolve, reject) => {
              self.request_random_secret().then(
                (secret) => {
                  console.log('set_password');
                  self.passw = secret;
                  self.passw256 = sha256(secret);
                  resolve(this);
                }
              ).catch(
                (err) => {
                  console.error(err);
                  reject(err);
                }
              );
            }
          );
        };

        /**
         * Returns a secret string for the current user.
         * @return {Promise<string>} - A generated random secret string provided by
         * the account service.
         */
        this.request_random_secret = () => {
          return new Promise(
            (resolve, reject) => {
              $.ajax(
                {
                  type: 'POST',
                  contentType: 'application/json',
                  url: api_endpoints.auth_random_secret,
                  headers: {'Token': Auth.token},
                  // timeout === 0 ==> no-timeout
                  timeout: 0,
                  success: (data) => {
                    resolve(data.secret);
                  },
                  error: (error) => {
                    console.log('error');
                    console.log(error);
                    reject('failure');
                  },
                }
              );
            }
          );
        };

        this.sync_on_startup = () => {
          self.send_unsent_msgs();

          // get all Family_relations:
          Family_relations.getActiveRelations(
            User.prof_id,
            (rel) => {
              console.log(rel);
              rel.forEach(
                function(el) {
                  el.online=false;
                  state.api_msg.relations_to_sync.push(el);
                  state.api_msg.relations.push(el);
                }
              );
              self.sync_with_customers();
            },
            function(err) {
              console.error(err);
            }
          );
        };

        this.send_unsent_msgs = () => {
          Messages.selectAllUnsentCurrUser().then(
            (unsent_messages) => {
              console.log('[api_msg.send_unsent_msgs]', unsent_messages);

              for (let i = 0; i < unsent_messages.length; i++) {
                // if user is unknown we get blocked if doing faster:
                setTimeout(
                  this.sendRocketMessage(
                    `customer_${unsent_messages[i].cust_id}`,
                    unsent_messages[i].content,
                    unsent_messages[i].on_client_created
                  ),
                  25000
                );
              }
            }
          );
        };

        this.sync_with_customers = () => {
          state.api_msg.processing_sync = true;
          // retrieve history:
          for (let i = 0; i < state.api_msg.relations_to_sync.length; i++) {
            this.getMessageHistory(
              'customer_'+state.api_msg.relations_to_sync[i].cust_id,
              20
            );
          }
          console.warn('sync of getting messages ready');
          Messages
            .countUnreadMsg(User.prof_id)
            .then(Messages.updateHomeScreenUnreadMessages);
          Messages.countMissedVideoCalls(User.prof_id)
            .then(
              (r) => {
                if (parseInt(r) > 0) {
                  $('#amount_missed_calls')
                    .show()
                    .html(r);
                }
              }
            );
        };
        this.handleTwVideoMsg = function(msg_item) {
          const command = msg_item.content;
          if (!command.includes(';')) {
            // if already in call, ignore attempt
            if (!(state.videochat.in_call || jqm_activePage === 'videochat')) {
              // go to video-chat-view:
              videochat.room_name = command.replace(this.TW_VIDEO_TAG, '');
              const channelParts = videochat.room_name.split('_');
              state.videochat.partner = channelParts[1];
              state.videochat.channel = command.replace(this.TW_VIDEO_TAG, '');
              state.videochat.incoming_call = true;
              notification_popup.show('video', msg_item.name);
            }

            console.log(this.TW_VIDEO_TAG, [msg_item]);
            Messages.insert([msg_item]);
            // other new message
          } else {
            if (
              command.includes(this.TW_ACTION_CANCEL) &&
              state.hasOwnProperty('notification_popup') &&
              state.notification_popup.hasOwnProperty('active')) {
              notification_popup.hide();
            }

            if (
              (command.includes(this.TW_ACTION_DECLINE) ||
                command.includes(this.TW_ACTION_STOP)) &&
              ('cust_id' in msg_item &&
                'videochat' in state &&
                'partner' in state.videochat &&
                msg_item.cust_id === state.videochat.partner)) {
              videochat.teardown();
            }
          }
        };

        this.declineCallByChannel = function(channel) {
          const channelParts = channel.split('_');
          const partner = channelParts[0] + '_' + channelParts[1];
          const msg = this.TW_VIDEO_TAG + channel +
            this.TW_ACTION_SEPARATOR +
            this.TW_ACTION_DECLINE;
          Messages.clearMissedCallByRoomName(channel);
          this.sendRocketMessage(partner, msg);
          console.log('Call end message: '+ msg + ' to: ' + partner);
        };

        /**
         * This function will be triggered every time a message received from server.
         * It determines how the received message is to be handled.
         * @param {string} msg_rc_raw - The message received by the RC-Server.
         */
        this.onMessage = function(msg_rc_raw) {
          let msg_rc = JSON.parse(msg_rc_raw);
          if (v.d) console.log('[api_msg.onMessage] msg_rc_raw', msg_rc);
          switch (msg_rc.msg) {
            case 'ping':
              {
                this.sendPong();
              }
              break;
            case 'changed':
              {
                if (msg_rc && !msg_rc.fields.args) {
                  console.error(msg_rc.fields);
                  break;
                }
                let receiving_timestamp = get_datetime_now();
                // e.g.: sender_username_parts ['customer', '84691']
                let sender_username_parts = msg_rc.fields.args[0].payload.sender
                  .username.split('_');
                let sender_type = sender_username_parts[0];
                let sender_account_id = parseInt(sender_username_parts[1]);

                let sender =
                  {
                    name: msg_rc.fields.args[0].payload.sender.name,
                    username: msg_rc.fields.args[0].payload.sender.username,
                    type: sender_type,
                    account_id: sender_account_id,
                  };
                // check if sender name is set:
                if (typeof sender.name === 'undefined') {
                  send.name = strings[294]; /* unset */
                }
                if (msg_rc.fields.args[0].text) {
                  // get all family_relation account ids with status 5
                  // (registration at family portal complete)
                  state.api_msg.allowed_account_ids = [];
                  const sender_account_id = parseInt(sender.account_id) || 0;
                  const updateAllowedAccountIds = () => {
                    return new Promise(
                      (resolve) => {
                        Family_relations.getActiveRelations(
                          User.prof_id,
                          (family_relations) => {
                            let allowedAccountIds = [];
                            family_relations.map(
                              (fr) => {
                                allowedAccountIds = [
                                  ...allowedAccountIds, fr.cust_id];
                              }
                            );
                            resolve(allowedAccountIds);
                          }
                        );
                      }
                    );
                  };
                  const filterMsg = () => {
                    const msg_item = {
                      rocket_chat_id: msg_rc.fields.args[0].payload['_id'],
                      sender_account_id: sender_account_id,
                      receipient_account_id: User.prof_id,
                      prof_id: User.prof_id,
                      cust_id: sender_account_id,
                      content: msg_rc.fields.args[0].text,
                      receiving: receiving_timestamp,
                      // received from rocket-chat with rocket-chat-id
                      // ==> status = 2
                      status: 2,
                      // needed to add to msg-stream,
                      // if i.e. view
                      // refresh on message_view triggered
                      name: sender.name,
                    };
                    const parseMsgType = (thisMsgItem) => {
                      if (msg_item.content.substring(
                        0,
                        this.TW_VIDEO_TAG.length
                      ) === this.TW_VIDEO_TAG) {
                        api_msg.handleTwVideoMsg(thisMsgItem);
                      } else {
                        this.handle_new_text_msgs(
                          [msg_item],
                          sender_account_id
                        );
                      }
                    };
                    // only when the account_id is in family_relations table
                    // and registered at familyportal (status = 5)
                    if (
                      state.api_msg.allowed_account_ids
                        .includes(sender_account_id)
                    ) {
                      parseMsgType(msg_item);
                    } else if (
                      state.api_msg.allowedAccountsRefresh <=
                      new Date().getTime() - this.REFRESH_ALLOWED_ACCOUNTS_TIME
                    ) {
                      return Family_relations.pull()
                        .then( () => {
                          state.api_msg.allowedAccountsRefresh =
                            new Date().getTime();
                        })
                        .then(updateAllowedAccountIds)
                        .then(
                          (newAllowedAccountIds) => {
                            state.api_msg.allowed_account_ids =
                              newAllowedAccountIds;
                          }
                        )
                        .then(filterMsg);
                    }
                  };
                  updateAllowedAccountIds()
                    .then(
                      (newAllowedAccountIds) => {
                        state.api_msg.allowed_account_ids =
                          newAllowedAccountIds;
                      }
                    )
                    .then(filterMsg);
                }
              }
              break;
            case 'added': {
              const relation = state.api_msg.relations.find(
                (rel) => {
                  return msg_rc.fields.username.includes(rel.cust_id) &&
                    msg_rc.id;
                });
              if (relation !== undefined) {
                relation.online = true;
                relation.id = msg_rc.id;
              }
              Family_relations
                .getRelationsByStatus(Family_relations.StateEnum.invitationSent)
                .then((sentInvitations) => {
                  const yesWeNeedToPull = sentInvitations.find(
                    (el) => {
                      return msg_rc.fields.username.includes(el.cust_id);
                    });
                  if (yesWeNeedToPull !== undefined) {
                    return Family_relations.pull();
                  }

                  return Promise.resolve();
                })
                .then(() => {
                  if (jqm_activePage === PAGE_SETTINGS_PERSONAL_INVITATIONS) {
                    spi.build_content();
                  }
                });
            }
              break;

            case 'removed': {
              for (let i = 0; i < state.api_msg.relations.length; i++) {
                if (state.api_msg.relations[i].id === msg_rc.id) {
                  console.log('User went offline');
                  console.log(msg_rc.id);
                  state.api_msg.relations[i].online = false;
                }
              }
            }
              break;

            case 'result': {
              // syncing history:
              if (msg_rc.error) {
                console.error(msg_rc);
              }
            }
              break;
            case 'error':
              console.error(msg_rc);
              if (msg_rc.reason === 'Must connect first') this.rocketLogin();
              break;
            default:
          }
        };

        this.handle_new_text_msgs = (msg_items, sender_account_id) => {
          console.log('[api_msg.handle_new_text_msgs] msg_items', msg_items);

          const show_popup = function(msg_item) {
            const isImage = msg_item.content.startsWith('#Attachments:');

            notification_popup.show(
              isImage ? 'image' : 'message',
              msg_item.name,
              escapeHtml(msg_item.content),
              // account_id to be able to switch to that user:
              msg_item.cust_id
            )
              .then( () => {
                msg_item.status = 3;
              })
              .catch( () => {
                msg_item.status = 2;
              }).then(() => {
                Messages.updateStatusAndRcId(
                  msg_item.content,
                  msg_item.sender_account_id,
                  msg_item.status,
                  msg_item.rocket_chat_id,
                  msg_item.on_client_created
                );
                if (msg_item.status === 2) {
                  Messages
                    .countUnreadMsg(User.prof_id)
                    .then(Messages.updateHomeScreenUnreadMessages);
                }
              });
          };
          if (msg_items.length === 1) {
            Messages.insert(msg_items)
              .then( () => {
                console.log('[api_msg.handle_new_text_msgs] ONE message inserted');
                if (jqm_activePage === 'message') {
                  // check active account_id:
                  console.log(
                    '[api_msg.handle_new_text_msgs] active account id',
                    state.msgs.active_account_id
                  );
                  if (sender_account_id === state.msgs.active_account_id) {
                    message_view.append_msg({
                      'el': msg_items[0],
                      'show': true,
                    });
                    // set the status to "read":
                    msg_items[0].status = 3;
                    Messages.updateStatusAndRcId(
                      msg_items[0].content,
                      msg_items[0].sender_account_id,
                      msg_items[0].status,
                      msg_items[0].rocket_chat_id,
                      msg_items[0].on_client_created
                    );
                  }
                } else {
                  if (
                    !(state.videochat.in_call || jqm_activePage === 'videochat')
                  ) {
                    if (msg_items[0].name.length === 0) {
                      Family_relations.select_by_cust_id(
                        msg_items[0].cust_id,
                        (res) => {
                          msg_items[0].name = res[0].email;
                          show_popup(msg_items[0]);
                        }
                      );
                    } else {
                      show_popup(msg_items[0]);
                    }
                  }
                }
              });
          } else {
            if (jqm_activePage === 'message') {
              // we do not know, how many of the messages are actually new,
              // therefore we trigger refresh as callback of insert:
              Messages
                .insert(msg_items)
                .then(message_view.load)
                .catch(
                  (err) => {
                    console.error(err);
                  }
                );
            } else {
              Messages
                .insert(msg_items)
                .then(() => {
                  return Messages.countUnreadMsg(User.prof_id)
                })
                .then((amount) =>
                  Messages.updateHomeScreenUnreadMessages(amount)
                );
            }
          }
        };

        /**
         * This loads the last `message_nb` messages between the current user
         * and the `contact_username`.
         *
         * It inserts the messages into the Messages table and triggers the update
         * of the message view.
         *
         * @param {string} contact_username - A Rocket.Chat username
         *                                    e.g.: `customer_84674`
         * @param {number} message_nb - Number of last messages to be loaded.
         */
        this.getMessageHistory = (contact_username, message_nb) => {
          wsp.open()
            .then(
              () => {
                return initDirectMessage(contact_username);
              }
            )
            .then(
              (room_id) => {
                return loadMessages(room_id, message_nb);
              }
            )
            .then(
              (response) => {
                let raw_msgs = response.result.messages;
                console.log('[api_msg.getMessageHistory] raw_msgs', raw_msgs);
                let filtered_msgs = [];
                let sender_account_id = 0;
                raw_msgs.map(
                  (message) => {
                    if (message.msg.substring(0, 9) !== this.TW_VIDEO_TAG) {
                      let sender_account_id = parse_account_id(message.u.username);
                      let recipient_account_id = User.prof_id;

                      // If the sender is the current user, then the recipient id
                      // is set to the account id of the user to synchronize with.
                      if ( sender_account_id === User.prof_id) {
                        recipient_account_id = parse_account_id(contact_username);
                      }
                      let this_msg = {
                        rocket_chat_id: message['_id'],
                        sender_account_id: sender_account_id,
                        receipient_account_id: recipient_account_id,
                        prof_id: User.prof_id,
                        cust_id: parse_account_id(contact_username),
                        content: message.msg,
                        receiving: date_from_unix_timestamp(message.ts['$date']),
                        // received from rocket-chat with id ==> status = 2
                        status: 2,
                        on_client_created: message['on_client_created'],
                      };
                      filtered_msgs.push(this_msg);
                    }
                  }
                );
                console.log(
                  '[api_msg.getMessageHistory] filtered_msgs',
                  filtered_msgs
                );
                if (filtered_msgs.length > 0) {
                  self.handle_new_text_msgs(filtered_msgs, sender_account_id);
                }
              }
            )
            .catch(
              function(e) {
                console.error(e);
              }
            );
        };

        /**
         * Send Message with the content/`message_body` to `username`.
         * The `on_client_created` param is mandatory to make the message
         * indistinguishable.
         * @param {string} username - The name of the user in RC,
         *  e.g. `customer_84674`.
         * @param {string} message_body - The content/body of the message.
         * @param {string} on_client_created - When the message was created on the
         *  client, e.g.: `2017-11-02T14:09:15Z`.
         */
        this.sendRocketMessage = function(username, message_body, on_client_created) {
          wsp.open()
            .then(
              () => {
                return initDirectMessage(username);
              }
            )
            .then(
              (room_id) => {
                console.log('[api_msg] Send message_body to room id: ' + room_id);
                return sendMessage(room_id, message_body, on_client_created);
              }
            )
            .then(
              (response) => {
                console.log('[api_msg.sendRocketMessage] response', response);
                Messages.updateStatusAndRcId(
                  content = response.result.msg,
                  sender_account_id = parse_account_id(response.result.u.username),
                  status = 2,
                  rocket_chat_id = response.result['_id'],
                  on_client_created = response.result.on_client_created)
                  .catch(
                    (error) => {
                      // Checks if the error is connected to an constrained error.
                      // In some weird case we have the same message twice,
                      // they differ only in the rocketchat id. Once 'unsend' and
                      // once with id. The message with 'unsend' can then not be
                      // updated and will be sent again and again.
                      if (error.code === 6) {
                        Messages.deleteObsoleteUnsent(
                          response.result.msg,
                          parse_account_id(response.result.u.username),
                          response.result.on_client_created
                        ).then((res)=>{
                          console.log(
                            '[api_msg.sendRocketMessage] deleted unsend content,',
                            response.result.msg
                          );
                          console.log(
                            '[api_msg.sendRocketMessage] deleted unsend res,',
                            res
                          );
                        }).catch(
                          (err) => console.error(err)
                        );
                      } else {
                        console.error(error);
                      }
                    }
                  );
              }
            )
            .catch(
              (e) => {
                console.error(e);
              }
            );
        };

        // send video-call invitation to rocket.chat user and enter video-room:
        this.sendVideoCall = (username) => {
          return new Promise((resolve, reject) => {
            state.videochat.partner = username;
            const partner = `customer_${username}`;
            const currTimestamp = new Date().getTime();
            const videoChannel = `${self.username}_${currTimestamp}`;
            state.videochat.channel = videoChannel;
            this.sendRocketMessage(partner, this.TW_VIDEO_TAG + videoChannel);
            videochat.room_name = videoChannel;
            resolve();
          });
        };

        this.rocketLogin = () => {
          console.log('rocketLogin');
          // check valid User.prof_id:
          if (User.prof_id > 0) {
            api_msg.username = `device_user_${User.prof_id}`;
            api_msg.userEmail = `device_user_${User.prof_id}@media4care.de`;
          } else {
            // upload and download User to retrieve valid prof_id:
            Usync.send_users().get_user();
          }
          if (!wsp.isClosed) {
            console.log('closing wsp');
            wsp
              .close()
              .then( self.doRocketLogin );
          } else {
            console.log('no need to close wsp');
            self.doRocketLogin();
          }
        };

        this.doRocketLogin = () => {
          wsp
            .open()
            .then(
              function() {
                connect();
              }
            )
            .then(
              () => {
                return login(self.username, self.passw256);
              }
            )
            .then(
              (response) => {
                if (response.error) {
                  if (response.error.reason === 'User not found') {
                    console.log('not_logged_in');
                    // maybe old login creds are set:
                    if (!state.api_msg.auth_secret_password) {
                      console.warn('resetting to Auth.secret');
                      state.api_msg.auth_secret_password = true;
                      self.set_auth_secret_as_password();
                      self.rocketLogin();
                      // reset if not => register with new random_secret:
                    } else {
                      console.warn('still not, registering with new creds');
                      state.api_msg.auth_secret_password = false;
                      self
                        .set_password()
                        .then(
                          () => {
                            self
                              .registerUser(self.username, self.passw, self.userEmail)
                              .then(
                                (response) => {
                                  console.error('after register success');
                                  console.log(response);
                                  self.rocketLogin();
                                }
                              )
                              .catch(
                                (err) => {
                                  console.log('catched register ERROR');
                                  console.error(err);
                                  self.rocketLogin();
                                }
                              );
                          }
                        );
                    }
                  }
                } else {
                  console.log(response.result.id);
                  self.user_id = response.result.id;
                }
              }
            )
            .then(
              function() {
                streamAllMessage('21', 'message', self.user_id);
              }
            )
            .then(
              function() {
                streamAllMessage('22', 'otr', self.user_id);
              }
            )
            .then(
              function() {
                streamAllMessage('23', 'notification', self.user_id);
              }
            )
            .then(
              function(res) {
                subscribe('24', 'userData');
              }
            )
            .then(
              function(res) {
                console.log(res);
                subscribe('25', 'activeUsers');
              }
            )
            // .then(
            //   () => {
            //     self.updateName(`${User.firstname} ${User.lastname}`);
            //   }
            // )
            .then(
              self.sync_on_startup
            )
            .catch(
              function(e) {
                console.error('login last catch');
                console.error(e);
              }
            );
        };

        this.registerUser = function(username, password, emailAddress) {
          return new Promise(
            (resolve, reject) => {
              $.ajax(
                {
                  type: 'POST',
                  contentType: 'application/json',
                  url: window.api_msg.rest_url + '/api/v1/users.register',
                  headers: {'Token': Auth.token},
                  // timeout === 0 ==> no-timeout
                  timeout: 0,
                  data: JSON.stringify( {
                    'username': username,
                    'email': emailAddress,
                    'pass': password,
                    'name': `${User.firstname} ${User.lastname}`,
                  }),
                  success: function(data) {
                    resolve('success');
                  },
                  error: function(error) {
                    console.log(error);
                    reject('failure');
                  },
                }
              );
            }
          );
        };

        this.sendPong = () => {
          wsp.sendPacked(
            {msg: 'pong'}
          );
        };
        // constantly check if still connected:
        this.interval_wsp = () => {
          const tryLogin = () => {
            if (thisapp_online && wsp.isClosed && [6, 8].includes(utilization) ) {
              self.reset_creds();
              self.rocketLogin();
            }
          };
          tryLogin();
          setInterval(
            () => {
              // if (thisapp_online && wsp.isClosed && [6, 8].includes(utilization) ) {
                // self.reset_creds();
                // self.rocketLogin();
                tryLogin();
              // }
            },
            60*1000
          );
        };

        /**
         * Updates the name of the current user.
         * @param {string} name - The new display name of the user to be set.
         */
        this.updateName = function(name) {
          loginREST(self.username, self.passw)
            .catch(
              (err) => console.error(err)
            )
            .then(
              (result) => {
                let settings = {
                  'url': window.api_msg.rest_url + '/api/v1/users.updateOwnBasicInfo',
                  'method': 'POST',
                  'headers': {
                    'Token': Auth.token,
                    'x-user-id': result.data.userId,
                    'x-auth-token': result.data.authToken,
                    'content-type': 'application/json',
                  },
                  'processData': false,
                  'data': `{\n\t\"data\":{\n\t\t\"name\": \"${name}\"\n\t}\n}`,
                };

                return new Promise(
                  function(resolve, reject) {
                    $.ajax(settings).done(
                      () => resolve('success')
                    ).fail(
                      (jqXHR, textStatus) => {
                        if (textStatus === 'error') {
                          let errorText = JSON.parse(jqXHR.responseText);
                          // RC has throws an error if the name update is to often
                          // requested in 60sec, if this happens execute it twice.
                          if (errorText.errorType === 'error-too-many-requests') {
                            setTimeout(
                              () => {
                                $.ajax(settings)
                                  .done(() => resolve('success'))
                                  .fail(() => reject('failure'));
                              },
                              60000
                            );
                          } else {
                            reject('failure');
                          }
                        } else {
                          reject('failure');
                        }
                      }
                    );
                  }
                );
              }
            );
        };

        this.start = () => {
          return api_msg
            .set_password()
            .then(() => {
              // start check interval 60 later to allow init on startup
              setTimeout(
                api_msg.interval_wsp,
                // 60 * 1000
                0
              );
            });
        };
      };
      resolve();
    }
  );
};

function subscribe(id, name, event = '') {
  return wsp.sendRequest(
    {
      'msg': 'sub',
      'id': id,
      'name': name,
      'params': [event, false],
    }
  );
}

const createWsp = () => {
  return new Promise(
    (resolve, reject) => {

      window.wsp = new WebSocketAsPromised(
        api_msg.ws_url,
        {
          packMessage: function (data) {
            return JSON.stringify(data);
          },
          unpackMessage: function (message) {
            return JSON.parse(message);
          },
          attachRequestId: (data, requestId) => Object.assign({id: requestId}, data),
          // attach requestId to message as `id` field
          extractRequestId: (data) => data && data.id,
          // read requestId from message `id` field
        }
      );

      wsp.onMessage.addListener(
        (data) => {
          console.log(data);
          api_msg.onMessage(data);
        }
      );
      resolve();
    }
  );
};


function connect() {
  wsp.sendPacked(
    {
      'msg': 'connect',
      'version': '1',
      'support': ['1'],
    }
  );
}

function login( username, password) {
  return wsp.sendRequest(
    {
      'msg': 'method',
      'method': 'login',
      'params':
      [
        {
          user: {
            username: username,
          },
          password: {
            digest: password,
            algorithm: 'sha-256',
          },
        },
      ],
    }
  );
}

/**
 * Login to Rocket.Chat based on the Rocket.Chat REST API.
 * @param {string} username - The user to login.
 * @param {string} password - The password of the user to login.
 * @return {Promise<any>}
 */
function loginREST(username, password) {
  let settings = {
    'url': api_msg.rest_url + '/api/v1/login',
    'method': 'POST',
    'headers': {
      'Token': Auth.token,
      'content-type': 'application/x-www-form-urlencoded',
    },
    'data': {
      'username': username,
      'password': password,
    },
  };

  let promise = new Promise(
    function(resolve, reject) {
      $.ajax(settings).done(
        (response) => resolve(response)
      ).fail(
        (response) => reject('failure')
      );
    }
  );
  return promise;
}


function createChannel(id, channel_name, usernames, read_only) {
  wsp.sendPacked(
    {
      'msg': 'method',
      'method': 'createChannel',
      'id': id,
      'params':
        [
          channel_name,
          usernames,
          read_only,
        ],
    }
  );
}

/**
 * Sends `message_text` created on `on_client_created` to the room `room_id`
 * @param {string} room_id - The room id for where to send this message.
 * @param {string} message_text - The message body
 *  (the text of the message itself).
 * @param {string} on_client_created - The timestamp in  ISO8601
 *  (e.g. 2017-11-02T14:09:15Z).
 * @return {Promise}
 */
function sendMessage(room_id, message_text, on_client_created) {
  return wsp.sendRequest(
    {
      'msg': 'method',
      'method': 'sendMessage',
      'params':
        [
          {
            'rid': room_id,
            'msg': message_text,
            'on_client_created': on_client_created,
          },
        ],
    }
  );
}

/**
 * Prior to sending a direct message to a user, a direct message
 * should be initialized/created.
 * This will return a room ID of the created room,
 * which you can then use to send future messages.
 * Making this call additional times, if a room has already been established,
 * does not result in an error. In addition, the same roomID is returned.
 * @param {string} username - user you want to write.
 * @return {Promise<string>} - The room id of the initialized/created room.
 */
function initDirectMessage(username) {
  return wsp.sendRequest(
    {
      'msg': 'method',
      'method': 'createDirectMessage',
      'params': [
        username,
      ],
    }
  ).then((response) => {
    if (response.result) {
      return response.result.rid;
    }

    return Promise.reject({error: response.error, username});
  });
}

function loadMessages(roomid, message_nb) {
  return wsp.sendRequest(
    {
      'msg': 'method',
      'method': 'loadHistory',
      'params': [
        roomid, null, message_nb, {'$date': 0},
      ],
    }
  );
}

function setReaction(id, message_id, reaction) {
  wsp.sendPacked(
    {
      'msg': 'method',
      'method': 'setReaction',
      'id': id,
      'params': [
        reaction,
        message_id,
      ],
    }
  );
}

function streamAllMessage(id, event, luserid) {
  let param1 = luserid + '/' + event;
  wsp.sendPacked(
    {
      'msg': 'sub',
      'id': id,
      'name': 'stream-notify-user',
      'params':
        [
          param1,
          false,
        ],
    }
  );
}

function joinChannel(id, room_id, reaction, join_code = '') {
  wsp.sendPacked(
    {
      'msg': 'method',
      'method': 'joinRoom',
      'id': id,
      'params':
        [
          room_id,
          join_code,
        ],
    }
  );
}

/**
 * Parse the account id from the Rocket.Chat username
 * @param {string} username - The username e.g. "device_user_2292080"
 * @return {number} account_id - 2292080
 */
function parse_account_id(username) {
  return parseInt(
    username
      .replace('device_user_', '')
      .replace('customer_', '')
  );
}
