/*
 * add window objects replacements to use Application in Browser
 * if you add a cordova plugin to the project, please add proper
 * replacements or maybe even mapings to browser features here
 * so we do not need to check everywhere if they exist
 */
const browser_plugins_replacements = () => {
  return new Promise(
    (resolve) => {
      // cordova-plugin-device
      window.device = {};
      // cordova-plugin-network-information:
      // TODO: add onOnline onOffline Event Listeners:
      // database mapper to webSQL
      core.load_js('SQLitePlugin.js', 'sqlitePlugin').then(resolve);
      // navigator.connection = {};
      navigator.connection.type = 'wifi';
      window.resolveLocalFileSystemURL = (url, successCB, errorCB) => {
        if ($.isFunction(errorCB)) {
          errorCB();
        } else {
          return false;
        }
      };
    }
  );
};
