// function for binding headerButtons:
window.header = {};
let page_id_before_settings = '';
let previous_page_id = 'home';
function go_help() {
  previous_page = document.URL;
  previous_page_id = $.mobile.activePage.attr('id');
  page.go_to('help.html');
  if (utilization === 6) {
    util6.build_header('settings-util');
    if (v.d) console.log('in home js go help ...');
  }
}

function go_settings() {
  previous_page = document.URL;
  if (utilization === 4) {
    profile_checkpassword_red = 'settings.html';
    page.go_to('profile_checkpassword.html');
  }
  else {
    page.go_to('settings.html');
  }
}

let bind_headerbuttons = function() {
  if (is_app > 0) {
    bind_headerbuttons_app();
  }
  else {
    bind_headerbuttons_web();
  }
};
header.bindBtns = bind_headerbuttons;

function unbind_headerbuttons() {
  if (is_app > 0) {
    unbind_headerbuttons_app();
  } else {
    unbind_headerbuttons_web();
  }
}

function unbind_headerbuttons_app() {
  $('.homebtn').off('touchstart').off('touchend');
  $('.backbtn').off('touchstart').off('touchend');
  $('.hilfe_home').off('touchstart').off('touchend');
  $('.settings').off('touchstart').off('touchend');
  $('.home_profile').off('touchstart').off('touchend');
}

function unbind_headerbuttons_web() {
  $('.homebtn').unbind('vclick');
  $('.backbtn').unbind('vclick');
  $('.hilfe_home').unbind('vclick');
  $('.settings').unbind('vclick');
  $('.home_profile').unbind('vclick');
}

function bind_headerbuttons_app() {

  $('.homebtn')
    .off()
    .on('touchstart', function (event) {
      event.preventDefault();
      $(this).addClass('homebtn_active');
    })
    .on('touchend', function (event) {
      event.preventDefault();
      $(this).removeClass('homebtn_active');
      unbind_headerbuttons();
      goHome();
    });

  $('.home_profile')
    .off()
    .on('touchstart', function (event) {
      event.preventDefault();
      $(this).addClass('homebtn_active');
    })
    .on('touchend', function (event) {
      event.preventDefault();
      $(this).removeClass('homebtn_active');
      unbind_headerbuttons();
      if (utilization === 4) {
        goHome();
      } else {
        page.go_to('profile_page.html');
      }
    });

  $('.backbtn')
    .off()
    .on('touchstart', function (event) {
      event.preventDefault();
      $(this).addClass('backbtn_active');
    })
    .on('touchend', function (event) {
      event.preventDefault();
      $(this).removeClass('backbtn_active');
      //unbind_headerbuttons();
      goback();
    });

  //Ende button in Videos and games
  if (utilization === 6) {
    $('.cancel_btn')
      .off()
      .on('touchstart', function (event) {
        event.preventDefault();
        console.log('zurück');
        //$(this).addClass('backbtn_active');
      })
      .on('touchend', function (event) {
        event.preventDefault();
        //$(this).removeClass('backbtn_active');
        //unbind_headerbuttons();
        goHome();
      });
  }

  $('.hilfe_home')
    .off()
    .on('touchstart', function (event) {
      event.preventDefault();
      //$(this).addClass('media_menu_active');
    })
    .on('touchend', function (event) {
      event.preventDefault();
      //$(this).removeClass('media_menu_active');
      unbind_headerbuttons();
      event.preventDefault();
      previous_page = document.URL;
      previous_page_id = $.mobile.activePage.attr('id');
      page.go_to('help.html');
    });
  $('.settings')
    .off()
    .on('touchstart', function (event) {
      event.preventDefault();
      page_id_before_settings = $.mobile.activePage.attr('id');
      console.log(page_id_before_settings);
      //$(this).addClass('media_menu_active');
    })
    .on('touchend', function (event) {
      event.preventDefault();
      //$(this).removeClass('media_menu_active');
      unbind_headerbuttons();
      unbind_start_buttons_nav();
      event.preventDefault();
      previous_page = document.URL;

      if (utilization === 4) {
        profile_checkpassword_red = 'settings.html';
        page.go_to('profile_checkpassword.html');
      }
      else {
        page.go_to('settings.html');
      }
    });
}

function bind_headerbuttons_web() {
  $('.homebtn').off().on(
    'vclick',
    function (event) {
      unbind_headerbuttons();
      event.preventDefault();
      goHome();
    }
  );
  $('.backbtn').off().on(
    'vclick',
    function (event) {
      unbind_headerbuttons();
      event.preventDefault();
      goback();
    }
  );
  $('.home_profile').off().on(
    'vclick',
    function (event) {
      unbind_headerbuttons();
      event.preventDefault();
      if (utilization === 4) {
        goHome();
      } else {
        page.go_to('profile_page.html');
      }
    }
  );
  $('.hilfe_home').off().on(
    'vclick',
    function (event) {
      unbind_headerbuttons();
      event.preventDefault();
      previous_page = document.URL;
      previous_page_id = $.mobile.activePage.attr('id');
      page.go_to('help.html');
    });
  $('.settings').off().on(
    'vclick',
    function (event) {
      page_id_before_settings = $.mobile.activePage.attr('id');
      console.log(page_id_before_settings);
      unbind_headerbuttons();
      unbind_start_buttons_nav();
      event.preventDefault();
      previous_page = document.URL;

      if (utilization === 4) {
        profile_checkpassword_red = 'settings.html';
        page.go_to('profile_checkpassword.html');
      } else {
        page.go_to('settings.html');
      }
    });
}


header.buildDefault = () => {
  console.warn(
    '++++Rebuilding/building homeheader: utilization -> ' + utilization
  );
  unbind_headerbuttons();
  $('#homeheader')
    .attr('style', 'box-shadow: 1px 1px 14px 7px rgba(0, 0, 0, 0.2);')
    .html('<div class="iconbar">' +
      '<div id="imprint_btn" class="iconbar--imprint" class="iconbar--imprint">&copy; media4care</div>' +
      '<div class="uhrzeit iconbar--clock"></div>' +
      '<div class="battery"></div>' +
      '</div>' +
      '<div class="homebtn" data-role="button" role="button"></div>' +
      '<div class="backbtn"></div>' +
      '<h2 class="ui-title header--title" style="margin-top: 9px !important;">Hauptmen&uuml;</h2>' +
      '<div class="settings header--settings" class="ui-btn-right">&nbsp;</div>' +
      '<div class="hilfe_home header--help">&nbsp;</div>' +
      '<div style="display:none;" id="lang_btns_wrap">' +
      '<div class="btn_lang_de" style="margin-left:20px; border-color: rgba(218,218,217,1);">&nbsp;</div>' +
      '<div class="btn_lang_pl" style="margin-left:20px; border-color: rgba(218,218,217,1);">&nbsp;</div>' +
      '</div>'
    );
  $('.backbtn').hide();
  $('.homebtn').hide();
  $('#homeheader').removeClass('util6--homeheader');
  header.bindBtns();
};
