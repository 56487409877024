/* uses home html view - all bootscreen related blocks:*/
const HOME_BOOTSCREEN_CONSTANTS = Object.freeze({
  barColor: Object.freeze({
    default: '#117cc2',
  }),
});
class homeBootscreen {
  render(
    msg = '',
    progressBarOptions
  ) {
    progressBarOptions = Object.assign(
      {
        show: false,
        overallProgress: 0,
        barColor: HOME_BOOTSCREEN_CONSTANTS.barColor.default,
        timeLeft: '0:00',
        fileDisplay: '',
      },
      progressBarOptions
    );
    let progressBar = '';
    if (progressBarOptions.show === true) {
      progressBar = `<div style="text-align:center;margin-top:100px;">
        <span style="font-size:23px;">
            ${
              parseFloat(progressBarOptions.overallProgress)
                .toFixed(2)
                .toString()
            } %
        </span>
        <div
            id="download_progress_bar"
            style="
                background-color:${progressBarOptions.barColor};
                width:${(progressBarOptions.overallProgress * 0.6).toString() + '%'};
                color:white;
                height:18px;
                z-index:1000;
                position:relative;
                margin:0 20% 0 20%;">
            &nbsp;
        </div>
        <div
            id="download_progress_bar_background"
            style="
                background-color:#ececec;
                width:60%;
                color:white;
                height:18px;
                position:relative;
                top:-18px;
                z-index:0;
                margin:0 20% 0 20%;">
            &nbsp;
        </div>
        <span style="color:#555;font-size:18px;">
            Restzeit: ${progressBarOptions.timeLeft}
        </span><br>
        <span style="color:#555;font-size:18px;">${progressBarOptions.fileDisplay}</span>
      </div>`;
    }
    $('#home')
      .html(
        `<div class="loading_top">
            <div class='pulsating-circle'></div>
        </div>
        <div class="loading_middle">
            <img src="${home_dir}img/m4c_logo.png" style=""/><br>
            <div class="akt">
                ${msg}
            </div>
        </div>
        ${progressBar}`
      );
  }
}
// TODO: not defined handling on instantiating:
const controllers = {
  home: {
    bootscreen: new homeBootscreen(),
  },
};
