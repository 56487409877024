
function refresh_battery_service() {
  if (v.d) console.log('refreshing battery');
  window.removeEventListener('batterystatus', onBatteryStatus, false);
  setTimeout(function () {
    window.addEventListener('batterystatus', onBatteryStatus, false);
    if (v.d) console.log('refreshing battery restarted');
  }, 1000);
}

function refresh_onlinestates() {
  document.removeEventListener('offline', onOffline, false);
  document.removeEventListener('online', onOnline, false);
  document.addEventListener('offline', onOffline, false);
  document.addEventListener('online', onOnline, false);
}

function onOffline() {
  checkConnection();
}

function onOnline() {
  checkConnection();
  if ([5, 6, 8].includes(utilization)) {
    thisapp.enable_family_sync();
  }
}

function checkConnection() {
  const networkState = navigator.connection.type;
  const conn_states = {};
  conn_states[Connection.UNKNOWN] = 'Unknown connection';
  conn_states[Connection.ETHERNET] = 'Ethernet connection';
  conn_states[Connection.WIFI] = 'WiFi connection';
  conn_states[Connection.CELL_2G] = 'Cell 2G connection';
  conn_states[Connection.CELL_3G] = 'Cell 3G connection';
  conn_states[Connection.CELL_4G] = 'Cell 4G connection';
  conn_states[Connection.CELL] = 'Cell generic connection';
  conn_states[Connection.NONE] = 'No network connection';

  if (v.d) console.log('Connection type: ' + conn_states[networkState]);
  thisapp_online = true;
  switch (conn_states[networkState]) {
    case 'No network connection':
      state.connected.generic = false;
      state.connected.wifi = false;
      state.connected.mobile = false;
      thisapp_online = false;
      break;
    case 'Unknown connection':
      state.connected.generic = true;
      state.connected.wifi = false;
      state.connected.mobile = false;
      break;
    case 'WiFi connection':
    case 'Ethernet connection':
      state.connected.generic = true;
      state.connected.wifi = true;
      state.connected.mobile = false;
      break;
    // rest must be mobile:
    default:
      state.connected.generic = true;
      state.connected.wifi = false;
      state.connected.mobile = true;
      break;
  }
  if (state.connected.wifi) {
    $('.wifi_icon').html(state.icon_strings.wifi);
  }
  else {
    $('.wifi_icon').html('');
  }
}
