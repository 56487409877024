// Fotos.status:
// 0, 1 = active
// 3 = toBeDeleted / Deleted

// Fotos.type:
// 5 = Image received from FamilyPortal Picture Sending Feature
// 4 = video owned and captured by tabletUser
// 3 = image owned and captured by tabletUser
// 1 and 2 are deprecated and not used anymore
const PERSONAL_MEDIA_TYPES = Object.freeze({
  TABLET_IMAGE: 3,
  TABLET_VIDEO: 4,
  TIMELINE_IMAGE: 5,
  MESSAGE_IMAGE: 6,
});

const createFotosModel = () => {
  return new Promise(
    (resolveCreate, rejectCreate) => {
      window.Fotos = {};
      Fotos.STATUS_STATES = Object.freeze({
        // active and default are equal, due to sql active is to be prefered
        'default': 0,
        'active': 1,
        'toDelete': 3,
        'deleted': 4,
      });
      Fotos.SEEN_STATES = Object.freeze({
        'notSeen': 1,
        'seen': 2,
      });
      Fotos.LOCAL_AVAILABLE = Object.freeze({
        'false': 0,
        'true': 1,
      });
      //TODO;: execute on init seen + state altering for fotos table
      Fotos.check_settings = function() {
        return new Promise(
          (resolve) => {
            Settings.select(
              'Fotos_seen',
              function(setting) {
                if (setting === 'done') {
                  if (v.d) {
                    console.log('app is prepared for new Fotos Alert');
                  }
                  resolve();
                } else {
                  Fotos.alter_table_msgs(resolve);
                }
              }
            );
          }
        );
      };

      Fotos.alter_table_msgs = function(resolve) {
        db.transaction(
          function (tx) {
            // check if Foto or msgs has already been seen by user
            // 1 = not seen, 2 = seen
            tx.executeSql(
              'ALTER TABLE Fotos add seen INTEGER DEFAULT 1',
              [],
              function (tx, result) {
                Settings.insert(
                  'Fotos_seen',
                  'done',
                  () => {
                    console.log('set Fotos_seen = done');
                    // Fotos.check_settings();
                    resolve();
                  }
                );
              }
            );
          },
          function (e) {
            console.log('ERROR: ' + e.message);
            Settings.insert(
              'Fotos_seen',
              'done',
              function () {
                console.log('set Fotos_seen = done');
                // Fotos.check_settings();
                resolve();
              }
            );
          }
        );
      };


      Fotos.check_settings_foto_status = function() {
        return new Promise(
          (resolve) => {
            Settings.select(
              'Fotos_status',
              function(setting) {
                if (setting === 'done') {
                  if (v.d) {
                    console.log('app is prepared for new Fotos Alert');
                  }
                  resolve();
                } else {
                  Fotos.alter_table_status(resolve);
                }
              }
            );
          }
        );
      };


      Fotos.alter_table_status = function(resolve) {
        db.transaction(function (tx) {
          // check if Foto or msgs has already been seen by user
          // 1 = not seen, 2 = seen
          tx.executeSql(
            'ALTER TABLE Fotos add status INTEGER DEFAULT 1',
            [],
            function(tx, result) {
              Settings.insert('Fotos_status', 'done', function() {
                console.log('set Fotos_status = done');
                resolve();
              });
            }
          );
        },
        function (e) {
          console.log('ERROR: ' + e.message);
          Settings.insert(
            'Fotos_status',
            'done',
            function () {
              console.log('set Fotos_status = done');
              resolve();
            }
          );
        });
      };


      Fotos.check_settings_hashes = function() {
        return new Promise(
          (resolve, reject) => {
            Settings.select('Fotos_photo_url_hash',
              function(setting) {
                console.log(setting);
                if (setting === 'done') {
                  console.log('app is prepared for new Fotos Hashes');
                  resolve();
                } else {
                  Fotos.alter_table_photo_url_hashes(resolve, reject);
                }
              }
            );
          }
        );
      };

      Fotos.alter_table_photo_url_hashes = function(resolve) {
        db.transaction(
          function (tx) {
            tx.executeSql(
              'ALTER TABLE Fotos add photo_url_hash TEXT',
              [],
              function (tx, result) {
                Settings.insert(
                  'Fotos_photo_url_hash',
                  'done',
                  () => {
                    console.log('set photo_url_hash = done');
                    resolve();
                  }
                );
              }
            );
          },
          function (e) {
            console.log('ERROR: ' + e.message);
            Settings.insert('Fotos_photo_url_hash',
              'done',
              () => {
                console.log('set photo_url_hash = done');
                resolve();
              }
            );
          }
        );
      };
      Fotos.check_settings_prev_hashes = () => {
        return new Promise(
          (resolve, reject) => {
            Settings.select(
              'Fotos_prev_url_hash',
              (setting) => {
                console.log(setting);
                if (setting === 'done') {
                  console.log('app is prepared for new Foto Prevs Hashes');
                  resolve();
                } else {
                  Fotos.alter_table_prev_url_hashes(resolve, reject);
                }
              }
            );
          }
        );
      };

      Fotos.alter_table_prev_url_hashes = function(resolve) {
        db.transaction(
          function (tx) {
            tx.executeSql(
              'ALTER TABLE Fotos add prev_url_hash TEXT',
              [],
              function (tx, result) {
                Settings.insert(
                  'Fotos_prev_url_hash',
                  'done',
                  () => {
                    console.log('set prev_url_hash = done');
                    resolve();
                  }
                );
              }
            );
          },
          function (e) {
            console.log('ERROR: ' + e.message);
            Settings.insert(
              'Fotos_prev_url_hash',
              'done',
              function () {
                console.log('set prev_url_hash = done');
                Fotos.check_settings_hashes();
              }
            );
          }
        );
      };

      Fotos.update_photourlhash_by_photourl = function(photo_url_hash, photo_url) {
        let q = '';
        if (photo_url.indexOf('prev') > -1) {
          q = 'UPDATE Fotos SET prev_url_hash = ? where prev_url = ?';
        } else {
          q = 'UPDATE Fotos SET photo_url_hash = ? where photo_url = ?';
        }
        db.transaction(
          function(tx) {
            // check if Foto or msgs has already been seen by user
            // 1 = not seen, 2 = seen
            tx.executeSql(
              q,
              [photo_url_hash, photo_url],
              function (tx, result) {
                console.log(`updated Fotos photo_url_hash = ${photo_url_hash}
                for photo_url = ${photo_url}`);
              }
            );
          },
          (e) => {
            console.log('ERROR: ' + e.message);
          }
        );
      };

      Fotos.update_seen_by_userid_fotoid = function(fuserid, ffotoid, ffoto_url) {
        // Notify the MSG_SYNC_LOG-Service (foto_clicked):
        // no complete ES6 support in current crosswalk therefore:
        if (typeof(ffoto_url) !== 'undefined') {
          let l_foto_url = ffoto_url
            .replace('file:///storage/emulated/0/media4carefotos/', '');
          msg_sync_log.update_api(
            l_foto_url,
            api_endpoints.msg_sync_foto_clicked
          );
          const now = get_datetime_now();
          db.transaction(
            function(tx) {
              tx.executeSql(
                `UPDATE Fotos SET 
                  seen = ?, last_edit = ?
                  where userid = ? 
                    AND (seen = ? OR seen is null OR seen = ?) 
                    AND fotoid = ?`,
                [
                  Fotos.SEEN_STATES.seen,
                  now,
                  fuserid,
                  Fotos.SEEN_STATES.notSeen,
                  0,
                  ffotoid,
                ],
                (tx, result) => {
                  if (v.d) {
                    console.log('updated Fotos seen = 2 for userid = ' +
                      fuserid + ' and fotoid = ' + ffotoid);
                  }
                }
              );
            },
            function (e) {
              if (v.d) {
                console.log('ERROR: ' + e.message);
              }
            }
          );
        }
      };

      Fotos.update_local_available_by_photourl_hash = function(foto_url) {
        let now = get_datetime_now();
        db.transaction(function(tx) {
          tx.executeSql(
            `UPDATE Fotos 
              SET local_available = ?,
              last_edit = ?
            where local_available = ? AND photo_url_hash = ?`,
            [Fotos.LOCAL_AVAILABLE.true, now, 0, foto_url],
            function (tx, result) {
              console.log('updated Fotos local_available = 1 for photo_url = '
                + foto_url);
            }
          );
        }, function (e) {
          console.log("ERROR: " + e.message);
        });
      };

      Fotos.set_status = function (fuserid, ffotoid, f_cb) {
        const now = get_datetime_now();
        db.transaction(
          function (tx) {
            // check if Foto or msgs has already been seen by user
            // 1 = not seen, 2 = seen
            tx.executeSql(
              `UPDATE Fotos
                SET 
                  status = ?,
                  last_edit = ? 
                  where userid = ? AND fotoid = ?`,
              [Fotos.STATUS_STATES.deleted, now, fuserid, ffotoid],
              function (tx, result) {
                console.log('updated Fotos status = 3 for userid = '
                  + fuserid + ' and fotoid = ' + ffotoid);
                if ($.isFunction(f_cb)) {
                  f_cb();
                }
              }
            );
          },
          function(e) {
            console.log('ERROR: ' + e.message);
          });
      };

      Fotos.select_to_remove = function (f_cb) {
        db
          .transaction(function (transaction) {
            transaction
              .executeSql(
                'SELECT F.* from Fotos F ' +
                'WHERE F.status = ? ;', [3],
                function (transaction, result) {
                  if (result !== null && result.rows !== null) {
                    let length = result.rows.length;
                    let arr = [];
                    for (let i = 0; i < length; i++) {
                      arr[i] = result.rows.item(i);
                    }
                    if ($.isFunction(f_cb)) f_cb(arr);
                  }
                }, errorHandler);
          });
      };

      Fotos.remove_all_pictures_from_db = function () {
        db
          .transaction(function (tx) {
            tx
              .executeSql(
                'DELETE FROM Fotos ', [], function (tx, result) {
                  for (i = 0; i < result.rows.length; i++) {
                    console.log('Removing this picture from the phone database : '
                      + result.rows.item(i).photo_url);
                  }
                });
          });
      };

      Fotos.select_all_pictures = function (c_b) {
        db.transaction(
          function (transaction) {
            transaction
              .executeSql(
                'SELECT * FROM Fotos ', [],
                function (transaction, result) {
                  if (result !== null && result.rows !== null) {
                    let length = result.rows.length;
                    let arr = [];
                    for (let i = 0; i < length; i++) {
                      let row = result.rows.item(i);
                      arr[i] = row;
                    }
                    console.log("Printing array in select func");
                    console.log(arr);
                    if ($.isFunction(c_b)) c_b(arr);
                  }
                }, errorHandler);
          }
        );
      };

      Fotos.remove_files = function (arr, c_b) {
        console.log(arr);
        arr.forEach(function (f) {
          console.log(f);
          Files.remove(f.photo_url);
          Files.remove(f.prev_url);
        });
        if ($.isFunction(c_b)) c_b();
      };

      Fotos.select_unseen_by_userid = function(fuserid, f_cb, limit = '') {
        db.transaction(
          function (tx) {
            tx.executeSql(
              `SELECT F.* from Fotos F
                       WHERE
                          F.userid = ? and
                          (
                              F.seen = ? OR
                              F.seen = ? OR
                              F.seen IS NULL
                          ) and
                          F.status != ? and
                          local_available = ? and
                          type = ?
                        order by last_edit desc ` + limit,
              [
                fuserid, Fotos.SEEN_STATES.notSeen,
                0,
                3,
                1,
                PERSONAL_MEDIA_TYPES.TIMELINE_IMAGE
              ],
              (transaction, result) => {
                if (result !== null && result.rows !== null) {
                  const length = result.rows.length;
                  let arr = [];
                  for (let i = 0; i < length; i++) {
                    arr[i] = result.rows.item(i);
                  }
                  if ($.isFunction(f_cb)) {
                    f_cb(arr);
                  }
                }
              },
              errorHandler
            );
          }
        );
      };

      Fotos.select_mostrecent_unseen_by_userid = function (fuserid, f_cb) {
        this.select_unseen_by_userid(fuserid, f_cb, 'limit 1');
      };

      Fotos.select_by_userid = function(this_userid, CB) {
        db.transaction(
          function(tx) {
            tx.executeSql(
              `SELECT F.* from Fotos F
                WHERE F.userid = ? and
                      F.status != ? and
                      F.local_available = ? and
                      order by F.last_edit desc;`,
              [this_userid, 3, 1, PERSONAL_MEDIA_TYPES.MESSAGE_IMAGE],
              function(transaction, result) {
                if (result !== null && result.rows !== null) {
                  let length = result.rows.length;
                  let arr = [];
                  for (let i = 0; i < length; i++) {
                    arr[i] = result.rows.item(i);
                  }
                  if ($.isFunction(CB)) {
                    CB(arr);
                  }
                }
              }, errorHandler);
          });
      };

      // construct to check for new/unseen Fotos:
      Fotos.show_last_unseen_alert = function () {
        quiz_rating = false;
        if ($('#paused_screen').length <= 0) {
          Fotos.select_mostrecent_unseen_by_userid(
            userid,
            function (fotos) {
              console.log(fotos);
              if (fotos.length > 0) {
                // turn on screen: 2017_07_11_mhe:
                if ($.isFunction(turnonscreen.on)) {
                  turnonscreen.on();
                }
                $('body').append(
                  `<div id="paused_screen">
                      <div style="margin-top: 5%;text-transform: none;">
                          Sie haben eine neue Nachricht
                          <div id="show_last_msg" style="text-transform:uppercase;">
                              Anzeigen
                          </div>
                      </div>
                    </div>`);
                $('#paused_screen')
                  .on(
                    clickhandler,
                    function (e) {
                      e.preventDefault();
                      e.stopPropagation();
                      Timeline.getUnseen(userid)
                        .then((fotos) => {
                          pswipe.postcards(
                            fotos,
                            true,
                            function() {
                              $('#paused_screen').off().remove();
                            }
                          );
                        });
                    }
                  );
              }
            }
          );
        }
      };

      Fotos.popup_timeout;

      /**
       * Shows new Foto Popup
       * @return {bool} - false if no popup is being opened (in videoPopup active)
       */
      Fotos.show_last_unseen = function() {
        if (
          state.notification_popup &&
          state.notification_popup.active &&
          state.notification_popup.type === 'video'
        ) {
          return false;
        }

        if (
          state.hasOwnProperty('videochat') &&
          state.videochat.hasOwnProperty('in_call') &&
          state.videochat.in_call === true
        ) {
          return false;
        }

        quiz_rating = false;
        let popup_timeout = 0;
        if (typeof(gallery) !== 'undefined') {
          popup_timeout = 4000;
        }
        // clean up potentially open pswipe gallery:
        Timeline.getUnseen(userid)
          .then((photos) => {
            if (photos.length > 0) {
              if (!state.pswipe.gallery_open) {
                if ($('#paused_screen').length > -1) {
                  $('#paused_screen').off().remove();
                }
                if (typeof gallery !== 'undefined') {
                  gallery.close();
                }
              }
              Fotos.popup_timeout = setTimeout(
                () => {
                  // deactivated for test 2017_04_13 mhe:
                  clearTimeout(Fotos.popup_timeout);
                  pswipe.postcards(
                    photos,
                    false,
                    function() {
                      // turn on screen: 2017_07_11_mhe:
                      if ($.isFunction(turnonscreen.on)) {
                        turnonscreen.on();
                      }
                    }
                  );
                },
                popup_timeout
              );
            }
          });
      };

      Fotos.insert = function(arr, iuf_callback) {
        let datetime_now = get_datetime_now();
        let dtnow_fscomp = datetime_now
          .replace(':', '-')
          .replace(':', '-')
          .replace(' ', '-');

        db.transaction(
          function (tx) {
            let q = '';
            let data = [];
            for (let i = 0; i < arr.length; i++) {
              arr[i].user_id = parseInt(arr[i].user_id) || 0;
              if (arr[i].user_id > 0) {
                switch (arr[i].type) {
                  // IMAGES:
                  case 1:
                  case PERSONAL_MEDIA_TYPES.TABLET_IMAGE:
                  case PERSONAL_MEDIA_TYPES.TIMELINE_IMAGE:
                  case PERSONAL_MEDIA_TYPES.MESSAGE_IMAGE:
                    // generate filenames if new Foto-Media does not provide such:
                    if (arr[i].photo_url.length === 0) {
                      arr[i].photo_url = dtnow_fscomp + i.toString() + ".jpg";
                    }
                    if (arr[i].prev_url.length === 0) {
                      arr[i].prev_url = dtnow_fscomp + i.toString() + "_prev.jpg";
                    }
                    // save new files to foto directory:
                    if (arr[i].photo_url.indexOf('file:///') < 0) {
                      arr[i].photo_url =
                        cordova.file.externalRootDirectory +
                        'media4carefotos/' + arr[i].photo_url;
                    }
                    if (arr[i].prev_url.indexOf('file:///') < 0) {
                      arr[i].prev_url =
                        cordova.file.externalRootDirectory +
                        'media4carefotos/' + arr[i].prev_url;
                    }
                    break;
                  // VIDEOS:
                  case 2:
                  case PERSONAL_MEDIA_TYPES.TABLET_VIDEO:
                    // generate filenames if new Foto-Media does not provide such:
                    if (arr[i].photo_url.length === 0)
                      arr[i].photo_url = dtnow_fscomp + i.toString() + ".jpg";

                    if (arr[i].prev_url.length === 0)
                      arr[i].prev_url = dtnow_fscomp + i.toString() + "_prev" + ".jpg";
                    if (arr[i].photo_url.indexOf('file:///') < 0) {
                      arr[i].photo_url = cordova.file.externalRootDirectory +
                        "DCIM/Camera/" + arr[i].photo_url;
                    }
                    if (arr[i].prev_url.indexOf('file:///') < 0) {
                      arr[i].prev_url = cordova.file.externalRootDirectory +
                        "media4carefotos/" + arr[i].prev_url;
                    }
                    break;
                }
                /*
                select_fotoid_by_url checks
                  if media file already has an entry in user_page_relation entry
                OR if it has to be added,
                since profile_family_pictures uses user-page_relation as base
                to be able to rate the entry "user media" OR ??comment in the future?
                */
                // Fotos.select_fotoid_by_url(arr[i].photo_url_hash, arr[i].user_id);
                if (i === 0 || i % 500 === 0) {
                  q =
                    `INSERT OR REPLACE INTO Fotos
                    (
                      fotoid,
                      userid,
                      photo_url,
                      photo_url_hash,
                      prev_url,
                      prev_url_hash,
                      sender,
                      firstname,
                      title,
                      type,
                      status,
                      seen,
                      local_available,
                      last_edit
                    )`;
                  q += ' values';
                }

                q += `(
                       (
                         SELECT fotoid from Fotos 
                          where userid = ${arr[i].user_id} 
                          AND photo_url_hash = '${arr[i].photo_url_hash}'
                       ),
                       ${arr[i].user_id},
                       (
                          SELECT CASE WHEN EXISTS 
                          (
                            SELECT 1 from Fotos where userid = ${arr[i].user_id} 
                            and photo_url_hash = '${arr[i].photo_url_hash}'
                          )=1
                          THEN
                          (
                            select photo_url from Fotos 
                              where userid = ${arr[i].user_id} 
                              AND photo_url_hash = '${arr[i].photo_url_hash}'
                          )
                          ELSE
                          '${arr[i].photo_url}' END as photo_url
                        ),
                       '${arr[i].photo_url_hash}',
                       (
                          SELECT CASE WHEN EXISTS 
                          (
                            SELECT 1 from Fotos
                              where userid = ${arr[i].user_id}
                              and photo_url_hash = '${arr[i].photo_url_hash}'
                          )=1
                          THEN
                          (
                            select prev_url from Fotos
                              where userid = ${arr[i].user_id}
                              AND photo_url_hash = '${arr[i].photo_url_hash}'
                          )
                          ELSE
                          '${arr[i].prev_url}'
                          END as prev_url
                        ),
                       '${arr[i].prev_url_hash}',
                       '${arr[i].sender}',
                       '${arr[i].firstname}',
                       ?,
                       ${arr[i].type},
                       ${arr[i].status},
                       (
                          SELECT seen from Fotos
                            where userid = ${arr[i].user_id}
                            AND photo_url_hash = '${arr[i].photo_url_hash}'
                       ),
                       (
                          SELECT local_available from Fotos
                            where userid = ${arr[i].user_id}
                            AND photo_url_hash = '${arr[i].photo_url_hash}'
                       ),
                       (
                          SELECT last_edit from Fotos
                            where userid = ${arr[i].user_id}
                            AND photo_url_hash = '${arr[i].photo_url_hash}'
                       )
                     )`;
                data.push(arr[i].title);
                if ((i + 1) !== arr.length && (i + 1) % 500 !== 0) {
                  q += ',';
                }


                if ((i + 1) % 500 === 0 || (i + 1) >= arr.length) {
                  if (v.d) console.log(q);
                  tx.executeSql(
                    q,
                    data,
                    function (transaction, result) {
                      // update Fotos.cloud_last_db here: 2016_06_06 mhe:
                      if (
                        typeof(arr[0].db_last_edit) !== "undefined"
                        && arr[0].db_last_edit >= "2017-01-01 12:00:00"
                      ) {
                        Fotos.cloud_db_last_edit = arr[0].db_last_edit;
                      }
                      console.log(result.rows);
                      console.log('yo alter 2');
                      if ($.isFunction(iuf_callback)) {
                        iuf_callback();
                      }
                    },
                    function (err) {
                      Usync.running = false;
                      console.error(err);
                    }
                  );
                }
              }
            }
          }
        );
      };

      Fotos.select_todownload = function(last_cloud_download, u_get_media_cb) {
        console.log('Fotos.select_todownload called!');
        // type=5 => to download
        if (!last_cloud_download || last_cloud_download.length < 19) {
          last_cloud_download = '2000-01-01 12:00:00';
        }
        console.log(`last cloud download:\n${last_cloud_download}`);
        db.transaction(
          function(tx) {
            tx.executeSql(
              `select fotoid, photo_url, prev_url, prev_url_hash, photo_url_hash 
                from Fotos where status != 3
                  and (last_edit >= ? OR last_edit is null)
                  and (local_available = ? OR local_available is null)
                  and photo_url_hash is not null
                  and prev_url_hash is not null`,
              // STATUS = 3 ==> to delete 2017_01_16 Mhe
              [last_cloud_download, /* ---- why not all unavailable Fotos?????*/ 0],
              function(transaction, result) {
                if (result !== null && result.rows !== null) {
                  let length = result.rows.length;
                  console.log(result.rows);
                  let media_arr = [];
                  if (length < 1) {
                    Usync.running = false;
                    return this;
                  }
                  let j = 0;
                  console.log('length ' + length);
                  for (let i = 0; i < length; i++) {
                    let row = result.rows.item(i);
                    if (v.d) console.log(row);
                    if (
                      row.photo_url_hash.length > 0
                      && row.photo_url_hash !== 'null'
                    ) {
                      media_arr[j] = {};
                      media_arr[j].hash = row.photo_url_hash;
                      media_arr[j].url = row.photo_url;
                      j++;
                    }

                    if (
                      row.prev_url_hash.length > 0
                      && row.prev_url_hash !== 'null'
                    ) {
                      media_arr[j] = {};
                      media_arr[j].hash = row.prev_url_hash;
                      media_arr[j].url = row.prev_url;
                      j++;
                    }
                  }
                  console.log(media_arr);
                  // no media to download, reset running state:
                  if (media_arr.length === 0) {
                    Usync.running = false;
                    // to patch no popup - call Usync progress:
                    // Usync.display_progress('','', 0, 0, 0, 0, 0);
                  } else {
                    console.log('starting select_todownload CALLBACK');
                    u_get_media_cb(media_arr, 0);
                  }
                } else {
                  console.log('no media_arr to download found!');
                  // no media to download, reset running state:
                  Usync.running = false;
                  // to patch no popup - call Usync progress:
                  // Usync.display_progress('','', 0, 0, 0, 0, 0);
                  return this;
                }
              },
              function(err) {
                console.error(err);
                Usync.running = false;
                return this;
              }
            );
          }
        );
      };

      Fotos.select_urls_since_last_backup = function(u_get_media_cb) {
        db.transaction(
          function (tx) {
            // only backup files that are device-specific:
            tx.executeSql(
              `select fotoid, photo_url, prev_url, prev_url_hash, photo_url_hash 
                from Fotos 
                where status != 3
                and (last_edit >= ? OR last_edit is null)
                and type IN (1,2,3,4)`,
              [sessionStorage.last_cloud_backup],
              function (transaction, result) {
                if (result !== null && result.rows !== null) {
                  let length = result.rows.length;
                  let media_arr = [];
                  let j = 0;
                  if (length < 1) {
                    return this;
                  }
                  for (let i = 0; i < length; i++) {
                    let row = result.rows.item(i);

                    media_arr[j] = row.photo_url;
                    media_arr[j + 1] = row.prev_url;
                    j = j + 2;
                  }
                  if (v.d) console.log(media_arr);
                  u_get_media_cb(media_arr, 0);
                }
              }, errorHandler);
          });
      };

      Fotos.select_all_since_last_backup = function(Urtl_callback) {
        // we need user id to identify
        db.transaction(
          function (tx) {
            tx.executeSql(
              `select userid,
                  photo_url,
                  CASE WHEN photo_url_hash is null then '' else photo_url_hash END
                    as photo_url_hash,
                  prev_url,
                  CASE WHEN prev_url_hash is null then '' else prev_url_hash END
                    as prev_url_hash,
                  sender, title, type, status
                from Fotos
                  where (last_edit >= ? or last_edit is null) AND type IN (1,2,3,4)`,
              [sessionStorage.last_cloud_backup],
              function (transaction, result) {
                if (result !== null && result.rows !== null) {
                  let length = result.rows.length;
                  if (v.usync) console.log(result.rows);
                  let upr_arr = [];
                  if (length < 1) {
                    return this;
                  }
                  for (let i = 0; i < length; i++) {
                    let row = result.rows.item(i);
                    if (v.d) console.log(row);
                    // remove device_specific paths
                    // (cordova.file.externalRootDirectory) + subfolders from urls:
                    // 2017_02_18 MHe:
                    row.photo_url = row.photo_url
                      .replace(cordova.file.externalRootDirectory, '')
                      .replace('media4carefotos/', '')
                      .replace('DCIM/Camera/', '');
                    row.prev_url = row.prev_url
                      .replace(cordova.file.externalRootDirectory, '')
                      .replace('media4carefotos/', '')
                      .replace('prev/', 'prev_');
                    row.photo_url = row.photo_url
                      .substr(row.photo_url.lastIndexOf('/') + 1);
                    row.prev_url = row.prev_url
                      .substr(row.prev_url.lastIndexOf('/') + 1);

                    upr_arr[i] = {
                      user_id: row.userid,
                      photo_url: row.photo_url,
                      photo_url_hash: row.photo_url_hash,
                      prev_url: row.prev_url,
                      prev_url_hash: row.prev_url_hash,
                      sender: row.sender,
                      title: whitelist(row.title),
                      type: row.type,
                      status: row.status
                    };
                  }
                  if (v.usync) console.log(upr_arr);
                  Urtl_callback(upr_arr, '/ufbackup');
                }
              }, errorHandler);
          });
      };

      Fotos.check_settings_comments = function() {
        return new Promise(
          (resolve, reject) => {
            Settings.select('fotos_comments', function(setting) {
              console.log(setting);
              if (setting === 'done') {
                if (v.d) console.log('app is prepared for Fotos comments');
                resolve();
              } else {
                return Fotos.alter_table_comments(resolve, reject);
              }
            });
          }
        );
      };

      Fotos.alter_table_comments = function(resolve, reject) {
        return db.transaction(
          function(tx) {
            tx.executeSql(
              'ALTER TABLE Fotos add comment TEXT DEFAULT  "" ',
              [],
              function (tx, result) {
                return Settings.insert(
                  'fotos_comments',
                  'done',
                  () => {
                    if (v.d) console.log('set fotos_comments = done');
                    resolve();
                  }
                );
              }
            );
          },
          (e) => {
            if (v.d) console.log("ERROR: " + e.message);
            return Settings.insert(
              'fotos_comments',
              'done',
              () => {
                if (v.d) console.log('set fotos_comments = done');
                resolve();
              }
            );
          }
        );
      };


      /* comment_sent 1 = not sent, 2 = sent */
      Fotos.check_settings_comment_sent = function() {
        return new Promise(
          (resolve, reject) => {
            return Settings.select("comment_sent", function(setting) {
              console.log(setting);
              if (setting === 'done') {
                if (v.d) console.log('app is prepared for Fotos comments_sent');
                console.log('the resolve', resolve);
                resolve();
              } else {
                return Fotos.alter_table_comment_sent(resolve);
              }
            });
          }
        );
      };

      Fotos.alter_table_comment_sent = function(resolve) {
        db.transaction(function(tx) {
          tx.executeSql(
            'ALTER TABLE Fotos add comment_sent INT NOT NULL DEFAULT 1',
            [],
            function (tx, result) {
              return Settings.insert(
                'comment_sent',
                'done',
                () => {
                  if (v.d) console.log('set comment_sent = done');
                  // return Fotos.check_settings_comment_sent();
                  resolve();
                }
              );
            }
          );
        },
        // on error: assume already altered:
        (e) => {
          if (v.d) {
            console.log('ERROR: ' + e.message);
          }
          return Settings.insert(
            'comment_sent',
            'done', () => {
              if (v.d) console.log('set comment_sent = done');
              // return Fotos.check_settings_comment_sent();
              resolve();
            }
          );
        });
      };

      Fotos.update_comment = function (comment, fotoid) {
        db.transaction(
          function (tx) {
            tx.executeSql(
              'UPDATE Fotos SET comment = ? where fotoid = ?',
              [comment, fotoid],
              function (tx, result) {
                if (v.d) {
                  console.log('updated Fotos comment = ' +
                    comment + ' for fotoid = ' + fotoid);
                }
              }
            );
          },
          (e) => {
            if (v.d) console.log('ERROR: ' + e.message);
          }
        );
      };

      Fotos.update_comment_sent = function (status, fotoid) {
        db.transaction(
          function (tx) {
            tx.executeSql(
              "UPDATE Fotos SET comment_sent = ? where fotoid = ? ",
              [status, fotoid],
              function (tx, result) {
                if (v.d) {
                  console.log('updated Fotos comment_sent = ' +
                    status + ' for fotoid = ' + fotoid);
                }
              }
            );
          },
          function (e) {
            if (v.d) console.log("ERROR: " + e.message);
          }
        );
      };

      Fotos.select_hashescomments_from_unsent_comments = function (cb_success) {
        db.transaction(
          function(tx) {
            tx.executeSql(
              `select photo_url_hash, comment from Fotos
                where comment_sent = ? 
                AND userid = ? AND comment != "" `,
              [1, userid], function (transaction, result) {
                if (result !== null && result.rows !== null) {
                  let length = result.rows.length;
                  let F_arr = [];
                  if (length < 1) {
                    return this;
                  }
                  for (let i = 0; i < length; i++) {
                    let row = result.rows.item(i);
                    F_arr[i] = {
                      photo_url_hash: row.photo_url_hash,
                      comment: row.comment
                    };
                  }
                  if (v.d) {
                    console.log(F_arr);
                  }
                  if ($.isFunction(cb_success)) {
                    cb_success(F_arr);
                  }
                }
              }, errorHandler);
          }
        );
      };

      Fotos.getImageFromHash = (hash) => {
        return new Promise((resolve, reject) => {
          db.executeSql(
            `SELECT * FROM Fotos WHERE photo_url_hash = ?`,
            [hash],
            resolve,
            reject,
          );
        }).then((result) => result.rows);
      };

      Fotos.init = () => {
        return new Promise(
          (resolveInit, rejectInit) => {
            Fotos.cloud_db_last_edit = '2000-08-17 14:54:43';
            Fotos.check_settings_comments()
              .then(Fotos.check_settings)
              .then(Fotos.check_settings_foto_status)
              .then(Fotos.check_settings_comment_sent)
              .then(Fotos.check_settings_hashes)
              .then(Fotos.check_settings_prev_hashes)
              .then(()=>{
                resolveInit();
              })
              .catch( (err) => {
                console.error('Fotos init error', err);
                rejectInit(err);
              });
          }
        );
      };
      resolveCreate();
    }
  );
};

// old widely used:
function AddImageToDB(userid, img_url, prev_url, type) {
  let datetime_now = get_datetime_now();
  if (v.d) console.log('going to write:usrid:' + userid + ' itemid');
  db.transaction(function(tx) {
    tx.executeSql(
      `INSERT INTO Fotos(
              userid, photo_url, prev_url, type, last_edit, local_available
            )
            VALUES (?,?,?,?,?,?)`,
      [userid, img_url, prev_url, type, datetime_now, 1],
      function (s) {
        console.log('added to db:');
        db.transaction(function (tx) {
          tx.executeSql(
            `INSERT INTO user_page_relation
                    ( userid, itemid, item_typeid, added_datetime)
                    VALUES (?, last_insert_rowid(), 2, ?)`,
            [userid, datetime_now],
            function() {
              console.log('Foto added to Directory');
              setTimeout(
                () => {
                  if (
                    $.mobile.activePage.attr('id') === 'profile_family_pictures'
                  ) {
                    list_family_pictures();
                  } else {
                    getProfileContentList();
                  }
                },
                500
              );
            }, errorHandler);
        });
        console.log(s);
      }, errorHandler);
  });
}
