window.home = {};
window.test_aktiviert = false;
window.sound_step = 14;
window.video_personal = false;
window.thisdate = new Date();
window.iconbar_extended = 0;
window.jqm_activePage = 'home';
window.previous_page_lock = false;


function goToTypelist() {
  // already defined in home.js button declarations!
  previous_page = document.URL;
  if (v.d) console.log('made typelist');
  page.go_to('typelist.html');
}

/*2017_01_10 MHe: check for new Fotos interval:*/
var interval_new_fotos = '';

// following function is loaded after all ContentBackend Database
// entries have been successfully imported
function load_after_trans() {
  create_hauptmenu();
  // Check if we already had onboarding done
  // If yes, just bind headerbuttons, if not
  // just start onboarding without button binding
  // so that user can not click settings …
  header.bindBtns();
  if (utilization === 6 || utilization === 8) {
    Settings.select('util6_onboarding' + userid.toString(), function(set) {
      if (set !== 'done') {
        onboarding.onboarding_control();
      }
    });
  }

  if ([5, 6, 8].includes(utilization)) {
    thisapp.enable_family_sync();
  }

  try_conn = 0;

  $('#media_menu_wrapper')
    .append(
      `<div class="startoverlay">
        ${/*loading program*/(strings[113] || 'Das Programm wird gestartet')}
      </div>`
    );
  startTime();
  emptyStorageCache();

  // upload stats MHe 16_03_01:
  request_token(get_user_stats);
  localStorage.wifistate = '';

  refresh_battery_service();

  if (is_app > -1 && !online_media_sources) {
    WifiWizard.isWifiEnabled(
      (wstatus) => {
        if (v.d) console.log(wstatus);
        if (wstatus) {
          localStorage.wifistate = ' ';
        } else {
          localStorage.wifistate = '';
        }
      },
      () => {
        if (v.d) console.log('error retrieving wifi status');
      }
    );
  } else if (online_media_sources && is_app > -1) {
    WifiWizard.isWifiEnabled(function(wstatus) {
      if (v.d) console.log(wstatus);
      if (wstatus) {
        localStorage.wifistate = '<img src="./img/ico/wifi_symbol_white.svg" style="height:15px;width:15px;margin-top:-4px;margin-left:8px;"/>';
      } else {
        localStorage.wifistate = '';
      }
    }, function() {
      if (v.d) console.log('error retrieving wifi status');
    });
    if (v.d) console.log('no video check');
    $('.startoverlay').remove();
  } else {
    if (v.d) console.log('no video check');
    $('.startoverlay').remove();
  }
}

function translate() {
  $('#media_menu_filme').html(strings[1]);
  $('#media_menu_books').html(strings[2]);
  $('#media_menu_profile').html(strings[3]);
  $('#media_menu_games').html(strings[4]);
  $('#media_menu_playlists').html(
    (utilization === UTILIZATION_MODE.B2C) ? strings['Suggestions'] : strings['Subjects']
  );
  $('#start_web_btn').html(strings[6]);
}

function create_hauptmenu() {
  var media_menu = '';
  // http://stackoverflow.com/questions/7507099/how-do-you-remove-a-buttons-active-state-with-jquery-mobile/29906779#29906779:
  if (utilization === 4) { //b2c with no internet
    $('#home .ui-title').html(strings[0]);
    $('#home .hilfe_home').attr('style', 'display: none !important;');
    $('#home .home_profile').attr('style', 'display: none !important;');
    $('#home .settings')
      .attr('style', 'position:fixed; right:50px !important;');
    // show_switch_date_btns is set in solomode.js
    if (show_switch_date_btns) {
      if (iconbar_extended === 0) {
        iconbar_extended = 1;
        $('#home .iconbar')
          .append(
            `<div id="day_back" style="position:fixed;top:20px;padding:30px">
              zurück</div>
            <div id="thisday" style="position:fixed; top:50px; left: 110px;">
              ${formatDate(thisdate)}
            </div>
            <div
              id="day_forward"
              style="position:fixed;top:20px;padding:30px;left:200px;">
              vor
            </div>`
          );
      }
    }
    unbind_start_buttons();
    b2c_nonet_select();
  }
  else if (utilization === 5) {
    $('#home .hilfe_home').attr('style', 'display: none !important;');
    $('#home .settings').attr('style', 'position:fixed; right:50px;');
    $('#home .ui-title').html('Worauf haben Sie Lust?');
    unbind_start_buttons();
    util5.build_welcome_screen();
  }
  else if (utilization === 6) {
    $('#home .hilfe_home').attr('style', 'display: none !important;');
    $('#home .settings').attr('style', 'position:fixed; right:50px;');
    $('#home .ui-title').addClass('clockdate');
    unbind_start_buttons();
    util6.show_motivation();
  }
  else if (utilization === 3) {
    $('#home .hilfe_home').attr('style', 'display: block;');
    $('#home .settings').attr('style', '');
    $('#home .ui-title').html('Max Mustermann');
    media_menu = '<div class="media_menu font1" style="background-size:320px;background-image:url(\'' + local_dir + '/img/ico/Web_01_750x502.png\');" id="media_menu_b2ci_web" data-role="button">Web</div>' +
      '<div class="media_menu font1" style="margin-left:54px;background-size:320px;background-image:url(\'' + local_dir + '/img/ico/Mail_02_750x502.png\');" id="media_menu_b2ci_email">Email</div>' +
      '<div class="media_menu font1" style="margin-left:54px;background-size:320px;background-image:url(\'' + local_dir + '/img/ico/Video_03_750x502.png\');" id="media_menu_b2ci_videochat" data-role="button">Videotelefonie</div>' +
      '<div class="media_menu font1" style="background-size:320px;background-image:url(\'' + local_dir + '/img/ico/fotosvideos_01_750x502.png\');" data-role="button" id="media_menu_b2ci_fotosvideos">Fotos &amp; Videos</div>' +
      '<div class="media_menu font1" style="margin-left:54px;background-size:320px;background-image:url(\'' + local_dir + '/img/ico/Games_01_750x502.png\');" id="media_menu_b2ci_entertain" data-role="button">Unterhaltung</div>' +
      '<div class="media_menu font1" style="margin-left:54px;background-size:320px;background-image:url(\'' + local_dir + '/img/ico/Info_01_750x502.png\');"  id="media_menu_b2ci_info" data-role="button">Information</div>';
    $('#media_menu_wrapper').empty().html(media_menu).trigger('create').attr('style', 'min-height:700px;height:100%;overflow-y:hidden;');
  }
  else if (utilization === 24) {
    $('#home .hilfe_home').attr('style', 'display: none;');
    $('#home .settings').attr('style', '');
    $('#home .ui-title').html(strings[0]);
    //TODO: choose userid dynamically!!!!!
    userid = 1;
    media_menu =
      `<div class="media_menu font1" style="margin-left:54px;background-image:url('${home_dir}img/ico/24h/24_icon_advisors.png');" id="media_menu_ratgeber" data-role="button">${strings[197]/*Ratgeber*/}</div>
				<div class="media_menu font1" style="background-image:url('${home_dir}img/ico/24h/24_icon_communication.png');" id="media_menu_kommunikation" data-role="button">${strings[187]/*Kommunikation*/}</div>
				<div class="media_menu font1" style="margin-left:54px;background-image:url('${home_dir}img/ico/24h/24_icon_translate.png');" id="media_menu_translate">${strings[196]/*Translate*/}</div>
				<div class="media_menu font1" style="margin-left:54px;background-image:url('${home_dir}img/ico/24h/24_icon_entertainment.png');" data-role="button" id="media_menu_entertain">${strings[198]/*Unterhaltung*/}</div>
				<div class="media_menu font1" style="background-image:url('${home_dir}img/ico/24h/24_icon_purchasing_aid.png');" id="media_menu_essen" data-role="button">${/*purchasing aid*/strings[215]}</div>
				<div class="media_menu font1" style="margin-left:54px;background-image:url('${home_dir}img/ico/24h/24_icon_internet.png');"  id="start_web_btn" data-role="button">${strings[214]/*internet*/}</div>`;
    $('#media_menu_wrapper').empty().html(media_menu).trigger('create').attr('style', 'min-height:700px;height:100%;overflow-y:hidden;');
  }
  else if (utilization === 8) {
    $('#home .hilfe_home').attr('style', 'display: block;');
    $('#home .home_profile').attr('style', 'display: none;');
    $('#home .backbtn').attr('style', 'display: none;');
    $('#home .settings').attr('style', '');
    $('#home .ui-title').html(strings[0]);
    //userid=1;
    media_menu =
      `<div
					class="ch-generate-proposal media_menu font1"
					data-role="button"
					data-proposal="fotogallery"
					style="background-image:url('${home_dir}/img/ico/home_tiles/M4C_Fotoalbum_750x510.png');">
					${strings[189]}
				</div>
				<div
					class="media_menu font1"
					style="margin-left:54px;background-image:url('${home_dir}/img/ico/home_tiles/M4C_videoanruf_750x510.png');"
					id="media_menu_videochat"
					data-role="button">${strings[305]/*video call*/}
					<div id="amount_missed_calls" class="new_msg_cnt" 
						style="position:relative;top:-480px;left:54px;display:none;">
					</div>
				</div>
				<div
					class="media_menu font1"
					style="background-image:url('${home_dir}/img/ico/home_tiles/M4C_Nachrichten_750x510.png');"
					data-role="button"
					id="media_menu_messages">${strings[40]/*messages*/}
					<div id="amount_unread_messages" class="new_msg_cnt" 
						style="position:relative;top:-480px;left:54px;display:none;">
					</div>
				</div>
				<div
					class="media_menu font1"
					style="background-image:url('${home_dir}/img/ico/home_tiles/M4C_Unterhaltung_750x510.png');"
					data-role="button"
					id="media_menu_entertain">${strings[198]/*entertainment*/}
				</div>
				<div
					class="media_menu font1"
					style="margin-left:54px;background-image:url('${home_dir}/img/ico/home_tiles/M4C_Stern_750x510.png');"
					id="media_menu_playlists"
					data-role="button">
					${strings[311]/*suggestions*/}
				</div>
				<div
					class="media_menu font1"
					style="margin-left:54px;background-image:url(\'./img/ico/Web_320x215.png\');"
					id="start_web_btn"
					data-role="button">
					${strings[6]}
				</div>`;
    $('#media_menu_wrapper').empty().html(media_menu).trigger('create')
      .attr('style', 'min-height:700px;height:100%;overflow-y:hidden;')
      //creating and inserting callback:
      .promise().done(
        () => {
          util6.bind_ch_generate_proposal();
          // calculation of unread messages and missed calls:
          Messages
            .countUnreadMsg(User.prof_id)
            .then(Messages.updateHomeScreenUnreadMessages);

          Messages.countMissedVideoCalls(User.prof_id)
            .then(
              (r) => {
                if (parseInt(r) > 0) {
                  $('#amount_missed_calls')
                    .show()
                    .html(r);
                }
              }
            );
        }
      );
  } else {
    $('#home .hilfe_home').attr('style', 'display: block;');
    $('#home .home_profile').attr('style', 'display: none;');
    $('#home .backbtn').attr('style', 'display: none;');
    $('#home .settings').attr('style', '');

    if (utilization === 1)
      $('#home .ui-title').html('Max Mustermann');
    else
      $('#home .ui-title').html(strings[0]);

    userid = 1;
    media_menu =
      `<div
        class="media_menu font1"
        style="background-image:url('${local_dir}/img/ico/Filme_320x215.png');"
        id="media_menu_filme"
        data-role="button">
        ${strings[1]}
      </div>
      <div
        class="media_menu font1"
        style="margin-left:54px;background-image:url('${local_dir}/img/ico/Buecher_320x215.png');"
        id="media_menu_books">
        ${strings[2]}
      </div>
      <div
        class="media_menu font1"
        style="margin-left:54px;background-image:url('${local_dir}/img/ico/Profile_320x215.png');"
        id="media_menu_profile"
        data-role="button">
        Profile
      </div>
      <div
        class="media_menu font1"
        style="background-image:url('${local_dir}/img/ico/Spiele_320x215.png');"
        data-role="button"
        id="media_menu_games">
        ${strings[4]}
      </div>
      <div
        class="media_menu font1"
        style="margin-left:54px;background-image:url('${local_dir}/img/ico/Themen_320x215.png');"
        id="media_menu_playlists"
        data-role="button">
        ${strings[5]/*categories*/}
      </div>
      <div
        class="media_menu font1"
        style="margin-left:54px;background-image:url('./img/ico/Web_320x215.png');"
        id="start_web_btn"
        data-role="button">
        ${strings[6]/*web*/}
      </div>`;
    $('#media_menu_wrapper')
      .empty()
      .html(media_menu)
      .trigger('create')
      .attr('style', 'min-height:700px;height:100%;overflow-y:hidden;');
  }
  // workaround to fix hauptmenu scrollable on tablet:
  $('#home .ui-content').attr('style', 'margin-top:0px !important;');
}

function add_webpasswort() {
  db.transaction(
    function(tx) {
      tx
        .executeSql(
          "INSERT OR IGNORE INTO settings (id,name,setting,edit_datetime) values(3,'globalwebpassword', '1414', '2015-01-01 00:00:00')",
          [], nullHandler, errorHandler);
    }, errorHandler, function() {
      if (v.d) console.log('web pw added');
    });
}
var web_checkpassword_red = '';


var pause_player_states = {};

var typelist_type = '';
/*not needed without cache:*/
var typelist_itemtype = '';

function unbind_start_buttons() {
  $('#media_menu_books').off();
  $('#media_menu_filme').off();
  $('#media_menu_games').off();
  $('#media_menu_quiz').off();
  $('#media_menu_help').off();
  $('#media_menu_playlists').off();
  $('#media_menu_users').off();
  $('#media_menu_profile').off();
  $('#media_menu_kommunikation').off();
  $('#media_menu_entertain').off();
  $('#media_menu_translate').off();
  $('#media_menu_ratgeber').off();
  $('#start_web_btn').off();
  $('#imprint_btn').off();
  $('#show_first_motivation').off();
  $('.start_daily_act').off();
  $('#media_menu_videochat').off();
  //for extended mode util8:
  $('.ch-generate-proposal').off();
  $('#media_menu_messages').off();
}

function bind_start_buttons() {
  console.log('binding start buttons');

  if (utilization === 24) {
    $('#media_menu_translate')
      .off()
      .on(clickhandler_prev, (event) => {
        event.preventDefault();
        $(this).addClass('media_menu_active');
      })
      .on(clickhandler, (event) => {
        event.preventDefault();
        unbind_start_buttons();
        previous_page = document.URL;
        page.go_to('translate.html');
      });

    $('#media_menu_ratgeber')
      .on(clickhandler_prev, (event) => {
        event.preventDefault();
        $(this).addClass('media_menu_active');
      })
      .on(clickhandler, (event) => {
        event.preventDefault();
        unbind_start_buttons();
        previous_page = document.URL;
        page.go_to('guide.html');
      });
    $('#media_menu_entertain')
      .off()
      .on(clickhandler_prev, (event) => {
        event.preventDefault();
        $(this).addClass('media_menu_active');
      })
      .on(clickhandler, (event) => {
        event.preventDefault();
        unbind_start_buttons();
        previous_page = document.URL;
        page.go_to('entertain.html');
      });
    $('#media_menu_essen')
      .off()
      .on(clickhandler_prev, (event) => {
        event.preventDefault();
        $(this).addClass('media_menu_active');
      })
      .on(clickhandler, (event) => {
        event.preventDefault();
        unbind_start_buttons();
        localStorage.typelist = 'book';
        sublist_mother_id = 1813;
        page.go_to('sublist.html');
      });
  }
  $('#imprint_btn')
    .off()
    .on(clickhandler_prev, (event) => {
      event.preventDefault();
      $(this).addClass('media_menu_active');
    })
    .on(clickhandler, (event) => {
      event.preventDefault();
      unbind_start_buttons();
      $(this).removeClass('media_menu_active');

      if (utilization !== 4) {
        page.go_to('imprint.html');
      }

    });
  $('#media_menu_filme')
    .off()
    .on(clickhandler_prev, (event) => {
      event.preventDefault();
      $(this).addClass('media_menu_active');
    })
    .on(clickhandler, (event) => {
      event.preventDefault();
      $(this).removeClass('media_menu_active');
      unbind_start_buttons();
      typelist_type = 'video';
      localStorage.setItem('typelist', typelist_type);
      typelist_itemtype = 'typelist' + typelist_type;
      goToTypelist();
    });

  $('#media_menu_games')
    .off()
    .on(clickhandler_prev, (event) => {
      event.preventDefault();
      $(this).addClass('media_menu_active');
    })
    .on(clickhandler, (event) => {
      event.preventDefault();
      $(this).removeClass('media_menu_active');
      unbind_start_buttons();
      typelist_type = 'game';
      previous_page = document.URL;
      localStorage.setItem('typelist', typelist_type);
      typelist_itemtype = 'typelist' + typelist_type;
      goToTypelist();
    });

  $('#media_menu_entertain').bind('vclick', (event) => {
    event.preventDefault();
    unbind_start_buttons();
    previous_page = document.URL;
    page.go_to('entertain.html');
  });

  $('#media_menu_quiz')
    .off()
    .on(clickhandler_prev, (event) => {
      event.preventDefault();
      $(this).addClass('media_menu_active');
    })
    .on(clickhandler, (event) => {
      event.preventDefault();
      $(this).removeClass('media_menu_active');
      unbind_start_buttons();
      typelist_type = 'quiz';
      typelist_itemtype = 'typelist' + typelist_type;
      localStorage.setItem('typelist', type);
      previous_page = document.URL;
      goToTypelist();

    });

  $('#media_menu_books')
    .off()
    .on(clickhandler_prev, (event) => {
      event.preventDefault();
      $(this).addClass('media_menu_active');
    })
    .on(clickhandler, (event) => {
      event.preventDefault();
      $(this).removeClass('media_menu_active');
      unbind_start_buttons();
      typelist_type = 'book';
      localStorage.setItem('typelist', typelist_type);
      previous_page = document.URL;
      goToTypelist();
    });

  $('#media_menu_playlists')
    .off()
    .on(clickhandler_prev, (event) => {
      event.preventDefault();
      $(this).addClass('media_menu_active');
    })
    .on(clickhandler, (event) => {
      event.preventDefault();
      $(this).removeClass('media_menu_active');
      unbind_start_buttons();
      previous_page = document.URL;
      page.go_to('categories.html');
    });

  $('#media_menu_videochat')
    .off()
    .on(clickhandler_prev, (event) => {
      event.preventDefault();
      $(this).addClass('media_menu_active');
    })
    .on(clickhandler, (event) => {
      event.preventDefault();
      $(this).removeClass('media_menu_active');
      unbind_start_buttons();
      previous_page = document.URL;
      relatives_list.target = 'videochat.html';
      page.go_to('relatives_list.html');
    });

  $('#media_menu_users')
    .off()
    .on(clickhandler_prev, (event) => {
      event.preventDefault();
      $(this).addClass('media_menu_active');
    })
    .on(clickhandler, (event) => {
      event.preventDefault();
      page.go_to('profile_checkpassword.html');
    });

  $('#media_menu_profile')
    .off()
    .on(clickhandler_prev, (event) => {
      event.preventDefault();
      $(this).addClass('media_menu_active');
    })
    .on(clickhandler, (event) => {
      event.preventDefault();
      $(this).removeClass('media_menu_active');
      unbind_start_buttons();
      previous_page = document.URL;
      open_profile_list_page();
    });

  $('#media_menu_kommunikation')
    .off()
    .on(clickhandler_prev, (event) => {
      event.preventDefault();
      $(this).addClass('media_menu_active');
    })
    .on(clickhandler, (event) => {
      event.preventDefault();
      unbind_start_buttons();
      page.go_to('ktafeln.html');
    });

  $('#start_web_btn')
    .off()
    .on(clickhandler_prev, (event) => {
      event.preventDefault();
      $(this).addClass('media_menu_active');
    })
    .on(clickhandler, (event) => {
      event.preventDefault();
      $(this).removeClass('media_menu_active');

      if (utilization !== 24) {
        unbind_start_buttons();
        previous_page = document.URL;
        page.go_to('web.html');
      }
      else {
        navigator.app.loadUrl('https://www.google.' + lang + '/', {openExternal: true});
      }
    });
  $('#media_menu_messages')
    .off()
    .on(clickhandler_prev, (event) => {
      event.preventDefault();
      $(this).addClass('media_menu_active');
    })
    .on(clickhandler, function(e) {
      e.preventDefault();
      e.stopPropagation();
      unbind_start_buttons();
      relatives_list.target = 'message.html';
      page.go_to('relatives_list.html');
    });
}


function open_profile_list_page() {
  db.transaction(function(tx) {
    tx.executeSql(
      'select * from settings where name = ?',
      ['settings_pw_profile'],
      function(transaction, result) {
        if (result !== null && result.rows !== null) {
          for (let i = 0; i < result.rows.length; i++) {
            let row = result.rows.item(i);
            console.log(row);
            console.log('|' + row.setting + '|');
            if (row.setting == 'off') {
              console.log('pw off');
              page.go_to('profile.html');
            } else {
              profile_checkpassword_red = 'profile.html';
              previous_page = document.URL;
              page.go_to('profile_checkpassword.html');
            }
          }
        }
      },
      function(err) {
        console.log(err);
      });
  });

}


function dropTables() {
  db.transaction(function(tx) {
    tx.executeSql('DROP TABLE IF EXISTS Media_type', [], nullHandler, errorHandler);
    tx.executeSql('DROP TABLE IF EXISTS Media', [], nullHandler, errorHandler);
    //tx.executeSql( 'DROP TABLE IF EXISTS Fotos');
    tx.executeSql('DROP TABLE IF EXISTS Fotodirs');
    tx.executeSql('DROP TABLE IF EXISTS media_fragen_relation', [], nullHandler, errorHandler);
    tx.executeSql('DROP TABLE IF EXISTS Bookfotos', [], nullHandler, errorHandler);
    tx.executeSql('DROP TABLE IF EXISTS fragen', [], nullHandler, errorHandler);
  }, errorHandler, successCallBack);
}

// function buttonstate(buttonelement) {
//   $(buttonelement).bind(clickhandler_prev, function() {
//     $(this).addClass('fake-active');
//   }).bind(clickhandler, function() {
//     $(this).removeClass('fake-active');
//   }).bind('touchcancel', function() {
//     // sometimes Android fires a touchcancel event rather than a touchend.
//     // Handle this too.
//     $(this).removeClass('fake-active');
//   });
// }

function bind_cancel_button() {
  $('.cancel_btn').off();
  if (is_app > 0) {
    $('.cancel_btn')
      .off()
      .on(clickhandler_prev, (event) => {
        event.preventDefault();
        $(this).addClass('i_antw_active');
      })
      .on(clickhandler, (event) => {
        event.preventDefault();
        $('.cancel_btn').off();
        goHome();
      });
  } else {
    $('.cancel_btn')
      .off()
      .on('vclick', (event) => {
        event.preventDefault();
        $('.cancel_btn').off();
        $(this).addClass('i_antw_active');
        goHome();
      });
  }
}

//Next game button/ skip game button in Util6 Games
function bind_game_next_button() {
  if (is_app > 0) {
    $('#skip_game')
      .off()
      .on(clickhandler_prev, (event) => {
        event.preventDefault();
        clearTimeout(quizfeedback_trigger);
      })
      .on(clickhandler, (event) => {
        event.preventDefault();
        if (v.d) console.warn('++++ SKIP GAME ++++');
        //only push valid media_id entries to watched array:
        if (typeof(util6.openmedia_data) !== 'undefined') if (util6.openmedia_data.media_id > 0) util6.watched_media.push(parseInt(util6.openmedia_data.media_id) || 0);
        if (v.d) console.log(util6.watched_media);
        $('#quizfeedback').hide();
        util6.pick_rand_media(function() {
          sessionStorage.quizid = util6.openmedia_data.media_id;
          $('#skip_game').off();
          loadNewQuiz();
        }, 'game', 0);
      });
  } else {
    $('#skip_game')
      .off()
      .on('vclick', (event) => {
        event.preventDefault();
        clearTimeout(quizfeedback_trigger);
        if (v.d) console.warn('++++ SKIP GAME ++++');
        //only push valid media_id entries to watched array:
        if (typeof(util6.openmedia_data) !== 'undefined') if (util6.openmedia_data.media_id > 0) util6.watched_media.push(parseInt(util6.openmedia_data.media_id) || 0);
        if (v.d) console.log(util6.watched_media);
        $('#quizfeedback').hide();
        util6.pick_rand_media(function() {
          sessionStorage.quizid = util6.openmedia_data.media_id;
          $('#skip_game').off();
          loadNewQuiz();
        }, 'game', 0);
      });
  }
}
