var sd_card_url = '';
if (typeof(state) === 'undefined') {
  state = {};
}
state.checksdcard = {
  contentFolder: false,
};
function checksdcard() {
  return new Promise(
    (resolve) => {
      $('#install_akt').html('<br>Überprüfe Stand der Anwendung');
      var done = function() {
        Files.resolve(
          sd_card_url,
          () => {
            Files.resolve(
              sd_card_url+'Android/data/com.media4care.dementia/files/',
              () => {
                sd_card_videores = true;
                state.checksdcard.contentFolder = true;
                resolve();
              },
              () => {
                Files.directory.make(
                  sd_card_url,
                  'Android/data/com.media4care.dementia/files/',
                  resolve
                )
                // if no rights to create Directory:
                  .catch(this_error);
              }
            );
          },
          () => {
            this_error();
          }
        );
      };

      const this_error = () => {
        sd_card_videores = false;
        resolve();
      };

      let check_sd_default = () => {
        Settings.select(
          'sdcard',
          (saved_url) => {
            sd_card_url = saved_url;
            done();
          },
          // error:
          // search for content folder:
          () => {
            let t1 = new find_content_source(
              // success_cb:
              () => {
                state.checksdcard.contentFolder = true;
                sd_card_videores = true;
                resolve();
              },
              // error_cb
              this_error
            );
            t1.find_potential_sd_cards();
          }
        );
      };

      if (device.version < '5.1') {
        switch (device.model) {
          case 'TrekStor PrimeTab P10':
            sd_card_url = 'file:///mnt/m_external_sd/';
            done();
            break;

          case 'A3-A20':
          case 'B3-A20':
          case 'Archos 101 Oxygen':
            sd_card_url = 'file:///storage/sdcard1/';
            done();
            break;

          case 'SM-T533':
          case 'SM-T535':
          // galaxxy tab 3:
          case 'GT-P5210':
          case 'SM-T530':
            sd_card_url = 'file:///storage/extSdCard/';
            done();
            break;
          //asus K01B:
          case 'K01B':
            sd_card_url = 'file:///storage/MicroSD/';
            done();
            break;
          // mediadementia sample tab v2:
          case 'rk312x':
            sd_card_url = 'file:///sdcard/';
            done();
            break;

          case 'X1060X':
          case 'SM-T585':
            check_sd_default();
            break;

          // works with lenovo
          default:
            sd_card_url = 'file:///storage/sdcard1/';
            done();
        }
      } else if (device.version >= '7.0') {
        switch (device.model) {
          // works with lenovo + Medion E10513:
          // TODO: add them here
          case 'TrekStor PrimeTab P10':
          case 'Lenovo TB3-X70L':
          // trekstor
          case 'TFQCOAL01232':
            sd_card_url = 'file:///mnt/m_external_sd/';
            done();
            break;

          case 'B3-A20':
          case 'A3-A20':
          case 'Archos 101 Oxygen':
            sd_card_url = 'file:///storage/sdcard1/';
            done();
            break;

          // galaxxy tab 3:
          case 'GT-P5210':
          case 'SM-T530':
          case 'SM-T533':
          case 'SM-T535':
            sd_card_url = 'file:///storage/extSdCard/';
            done();
            break;

          //asus K01B:
          case 'K01B':
            sd_card_url = 'file:///storage/MicroSD/';
            done();
            break;

          //mediadementia sample tab v2:
          case 'rk312x':
            sd_card_url = 'file:///sdcard/';
            done();
            break;

          case 'X1060X':
          case 'SM-T585':
          case 'P1050X':
            check_sd_default();
            break;

          //works with lenovo + Medion E10513:
          default:
            sd_card_url = 'file:///mnt/m_external_sd/';
            done();
        }
      } else {
        switch (device.model) {
          case 'TrekStor PrimeTab P10':
          case 'Lenovo TB3-X70L':
          // trekstor
          case 'TFQCOAL01232':
            sd_card_url = 'file:///mnt/m_external_sd/';
            done();
            break;
          //fat 128gb medion tab
          case 'P1050X':
            sd_card_url = 'file:///storage/sdcard1/';
            done();
            break;

          case 'A3-A20':
          case 'B3-A20':
          case 'Archos 101 Oxygen':
          // galaxxy tab 3:
          case 'GT-P5210':
            sd_card_url = 'file:///storage/sdcard0/';
            break;

          case 'SM-T530':
          case 'SM-T533':
          case 'SM-T535':
            sd_card_url = 'file:///storage/extSdCard/';
            done();
            break;

          //asus K01B:
          case 'K01B':
            sd_card_url = 'file:///storage/MicroSD/';
            done();
            break;

          //mediadementia sample tab v2:
          case 'rk312x':
            sd_card_url = 'file:///sdcard/';
            done();
            break;

          case 'X1060X':
          case 'SM-T585':
            check_sd_default();
            break;

          default:
            sd_card_url = 'file:///storage/sdcard0/';
            done();
        }
      }
    }
  );
}
