function onBatteryStatus(info, batterystatus) {
  drawBattery(info, batterystatus);
  if(logging)console.log("Level: " + info.level + " isPlugged: "+
    info.isPlugged);
}

const drawBattery = function(info, batterystatus) {
  let s = "" + info.level + " %";
  if (info.isPlugged === true) {
    s += " "+strings[98];
  }
  // adding wlan status on 2016-04-11 MHe
  $('.battery').html(s);
  sessionStorage.battery = s;
};