let check_media = [0, 0, 0, 0];
let daily_media_ids = [];
let next_mediaid;
let curr_sort_id;
let only_one_item = true;
let solo_sound_assi = false;
// need to make it global so we can externally stop it:
let audio = new Audio(local_dir + 'audio/intro_start.mp3');
var intervalplayer;
let check_date_interval;
let show_switch_date_btns = false;
let solo = {};


function build_b2c_list() {
  solo_screen = 'selection';
  //
  if (solo_sound_assi) {
    clearInterval(intervalplayer);
    audio.pause();
    audio.setAttribute('src', local_dir + 'audio/intro_themenauswahl.mp3');
    audio.currentTime = 0;
    audio.play();
  }
  // patching thisdate, with every build_b2c_list run thisdate jumps one week further, reason unknown, therefore patching MHe 2016_11_15:
  thisdate = new Date();
  let today = formatDate(thisdate);
  if (logging) console.log('today: ' + today);
	  let selector = '';
	  if (arraysEqual(check_media, [0, 0, 0, 0])) {
    selector = 'select 1 as ordering, m.mediaid, m.name, dmar.day_cat_title, dc.day, \'Spiel & Spaß\' as dc_title, m.preview_image, m.has_mother, m.type, m_t.title, m_t.sort_id, dmar.date as d_date from Media m ' +
                    'LEFT JOIN media_trans m_t ON m_t.media_id = m.mediaid ' +
                    'LEFT JOIN days_media_activity_rel dmar ON dmar.media_id = m.mediaid ' +
                    'LEFT JOIN day_categories dc ON dc.day = strftime(\'%w\',\'' + getFreshestContent(1) + '\') ' +
                    'where m_t.language = \'' + lang + '\' and dmar.date=\'' + getFreshestContent(1) + '\' and dmar.sort_id=1 ' +
										'UNION ' +
										'select 2 as ordering, m.mediaid, m.name, dmar.day_cat_title, dc.day, \'Entspannung\' as dc_title, m.preview_image, m.has_mother, m.type, m_t.title, m_t.sort_id, dmar.date as d_date from Media m ' +
                    'LEFT JOIN media_trans m_t ON m_t.media_id = m.mediaid ' +
                    'LEFT JOIN days_media_activity_rel dmar ON dmar.media_id = m.mediaid ' +
                    'LEFT JOIN day_categories dc ON dc.day = strftime(\'%w\',\'' + getFreshestContent(2) + '\') ' +
                    'where m_t.language = \'' + lang + '\' and dmar.date=\'' + getFreshestContent(2) + '\' and dmar.sort_id=1 ' +
    //				"UNION "+
    //				"select 3 as ordering, m.mediaid, m.name, dmar.day_cat_title, dc.day, 'Koordination' as dc_title, m.preview_image, m.has_mother, m.type, m_t.title, m_t.sort_id, dmar.date as d_date from Media m "+
            			//        "LEFT JOIN media_trans m_t ON m_t.media_id = m.mediaid "+
            			//        "LEFT JOIN days_media_activity_rel dmar ON dmar.media_id = m.mediaid "+
            			//        "LEFT JOIN day_categories dc ON dc.day = strftime('%w','"+getFreshestContent(3)+"') "+
            			//        "where m_t.language = '"+lang+"' and dmar.date='"+getFreshestContent(3)+"' and dmar.sort_id=1 "+
    //			"UNION "+
    //			"select 4 as ordering, m.mediaid, m.name, dmar.day_cat_title, dc.day, 'Geschichten' as dc_title, m.preview_image, m.has_mother, m.type, m_t.title, m_t.sort_id, dmar.date as d_date from Media m "+
    //      "LEFT JOIN media_trans m_t ON m_t.media_id = m.mediaid "+
    //      "LEFT JOIN days_media_activity_rel dmar ON dmar.media_id = m.mediaid "+
    //      "LEFT JOIN day_categories dc ON dc.day = strftime('%w','"+getFreshestContent(4)+"') "+
    //      "where m_t.language = '"+lang+"' and dmar.date='"+getFreshestContent(4)+"' and dmar.sort_id=1 "+
										'order by ordering;';
  } else {
    selector = 'select m.mediaid, m.name, dmar.day_cat_title, dc.day, dc.title as dc_title, m.preview_image, m.has_mother, m.type, m_t.title, m_t.sort_id from Media m ' +
                    'LEFT JOIN media_trans m_t ON m_t.media_id = m.mediaid ' +
                    'LEFT JOIN days_media_activity_rel dmar ON dmar.media_id = m.mediaid ' +
                    'LEFT JOIN day_categories dc ON dc.day = strftime(\'%w\',\'' + today + '\') ' +
                    'where m_t.language = \'' + lang + '\' and dmar.date=\'' + today + '\' order by dmar.sort_id asc;';
  }
  if (logging) console.log(check_media);
  if (logging) console.log(selector);

  let new_listing = '<div id="solo_start_screen" style="margin:-30px auto; width:851px; text-align:center;background-color:transparent;box-shadow:none;">';
  db
    .transaction(function(transaction) {
      transaction
        .executeSql(
          selector,
          [], function(
            transaction, result) {
            let ui_header;
            let weekday = (new Date(today)).getDay();
            let row;
            if (logging)console.log('transaction');
            if (result !== null	&& result.rows !== null) {
              let length = result.rows.length;
              if (arraysEqual(check_media, [0, 0, 0, 0])) {
                let style = 'style="text-align:center;';
                let style_add = '';
                for (let k = 0; k < length; k++) {
                  style_add = '';
    											row = result.rows.item(k);
    											if (logging)console.log(row);
                  only_one_item = true;
	                     						ui_header = row.dc_title;

                  let l = k + 1;
                  if (k === 0)style_add += 'background-image:url(\'' + local_dir + '/img/ico/Spiele_320x215.png\');';
                  if (k === 1)style_add += 'background-image:url(\'' + local_dir + '/img/fotobuecher/prev/Filme_entspannung_250x170.png\');';

	                      new_listing += '<div id="item' + l + '" class="media_menu show_first_motivation" data-mediaid="' +
	                          row.mediaid +
	                          '" data-media-type="' +
	                          row.type +
	                          '" data-item-order=1"' +
	                          '" data-media-title="' +
	                          row.title +
	                          '" data-date="' +
	                          row.d_date +
	                          '" data-video-url="' +
	                          // + video_dir
	                          row.name +
														'" data-role="button" ' + style + style_add + '">' +
	                          // '<span style="position:relative;top:-8px;">'+row.dc_title+'</span>'+
														row.dc_title +
														// '<p class="tapbutton tapbutton2" style="float:right;line-height:80px;margin-top:27.5px;width:200px;" >starten</p>'+
														'</div>';

	                          curr_sort_id = 0;
	                          sessionStorage.mediaid = 0;
                }
                /* new_listing += '<div id="item6" class="media_menu show_first_motivation" data-video-url="diashow" data-role="button"  '+style+'background-image:url(\''+local_dir+'/img/ico/Bilderfamilie_250x170.png\');">'+
	                          //'<span style="position:relative;top:-8px;">Fotos & Grüße</span>'+
														strings[194]+//diashow
														'</div>';*/
                new_listing += '<div id="item5" class="media_menu show_first_motivation" data-video-url="feeling_well" data-role="button"  ' + style + 'background-image:url(\'' + local_dir + '/img/ico/Profile_320x215.png\');">' +
	                        	/* Wohlbefinden*/strings[195] + '</div>';
                new_listing += '<div id="item5" class="media_menu show_first_motivation" data-video-url="fotoalbum" data-role="button"  ' + style + 'background-image:url(\'' + local_dir + '/img/ico/Biographie_250x170.png\');">' +
	                          // '<span style="position:relative;top:-8px;">Fotoalbum</span>'+
                /* Fotoalbum*/strings[189] + '</div>';
              } else {
                only_one_item = false;
    									if (logging) console.log('result-length: ' + length);
    									for (let i = 0; i < length; i++) {
    										row = result.rows.item(i);
                  daily_media_ids[0] = row.mediaid;
    										let j = i + 1;
    										ui_header = row.dc_title;
    									//	weekday = row.day;
    										new_listing += '<div id="item' + j + '" class="media_menu  show_first_motivation" data-mediaid="' +
    												row.mediaid +
    												'" data-media-type="' +
    												row.type +
    												'" data-item-order="' +
    												j +
    												'" data-media-title="' +
    												row.title +
    												'" data-video-url="' +
    												// + video_dir
    												row.name +
    												'" data-role="button" style="/*padding:0px 15px 3px 15px;height:155px;min-height:155px;*/"><img class="videoElement" src="' +
    												local_dir +
    												'img/fotobuecher/prev/';
    										// sunday quizes use always special icon
    										if (weekday === 0 && j === 3) {
    											new_listing += 	'Icon_Spiele_textraetsel_250x170.gif';
    										} else {
    											new_listing += 	row.preview_image;
    										}
    										new_listing += 	'" width="320" height="180" style="width:185px !important;height:123px !important;margin-top:-20px;" />' +
    												'<span style="position:relative;top:-8px;">' +
    												row.day_cat_title +
    												'</span><p id="start_daily_act' +
    												j +
    												'" class="tapbutton tapbutton2 start_daily_act" style="float:right;line-height:80px;margin-top:27.5px;width:200px;">START</p>';
    										if (check_media[i] == 1) {
    											new_listing += '<div style="margin-right:25px;margin-top:35px;background-image:url(\'' + local_dir + './img/ico/haken_green_250x250.png\');height:80px;width:80px;border-radius:80px;float:right;background-size: cover;background-position: center;">&nbsp;</div>';
    										}
    										new_listing += '</div>';
    									}
              }
            }
            new_listing += '</div>';

            // if 'new' day make different style:
            if (arraysEqual(check_media, [0, 0, 0, 0])) {
									  $('#media_menu_wrapper').empty().html(new_listing).trigger('create').attr('style', 'min-height:700px;height:100%;overflow-y:hidden;');
            }
            $('#media_menu_wrapper').empty().html(new_listing).trigger('create').attr('style', 'min-height:700px;height:100%;overflow-y:hidden;');
            $('#home .settings').attr('style', 'position:fixed; right:50px; !important');
            $('#home .hilfe_home').attr('style', 'display: none; !important');
            let formatedDate = today.substring(8, 10) + '.' + today.substring(5, 7) + '.' + today.substring(0, 4);
            switch (weekday) {
              case 1:
                $('#home .ui-title').html('Montag - ' + formatedDate);
                break;
              case 2:
                $('#home .ui-title').html('Dienstag - ' + formatedDate);
                break;
              case 3:
                $('#home .ui-title').html('Mittwoch - ' + formatedDate);
                break;
              case 4:
                $('#home .ui-title').html('Donnerstag - ' + formatedDate);
                break;
              case 5:
                $('#home .ui-title').html('Freitag - ' + formatedDate);
                break;
              case 6:
                $('#home .ui-title').html('Samstag - ' + formatedDate);
                break;
              case 0:
                $('#home .ui-title').html('Sonntag - ' + formatedDate);
                break;
              default:
                $('#home .ui-title').html(ui_header);
            }
          }, errorHandler);
    }, errorHandler, function() {
      if (only_one_item) {
        if (logging) console.log('binding solo_stort_button');
        bind_solo_start_button();
      } else {
        if (logging) console.log('binding ALL solo_stort_buttons');
        bind_start_buttons();
      }
    });
}

function b2c_nonet_select() {
  // logging=true;
  // getcheckedMedia_query = "select distinct substr(action_env,0,2) as item_order from user_stats where userid="+userid+" and action_env like '%"+formatDate(thisdate)+"'";
  // getCheckedMedia(getcheckedMedia_query, build_b2c_list);
  check_media = [0, 0, 0, 0];
  curr_sort_id = 0;
  sessionStorage.mediaid = 0;
  // ESt: geschummelt...
  setTimeout(build_welcome_screen, 100);
}

// we need to know if we are on welcome screen or selection page :
var solo_screen = '';
var intervalplayer;
function build_welcome_screen() {
  // always set seba to false
  audio.pause();
  solo_sound_assi = false;
  clearInterval(intervalplayer);
  Settings.update('solo_sound_assi', 'false', nullHandler);
  solo_screen = 'welcome';
  // var welcome_text = 'Willkomen zum Tagesprogramm<br>Drücken Sie auf Starten';
  audio.setAttribute('src', local_dir + 'audio/intro_start.mp3');


  welcome_text = '<div id="solo_start_screen" style="position: fixed; top: 55px;height:700px; left: 15px;text-align:center">' +
                    '<img src="./img/ico/play_300x300.png" class="btn_snd_play" style="right:50px;top:550px;" id="welcome_sound_playbtn" />' +
                    '<div id="welcome_go">starten</div>' +
                  '</div>';
  console.log('appending welcome_text: ' + welcome_text);
  $('#media_menu_wrapper').empty().html(welcome_text).trigger('create').attr('style', 'min-height:700px;height:100%;overflow-y:hidden;');
  $('#home .hilfe_home').attr('style', 'display: none; !important');
  $('#home .settings').attr('style', 'position:fixed; right:50px; !important');

  if (is_app > -1) {
    $('#welcome_go')
      .off()
      .on('touchstart', function(event) {
        event.preventDefault();
        $(this).addClass('i_antw_active');
      })
      .on('touchend', function(event) {
        event.preventDefault();
        if (typeof audio !== 'undefined') {
          // audio.pause();
          clearInterval(intervalplayer);
        }
        build_b2c_list();
      });


    $('#welcome_sound_playbtn')
      .off()
      .on('touchstart', function(event) {
        event.preventDefault();
        $(this).addClass('btn_snd_play_active');
        welcome_sound_playbtn();
      });
  } else {
    $('#welcome_go')
      .off()
      .on('vclick', function(event) {
        event.preventDefault();
        build_b2c_list();
        if (typeof audio !== 'undefined') {
          clearInterval(intervalplayer);
        }
      });

    $('#welcome_sound_playbtn')
      .off()
      .on('vclick', function(event) {
        event.preventDefault();
        $(this).addClass('btn_snd_play_active');
        welcome_sound_playbtn();
      });
  }
  if (solo_sound_assi) {
    welcome_sound_play();
  }
}

function welcome_sound_playbtn() {
  if (solo_sound_assi) {
    $('#welcome_sound_playbtn').attr('src', './img/ico/play_300x300.png');
    audio.pause();
    solo_sound_assi = false;
    clearInterval(intervalplayer);
    Settings.update('solo_sound_assi', 'false', nullHandler);
  } else {
    solo_sound_assi = true;
    Settings.update('solo_sound_assi', 'true', welcome_sound_play);
  }
}

function welcome_sound_play() {
  Settings.select('solo_sound_assi', function(set) {
    if (set === 'true') {
      solo_sound_assi = true;
      $('#welcome_sound_playbtn').attr('src', './img/ico/button_pause_140x140.gif');
      setTimeout(function() {
        // if solo_sound_assi might be disabled in the 5s till execution:
        if (solo_sound_assi && solo_screen === 'welcome') {
          let count = 1;
          audio.currentTime = 0;
          audio.play();
          // only start interval if on home screen/welcome page:
          intervalplayer = setInterval(function() {
            if (solo_sound_assi && solo_screen === 'welcome')audio.play();
            count += 1;
            if (count == 10) clearInterval(intervalplayer);
          }, 30000);
        }
      }, 5000);
    } else if (set === 'false') {
      solo_sound_assi = false;
    } else {
      // write settings:
      Settings.insert('solo_sound_assi', 'false', nullHandler);
    }
  });
}

function show_message_dummy() {
  let welcome_text = 'Es sind noch keine Nachrichten von Ihrer Familie und Ihren Freunden verfügbar!';

  welcome_text = '<div id="solo_start_screen" style="position: fixed; top: 55px;height:700px; left: 15px;text-align:center">' +
										'<div style="font-size:70px; width:700px; margin: 50px auto;">' + welcome_text + '</div>' +
										'<div id="show_motivation">Weiter</div>' +
									'</div>';
  console.log('appending welcome_text: ' + welcome_text);
  $('#media_menu_wrapper').empty().html(welcome_text).trigger('create').attr('style', 'min-height:700px;height:100%;overflow-y:hidden;');
  $('#welcome_go')
    .off()
    .on('touchstart', function(event) {
      event.preventDefault();
      $(this).addClass('i_antw_active');
    })
    .on('touchend', function(event) {
      event.preventDefault();
      build_b2c_list();
    });
  $('#show_motivation')
    .off()
    .on('touchstart', function(event) {
      event.preventDefault();
      $(this).addClass('i_antw_active');
    })
    .on('touchend', function(event) {
      event.preventDefault();
      build_b2c_list();
    });
  $('#show_motivation')
    .off()
    .on('vclick', function(event) {
      event.preventDefault();
      $(this).addClass('i_antw_active');
      build_b2c_list();
    });
}


function checkDateChange() {
  if ($.mobile.activePage.attr('id') === 'home') {
    let act_date = new Date();
    if (thisdate.getDate() != act_date.getDate()) {
      if (logging)console.log('It\'s a new Day: ' + formatDate(act_date));
      thisdate = act_date;
      b2c_nonet_select();
    }
  }
}

function getCheckedMedia(query, callback) {
  let selector = query;
  check_media = [0, 0, 0, 0];
  db.transaction(
    function(tx) {
      tx.executeSql(
        selector,
        [],
        function(transaction, result) {
          if (result !== null	&& result.rows !== null) {
            let length = result.rows.length;
            for (let i = 0; i < length; i++) {
              let row = result.rows.item(i);
              if (logging) {
                console.log(row.item_order);
              }
              if (row.item_order > 0 && row.item_order < 5) {
                if (logging) {
                  console.log(row.item_order + 'st media checked!');
                }
                check_media[row.item_order - 1] = 1;
              }
            }
          }
        },
        errorHandler);
    },
    errorHandler,
    () => {
      ;
      if (callback) {
        callback();
      }
    }
  );
}
/* this_mediaid: current media_id, the next one will be opened
 * next_next: if true, then set current media_id to next one and show Motivation!
 */
function open_next_media(this_mediaid, next_next) {
  if (solo_sound_assi) audio.removeEventListener('ended', motivation_after_audio, false);
  let mediaid = this_mediaid;
  let next_media_type;
  let media_title;
  let media_name;

  // using thisdate to be able to test different days... 2016-11-02 MHe
  // var curr_date = new Date();
  let curr_date = thisdate;

  let thisday = formatDate(curr_date);
  let selector = 'select m.mediaid, m.name, m_t.title, dmar.sort_id, m.type from Media m' +
	 					' left join days_media_activity_rel dmar on dmar.media_id = m.mediaid' +
						' LEFT JOIN media_trans m_t ON m_t.media_id = m.mediaid' +
						' left join day_categories dc on dc.day = strftime(\'%w\',\'' + thisday + '\') where dmar.date=\'' + thisday + '\' order by dmar.sort_id';
  db.
    transaction(function(transaction) {
	    transaction
	        .executeSql(
	            selector,
	            [], function(
	                transaction, result) {
            let length = result.rows.length;
            let row;
            if (logging) {
              console.log(selector);
            }
            // very first media
            if (parseInt(media_id) === 0) {
              row = result.rows.item(0);
              curr_sort_id = row.sort_id;
              next_mediaid = row.mediaid;
              next_media_type = row.type;
              media_title = row.title;
              media_name = row.name;
            } else {
    									for (let i = 0; i < length; i++) {
    										row = result.rows.item(i);
    										if (logging) {
                  console.log('current mediaid: ' + row.mediaid + ', current sort_id: ' + row.sort_id);
                }
                if (mediaid == row.mediaid) {
    											curr_sort_id = row.sort_id;
    											if (logging) {
                    console.log('sort_id remembered: ' + curr_sort_id);
                  }
    										}
    										// if its the next sort_id remember the mediaid:
    										if ((parseInt(curr_sort_id) + 1) == row.sort_id) {
    											next_mediaid = row.mediaid;
    											next_media_type = row.type;
    											media_title = row.title;
	    										media_name = row.name;
                }
    									}
            }
          }, errorHandler);
    }, errorHandler, function() {
      sessionStorage.mediaid = next_mediaid;
      curr_sort_id = parseInt(curr_sort_id) + 1;

      if (next_next) {
        if (logging) {
          console.log('--> media ' + next_mediaid + ' overjumped... showing motivation for ' + parseInt(curr_sort_id) + 1 + 'media');
        }
        showMotivation();
      } else {
        if (logging) {
          console.log('--> next_mediaid: ' + next_mediaid + ', media_title: ' + media_title + ', media_name: ' + media_name + ', next_media_type: ' + next_media_type);
        }
        add_user_stats(next_mediaid, 1, (parseInt(curr_sort_id) + 1) + 'st media daily program on ' + formatDate(thisday), '');
        addtoProfileTimeline(next_mediaid, 1);

        if (next_media_type === 'video') {
          if (is_app < 0) {
            media_name = 'videos/' + media_name;
          }
          openvideo(media_name, media_title, next_mediaid);
        }

        if (next_media_type === 'game') {
          if (media_name === 'quiz') {
            // var id = $(this).attr('data-mediaid');
            sessionStorage.quizid = next_mediaid;
            page.go_to('quiz.html');
          } else {
            page.go_to('./games' + media_name);
          }
        }
        // ESt: in this utilization mode all first medias are quotes
        if (parseInt(curr_sort_id) === 1) {
          if (logging) console.log('i quotes or jokes !');
          sessionStorage.bookid = next_mediaid;
          page.go_to('quote.html');
        }

        if (parseInt(curr_sort_id) > 1 && next_media_type === 'book') {
          unbind_quote_nav_buttons();
          if (logging) console.log('it s a book !');
          sessionStorage.bookid = next_mediaid;
          page.go_to('quote.html');
        }

        if (next_media_type === 'quiz') {
          sessionStorage.quizid = next_mediaid;
          page.go_to('quiz.html');
        }
      }
    });
}

function bind_start_buttons_nav() {
  // navigating through dates in developer mode
  if (show_switch_date_btns && utilization == 4) {
    $('#day_back')
      .off()
      .on(clickhandler, function(evt) {
        evt.preventDefault();
        thisdate.setDate(thisdate.getDate() - 1);
        $('#thisday').html(formatDate(thisdate));
        b2c_nonet_select();
      });
    $('#day_forward')
      .off()
      .on(clickhandler, function(evt) {
        evt.preventDefault();
        thisdate.setDate(thisdate.getDate() + 1);
        $('#thisday').html(formatDate(thisdate));
        b2c_nonet_select();
      });
  }
}

function unbind_start_buttons_nav() {
  $('#day_back').off();
  $('#day_forward').off();
}

function bind_solo_start_button() {
	 if (is_app > 0) {
		 $('.show_first_motivation').on('touchstart', function(event) {
			 event.preventDefault();
			 $(this).addClass('media_menu_active');
		 })
		 .on('touchend', function(event) {
			 event.preventDefault();
			 if (logging) console.log('show_first_motivation clicked');
			 $(this).removeClass('media_menu_active');
			 let data_video_url = $(this).attr('data-video-url');
			 if (data_video_url === 'diashow') {
				 // start diashow:
					 Fotos.select_by_userid(userid, function(arr) {
						 pswipe.diashow_postcards(arr);
					 });
			 } else if (data_video_url === 'fotoalbum') {
					 Fotos.select_by_userid(userid, function(arr) {
            pswipe.fotogallery_postcards(arr);
					 });
			 } else if (data_video_url === 'feeling_well') {
    				 page.go_to('./feeling_questions.html');
		 	 } else {
				 $('.show_first_motivation').off();
				 // getting various activites from different weekdays of the week thisdate is part of:
				 thisdate = new Date($(this).attr('data-date'));
				 page.go_to('motivation.html');
			 }
		 });
 	} else {
		 $('.show_first_motivation').on('vclick', function(event) {
      event.preventDefault();
		 	 	if (logging) console.log('show_first_motivation clicked');
			 	if ($(this).attr('data-video-url') === 'diashow') {
        // start diashow:
        Fotos.select_by_userid(userid, function(arr) {
          pswipe.diashow_postcards(arr);
        });
      } else if ($(this).attr('data-video-url') === 'fotoalbum') {
	 					 Fotos.select_by_userid(userid, function(arr) {
	 						 pswipe.fotogallery_postcards(arr);
	 					 });
      }	else {
        $('.show_first_motivation').off();
        // getting various activites from different weekdays of the week thisdate is part of:
        thisdate = new Date($(this).attr('data-date'));
        page.go_to('motivation.html');
      }
		 });
	 }
}
function arraysEqual(a, b) {
  if (a === b) return true;
  if (a === null || b === null) return false;
  if (a.length != b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

function padZero(value) {
  return value.toString().padStart(2, '0');
}

/*
formatDate takes a Date() as parameter and returns a String like '2016-01-30'
*/
function formatDate(date) {
  let today = new Date(date);
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!
  let yyyy = today.getFullYear();
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  today = yyyy + '-' + mm + '-' + dd;
  return today;
}

/*
formatDate takes a Date() as parameter and returns a String like '201601301001'
*/
function formatTimestamp(timestamp){
	var today = new Date(timestamp);
	var min = today.getMinutes();
	var hh = today.getHours();
	var dd = today.getDate();
	var mm = today.getMonth()+1; //January is 0!
	var yyyy = today.getFullYear();
	if(dd<10) dd='0'+dd;
	if(mm<10) mm='0'+mm;
	if(hh<10) hh='0'+hh;
	if(min<10) min='0'+min;
	today = yyyy+mm+dd+hh+min;
	return today;
}

/* function to get latest day with content
 * content=1: Spiel und Spass
 * content=2: Entspannung
 * content=3: Bewegung
 * contetn=4: Geschichten und Gedichte
*/
function getFreshestContent(content) {
  let day;
  switch (parseInt(content)) {
    case 1:
      if (Math.random() <= 0.333) {
        day = 2;
      } else if (Math.random() < 0.666) {
        day = 4;
      } else {
        day = 6;
      }
      break;
    case 2:
      if (Math.random() <= 0.333) {
        day = 3;
      } else if (Math.random() < 0.666) {
        day = 5;
      } else {
        day = 7;
      }
      break;
    case 3:
      day = 1;
      break;
    case 4:
      day = 7;
      break;
    default:
      break;
  }
  let ret = thisdate;
  ret = new Date(ret.setDate(ret.getDate() + day - ret.getDay()));
  return formatDate(new Date(ret));
}
