class CheckVideoUrls {
  CONSTANTS() {
    return Object.freeze({
      VIDEO_FOUND: 'success',
      VIDEO_NOT_FOUND: 'error',
    });
  }
  init() {
    return new Media().video().getUncheckedUrls()
      .then((videos) => {
        return Promise.all(
          videos.map(
            (vid) => {
              return this.lookForVideo(vid);
            }
          )
        );
      });
  };

  lookForVideo(video = {mediaid: 0, name: ''}) {
    return this.searchInPath(sd_card_url, video.name)
      .then((res) => {
        const mediaModel = new Media();
        if (res.status === 'success') {
          return mediaModel.video().enable(res.path, video.mediaid)
            .then(Promise.resolve());
        } else {
          return this.searchInPath(
            cordova.file.externalDataDirectory,
            video.name
          )
            .then(() => {
              if (res.status === this.CONSTANTS().VIDEO_FOUND) {
                return mediaModel.video().enable(res.path, video.mediaid)
                  .then(Promise.resolve());
              }
            })
            .catch(() => {
              return mediaModel.video().disable()
                .then(Promise.resolve);
            });
        }
      });
  };

  searchInPath(rootPath, subPath) {
    const potentialSdCardPath =
      rootPath +
      'Android/data/com.media4care.dementia/files/videos/' +
      subPath;
    return Files.resolvePromise(potentialSdCardPath)
      .then(() => {
        return {
          status: this.CONSTANTS().VIDEO_FOUND,
          path: potentialSdCardPath,
        };
      })
      .catch(() => {
        return {
          status: this.CONSTANTS().VIDEO_NOT_FOUND,
          path: potentialSdCardPath,
        };
      });
  };
}
