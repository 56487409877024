/* eslint max-len: ["error", 190] */


/**
 * Logs a success message to the console.
 */
function successCallBack() {
  console.log('DEBUGGING: success');
}

/**
 * Create basic database structure.
 * (This function ist called when the application loads.)
 * @param {function} oBL_callback - Function to be executed as callback.
 */

function createOnBodyLoad() {
  /**
   * window.dbExecute = promiseDBDeco();
   * dbExecute('select * from Messages', []).then((resolve) => {console.log('works')});
   */
  return new Promise(
    (resolveCreate, rejectCreate) => {
      window.onBodyLoad = () => {
        return new Promise(
          (resolve, reject) => {
            if (!sqlitePlugin.openDatabase) {
              // not all mobile devices support databases  if it does not, the
              // following alert will display
              // indicating the device will not be albe to run this application
              alert('Databases are not supported in this browser.');
              return;
            }

            const createTableVersionSql = `CREATE TABLE IF NOT EXISTS table_version(
              id INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
              table_name TEXT,
              version INTEGER NOT NULL DEFAULT 0,
              comment TEXT,
              last_updated TIMESTAMP NOT NULL DEFAULT CURRENT_TIMESTAMP,
              created TIMESTAMP NOT NULL DEFAULT CURRENT_TIMESTAMP,
              unique(table_name))`;

            // Create TRigger for table table_versions AU (after update)
            // every time a row in the table_versions gets updated the last_updated column gets updated
            const createTriggerTableVersions = `CREATE TRIGGER IF NOT EXISTS TR_table_versions_AU 
              AFTER UPDATE ON table_version 
              FOR EACH ROW 
              BEGIN 
              UPDATE table_version SET last_updated = datetime('now') where id = old.id; 
              END;`;

            const createUserTable = `CREATE TABLE IF NOT EXISTS User(
              userid INTEGER NOT NULL PRIMARY KEY,
              firstname TEXT NOT NULL,
              lastname TEXT NOT NULL,
              prof_img TEXT NOT NULL,
              creation_datetime datetime,
              status INT NOT NULL
            )`;

            const dropBiosTable = `DROP TABLE IF EXISTS bios`;

            const createBiosTable = `CREATE TABLE IF NOT EXISTS bios (
              id INTEGER NOT NULL PRIMARY KEY, 
              listing TEXT NOT NULL, 
              sort INT NOT NULL)`;

            const seedBiosTable = `INSERT OR IGNORE INTO 
              bios(id,listing,sort) 
              values
                (1,"Geburtsdatum",1),
                (2,"Geburtsort",2),
                (3,"Name der Mutter",3),
                (4,"Name des Vaters",4),
                (6,"Beruf der Eltern",6),
                (7,"Geschwister (Namen)",7),
                (8,"Bezugsperson (Namen) ",8),
                (9,"Schulabschluss/ Ausbildung/Beruf",9),
                (10,"Sprachen/ Dialekte",10),
                (11,"Familienstand (verwitwet) ",11),
                (12,"Kinder (Anzahl + Name) ",12),
                (13,"Enkel (Anzahl + Name)",13),
                (15,"Wichtige pers&ouml;nliche Erlebnisse",15),
                (16,"Traumatische Ereignisse",16),
                (17,"Wertvorstellung/Was ist noch heute wichtig?",17),
                (18,"religi&ouml;se Befindlichkeit",18),
                (19,"soziale Kontakte/ Freunde + Bekannte",19),
                (20,"Charaktereigenschaften",20),
                (21,"Kleidung",21),
                (22,"Lieblingsfarbe",22),
                (23,"Geruchsvorlieben",23),
                (24,"Schmuck",24),
                (25,"Lieblingsspeisen",25),
                (26,"Lieblingsgetr&auml;nke",26),
                (28,"Speisenabneigungen",28),
                (29,"Getr&auml;nkeabneigungen",29),
                (31,"Schlafgewohnheiten",31),
                (32,"Ruhezeiten",32),
                (33,"Besonderheiten",33),
                (41,"Sinneseinschr&auml;nkungen",41),
                (42,"Lieblingsger&auml;usche/ Abneigungen",42),
                (43,"Lieblingsger&uuml;che/ Abneigungen",43),
                (44,"Rechts-/ oder Linksh&auml;nder?",44),
                (45,"Welche Rolle spielt Bewegung?",45),
                (46,"K&ouml;rperkontakt erw&uuml;nscht?",46),
                (47,"Pr&auml;ferenz des Pflegepersonals",47),
                (49,"Sammelleidenschaft",49),
                (50,"Hobbys",50),
                (51,"Interessen",51),
                (52,"Musik",52),
                (53,"Handwerk",53),
                (54,"Politik",54),
                (55,"Verein",55),
                (56,"Sport",56),
                (57,"Vorlieben",57),
                (58,"Abneigungen",58),
                (59,"Duschen oder baden?",59),
                (60,"warmes oder kaltes Wasser?",60),
                (61,"Zahnpflege/ Prothese",61),
                (62,"K&ouml;rperpflegemittel",62)`;

            const createUserBioTable = `CREATE TABLE IF NOT EXISTS user_bio(
              id INTEGER NOT NULL PRIMARY KEY,
              userid INT NOT NULL,
              bios_id INT NOT NULL,
              description TEXT NOT NULL,
              unique(
                userid,
                bios_id,
                description
              )
            )`;

            const insertTestUser = `INSERT OR IGNORE INTO User 
              (userid, firstname, lastname, prof_img, status) 
              values (1, 'Max','Mustermann','none',1)`;

            const insertTestUserTwo = `INSERT OR IGNORE INTO User 
              (userid, firstname, lastname, creation_datetime, prof_img, status) 
              values (2,'Max', 'Mustermann', '2015-05-21 12:37:28', 'none', 1)`;

            const createUserPageRelation = `CREATE TABLE IF NOT EXISTS user_page_relation(
              id INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
              userid INTEGER NOT NULL,
              itemid INTEGER NOT NULL,
              item_typeid INTEGER NOT NULL,
              added_datetime datetime,
              comment TEXT,
              rating INTEGER,
              FOREIGN KEY(userid) REFERENCES User (userid))`;

            const insertPageRelations = `INSERT OR IGNORE INTO 
              user_page_relation(id,userid,itemid,item_typeid,added_datetime)
              values
                (1,2,20,1,"2015-05-21 12:37:28"),
                (2,2,378,1,"2015-05-21 12:37:28"),
                (3,2,4,1,"2015-05-21 12:37:28"),
                (4,2,90,1,"2015-05-21 12:37:28"),
                (5,2,115,1,"2015-05-21 12:37:28"),
                (6,2,200,1,"2015-05-21 12:37:28")`;

            const createFotosTable = `CREATE TABLE IF NOT EXISTS Fotos(
              fotoid INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
              userid INTEGER NOT NULL,
              photo_url TEXT NOT NULL,
              prev_url TEXT NOT NULL,
              type INTEGER NOT NULL
            )`;

            const addFirstNameToFotosTable = `ALTER TABLE Fotos ADD COLUMN firstname TEXT default ''`;

            const createPflegeboxApplication = `CREATE TABLE IF NOT EXISTS pflegebox_application(
              id INTEGER NOT NULL PRIMARY KEY,
              o_box_set INTEGER NOT NULL,
              o_cust_ordering TEXT NOT NULL,
              o_bed_linings INTEGER,
              o_glove_size TEXT NOT NULL,
              o_delivery_to TEXT NOT NULL,
              o_order_date DATE NOT NULL,
              o_vollmacht_signature BLOB NOT NULL,
              o_signature BLOB NOT NULL,
              p_name TEXT NOT NULL,
              p_surname TEXT NOT NULL,
              p_title TEXT NOT NULL,
              p_area TEXT NOT NULL,
              p_zip TEXT NOT NULL,
              p_street TEXT NOT NULL,
              p_birth DATE,
              p_insurance TEXT NOT NULL,
              p_insurance_nr TEXT NOT NULL,
              p_ins_status TEXT,
              p_care_degree INT,
              p_care_stage INT,
              p_how_care TEXT,
              ct_email TEXT NOT NULL,
              ct_name TEXT NOT NULL,
              ct_surname TEXT NOT NULL,
              ct_title TEXT NOT NULL,
              ct_area TEXT NOT NULL,
              ct_zip TEXT NOT NULL,
              ct_street TEXT NOT NULL,
              ct_tel TEXT,
              ct_relation TEXT NOT NULL,
              ct_accredited BOOL,
              cs_name TEXT NOT NULL,
              cs_adress TEXT NOT NULL,
              cs_partner_nr TEXT NOT NULL,
              created TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
              sent_state TEXT NOT NULL DEFAULT "unsent",
              sent_update TIMESTAMP DEFAULT NULL,
              unique (
                  p_name,
                  p_surname,
                  p_zip,
                  p_street,
                  p_insurance_nr,
                  created ))`;

            const createPatientTable = `CREATE TABLE IF NOT EXISTS patient(
              id INTEGER NOT NULL PRIMARY KEY,
              name TEXT NOT NULL,
              surname TEXT NOT NULL,
              title TEXT NOT NULL,
              area TEXT NOT NULL,
              zip TEXT NOT NULL,
              street TEXT NOT NULL,
              birth DATE,
              tel TEXT,
              insurance TEXT NOT NULL,
              insurance_nr TEXT NOT NULL,
              ins_status TEXT,
              care_degree INT,
              care_stage INT,
              how_care TEXT, ${''/* Pflegesachleistung, Pflegegeld oder Kombinationsleistung*/}
              last_edit TIMESTAMP DEFAULT CURRENT_TIMESTAMP, unique(name,surname))`;

            const createCaretaker = `CREATE TABLE IF NOT EXISTS caretaker(
              id INTEGER NOT NULL PRIMARY KEY,
              email TEXT NOT NULL,
              name TEXT NOT NULL,
              surname TEXT NOT NULL,
              title TEXT NOT NULL,
              area TEXT NOT NULL,
              zip TEXT NOT NULL,
              street TEXT NOT NULL,
              tel TEXT,
              relation TEXT NOT NULL,
              accredited BOOL,
              last_edit TIMESTAMP DEFAULT CURRENT_TIMESTAMP, unique(name,surname))`;

            const createCareService = `CREATE TABLE IF NOT EXISTS careservice(
              id INTEGER NOT NULL PRIMARY KEY,
              email TEXT,
              name TEXT NOT NULL,
              adress TEXT NOT NULL,
              partner_nr TEXT NOT NULL,
              last_edit TIMESTAMP DEFAULT CURRENT_TIMESTAMP, unique(name))`;

            const createOrdering = `CREATE TABLE IF NOT EXISTS ordering(
              id INTEGER NOT NULL PRIMARY KEY,
              box_set INTEGER NOT NULL,
              cust_ordering TEXT NOT NULL,
              bed_linings INTEGER,
              glove_size TEXT NOT NULL,
              delivery_to TEXT NOT NULL,
              order_date DATE NOT NULL,
              vollmacht_signature BLOB NOT NULL,
              signature BLOB NOT NULL,
              last_edit TIMESTAMP DEFAULT CURRENT_TIMESTAMP)`;

            const createRatingsTable = `CREATE TABLE IF NOT EXISTS ratings_tl (
              id INTEGER NOT NULL PRIMARY KEY,
              upr_id INT NOT NULL,
              rating INT NOT NULL,
              last_edit datetime,
              FOREIGN KEY(upr_id)
              REFERENCES user_page_relation(id))`;

            const addTitleToFotosTable = `ALTER TABLE Fotos add title TEXT DEFAULT 'untitled'`;
            const addSenderToFotosTable = `ALTER TABLE Fotos add sender TEXT DEFAULT 'unknown'`;

            const createFotodirTable = `CREATE TABLE IF NOT EXISTS Fotodirs (
              id     INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
              userid INTEGER NOT NULL,
              title  TEXT    NOT NULL,
              FOREIGN KEY (userid) REFERENCES User (userid))`;

            const createFotodirRel = `CREATE TABLE IF NOT EXISTS Fotodirrel (
              id        INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
              fotodirid INTEGER NOT NULL,
              fotoid    INTEGER NOT NULL,
              FOREIGN KEY (fotodirid) REFERENCES Fotodirs (id),
              FOREIGN KEY (fotoid) REFERENCES Fotos (fotoid))`;

            const createUserMessages = `CREATE TABLE IF NOT EXISTS user_messages (
                um_id             INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
                userid            INTEGER NOT NULL,
                sender            TEXT    NOT NULL,
                subject           TEXT    NOT NULL,
                message           TEXT    NOT NULL,
                status            INTEGER NOT NULL,
                creation_datetime datetime,
                last_edit         datetime,
                FOREIGN KEY (userid) REFERENCES User (userid))`;

            const createUserMediaComments = `CREATE TABLE IF NOT EXISTS user_media_comments (
                umc_id            INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
                userid            INTEGER NOT NULL,
                mediaid           INTEGER NOT NULL,
                comment           TEXT    NOT NULL,
                creation_datetime datetime,
                last_edit         datetime,
                FOREIGN KEY (userid) REFERENCES User (userid),
                FOREIGN KEY (mediaid) REFERENCES Media (mediaid))`;

            const createUserStats = `CREATE TABLE IF NOT EXISTS user_stats (
              us_id           INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
              userid          INTEGER NOT NULL,
              mediaid         INTEGER,
              categoryid      INTEGER,
              action_id       INTEGER NOT NULL,
              action_env      TEXT,
              action_datetime datetime,
              us_status       INTEGER NOT NULL,
              last_edit       datetime,
              FOREIGN KEY (userid) REFERENCES User (userid),
              FOREIGN KEY (mediaid) REFERENCES Media (mediaid),
              FOREIGN KEY (categoryid) REFERENCES categories (id))`;

            const createUserProposalsV3Table = `CREATE TABLE IF NOT EXISTS user_proposals_v3 (
              up_id          INTEGER  NOT NULL PRIMARY KEY AUTOINCREMENT,
              up_userid      INTEGER  NOT NULL,
              up_type        TEXT     NOT NULL,
              up_media_id    INTEGER,
              up_category_id INTEGER,
              up_text_id     INTEGER,
              day            datetime NOT NULL,
              up_status      INTEGER  NOT NULL,
              up_sort_id     INTEGER  NOT NULL,
              last_edit      datetime,
              FOREIGN KEY (up_userid) REFERENCES User (userid),
              FOREIGN KEY (up_media_id) REFERENCES Media (mediaid),
              FOREIGN KEY (up_category_id) REFERENCES categories (id),
              FOREIGN KEY (up_text_id) REFERENCES texts (id))`;

            const createInvitationsTable = `CREATE TABLE IF NOT EXISTS invitations_v3 (
              inv_id        INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
              inv_userid    INTEGER NOT NULL,
              inv_mail      TEXT    NOT NULL,
              inv_status    INT     NOT NULL,
              inv_title     TEXT,
              inv_firstname TEXT,
              inv_lastname  TEXT,
              inv_phone     TEXT,
              created       datetime,
              unique (inv_userid, inv_mail),
              FOREIGN KEY (inv_userid) REFERENCES User (userid))`;

            const seedFromInvitationsV2Table = `INSERT OR IGNORE INTO invitations_v3 
              (inv_userid, inv_mail, inv_status, created)
              SELECT inv_userid, inv_mail, inv_status, created
              from invitations_v2`;

            const createFamilyPortalRegisterDirect = `CREATE TABLE IF NOT EXISTS Family_portal_register_direct (
              id INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT, 
              userid INTEGER NOT NULL,
              prof_id INTEGER NOT NULL,
              mail TEXT NOT NULL,
              password TEXT,
              title TEXT,
              firstname TEXT,
              lastname TEXT,
              phone TEXT,
              status INT NOT NULL,
              created datetime,
              unique(userid, mail),
              FOREIGN KEY(userid) REFERENCES User(userid))`;

            const dropFeelingTable = `DROP TABLE IF EXISTS feeling`;
            const createFeelingTable = `CREATE TABLE IF NOT EXISTS feeling (
                feel_id INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT)`;

            const insertFeeling = `INSERT OR IGNORE INTO feeling (feel_id) values (1), (2)`;

            const dropFeelingTrans = `DROP TABLE IF EXISTS feeling_trans`;
            const createFeelingTrans = `CREATE TABLE IF NOT EXISTS feeling_trans (
              ft_id      INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
              feeling_id INTEGER NOT NULL,
              language   TEXT    NOT NULL,
              question   TEXT    NOT NULL,
              level1     TEXT    NOT NULL,
              level2     TEXT    NOT NULL,
              level3     TEXT    NOT NULL,
              unique (language, question, level1, level2, level3),
              FOREIGN KEY (feeling_id) REFERENCES feeling (feel_id))`;

            const insertFeelingTrans = `INSERT OR IGNORE INTO feeling_trans 
              (ft_id, feeling_id, language, question, level3, level2, level1)
              values (1, 1, 'de', 'Wie haben Sie heute Nacht geschlafen?', 'Gut', 'Okay', 'Schlecht')`;

            const createPlaylistTable = `CREATE TABLE IF NOT EXISTS Playlists (
              id     INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
              userid INT     NOT NULL,
              title  TEXT    NOT NULL,
              FOREIGN KEY (userid) REFERENCES User (userid))`;

            const insertPlaylist = `INSERT OR IGNORE INTO Playlists 
              (id, userid, title)
              values (1, 1, 'Welt der Tiere'),
                (2, 1, 'Fr&uuml;hlingsgef&uuml;hle')`;

            const createPlaylistItemTable = `CREATE TABLE IF NOT EXISTS Playlistitems (
              id         INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
              playlistid INT     NOT NULL,
              mediaid    INT     NOT NULL,
              rating     INT     NOT NULL,
              sort_id    INT     NOT NULL,
              FOREIGN KEY (playlistid) REFERENCES Playlists (id),
              FOREIGN KEY (mediaid) REFERENCES Media (mediaid))`;

            const insertPlaylistItems = `INSERT OR IGNORE INTO
              Playlistitems(id,playlistid,mediaid,rating,sort_id)
              values
                (1,1,141,3,1),
                (2,1,135,3,1),
                (3,1,134,3,1),
                (4,1,115,3,1),
                (5,1,7,3,1),
                (6,1,24,3,1),
                (7,1,31,3,1),
                (8,1,58,3,1),
                (9,1,56,3,1),
                (10,1,53,3,1),
                (11,1,76,3,1),
                (12,1,82,3,1),
                (13,1,79,3,1),
                (14,1,310,3,1),
                (15,1,97,3,1),
                (16,1,314,3,1),
                (17,2,158,3,1),
                (18,1,157,3,1),
                (19,1,174,3,1),
                (20,1,173,3,1),
                (21,1,9,3,1),
                (22,1,14,3,1),
                (23,1,47,3,1),
                (24,1,61,3,1),
                (25,1,84,3,1),
                (26,1,83,3,1),
                (27,1,71,3,1),
                (28,1,77,3,1),
                (29,1,258,3,1)`;

            const createTimelineTable = `CREATE TABLE IF NOT EXISTS Timeline (
              id         INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
              user_id    INT     NOT NULL,
              hash       TEXT    NOT NULL,
              sender     TEXT    NOT NULL,
              status     INT     NOT NULL,
              title      TEXT,
              url        TEXT,
              seen       BOOLEAN,
              comment    TEXT,
              type       TEXT,
              updated_at TEXT,
              FOREIGN KEY (user_id) REFERENCES User (userid),
              UNIQUE (hash)
            )`;

            dbExecute(createTableVersionSql, [])
              .then(() => {
                console.log('createTableVersionSql');
                return dbExecute(createTriggerTableVersions, []);
              })
              .then(() => {
                console.log('createUserTable');
                return dbExecute(createUserTable, []);
              })
              .then(() => {
                return dbExecute(createTimelineTable, []);
              })
              .then(() => {
                console.log('dropBiosTable');
                return dbExecute(dropBiosTable, []);
              })
              .then(() => {
                console.log('createBiosTable');
                return dbExecute(createBiosTable, []);
              })
              .then(() => {
                console.log('seedBiosTable');
                return dbExecute(seedBiosTable, []);
              })
              .then(() => {
                console.log('createUserBioTable');
                return dbExecute(createUserBioTable, []);
              })
              .then(() => {
                console.log('insertTestUser');
                return dbExecute(insertTestUser, []);
              })
              .then(() => {
                console.log('insertTestUserTwo');
                return dbExecute(insertTestUserTwo, []);
              })
              .then(() => {
                console.log('createUserPageRelation');
                return dbExecute(createUserPageRelation, []);
              })
              .then(() => {
                console.log('insertPageRelations');
                return dbExecute(insertPageRelations, []);
              })
              .then(() => {
                console.log('createFotosTable');
                return dbExecute(createFotosTable, []);
              })
              .then(() => {
                console.log('addFirstNameToFotosTable');
                return window.helper_db.checkIfColumnExists('Fotos', 'firstname').then((exist) => {
                  if (exist) {
                    return new Promise((r) => { r(); });
                  } else {
                    return dbExecute(addFirstNameToFotosTable, []);
                  }
                });
              })
              .then(() => {
                console.log('createPflegeboxApplication');
                return dbExecute(createPflegeboxApplication, []);
              })
              .then(() => {
                console.log('createPatientTable');
                return dbExecute(createPatientTable, []);
              })
              .then(() => {
                console.log('createCaretaker');
                return dbExecute(createCaretaker, []);
              })
              .then(() => {
                console.log('createCareService');
                return dbExecute(createCareService, []);
              })
              .then(() => {
                console.log('createOrdering');
                return dbExecute(createOrdering, []);
              })
              .then(() => {
                console.log('createRatingsTable');
                return dbExecute(createRatingsTable, []);
              })
              .then(() => {
                console.log('addTitleToFotosTable');
                return window.helper_db.checkIfColumnExists('Fotos', 'title').then((exist) => {
                  if (exist) {
                    return new Promise((r) => { r(); });
                  } else {
                    return dbExecute(addTitleToFotosTable, []);
                  }
                });
              })
              .then(() => {
                console.log('addSenderToFotosTable');
                return window.helper_db.checkIfColumnExists('Fotos', 'sender').then((exist) => {
                  if (exist) {
                    return new Promise((r) => { r(); });
                  } else {
                    return dbExecute(addSenderToFotosTable, []);
                  }
                });
              })
              .then(() => {
                console.log('createFotodirTable');
                return dbExecute(createFotodirTable, []);
              })
              .then(() => {
                console.log('createFotodirRel');
                return dbExecute(createFotodirRel, []);
              })
              .then(() => {
                console.log('createUserMessages');
                return dbExecute(createUserMessages, []);
              })
              .then(() => {
                console.log('createUserMediaComments');
                return dbExecute(createUserMediaComments, []);
              })
              .then(() => {
                console.log('createUserStats');
                return dbExecute(createUserStats, []);
              })
              .then(() => {
                console.log('createUserProposalsV3Table');
                return dbExecute(createUserProposalsV3Table, []);
              })
              .then(() => {
                console.log('createInvitationsTable');
                return dbExecute(createInvitationsTable, []);
              })
              .then(() => {
                console.log('seedFromInvitationsV2Table');
                return window.helper_db.is_table('invitations_v2')
                  .then((exist) => {
                    if (exist) {
                      return dbExecute(seedFromInvitationsV2Table, []);
                    }
                    return new Promise((r) => { r(); });
                  });
              })
              .then(() => {
                console.log('createFamilyPortalRegisterDirect');
                return dbExecute(createFamilyPortalRegisterDirect, []);
              })
              .then(() => {
                console.log('dropFeelingTable');
                return dbExecute(dropFeelingTable, []);
              })
              .then(() => {
                console.log('createFeelingTable');
                return dbExecute(createFeelingTable, []);
              })
              .then(() => {
                console.log('insertFeeling');
                return dbExecute(insertFeeling, []);
              })
              .then(() => {
                console.log('dropFeelingTrans');
                return dbExecute(dropFeelingTrans, []);
              })
              .then(() => {
                console.log('createFeelingTrans');
                return dbExecute(createFeelingTrans, []);
              })
              .then(() => {
                console.log('insertFeelingTrans');
                return dbExecute(insertFeelingTrans, []);
              })
              .then(() => {
                console.log('createPlaylistTable');
                return dbExecute(createPlaylistTable, []);
              })
              .then(() => {
                console.log('insertPlaylist');
                return dbExecute(insertPlaylist, []);
              })
              .then(() => {
                console.log('createPlaylistItemTable');
                return dbExecute(createPlaylistItemTable, []);
              })
              .then(() => {
                console.log('insertPlaylistItems');
                return dbExecute(insertPlaylistItems, []);
              })
              .then(() => {
                console.log('Database chain executed');
                resolve();
              })
              .catch((err) => {
                console.error('boot/db create error:', err);
                reject(err);
              });
          }
        );
      };
      resolveCreate();
    }
  );
}

/**
 * Empties the storage Cache.
 */
function emptyStorageCache() {
  localStorage.removeItem('typelistbook');
  localStorage.removeItem('typelistvideo');
  localStorage.removeItem('typelistquiz');
  localStorage.removeItem('typelistgame');
  localStorage.removeItem('list');
  localStorage.removeItem('sublist');
  for (let i=0; i<60; i++) {
    localStorage.removeItem('list'+i);
    localStorage.removeItem('list'+i+'title');
  }
  sessionStorage.removeItem('photodirsu1');
  sessionStorage.video_enabled = true;
}

/**
 * List the values in the database to the screen using jquery to
 * update the #lbUsers element
 * @constructor
 */
function ListDBValues() {
  if (!sqlitePlugin.openDatabase) {
    alert('Databases are not supported in this browser.');
    return;
  }

  // this line clears out any content in the #lbUsers element on the
  // page so that the next few lines will show updated
  // content and not just keep repeating lines
  $('#lbUsers').html('');

  // this next section will select all the content from the User table
  // and then go through it row by row
  // appending the UserId  FirstName  LastName to the  #lbUsers element
  // on the page
  db.transaction(function(transaction) {
    transaction.executeSql('SELECT * FROM User;', [],
      function(transaction, result) {
        if (result !== null && result.rows !== null) {
          for (let i = 0; i < result.rows.length; i++) {
            let row = result.rows.item(i);
            console.log('<br>' + row.userid + '. ' + row.firstname+ ' ' + row.lastname + ' ' + row.prof_img);
          }
        }
      }, errorHandler);
  }, errorHandler, nullHandler);

  return;
}

function getPhotoDirsByUserid(refresh) {
  // TODO(peter) Write better jsDoc for the function. And maybe rename "refresh" to "reset".
  let starttime = new Date().getTime();
  let userid = sessionStorage.userid;
  let userphotodirs = 'photodirsu'+userid;
  if (refresh === true) {
    sessionStorage.removeItem(userphotodirs);
  }

  db.transaction(function(transaction) {
    let session_htmlphotodirs = sessionStorage.getItem(userphotodirs);
    if (session_htmlphotodirs !== null && session_htmlphotodirs !== 'null' && use_ls_cache === true && session_htmlphotodirs !== '') {
      $('#Gallery').html(session_htmlphotodirs);
      let endtime = new Date().getTime();
      let took = endtime-starttime;
      console.log('building foto gallery took '+took+'ms');
    } else {
      let preview_foto = '';
      transaction.executeSql(
        `SELECT
          DISTINCT Fotodirs.id AS dirid,
          Fotodirs.title AS dirtitle,
          Fotos.prev_url as prev_url
        FROM
          Fotodirs
        LEFT JOIN Fotodirrel ON
          Fotodirs.id = Fotodirrel.fotodirid
        LEFT JOIN Fotos ON
          Fotodirrel.fotoid = Fotos.fotoid
        where
          Fotodirs.userid =?
        GROUP BY
          Fotodirs.id
        ORDER BY
          Fotodirs.title;`,
        [sessionStorage.userid],

        function(transaction, result) {
          if (result !== null && result.rows !== null) {
            let html_photodirs = '';
            for (let i = 0; i < result.rows.length; i++) {
              let row = result.rows.item(i);
              console.log('FotoDirTitle: '+row.dirtitle+' dirid:'+row.dirid+' url:'+row.prev_url);
              if (row.prev_url === null || row.prev_url === 'null') {
                preview_foto = './img/ico/fotoalben.jpg';
              } else {
                preview_foto = row.prev_url;
              }
              // TODO(peter): convert string to template strings.
              html_photodirs += '<div class="grid-wrapper-fotoalbum img-element" onclick="previous_page = document.URL;sessionStorage.dirtitle = \''
                + row.dirtitle
                + '\';sessionStorage.dirid = '
                + row.dirid
                + '; $( \':mobile-pagecontainer\' ).pagecontainer( \'change\', \'./foto.html\', { transition: \'slide\' } );/*getDirPhotosByDirid(\''
                + row.dirid+'\');*/" id="prevdir'
                + row.dirid+'"><img src="'
                + preview_foto
                + '" style="width:220px;padding:0;margin:0;background-color:#146aae;" />';
              html_photodirs += ''
                + row.dirtitle
                + '<div class="papierkorb" onclick="if (!e) var e = window.event;e.cancelBubble = true;if(e.stopPropagation) e.stopPropagation();deletephotodir(\''
                + row.dirid
                + '\',\''
                + row.dirtitle+'\');">&nbsp;</div></div>';
            }
            console.log(html_photodirs);
            sessionStorage.setItem(userphotodirs, html_photodirs);

            $('#Gallery').html(html_photodirs);
            let endtime = new Date().getTime();
            let took = endtime-starttime;
            console.log('building foto gallery took '+took+'ms');
          }
        }, errorHandler);
    }
  }, errorHandler, function() {
    console.log('showing Foto Alben');
  });
}

function getDirPhotosByDirid() {
  // TODO(peter) Write better jsDoc for the function.
  let dirid = sessionStorage.dirid;
  let dirtitle = sessionStorage.dirtitle;
  console.log(dirid);
  let preview_foto = '';
  db.transaction(function(transaction) {
    transaction.executeSql(`SELECT Fotos.*, Fotodirs.title AS dirtitle
      FROM Fotos
             LEFT JOIN Fotodirrel ON Fotodirrel.fotoid = Fotos.fotoid
             LEFT JOIN Fotodirs ON Fotodirrel.fotodirid = Fotodirs.id
      WHERE Fotodirs.id = ?
      ORDER BY Fotos.fotoid desc;`, [dirid],
      function(transaction, result) {
        if (result !== null && result.rows !== null) {
          let html_photodirs = '';
          for (let i = 0; i < result.rows.length; i++) {
            let row = result.rows.item(i);

            // WORKAROUND FOR OLDER FOTOS (v2.05 without prev_url and type cols):
            let version_check = row.photo_url.replace('file:/', '');
            console.log('FotoDirTitle: '+row.dirtitle+' foto id:'+row.photo_url);

            if (row.type==1) {
              preview_foto = row.prev_url;
              console.log('new media content');
              html_photodirs +=
                '<div class="grid-wrapper-video img-element" style="height:360px !important;"><a class="img-wrapper img-element" onclick="showImageSlider(\''
                + row.photo_url+'\');return false;" data-rel="dialog" data-ajax="false" href="'
                + row.photo_url+'"><img src="'
                + preview_foto
                + '" /></a><div class="papierkorb" onclick="confirm_deletephoto(\''
                + row.fotoid
                + '\',\''
                + row.photo_url
                + '\',\''
                + row.prev_url
                + '\',\''
                + row.type
                + '\');">&nbsp;</div><br clear="all"/></div>';
            }
            if (row.type==2) {
              preview_foto = row.prev_url;
              console.log('new media content');
              html_photodirs +=
                '<div class="grid-wrapper-video img-element" style="height:360px !important;"><a class="img-wrapper img-element" data-ajax="false" href="'
                + preview_foto
                + '"><img src="./img/ico/ico-play.png" style="position:relative;top:126px;left:144px;" onclick="if(!e) var e = window.event;e.cancelBubble = true;if (e.stopPropagation) e.stopPropagation();openpersonalvideo(\''
                + row.photo_url+'\');" /><img src="'
                + preview_foto
                + '" onclick="if(!e) var e = window.event;e.cancelBubble = true;if (e.stopPropagation) e.stopPropagation();openpersonalvideo(\''
                + row.photo_url
                + '\');" /></a><div class="papierkorb" onclick="confirm_deletephoto(\''
                + row.fotoid
                + '\',\''
                + row.photo_url
                + '\',\''
                + row.prev_url
                + '\',\''
                + row.type
                + '\');">&nbsp;</div><br clear="all"/></div>';
            }
          }
          $('#fotoheader h2').html('Fotoalbum '+dirtitle);
          $('#FotosGallery').html(html_photodirs);
          console.log(html_photodirs);
        }
      }, errorHandler);
  }, errorHandler, function() {
    console.log('showing FotoAlbum Fotos');
    (function(window, $, PhotoSwipe) {
      let
        options = {
          preventHide: false,
          maxUserZoom: 1,
          allowUserZoom: true,
          slideshowDelay: 10000,
          autoStartSlideshow: true,
          captionAndToolbarShowEmptyCaptions: true,
          jQueryMobile: true,
          captionAndToolbarFlipPosition: true,
          getToolbar: function() {
            return '<div class="ps-toolbar-close" style="padding-top: 12px;">Zur&uuml;ck</div><div class="ps-toolbar-play" style="padding-top: 12px;">Diashow</div><div class="ps-toolbar-previous" style="padding-top: 12px;">Vorheriges</div><div class="ps-toolbar-next" style="padding-top: 12px;">N&auml;chstes</div><div class="say-hi" style="padding-top: 12px;">Men&uuml;</div>';

            // NB. Calling PhotoSwipe.Toolbar.getToolbar() will return the default toolbar HTML
          },
        },
        imageSliderInstance = window.Code.PhotoSwipe.attach( window.document.querySelectorAll('#FotosGallery a'), options );
      let sayHiEl,
        sayHiClickHandler = function(e) {
          history.go(-3);
        };
      // onShow - store a reference to our "say hi" button
      imageSliderInstance.addEventHandler(PhotoSwipe.EventTypes.onShow, function(e) {
        sayHiEl = window.document.querySelectorAll('.say-hi')[0];
      });

      // onToolbarTap - listen out for when the toolbar is tapped
      imageSliderInstance.addEventHandler(PhotoSwipe.EventTypes.onToolbarTap, function(e) {
        if (e.toolbarAction === PhotoSwipe.Toolbar.ToolbarAction.none) {
          if (e.tapTarget === sayHiEl || Util.DOM.isChildOf(e.tapTarget, sayHiEl)) {
            history.go(-3);
          }
        }
      });

      // onBeforeHide - clean up
      imageSliderInstance.addEventHandler(PhotoSwipe.EventTypes.onBeforeHide, function(e) {
        sayHiEl = null;
      });
    }(window, window.jQuery, window.Code.PhotoSwipe));
  });
}

/**
 * Creates a new Photodirectory.
 * This functions adds a new entry to the Fotodirs table.
 * @param {string} title - Name of the new Photodirectory.
 */
function createPhotoDir(title) {
  userid = sessionStorage.userid;
  console.log('creating Dir with name:'+title+' userid:'+userid);

  db.transaction(function(transaction) {
    transaction.executeSql('INSERT INTO Fotodirs(userid, title) VALUES (?,?)', [userid, title], function(s) {
      console.log(s);/* set true to refresh: */
      switchtolastaddedfotodir(); getPhotoDirsByUserid(true);/* getDirPhotosByDirid('8');*/
    }, errorHandler);
  });
}

/**
 * This will guide the user to the last created photodirectory.
 */
function switchtolastaddedfotodir() {
  db.transaction(function(transaction) {
    transaction.executeSql('SELECT * FROM Fotodirs ORDER BY id desc limit 1', [],
      function(transaction, result) {
        if (result !== null && result.rows !== null) {
          for (let i = 0; i < result.rows.length; i++) {
            let row = result.rows.item(i);
            previous_page = document.URL;
            sessionStorage.dirtitle = row.title;
            sessionStorage.dirid = row.id;
            $( ':mobile-pagecontainer' ).pagecontainer( 'change', './foto.html', {transition: 'slide'} );
          }
          $('#Users').append('</ul>');
        }
      }, errorHandler);
  });
}

function shuffle(array) {
  // TODO(peter) Move this function out of db.js
  let tmp, current, top = array.length;

  if (top) {
    while (--top) {
      current = Math.floor(Math.random() * (top + 1));
      tmp = array[current];
      array[current] = array[top];
      array[top] = tmp;
    }
  }

  return array;
}


Array.prototype.shuffle = function() {
  let i = this.length;
  while (--i) {
    let j = Math.floor(Math.random() * (i + 1));
    let temp = this[i];
    this[i] = this[j];
    this[j] = temp;
  }

  return this; // for convenience, in case we want a reference to the array
};
