var util6 = {};

util6.build_welcome_screen = function(){
  if (v.d) console.log('BUILDING WELCOME SCREEN');
  util6.show_motivation();
};

util6.step = 0;
/*save played media in every loop: */
util6.loop_media_played = [];
util6.loop_media = [];
util6.possible_proposals = [/*"text_joke", "text_phrase", "quiz_sound",*/ "quiz_foto", "quiz_text", "relaxation", "fotogallery"];
util6.proposal = {};

util6.goHome = function(){
  if (v.d) console.log('go to motivation');
  /* since no page_transition is taking place, we need to check for new, unseen Fotos manually:*/
  //Fotos.show_last_unseen();
  //then fill motivation page with new content:
  //if (v.d) console.log(util6.proposal);
  //if (v.d) console.log('$.mobile.activePage.attr(id): '+$.mobile.activePage.attr('id'));
  if($.mobile.activePage.attr('id') === "home"){
    util6.show_motivation();
  } else{
    goHome();
  }
};

util6.show_motivation = function(){
  if (v.d) console.log('building motivation content');
  //check if removing pause button globally possible right here
  var motivation_msg = '';
  var html_text = '';
  var motivation_sound_url = local_dir+'audio/';
  //b2c solo mode with assi util6.js - MVP Loop
  /*
  * show kachel type selection options here
  * 2017_03_01 MHe:
  */

  /*  ["text_joke", "text_phrase", "quiz_sound", "quiz_foto", "quiz_text", "relaxation", "fotogallery"];
  *	data-proposal value can be filled as an string "array" with delimiter ",": like  .: data-proposal="quiz_sound,quiz_foto" :.
  */
  $('#media_menu_wrapper').empty().attr('style','background-color: none;')
  .html(
    `<div id="solo_start_screen" class="util6-tile-stage">
      <div class="solo_start_screen--wrapper">
        <div class="ch-generate-proposal util6-tile-stage--tile util6-tile-left"
          data-role="button"
          data-proposal="recommended_game"
          style="background-image:url('${home_dir}img/util6_tile_game.png');"
        >
          <div class="util6-tile-title-wrap">Spiele</div>
        </div>
        <div class="ch-generate-proposal util6-tile-stage--tile util6-tile-middle"
          data-role="button"
          data-proposal="fotogallery"
          style="background-image:url('${home_dir}img/util6_tile_foto.png');"
        >
        <!--<span class="util6-tile-title">-->
          <div class="util6-tile-title-wrap">${strings[189]}</div>
        </div>
        <div class="ch-generate-proposal util6-tile-stage--tile util6-tile-right"
          data-role="button" data-proposal="relaxation"
          style="background-image:url('${home_dir}img/util6_tile_film.png');"
        >
          <div class="util6-tile-title-wrap">Filme</div>
        </div>
      </div>
    </div>`)
  .trigger('create')

  //creating and inserting callback:
  .promise().done(function(){
    util6.bind_ch_generate_proposal();
  });
  util6.build_header('home');
};

util6.bind_ch_generate_proposal = function(){
  $('.ch-generate-proposal')
  .off()
  .on(clickhandler_prev, function(e){
    e.preventDefault();	e.stopPropagation();
    //no double button init except for fotogallery (no page transition event happens afterwards)
    var this_proposals_str = $(this).attr('data-proposal');
    //if(this_proposals_str.indexOf("fotogallery")==-1) $('.ch-generate-proposal').off(clickhandler_prev);
    $(this).addClass('media_menu_active');
  })
  .on(clickhandler, function(e){
    e.preventDefault();	e.stopPropagation();
    var this_proposals_str = $(this).attr('data-proposal');
    //no double button init except for fotogallery (no page transition event happens afterwards)
    //if(this_proposals_str.indexOf("fotogallery")==-1) $('.ch-generate-proposal').off(clickhandler);
    $('.ch-generate-proposal').off().removeClass('media_menu_active');
    // also remove standard main-menu btn listeners for extended mode:
    unbind_start_buttons();
    var this_proposals = this_proposals_str.split(",");
    if(v.d)console.log(this_proposals);
    if(v.d)console.log(this_proposals.length);

    util6.step++;
    util6.proposal.open = "rand";
    var rand_id = Math.floor((Math.random() * this_proposals.length));
    util6.proposal.type = this_proposals[rand_id];
    if(v.d)console.log('selected as next proposal:');
    if(v.d)console.log(util6.proposal);
    util6.loop_media.pop(util6.proposal);
    //util6.goHome();
    util6.get_next_media();
  });
};

util6.proposal_new = function(){
  /*
  possible proposals:
  feeling, joke, quiz, surprise_game,
  */
  util6.proposal = {};
  if(v.d)console.log('generating new proposal');
  if(v.d)console.log('STEP        '+util6.step);
  //first element:
  //util6.loop_media_played.length===0 &&
  // for demo on 08.03.17 no feeling question:
  /*	if(util6.step===1){
  $(':mobile-pagecontainer').pagecontainer('change',
  './feeling_questions.html', {
  transition : 'slide'
});
}*/
//1st ELEMENT AFTER feeling_questions:
if(util6.step%4===0)
{
  util6.proposal.type = "quiz_foto";
  if (util6.step === 0){
    util6.proposal.open  = "direct";
    util6.proposal.media_id = 1969;
  } else {
    util6.proposal.open  = "rand";
  }
  util6.loop_media.pop(util6.proposal);
  util6.goHome();
  util6.step++;
}//2nd ELEMENT AFTER feeling_questions:
else if(util6.step%4===2){
  util6.proposal.type = "quiz_text";
  if (util6.step === 2){
    util6.proposal.media_id = 1970;
    util6.proposal.open  = "direct";
  } else {
    util6.proposal.open = "rand";
  }
  util6.loop_media.pop(util6.proposal);
  util6.goHome();
  util6.step++;
} else if(util6.step%4===1){
  util6.proposal.type = "relaxation";
  if (util6.step === 1){
    /*the turtle relaxation movie:*/
    util6.proposal.media_id = 24;
    util6.proposal.open = "direct";
  } else {
    util6.proposal.open = "rand";
  }
  util6.loop_media.pop(util6.proposal);
  util6.goHome();
  util6.step++;
}else if(util6.step%4===3){
  util6.proposal.type = "fotogallery";
  /*the turtle relaxation movie:*/
  util6.proposal.media_id = 24;
  util6.proposal.open = "rand";
  util6.loop_media.pop(util6.proposal);
  util6.old_rand = 3;
  util6.step++;
  util6.goHome();
}
else{
  util6.proposal_new_gen();
}
};

util6.proposal_new_gen = function(){
  if(v.d)console.log('proposal_new_gen');
  util6.step++;
  util6.proposal.open = "rand";
  var rand_id = Math.floor((Math.random() * util6.possible_proposals.length));
  // making sure, that new proposal type will be delivered:
  while (rand_id === util6.old_rand){
    rand_id = Math.floor((Math.random() * util6.possible_proposals.length));
  }
  if (v.d) console.log('old random: '+util6.old_rand+'   new random: '+rand_id);
  if (rand_id != util6.old_rand){
    util6.old_rand = rand_id;
    util6.proposal.type = util6.possible_proposals[rand_id];
    if(v.d)console.log('selected as next proposal:');
    if(v.d) console.log(util6.proposal);
    util6.loop_media.pop(util6.proposal);
    util6.goHome();
  }
};



// if we like to call the same function/view again:
util6.self_call = false;
util6.get_next_media = function(){
  if(util6.proposal.open === "direct"){
    //open media directly:
    util6.select_direct(util6.open_next_media);
  } else{
    //switch selet depending on picked type:
    //["text_joke", "text_phrase", "quiz_sound", "quiz_foto", "quiz_text", "relaxation", "fotogallery"];
    //(cb, media_type, media_type_id)
    switch(util6.proposal.type){
      case  "text_joke":
      util6.pick_rand_media(util6.open_next_media, "book");
      break;

      case "text_phrase":
      //util6.select_quiz(util6.open_next_media);
      util6.pick_rand_media(util6.open_next_media, "book");
      break;

      case "quiz_sound":
      //24 = Geräuschrätsel
      util6.pick_rand_media(util6.open_next_media, "game", 24);
      break;

      case "quiz_foto":
      //util6.select_quiz(util6.open_next_media);
      util6.pick_rand_media(util6.open_next_media, "game", 25);
      break;

      case "quiz_text":
      //util6.select_quiz(util6.open_next_media);
      util6.pick_rand_media(util6.open_next_media, "game", 23);
      break;

      case "relaxation":
      //util6.select_video(util6.open_next_media);
      util6.pick_rand_media(util6.open_next_media, "video", 1);
      break;

      case "interest":
      //util6.select_video(util6.open_next_media);
      util6.pick_rand_media(util6.open_next_media, "video", 2);
      break;

      case "sing":
      //util6.select_video(util6.open_next_media);
      util6.pick_rand_media(util6.open_next_media, "video", 18);
      break;

      case "fotogallery":
      //set openmedia_data manually
      util6.openmedia_data.media_type = "fotogallery";
      util6.openmedia_data.media_title = strings[189]/*gallery*/;
      util6.open_next_media();
      break;

      case "recommended_game":
      //new game from the top 25 played games in the last month
      util6.pick_rand_media(util6.open_next_media, "game", 0);
      break;

      default:
      //util6.select_quiz(util6.open_next_media);
      util6.pick_rand_media(util6.open_next_media, "video", 1);
    }

  }
};
util6.openmedia_data = {};
util6.watched_media = [];

util6.open_next_media = function(){

  if(solo_sound_assi) {
    audio.removeEventListener('ended',motivation_after_audio,false);
  }
  if(v.d)console.log('OPENING NEXT MEDIA');
  if(v.d)console.log(util6.proposal);

  if(v.d)console.log('--> media_id: '+util6.openmedia_data.media_id);

  //only push valid media_id entries to watched array:
  if(util6.openmedia_data.media_id >0) {
    util6.watched_media.push( parseInt(util6.openmedia_data.media_id)||0 );
  }
  console.log(util6.watched_media);

  //TODO: CHECK following 2  lines:
  //add_user_stats(util6.proposal.media_id, 1, (parseInt(curr_sort_id)+1)+'st media daily program on '+formatDate(thisday), '');
  //addtoProfileTimeline(util6.proposal.media_id, 1);

  if (util6.openmedia_data.media_type === "video")
  {
    if (is_app<0 || online_media_sources){
      util6.openmedia_data.media_name = 'videos/'+util6.openmedia_data.media_name;
    }
    openvideo(
      util6.openmedia_data.media_name,
      util6.openmedia_data.media_title,
      util6.openmedia_data.media_id
    );
    //if we want to see the next video, than we just 'reload' the page
    if ($.mobile.activePage.attr('id') === "videocontainer")
    {
      //recreate player:
      $('#videocontainer').removeClass('background_switch');
      $('#video_content').html(
        `<div id="skip_video" style="z-index: 1">Weiter</div>
        <div class="backbtn"
          style="position:fixed;
            top:4px !important;
            margin-left: 0px !important;
            left: 12px !important;"
        ></div>
        <div style="max-width:1280px;max-height:720px;margin:80px auto;">
          <video controls
            style="width:100%;
              height:720px;
              background:transparent;
              object-fit: fill;"
            id="video1">
          </video>
        </div>
        <div id="video-controls"
          style="display:none;position:relative;top:-60px;">
          <button type="button" id="play-pause">Pause</button>
          <div id="seek-bar" style="background-color:#fff;"></div>
          <button type="button" id="mute">Mute</button>
          <input type="range"
            id="volume-bar"
            min="0"
            max="1"
            step="0.1"
            value="1">
        </div>`
      );

      unbind_headerbuttons();
      $( '.backbtn' )
        .addClass('cancel_btn')
        .removeClass('backbtn')
        .addClass('btnbigblue');
      $('.cancel_btn')
        .html('ende')
        .attr('style', 'top: 8px; left: 46px; position: fixed; z-index: 1;');

      bind_headerbuttons();

      bind_cancel_button();

      $('#video1').html(sessionStorage.video_html);
      var vid=document.getElementById("video1");
      // Buttons
      if (typeof soundvolume != 'undefined'){
        vid.volume = soundvolume;
      }
      vid.play();
      vid.addEventListener('ended',video_finished,false);
      $('#video1')
        .off("timeupdate")
        .on("timeupdate", function(e){
          video_currentTime = this.currentTime;
          video_duration = this.duration;
        })
        .off(clickhandler)
        .on(clickhandler, function(e){
          e.preventDefault();
          e.stopPropagation();
          if (vid.paused) {
            vid.play();
          } else {
            vid.pause();
          }
        });
      bind_skip_video_button();
    }
  }

  if (util6.openmedia_data.media_type === "game") {
    if (util6.openmedia_data.media_name === "quiz") {
      //var id = $(this).attr('data-mediaid');
      sessionStorage.quizid = util6.openmedia_data.media_id;
      page.go_to('quiz.html');
    } else {
      page.go_to('./games'+media_name);
    }
  }

  if (util6.openmedia_data.media_type === "book") {
    unbind_quote_nav_buttons();
    if (logging) console.log('it s a book !');
    sessionStorage.bookid = util6.openmedia_data.media_id;
    page.go_to('quote.html');
  }

  if (util6.openmedia_data.media_type === "quiz") {
    sessionStorage.quizid = util6.openmedia_data.media_id;
    page.go_to('quiz.html');
  }

  if (util6.openmedia_data.media_type === "fotogallery") {
    // Fotos.select_by_userid(userid, function(arr){

    Timeline.getAllByUser(userid)
      .then((photos) => {
        if (photos.length) {
          // set to true to activate diashow again 2017_04_13 test deactivating mhe
          pswipe.fotogallery_postcards(photos, false);
        } else {
          unbind_start_buttons();
          thisapp.popup(
            `<div class="overlay--main-col-md-center">
              <h2 style="padding-top: 120px;"
                class="overlay--main-col--heading-center-xxl">
                ${  /*Fotoalbum*/ strings[189]}
              </h2>
              <p class="overlay--main-col--masstext-textcenter">
                ${  /*Hier erscheinen bald die gesendeten Bilder Ihrer Familienmitglieder.*/ strings[296]}
              </p>
            </div>`,
            'OK',
            nullHandler
          );
          bind_start_buttons();
        }
      });
  }
};
util6.select_direct = function(cb) {
  util6.openmedia_data = {};
  if (solo_sound_assi) {
    audio.removeEventListener('ended',motivation_after_audio,false);
  }

  let selector = `SELECT
    m.mediaid AS media_id,
    m.name AS media_name,
    m_t.title AS media_title,
    m.type AS media_type FROM Media m
    LEFT JOIN media_trans m_t
    ON m_t.media_id = m.mediaid
    AND m_t.language = ?
    WHERE m.mediaid = ? LIMIT 1`;
  db.transaction(
    function(tx) {
      tx.executeSql(
        selector,
        [lang, util6.proposal.media_id],
        function(transaction, result) {
          //TODO: implement check if result is empty:
          if (logging){console.log(selector);}
          util6.openmedia_data = result.rows.item(0);
          if ($.isFunction(cb)) cb();
        },
        errorHandler
      );
    },
    errorHandler,
    nullHandler
  );
};

util6.pick_rand_media = function(cb, media_type, media_type_id) {
  util6.openmedia_data = {};
  q_media_type_id = "";
  var media_pool = [];

  //getting the media-pool based on difficulty:
  // easy
  if (User.difficulty === 1) {
    media_pool = [244,1715,360,362,367,1770,1194,1641];
  } // difficult
  else if (User.difficulty === 3) {
    media_pool = [248,1650,251,1232,1693,310,361,1119,363,1196];
  } // medium or no setting
  else {
    media_pool = [247,1642,250,376,358,1685,1118,1115,1078];
  }

  //flush watched_media when not much more left
  if (util6.watched_media.length > media_pool.length-2){
    if ( v.d ) console.log("-- util6.watched_media flushed --");
    util6.watched_media = [];
  }

  //check if media_type_id is set:
  if(typeof(media_type_id)!=="undefined") {
    q_media_type_id = "Mt.media_type_id = "+media_type_id+ " AND ";
  }

  q_media_type = "";
  if(typeof(media_type)!=="undefined") {
    q_media_type = "m.type = '"+media_type+ "' AND ";
  }

  not_in = "";
  if (util6.watched_media.length>0) {
    not_in = " m.mediaid NOT IN ("+util6.watched_media+") AND ";
  }
  where = "";

  if(q_media_type_id.length>0 || q_media_type_id.length>0){
    where = "WHERE "+q_media_type+q_media_type_id+not_in;
    where = where.substring(0,(where.length-4));
  }
  var q = "";
  var r = Math.floor(Math.random()*14);
  if (media_type_id===25){
    q = `SELECT
      m.mediaid AS media_id,
      m.name AS media_name,
      m_t.title AS media_title,
      m.type AS media_type
      FROM Media m
      LEFT JOIN Media_type Mt ON Mt.media_type_id = m.media_type_id
      LEFT JOIN media_trans m_t ON m_t.media_id = m.mediaid AND m_t.language = ?
      ${where
      /* 2017-05-02 new mediapool:
      -- Was sehen Sie auf dem Bildausschnitt?
      -- Essen - Früchte -> 1757
      -- Essen - Gemüse -> 1759
      -- Essen - Kuchen -> 1758
      -- Hobby - Gartenarbeit -> 1761
      -- Hobby - Handarbeit -> 1760
      -- Natur - Blumen -> 1764
      -- Natur - Tiere -> 310
      -- Sonstiges - Alltägliches -> 305
      -- Zuhause - Badezimmer -> 1762
      -- Zuhause - Küche -> 1763

      -- Passt das Wort zum Bild?
      -- Essen - Früchte -> 1769
      -- Essen - Gemüse -> 1771
      -- Essen - Gerichte -> 1772
      -- Hobby - Instrumente -> 392
      -- Hobby - Fahrzeuge und Verkehr -> 393
      -- Zuhause - Badezimmer -> 394
      -- Zuhause - Haushalt -> 1417
      -- Zuhause - Küche -> 396

      -- Welche Farbe sehen Sie?
      -- komplettes Spiel -> 361
      */}
      AND (
        m.has_mother IN (
          SELECT me.mediaid
          FROM Media me
          WHERE me.has_mother = 361
        )
        OR m.mediaid IN (
          1757, 1759, 1758, 1761, 1760, 1764, 310, 305, 1762,
          1763, 1769, 1771, 1772, 392, 393, 394, 1417, 396, 361
        )
      )
      AND m.access = "public"
      ORDER BY m.total_used DESC`;
  }
  else if (media_type_id===23){
    q = `SELECT
      m.mediaid AS media_id,
      m.name AS media_name,
      m_t.title AS media_title,
      m.type AS media_type
      FROM Media m
      LEFT JOIN Media_type Mt ON Mt.media_type_id = m.media_type_id
      LEFT JOIN media_trans m_t ON m_t.media_id = m.mediaid AND m_t.language = ?
      WHERE m.mediaid = 244
      ORDER BY m.total_used DESC`;
  }
  // show random from last 15 most used medias (type:game)
  else if (media_type_id===0){
    q = `SELECT
      m.mediaid AS media_id,
      m.name AS media_name,
      m_t.title AS media_title,
      m.type AS media_type
      FROM Media m
      LEFT JOIN Media_type Mt ON Mt.media_type_id = m.media_type_id
      LEFT JOIN media_trans m_t ON m_t.media_id = m.mediaid AND m_t.language = ?
      where ${not_in}
      m.mediaid in (${media_pool})
      AND m.access = "public"
      ORDER BY m.total_used DESC`;
  }
  else {
    q = `SELECT
      m.mediaid AS media_id,
      m.name AS media_name,
      m_t.title AS media_title,
      m.type AS media_type
      FROM Media m
      LEFT JOIN Media_type Mt ON Mt.media_type_id = m.media_type_id
      LEFT JOIN media_trans m_t ON m_t.media_id = m.mediaid AND m_t.language = ?
      ${where}
      ORDER BY m.total_used DESC`;
  }

  r = media_pool.length - util6.watched_media.length - 1;

  if (media_type_id!==23){
    q += ' limit '+r+', 1;';
  }

  if(v.d)console.log('PICKING rand media');
  if(v.d)console.log(q);
  db
  .transaction(function(tx) {
    tx.executeSql(
      q,
      [ lang ],
      function(transaction, result)
      {
        if (result !== null && result.rows !== null) {
          if(v.d)console.log(result.rows);
          util6.openmedia_data = result.rows.item(0);
          if(typeof(util6.openmedia_data) == "undefined"){
            util6.pick_rand_media(cb, media_type, media_type_id);
          } else{
            if(jQuery.isFunction(cb)) cb();
          }
        }
        else{
          console.error('no result on util6.pick_rand_media');
          util6.watched_media = [];
          util6.pick_rand_media(cb, media_type, media_type_id);
          //return false;
        }
      },
      function(err){
        //TODO: check error handling!
        console.error('errot at util6.pick_rand_media');
        console.error(err);
        return false;
      });
  });
};

util6.straight_audio = function(file_url){
  var this_audio = new Audio(file_url);
  this_audio.volume = soundvolume;
  this_audio.play();
};

util6.welcome_sound_playbtn = function(){
  if(util6.solo_sound_assi){
    $('#welcome_sound_playbtn').attr("src","./img/ico/play_300x300.png");
    audio.pause();
    util6.solo_sound_assi = false;
    clearInterval(util6.intervalplayer);
    Settings.update("solo_sound_assi","false",nullHandler);
  } else{
    util6.solo_sound_assi = true;
    Settings.update("solo_sound_assi","true",welcome_sound_play);
  }
};

util6.welcome_sound_play = function(){
  Settings.select("solo_sound_assi",function(set){
    if(set==="true"){
      solo_sound_assi = true;
      $('#welcome_sound_playbtn').attr("src","./img/ico/button_pause_140x140.gif");
      setTimeout(function(){
        //if solo_sound_assi might be disabled in the 5s till execution:
        if(solo_sound_assi && solo_screen==="welcome"){
          var count = 1;
          audio.currentTime = 0;
          audio.play();
          //only start interval if on home screen/welcome page:
          intervalplayer = setInterval(function(){
            if(solo_sound_assi && solo_screen==="welcome")audio.play();
            count += 1;
            if (count==10) clearInterval(intervalplayer);
          }, 30000);
        }

      },5000);
    } else if(set === "false"){
      solo_sound_assi = false;
    } else{
      //write settings:
      Settings.insert("solo_sound_assi","false",nullHandler);
    }
  });
};


util6.build_header = function( pagetrigger ){
  if( utilization === 6 ){
    if( pagetrigger == 'home' ){

      if(v.d)console.warn('++++++ BUILDING HOME HEADER ++++++ ' + pagetrigger );

      $('.ui-header')
        .attr('style','height: 150px; background-size: cover !important;');
      $('.ui-title').removeClass('clockdate');
      $('.header--title')
        .attr('style',
          'font-size: 48px !important; margin: 25px auto 0 auto !important;'
        )
        .html('Drücken Sie auf ein Feld');
      $('.header--settings')
        .attr('style','top: 62px !important; right: 140px;');
      $('.iconbar').show();
      $('.cancel_btn').hide();
      $('.backbtn').hide('');
      $('.hilfe_home').attr('style', 'top: 62px; right: 60px;');

      //(2017-05-24) - This is the util6-full-header: Can be deleted at some point

      /*
      $('#home .hilfe_home').attr('style','display: none !important;');
      $('#home .settings').attr('style','position:fixed; right:60px;');
      $('#home .ui-title').addClass('uhrzeit');
      $('.iconbar').attr('style', 'display: none;');
      $('#homeheader').addClass('util6--homeheader');
      $('.header--title').attr('style', 'margin-top: 0px !important;');
      $('.header--settings').addClass('util6--homeheader-settings');
      */

    } else if( pagetrigger == 'initial' ){
      if(v.d)console.warn('++++++ BUILDING INITIAL HEADER ++++++ '+pagetrigger);
      $('.ui-header').attr('style', 'line-height: 92px !important;');
      $('.backbtn').attr('style', 'margin-top: 15px !important;');
      $('.hilfe_home').attr('style', 'display: none;');
      $('.iconbar').attr('style', 'display: none;');
      if( $( '#updatepage_header' ).length == 1 ){
                $('#updatepage_header').addClass('util6--homeheader');
            }
            if( $('#web_list_header').length == 1 ){
                $('#web_list_header').addClass('util6--homeheader');
            }
      $('.header--title').attr('style', 'margin-top: 0px !important;');
      $('.homebtn').hide();
      $('.header--settings').addClass('util6--homeheader-settings');
      $('.settings').attr('style','position:fixed; right:60px;').hide();
            $('.hilfe_home').show().attr('style', 'top: 21px;');
    } else if( pagetrigger == 'solo'  ){
      if(v.d)console.warn('++++++ BUILDING SOLO HEADER ++++++ ' + pagetrigger );
      $('.ui-header').attr('style', 'line-height: 92px !important;');
      $('.backbtn').attr('style', 'margin-top: 15px !important;');
      $('.header--title').attr('style', 'margin-top: 0px !important;');
      $('.header--title').html('Einstellungen');
      $('.homebtn').hide();
      $('.iconbar').attr('style', 'display: none;');
      $('#updatepage_header').addClass('util6--homeheader');
            $('.hilfe_home').show().attr('style', 'top: 21px;');
            $('.header--settings').addClass('util6--homeheader-settings');
      $('.settings').attr('style','position:fixed; right:60px;').hide();
    } else if ( pagetrigger == 'invitations' ) {
      if(v.d)console.warn('++++++ BUILDING INVITATIONS HEADER ++++++ ' + pagetrigger );
      $('.backbtn').attr('style', 'margin-top: 15px !important;');
      $('.header--title').attr('style', 'margin-top: 0px !important;');
      $('.header--title').html('Einstellungen');
      $('.homebtn').hide();
      $('.iconbar').attr('style', 'display: none;');
      $('#updatepage_header').addClass('util6--homeheader');
      $('.header--settings').addClass('util6--homeheader-settings');
            $('.hilfe_home').show().attr('style', 'top: 21px;');
      $('.settings').attr('style','position:fixed; right:60px;').hide();
    } else if ( pagetrigger == 'shutdown' ) {
      if(v.d)console.warn('++++++ BUILDING SHUTDOWN HEADER ++++++ ' + pagetrigger + ' Utilization: ' + utilization );
      $('.ui-header').attr('style', 'line-height: 92px !important;');
      $('.backbtn').attr('style', 'margin-top: 15px !important;');
      $('.header--title').attr('style', 'margin-top: 0px !important;');
      $('.header--title').html('Einstellungen');
      $('.homebtn').hide();
      $('.iconbar').attr('style', 'display: none;');
      $('.ui-header').addClass('util6--homeheader');
            $('.hilfe_home').show().attr('style', 'top: 21px;');
      $('.header--settings').addClass('util6--homeheader-settings');
      $('.settings').attr('style','position:fixed; right:60px;').hide();
    } else if ( pagetrigger == 'usagetype' ) {
      if(v.d)console.warn('++++++ BUILDING USAGETYPE HEADER ++++++ ' + pagetrigger );
      $('.backbtn').attr('style', 'margin-top: 15px !important;');
      $('.header--title').attr('style', 'margin-top: 0px !important;');
      $('.header--title').html('Einstellungen');
      $('.homebtn').hide();
      $('.iconbar').attr('style', 'display: none;');
      $('#updatepage_header').addClass('util6--homeheader');
      $('.header--settings').addClass('util6--homeheader-settings');
            $('.hilfe_home').show().attr('style', 'top: 21px;');
      $('.settings').attr('style','position:fixed; right:60px;').hide();
    } else if ( pagetrigger == 'password' ) {
      if(v.d)console.warn('++++++ BUILDING PASSWORD HEADER ++++++ ' + pagetrigger );
      $('.ui-header').attr('style', 'line-height: 92px !important;');
      $('.backbtn').attr('style', 'margin-top: 15px !important;');
      $('.header--title').attr('style', 'margin: 0px auto !important;');
      $('.header--title').html('Einstellungen');
      $('.homebtn').hide();
      $('.iconbar').attr('style', 'display: none;');
      $('#profile_checkpasswordheader').addClass('util6--homeheader');
      $('.header--settings').addClass('util6--homeheader-settings');
            $('.hilfe_home').show().attr('style', 'top: 21px;');
      $('.settings').attr('style','position:fixed; right:60px;').hide();
    } else if ( pagetrigger == 'settings-util' ) {
      if(v.d)console.warn('++++++ BUILDING Settings-Util HEADER ++++++ ' + pagetrigger );
      $('.ui-header').addClass('util6--homeheader');
      $('.backbtn').attr('style', 'margin-top: 15px !important;');
      $('.header--title').attr('style', 'margin: 0px auto !important;');
      $('.header--title').html('Einstellungen');
      $('.homebtn').hide();
      $('.iconbar').attr('style', 'display: none;');
      $('.header--settings').addClass('util6--homeheader-settings');
            $('.hilfe_home').show().attr('style', 'top: 21px;');
      $('.settings').attr('style','position:fixed; right:60px;').hide();
    } else if ( pagetrigger == 'update' ) {
      if(v.d)console.warn('++++++ BUILDING UPDATE HEADER ++++++ ' + pagetrigger );
      $('.ui-header').attr('style', 'line-height: 92px !important;');
      $('.ui-header').addClass('util6--homeheader');
      $('.backbtn').attr('style', 'margin-top: 15px !important;');
      $('.header--title').attr('style', 'margin: 0px auto !important;');
      $('.header--title').html('Einstellungen');
      $('.homebtn').hide();
      $('.iconbar').attr('style', 'display: none;');
      $('.header--settings').addClass('util6--homeheader-settings');
            $('.hilfe_home').show().attr('style', 'top: 21px;');
      $('.settings').attr('style','position:fixed; right:60px;').hide();
    } else if ( pagetrigger == 'pflegebox' ) {
      if(v.d)console.warn('++++++ BUILDING UPDATE HEADER ++++++ ' + pagetrigger );
      $('.ui-header').attr('style', 'line-height: 92px !important;');
      $('.ui-header').addClass('util6--homeheader');
      $('.backbtn').attr('style', 'margin: 15px !important;');
      $('.header--title').attr('style', 'margin: 0px 30% !important;');
      $('.header--title').html('Antrag Pflegebox');
      $('.homebtn').hide();
      $('.iconbar').attr('style', 'display: none;');
            $('.hilfe_home').show().attr('style', 'top: 21px;');
      $('.header--settings').addClass('util6--homeheader-settings');
      $('.settings').attr('style','position:fixed; right:60px;').hide();
    }  else if ( pagetrigger == 'help-util6' ) {
      if(v.d)console.warn('++++++ BUILDING help-util6 HEADER ++++++ ' + pagetrigger );
      $('.ui-header').attr('style', 'line-height: 92px !important;')
      .addClass('util6--homeheader');
      $('.backbtn').attr('style', 'margin-top: 15px !important;');
      $('.hilfe_home').attr('style', 'display: none;');
      $('.iconbar').attr('style', 'display: none;');
      if( $( '#updatepage_header' ).length == 1 ){
                $('#updatepage_header').addClass('util6--homeheader');
            }
            if( $('#web_list_header').length == 1 ){
                $('#web_list_header').addClass('util6--homeheader');
            }
      $('.header--title').attr('style', 'margin-top: 0px !important;');
      $('.homebtn').hide();
      $('.header--settings').addClass('util6--homeheader-settings');
      $('.settings').attr('style','position:fixed; right:60px;').hide();
      $('.hilfe_home').show().attr('style', 'top: 21px;');
    }
  }
};
