function whitelist(s) {
  s = s.replace('\r\n', '<br>');
  s = s.replace('\n', '<br>');
  s = s.replace('\r', '<br>');
  return s.replace(/[^a-zA-Z0-9<>/]/gi, '');
}
/*
2016-09-01 Mhe:
User update steps:
*/
const createUsyncModel = () => {
  return new Promise(
    (resolve) => {
      window.Usync = {};
      // Lock to determine if user synchronization process is already running:
      Usync.running = false;
      Usync.api_url = url.device_user_api;
      // local testing:
      // Usync.api_url = "http://192.168.0.21:8080";
      // update tables for syn user:
      // need additional unique constraints to prevent
      // duplicate entries after downloading tables:

      Usync.check_settings = function() {
        Settings.select('cloudsyncconstraints_hash3', function(setting) {
          console.log(setting);
          if (setting === 'done') {
            if (v.usync) console.log('app is prepared for cloud sync v2');
          } else {
            Usync.write_db_constraints();
          }
        });
        Settings.select('cloudsyncconstraints_2', function(setting) {
          console.log(setting);
          if (setting === 'done') {
            if (v.usync) console.log('app is prepared for cloud sync v2');
          } else {
            Usync.write_db_constraints_2();
          }
        });
        return this;
      };

      /* introducing settings usync_backup_upload start*/
      Usync.check_lastbackup = function(clb_callback) {
        Settings.select('cloud_backup', function(setting) {
          console.log(setting);
          if (!setting) {
            sessionStorage.last_cloud_backup = '2000-01-01 12:00:00';
          } else {
            sessionStorage.last_cloud_backup = setting;
          }

          /* TODO: start backup with filtered entries since last backup*/
          if ($.isFunction(load_callback)) clb_callback(setting);
        });
        return this;
      };

      Usync.write_db_constraints = function() {
        let now = get_datetime_now();
        db.transaction(function(tx) {
          tx.executeSql(
            'drop index IF EXISTS unique_upr_item',
            [],
            () => {
              console.log('dropped unique_upr_item');
            }
          );
          tx.executeSql(
            'drop index IF EXISTS unique_umc',
            [],
            () => {
              console.log('dropped unique_umc');
            }
          );
          tx.executeSql(
            'drop index IF EXISTS unique_urtl',
            [],
            () => {
              console.log('dropped unique_urtl');
            }
          );
          tx.executeSql(
            'drop index IF EXISTS unique_user_msg',
            [],
            () => {
              console.log('dropped unique_user_msg');
            }
          );
          tx.executeSql(
            'drop index IF EXISTS unique_foto_hash',
            [],
            () => {
              console.log('dropped unique_fotos');
            }
          );

          tx.executeSql(
            `create unique index unique_upr_item on 
            user_page_relation(userid,itemid,added_datetime);`,
            [],
            () => {
              console.log('user_page_relation');
            }
          );
          tx.executeSql(
            `create unique index unique_umc on 
            user_media_comments(userid,mediaid);`,
            [],
            () => {
              console.log('user_media_comments');
            }
          );
          tx.executeSql(
            'create unique index unique_urtl on ratings_tl(upr_id);',
            [],
            () => {
              console.log('ratings_tl');
            }
          );
          tx.executeSql(
            `create unique index unique_user_msg on
            user_messages(userid, sender, creation_datetime);`,
            [],
            () => {
              console.log('user_messages');
            }
          );

          // NEED TO ADD last_edit to Fotos to make
          // time-dependend select in respect to last_ usermediasync
          tx.executeSql(
            'ALTER TABLE Fotos add last_edit datetime DEFAULT null',
            [],
            function(tx) {
              console.log('Foto last_edit added');
              tx.executeSql(
                'UPDATE Fotos SET last_edit = ? WHERE last_edit is null',
                [now/* ,"2010-01-01 12:00:00"*/],
                nullHandler,
                errorHandler
              );

              tx.executeSql(
                `create unique index unique_foto_hash on 
                Fotos(userid, photo_url_hash);`,
                [],
                function(tx, result) {
                  Settings.insert(
                    'cloudsyncconstraints_hash3',
                    'done',
                    function() {
                      console.log('set cloudsyncconstraints_hash3 = done');
                      Usync.check_settings();
                    }
                  );
                }
              );
            },
            errorHandler
          );
        }, function(e) {
          if (v.usync) console.log('ERROR: ' + e.message);
        });
      };

      /*
      * need to add local available col to know,
      * if file was already downloaded or not,
      * cloud sync entry seems to be not reliable enough
      * 2017_02_13 MHe:
      */
      Usync.write_db_constraints_2 = function() {
        db.transaction(function(tx) {
          tx.executeSql(
            'ALTER TABLE Fotos add local_available INT NOT NULL DEFAULT 0',
            [],
            () => {
              Settings.insert('cloudsyncconstraints_2', 'done', function() {
                if (v.usync) console.log('set cloudsyncconstraints_2 = done');
                Usync.check_settings();
              });
            },
            errorHandler
          );
        }, function(e) {
          if (v.usync) console.error('Error with writing db constraints_2');
          console.err(e.message);
        });
      };
      // Flag to determine if usync process should be triggered
      // if array is empty:
      Usync.retrying_foto_download = false;
      Usync.request_new_fotos = function() {
        if (!Usync.running) {
          Auth.check(
            function() {
              Usync.running = true;
              Usync.download(
                '/ask_for_new_user_media',
                // success cb
                // (is triggered by Usync.download
                // when data_decoded is not empty):
                (data_decoded) => {
                  Fotos.insert(
                    data_decoded,
                    function() {
                      Usync.get_new_media();
                    }
                  );
                },
                // error cb
                // (is triggered by Usync.download
                // when arr is empty or undefined):
                () => {
                  // if retrying flag is set try again to download photos:
                  if (Usync.retrying_foto_download) {
                    Usync.get_new_media();
                  } else {
                    Usync.running = false;
                    return this;
                  }
                }
              );
            }
          );
        }
      };

      Usync.request_media = function() {
        this.get_fotos_table()
          .get_media();
      };

      Usync.upstream = function() {
        if (!Usync.running) {
          Auth.check(
            Usync.check_lastbackup(
              Usync.send_users()
                .send_upr()
                .send_umc()
                .send_urtl()
                .send_ubio()
                .send_umsg()
                // .send_fotos_table()
                .send_media()
            )
          );
        }
      };

      // 2, send user accounts
      Usync.sendUsers = () => {
        return new Promise(
          (resolve, reject) => {
            User.select_all_to_backup(
              function(arr, url) {
                if (Auth.token.length > 0) {
                  Usync.upload(arr, url, resolve, reject);
                }
              }
            );
          }
        );
      };

      Usync.send_users = function(su_callback, err_cb) {
        console.log(typeof(su_callback));
        User.select_all_to_backup(
          function(arr, url) {
            if (Auth.token.length > 0) {
              Usync.upload(arr, url, su_callback, err_cb);
            }
          }
        );
        return this;
      };
      // 3, send user_page_relation
      Usync.send_upr = function() {
        User_page_relation.select_all(Usync.upload);
        return this;
      };
      // 4. send user_media_comments
      Usync.send_umc = function() {
        User_media_comments.select_all_to_backup(Usync.upload);
        return this;
      };
      // 5. send ratings_tl
      Usync.send_urtl = function() {
        Ratings_tl.select_all_to_backup(Usync.upload);
        return this;
      };
      // 6. send user_biography
      Usync.send_ubio = function() {
        User_bio.select_all_to_backup(Usync.upload);
        return this;
      };
      // 7. send user_messages
      Usync.send_umsg = function() {
        User_messages.select_all_to_backup(Usync.upload);
        return this;
      };
      // 8, send table Fotos entries[l]
      Usync.send_fotos_table = function() {
        Fotos.select_all_since_last_backup(Usync.upload);
        return this;
      };
      // 9, upload fotos
      Usync.send_media = function() {
        Fotos.select_urls_since_last_backup(Storage_api.upload_file);
        return this;
      };

      Usync.downstream = function() {
        if (!Usync.running) {
          refresh_token(
            Usync.get_user()
              .get_upr()
              .get_umc()
              .get_urtl()
              .get_ubio()
              .get_umsg()
              /*
              .get_fotos_table()
              .get_media()
              */
              .get_fotos()
              .get_timeline()
          );
        }
      };
      // 2-2, get user accounts
      Usync.get_user = function() {
        this.download('/getuser', User.insert_users);
        return this;
      };
      // 2-3, get user_page_relation
      Usync.get_upr = function() {
        this.download('/getupr', User_page_relation.insert);
        return this;
      };
      // 2-4. get user_media_comments
      Usync.get_umc = function() {
        this.download('/getumc', User_media_comments.insert);
        return this;
      };
      // 2-5. get ratings_tl
      Usync.get_urtl = function() {
        this.download('/geturtl', Ratings_tl.insert);
        return this;
      };
      // 2-6. get user_biography
      Usync.get_ubio = function() {
        this.download('/getubio', User_bio.insert);
        return this;
      };
      // 2-7. get user_messages
      Usync.get_umsg = function() {
        this.download('/getumsg', User_messages.insert);
        return this;
      };

      // 2-8, get table Fotos entries[l]
      Usync.get_fotos_table = function() {
        this.download('/get_user_fotostable', Fotos.insert);
        return this;
      };

      // 2-9, download fotos
      Usync.get_media = function() {
        Storage_api.errors = [];
        Fotos.select_todownload('', Storage_api.download_file);

        return this;
      };

      Usync.get_fotos = function() {
        Usync.get_fotos_table();
        // that is very bad, TODO: rewrite with correct queing callback!!
        setTimeout(function() {
          Usync.get_media();
        }, 5000);
      };

      Usync.get_timeline = function() {
        timelineService.pull();
      };

      Usync.get_new_media = function() {
        let permissions = cordova.plugins.permissions;
        permissions.checkPermission(
          permissions.WRITE_EXTERNAL_STORAGE,
          function(status) {
            if (!status.hasPermission) {
              console.warn('keine SPEICHERBERECHTIGUNG erteilt!');
              thisapp.popupBetter(
                `<br><br>
                          <center>Ihnen wurde eventuell ein Foto geschickt.<br>
                          Es gab jedoch ein Problem!<br>
                          Sie müssen in den Android-Einstellungen der<br>
                          App media4care die
                          Speicherzugriff-Berechtigung erteilen</center>`,
                `Einstellungen`,
                `Später`,
                function() {
                  if (typeof cordova.plugins.settings.openSetting !== 'undefined') {
                    cordova.plugins.settings.openSetting(
                      'application',
                      function() {
                        console.log('opened application settings');
                      },
                      function() {
                        console.log(`failed to open application settings`);
                      }
                    );
                  }
                }
              );
              Usync.running = false;
            } else {
              Settings.select(
                'cloud_download',
                function(set) {
                  Storage_api.errors = [];
                  Fotos.select_todownload(set, Storage_api.download_file);
                }
              );
            }
          }
        );
        return this;
      };

      Usync.download = function(get_url, ud_callback, err_cb) {
        console.log(
          '[Usync.download] begin',
          Usync.running
        );
        // testing go api:
        const download_url = Usync.api_url + get_url;
        $.ajax({
          type: 'POST',
          data: {/* token: Auth.token*/},
          url: download_url,
          xhrFields: {
            withCredentials: false,
          },
          headers: {
            'Token': Auth.token,
            'Cloud_db_last_edit': Fotos.cloud_db_last_edit,
          },
          cache: false,
          timeout: 10000,
          success: function(data, textStatus, xhr) {
            // console.log(xhr.status);
            console.log(get_url);
            if (v.usync) console.log('successful contacted get URL');
            let data_decoded = '';
            if (typeof(data) === 'object') {
              data_decoded = data;
            } else {
              data_decoded = JSON.parse(data);
            }
            // get all family_relation account ids with status 5
            // (registration at family portal complete)
            let allowed_account_emails = [];
            let valid_data;
            Family_relations.getActiveRelations(
              User.prof_id,
              (family_relations) => {
                family_relations.map(
                  (fr) => {
                    if (fr.inv_status === 5) {
                      allowed_account_emails.push(fr.email);
                    }
                  }
                );
                console.log(
                  '[Usync.download] allowed_emails: ',
                  allowed_account_emails
                );
                // only when the email is in family_relations table
                // and registered at family portal (status = 5).
                // Filter the array for valid emails:
                if (data_decoded) {
                  valid_data = data_decoded.filter(
                    (data) => {
                      return allowed_account_emails.includes(data.sender);
                    }
                  );
                } else {
                  Usync.running = false;
                  if ($.isFunction(err_cb)) err_cb();
                }
                console.log(
                  '[Usync.download] data_decoded: ',
                  data_decoded
                );
                console.log(
                  '[Usync.download] valid_data: ',
                  valid_data
                );
                if (v.usync) console.log(valid_data);
                if (valid_data) {
                  if (valid_data.length > 0) {
                    if (v.usync) console.log(valid_data);
                    ud_callback(valid_data);
                  } else {
                    Usync.running = false;
                    if ($.isFunction(err_cb)) err_cb();
                  }
                } else {
                  if (v.usync) {
                    console.warn(
                      'response data seems invalid length = 0 ? ' + download_url
                    );
                  }
                  Usync.running = false;
                  if ($.isFunction(err_cb)) err_cb();
                }
              },
              // error cb:
              () => {
                Usync.running = false;
                if ($.isFunction(err_cb)) err_cb();
              }
            );
          },
          error: function(error, err_cb) {
            core.ajax_error(error, function() {
              Usync.running = false;
              if ($.isFunction(err_cb)) err_cb();
            });
          },

        });
      };

      Usync.upload = function(User_arr, User_url, Uu_callback, err_cb) {
        // array2json in controller/statistics.js
        let data_json = array2json(User_arr);
        if (v.usync) console.log(data_json);

        let stats_url = Usync.api_url + User_url;
        $.ajax({
          type: 'POST',
          data: {data_json: data_json, token: Auth.token},
          url: stats_url,
          xhrFields: {
            withCredentials: false,
          },
          headers: {'Token': Auth.token},
          cache: false,
          timeout: 20000,
          success: function(data) {
            if (data == 'success') {
              if (v.usync) console.log('table submitted');
            } else {
              // TODO: set to false after upstream finished:
              // Usync.running = false;
            }
            if ($.isFunction(Uu_callback)) Uu_callback();
          },
          error: function(error, err_cb) {
            core.ajax_error(error, function() {
              Usync.running = false;
              if ($.isFunction(err_cb)) err_cb();
            });
          },
        });
      };

      Usync.display_progress = function(
        info,
        filename,
        percent,
        loaded,
        total,
        step,
        steps
      ) {
      };

      resolve();
    }
  );
};
