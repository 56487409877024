const createHelperSound = () => {
  return new Promise(
    (createResolve, createReject) => {
      /* plays the sound of [src] */
      window.sound = (src, finished_cb) => {
        // chromium socks workaround start
        // MHe 2016-08-03
        // html 5 player chromium issue when many socks are open
        // no more media will be played, trying to kill all old ones:
        if (globalaudio) globalaudio.src = "";
        globalaudio = new Audio(src); // buffers automatically when created
        //chromium socks workaround end
        if (typeof soundvolume !== 'undefined') {
          if (v.d) console.log('playing sound with volume ' + soundvolume);
          globalaudio.volume = soundvolume;
        }
        globalaudio.setAttribute('src', src);
        globalaudio.setAttribute('autoplay', 'autoplay');
        //audioElement.load()

        $.get();
        globalaudio.addEventListener(
          'load',
          () => {
            globalaudio.play();
          },
          true
        );
        globalaudio.addEventListener('ended', sound_finished, false);

        if ($.isFunction(finished_cb)) {
          globalaudio.addEventListener('ended', finished_cb, false);
        }

        globalaudio.play();
      };

      window.sound_finished = () => {
        if (typeof globalaudio !== 'undefined') {
          globalaudio.src = '';
          globalaudio.remove();
        }
      };

      window.check_sound_settings = () => {
        return new Promise(
          (resolve, reject) => {
            Settings.select('soundvolume', function (setting) {
              if (v.d) console.log(setting);
              const this_volume = parseInt(setting) || 0;
              if (this_volume > 0) {
                // globalaudio = this_volume;
                window.soundvolume = this_volume;
                resolve();
              } else {
                // add soundvolume to settings table:
                Settings.insert(
                  'soundvolume',
                  '1',
                  resolve
                );
              }
            });
          }
        );
      };

      createResolve();
    }
  );
};
