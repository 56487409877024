const createContentBackendAuth = () => {
  state.content_back_end = {
    token: '',
  };
  window.contentBackendAuth = new function() {
    var self = this;
    this.get_token = (try_conn = 0) => {
      return new Promise(
        (resolve) => {
          if (!thisapp_online) {
            resolve();
          }
          $.ajax(
            {
              type: 'POST',
              data:
                {
                  manufacturer: device.manufacturer,
                  platform: device.platform,
                  version: device.version,
                  uuid: device.uuid,
                  cordova: device.cordova,
                  model: device.model,
                  app_version: app_core_version,
                },
              url: url.content_be + '/get_token.php',
              cache: false,
              xhrFields:
                {
                  withCredentials: false,
                },
              timeout: conf.get_timeout,
              headers: {},
              success: (result) => {
                if (result === 'error' || result === '') {
                  if (try_conn < 6) {
                    if (lastsync_dl >= '2000-01-01 00:00:00') {
                      ++try_conn;
                    }

                    setTimeout(
                      () => {
                        self.get_token(try_conn);
                      },
                      1500
                    );
                    resolve();
                  } else {
                    console.error('no chance to get a token', result);
                    resolve();
                    // TODO: should be reject(?)
                  }
                } else {
                  state.content_back_end.token = result;
                  resolve();
                }
              },
              error: () => {
                resolve();
              },
            }
          );
        }
      );
    };
  };
};
