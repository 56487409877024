const createFiles = () => {
  return new Promise(
    (resolve, reject) => {
      window.files = {};

      files.constants = () => {
        return Object.freeze({
          STATUS: Object.freeze({
            'UNKNOWN': '1',
            'LOCALLY_AVAILABLE': '2',
          }),
        });
      };

      files.set_status = function(f_ss, new_status, file_id) {
        db.transaction(
          function(tx) {
            tx.executeSql(
              'UPDATE files_v3 SET status = ? WHERE id = ?',
              [new_status, file_id],
              function(transaction, result) {
                if ($.isFunction(f_ss)) {
                  f_ss();
                }
              },
              errorHandler
            );
          }
        );
        return this;
      };

      files.setStatus = function(newStatus, fileId) {
        return new Promise(
          (resolve, reject) => {
            db.transaction(
              function(tx) {
                tx.executeSql(
                  'UPDATE files_v3 SET status = ? WHERE id = ?',
                  [newStatus, fileId],
                  resolve,
                  reject
                );
              }
            );
          }
        );
      };

      files.selectAll = () => {
        return new Promise(
          (resolve, reject) => {
            db.transaction(
              (tx) => {
                tx.executeSql(
                  `SELECT id, path, size, md5 from files_v3`,
                  [],
                  (transaction, result) => {
                    let res = [];
                    if (result !== null && result.rows !== null) {
                      for (let i = 0; i < result.rows.length; i++) {
                        res = [...res, ...[result.rows.item(i)]];
                      }
                      resolve(res);
                    }
                  },
                  reject
                );
              }
            );
          }
        );
      };

      files.set_status_by_filename = (
        filename,
        status,
        success_callback,
        error_callback
      ) => {
        db.transaction(
          function(tx) {
            tx.executeSql(
              `UPDATE files_v3 SET status = ? WHERE path like '%${filename}'`,
              [status],
              function(transaction, result) {
                if($.isFunction(success_callback))
                  success_callback(filename, status);
              },
              error_callback
            );
          }
        );
        return this;
      };
      resolve();
    }
  );
};
