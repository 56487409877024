// reduce code
const createPage = () => {
  return new Promise(
    (resolve, reject) => {
      window.page = {
        go_to: function (viewUrl) {
          checkConnection();
          $(':mobile-pagecontainer')
            .pagecontainer(
              'change',
              home_dir + viewUrl,
              {
                transition: 'slide',
              }
            );
        },
        back_to: function (viewUrl) {
          $(':mobile-pagecontainer')
            .pagecontainer(
              'change',
              home_dir + viewUrl,
              {
                transition: 'slide',
                reverse: true,
              }
            );
        },
      };
      resolve();
    }
  );
}
