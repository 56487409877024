const TIMELINE_STATUS_TODELETE = 3;
const TIMELINE_STATUS_DELETED = 4;
const FOTOS_STATUS_SEEN = 2;
const FOTOS_TYPE_TIMELINE = 5;


class Timeline {
  constructor(data) {
    this.id = data.id;
    this.user_id = data.user_id || data.userid;
    this.hash = data.hash || data.photo_url_hash;
    this.url = data.url || data.photo_url || null;
    this.sender = data.sender;
    this.title = data.title;
    this.seen = !!data.seen;
    this.comment = data.comment;
    this.type = data.type;
    this.status = data.status;
    this.updated_at = data.updated_at || data.last_edit || data.db_last_edit || data.created_at;
  }

  static create(data) {
    const timeline = new Timeline(data);

    const sql = `INSERT OR IGNORE INTO 
      Timeline(user_id, hash, url, seen, sender, title, comment, type, status, updated_at)
      VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?)
    `;

    return insertRecord(sql, [
      timeline.user_id,
      timeline.hash,
      timeline.url,
      (timeline.seen ? 1 : 0),
      timeline.sender,
      timeline.title,
      timeline.comment,
      timeline.type,
      timeline.status,
      timeline.updated_at,
    ]);
  }

  static shouldRemove(item) {
    const toRemoveStatus = [
      TIMELINE_STATUS_TODELETE,
      TIMELINE_STATUS_DELETED,
    ];

    return toRemoveStatus.includes(item.status);
  }

  static getByHash(hash) {
    return query('SELECT * FROM Timeline WHERE hash = ?', [hash])
      .then(Array.from)
      .then((items) => items.length ? new Timeline(items[0]) : null);
  }

  static getUnseen(user_id) {
    const sql = `SELECT * from Timeline WHERE seen = 0 AND
                user_id = ? ORDER BY updated_at desc`;
    return query(sql, [user_id])
      .then(Timeline.fromRecords);
  }

  static getDownloadPending() {
    const sql = `SELECT * from Timeline WHERE
                    url IS NULL AND status NOT IN
                    (${TIMELINE_STATUS_TODELETE}, ${TIMELINE_STATUS_DELETED})
                `;
    return query(sql)
      .then(Timeline.fromRecords);
  }

  setSeen(user_id) {
    return query(
      'UPDATE Timeline set seen = 1 WHERE id = ? AND user_id = ?',
      [this.id, user_id],
    );
  }

  setURL(url) {
    return query('UPDATE Timeline SET url = ? WHERE id = ?', [url, this.id]);
  }

  toURL() {
    const params = $.param({hash: this.hash, token: Auth.token});
    return this.url || `${url.storage_api}/redirect?${params}`;
  }

  setStatus(status, updated_at) {
    const sql = 'UPDATE Timeline SET status = ?, updated_at = ? WHERE id = ?';
    return query(sql, [status, updated_at, this.id]);
  }

  static getToDelete() {
    const sql = 'SELECT * from Timeline WHERE url IS NULL AND status = ?';
    return query(sql, [TIMELINE_STATUS_TODELETE])
      .then(Timeline.fromRecords);
  }

  static destroy(item) {
    return Timeline.getByHash((item.hash || item.photo_url_hash))
      .then((timeline) => {
        if (timeline) {
          timeline.setStatus(TIMELINE_STATUS_TODELETE, timeline.updated_at);
        }
      });
  }

  static setAllSeen(user_id) {
    return query('UPDATE Timeline SET seen = 1 AND user_id = ?', [user_id]);
  }

  static getAll() {
    return query('SELECT * FROM Timeline').then(Timeline.fromRecords);
  }

  static getAllByUser(user_id) {
    const sql = `SELECT * FROM Timeline WHERE
      user_id = ? AND status NOT IN
      (${TIMELINE_STATUS_TODELETE}, ${TIMELINE_STATUS_DELETED})
      ORDER BY updated_at desc`;
    return query(sql, [user_id])
      .then(Timeline.fromRecords);
  }

  static getLastInsertedDate() {
    return query('SELECT max(updated_at) updated_at FROM Timeline')
      .then((result) => {
        const items = Array.from(result);

        return items[0].updated_at || '2017-01-01 00:00:00';
      });
  }

  static fromRecords(results) {
    return Array.from(
      results,
      (record) => new Timeline(record)
    );
  }

  static migrateFromPhotos() {
    return helper_db.get_table_version('Timeline')
      .then(function(version) {
        if (version <= 2) {
          query('SELECT * FROM Fotos WHERE type = ?', [FOTOS_TYPE_TIMELINE])
            .then((results) => {
              const promises = Array.from(results, function transform(record) {
                record.seen = (record.seen === FOTOS_STATUS_SEEN);
                return Timeline.create(record)
                  .catch(console.error);
              });
              return Promise.all(promises);
            })
            .then(() => {
              return helper_db
                .set_table_version('Timeline', 3, 'Pull images from Fotos table');
            });
        }
      });
  }
}
