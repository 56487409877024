/* jshint esversion: 6 */
// TODO 2. maybe put header somewhere config
let msg_sync_log = {};
msg_sync_log.update_api = function(l_foto_hash, l_url) {
  if (v.d) {
    console.log(
      `Notify msg_sync_log-API at ${l_url} that file (hash) ${l_foto_hash}`
    );
  }
  let l_data = {foto_url: l_foto_hash};
  $.ajax({
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Basic cFJYeFhzWUkyRlFMMmkwSFRxOmIyVXZUbE1WcUhzbzhDMHJuNA==',
    },
    url: l_url,
    data: JSON.stringify(l_data),
    tryCount: 0,
    retryLimit: 3,
    success: function(msg) {
      if (v.usync) {
        console.log('service MSG_SYNC_LOG:');
        console.log(msg);
      }
    },
    error: function(textStatus, errormessage) {
      if (this.tryCount < this.retryLimit) {
        this.tryCount++;
        if (v.usync) {
          console.log(
            `retrying connection to ${this.url} ${this.tryCount}. attempt`
          );
        }
        $.ajax(this);
      } else {
        if (v.usync) {
          console.log('service MSG_SYNC_LOG-Error:');
          console.log(errormsg);
          return;
        }
      }
    },
  });
};

msg_sync_log.last_online = function(l_device_id, l_url) {
  if (v.d) {
    console.log(
      `Notify msg_sync_log-API at ${l_url} witt device ${l_device_id}`
    );
  }
  const l_data = {device_id: l_device_id};
  $.ajax({
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Basic cFJYeFhzWUkyRlFMMmkwSFRxOmIyVXZUbE1WcUhzbzhDMHJuNA==',
    },
    url: l_url,
    data: JSON.stringify(l_data),
    tryCount: 0,
    retryLimit: 3,
    success: function(msg) {
      if (v.usync) {
        console.log('service MSG_SYNC_LOG:');
        console.log(msg);
      }
    },
    error: function(textStatus, errormessage) {
      if (this.tryCount < this.retryLimit) {
        this.tryCount++;
        if (v.usync) {
          console.log(
            `retrying connection to ${this.url} ${this.tryCount}. attempt`
          );
        }
        $.ajax(this);
      } else {
        if (v.usync) {
          console.log('service MSG_SYNC_LOG-Error:');
          console.log(errormessage);
          return;
        }
      }
    },
  });
};
