const createApiRelatives = () => {
  return new Promise(
    (resolveCreate) => {
      window.api_relatives = {};

      api_relatives.msg_comment = (
        comment, file_hash, success_cb, error_cb
      ) => {
        $.ajax(
          {
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify( {
              'cf_url': file_hash,
              'prof_id': User.prof_id,
              'cf_response': comment,
            }),
            url: api_endpoints.msg_comment,
            cache: false,
            xhrFields: {
              withCredentials: false,
            },
            timeout: conf.get_timeout,
            headers: {Token: Auth.token},
            success: function(resp, textStatus, xhr) {
              console.log(xhr.status);
              console.log(resp);
            },

            error: function() {
              if (try_conn < 4) {
                ++try_conn;
                setTimeout(function() {
                  // check if tab has wifi issues (medion):
                  // re-enable_wifi(request_token, gt_callback);

                  api_relatives.msg_comment(comment, file_hash, success_cb, error_cb);
                }, 1500);
              } else {
                console.error('sending foto comment failed with 4 attempts');
                if ($.isFunction(error_cb)) error_cb();
                return;
              }
            },
          });
      };

      api_relatives.get_relations = function(prof_id) {
        prof_id = parseInt(prof_id)||0;
        if (prof_id > 0) {
          return $.ajax(
            {
              type: 'POST',
              contentType: 'application/json',
              data: JSON.stringify( {
                'prof_id': prof_id,
              }),
              url: api_endpoints.get_customers_by_prof_ids,
              cache: false,
              xhrFields: {
                withCredentials: false,
              },
              timeout: conf.get_timeout,
              headers: {Token: Auth.token},
              success: function(resp, textStatus, xhr) {
                console.log(xhr.status);
                console.log(resp);
                // success_cb(resp);
                return resp;
              },

              error: function() {
                if (try_conn < 4) {
                  ++try_conn;
                  setTimeout(function() {
                    api_relatives.get_relations(User.prof_id);
                  }, 1500);
                } else {
                  console.error('receiving get_relations failed with 4 attempts');
                  if ($.isFunction(error_cb)) error_cb();
                  return;
                }
              },
            });
        }
      };
      /**
       * Customer returned by the relatives api.
       * @typedef {Object} RelativesApiCustomer
       * @property {number} cust_id - Id of the customer.
       * @property {string} prename - First name of the customer.
       * @property {string} lastname - Last name of the customer.
       * @property {string} email - Email of the customer.
       * @property {string} prof_id - Corresponding prof_id
       * (e.g. the senior of the customer)
       */

      /**
       * Get all customers associated with `prof_ids` of profiles/seniors.
       * @param {number[]} prof_ids - Ids of the profiles.
       * @param {number} attempt_count - The max attempts to execute the request.
       * @return {Promise<RelativesApiCustomer[]>} - Array of corresponding customers.
       */
      api_relatives.get_customers_by_prof_ids = function(prof_ids, attempt_count=4) {
        let settings = {
          'url': api_endpoints.get_customers_by_prof_ids,
          'method': 'POST',
          'headers': {
            'Token': Auth.token,
          },
          'contentType': 'application/json',
          'cache': false,
          'xhrFields': {
            'withCredentials': false,
          },
          'timeout': conf.get_timeout,
          'data': JSON.stringify({
            'prof_id': prof_ids,
          }),
        };

        return new Promise(
          (resolve, reject) => {
            $.ajax(settings)
              .done((resp) => resolve(resp))
              .fail((jqXHR) => {
                if (attempt_count > 1) {
                  setTimeout(function() {
                    resolve(
                      api_relatives.get_customers_by_prof_ids(
                        User.prof_id,
                        --attempt_count
                      )
                    );
                  },
                  1500);
                } else {
                  reject(jqXHR);
                }
              });
          }
        );
      };

      resolveCreate();
    }
  );
}
