class find_content_source {
  constructor(success_cb, error_cb) {
    this._potential_sdcard_folders = [];

    this._check_content_folder_availability = (pos, length) => {
      window.resolveLocalFileSystemURL(
        this._potential_sdcard_folders[pos] +
        // 'm4c_sd_card_identifier.txt',
        'Android/data/com.media4care.dementia/files/videos/',
        // folder found:
        fs => {
          console.log("FOUND for "+this._potential_sdcard_folders[pos]);
          sd_card_url = this._potential_sdcard_folders[pos];
          Settings.insert_replace(
            "sdcard",
            sd_card_url,
            () => {
              if ($.isFunction(success_cb)) success_cb(sd_card_url);
            }
          );
        },
        // folder not found:
        err => {
          pos++;
          if(pos < length) {
            this._check_content_folder_availability(pos, length);
          } else {
            console.error("no more pot sd folders to check");
            if ($.isFunction(this._error_cb)) this._error_cb(err);
          }
        }
      );
    };
    this._error_cb = error_cb;
  }

  find_potential_sd_cards() {
    window.resolveLocalFileSystemURL(
      'file:///storage/',
      fs => {
        console.log(fs);
        let directoryReader = fs.createReader();
        // get a list of all entries in the directory
        directoryReader.readEntries(
          entries => {
            console.log(entries);
            let default_folders =
              [
                "emulated",
                "self"
              ];
            for (let i=0; i<entries.length; i++) {
              console.log(entries[i]);
              if(!default_folders.includes(entries[i].name)) {
                let this_pot_sdcard =
                  `file:///storage/${entries[i].name}/`;
                this._potential_sdcard_folders
                  .push(this_pot_sdcard);
              }
            }
            let length = this._potential_sdcard_folders.length;

            if(length < 1) {
              console.error("no potential sd card found");
              if($.isFunction(this._error_cb)) this._error_cb();
            } else {
              this._check_content_folder_availability(0, length);
            }
          },
          fail
        );
      },
      fail
    );
  }
}
