const SETTINGS_VALUES = Object.freeze({
  DEPRECATED_FALSE: '0',
  DEPRECATED_TRUE: '1',
  ON: 'on',
  OFF: 'off',
  TRUE: 'true',
  FALSE: 'false',
});

const DEFAULT_SETTINGS = [
  {
    'name':  'sync_action_file_create',
    'value': '2000-01-01 00:00:00',
  },
  {
    'name': 'globalprofilepassword',
    'value': '2828',
  },
  {
    'name': 'settings_profile_rate',
    'value': SETTINGS_VALUES.OFF,
  },
  {
    'name': 'settings_pw_web',
    'value': SETTINGS_VALUES.OFF,
  },
  {
    'name': 'settings_pw_profile',
    'value': SETTINGS_VALUES.OFF,
  },
  {
    'name': 'soundvolume',
    'value': '1',
  },
  {
    'name': 'language',
    'value': nat_lang,
  },
  {
    'name': 'utilization',
    'value': '2',
  },
  {
    'name': 'update',
    'value': SETTINGS_VALUES.DEPRECATED_FALSE,
  },
  {
    'name': 'updateMediaFiles',
    'value': SETTINGS_VALUES.DEPRECATED_TRUE,
  },
  {
    'name': 'contentBackendDb',
    'value': SETTINGS_VALUES.DEPRECATED_FALSE,
  },
  {
    'name': 'sdCardInstallation',
    'value': SETTINGS_VALUES.TRUE,
  },
  {
    'name':  'VIDEOS_SAVED_INTERNALLY',
    'value': SETTINGS_VALUES.FALSE,
  },
  {
    'name':  'callAutoAnswer',
    'value': SETTINGS_VALUES.OFF,
  },

];

const createSettings = () => {
  return new Promise(
    (resolveCreate, rejectCreate) => {
      window.Settings =
        {
          init: () => {
            return new Promise(
              (resolve, reject) => {
                ++built_settings;
                db.transaction(
                  (tx) => {
                    tx.executeSql(
                      `CREATE TABLE IF NOT EXISTS settings
                        (
                          id INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
                          name TEXT NOT NULL,
                          setting TEXT NOT NULL,
                          edit_datetime datetime,
                          unique(name)
                        )`,
                      [],
                      () => {
                        helper_db.set_table_version(
                          'settings',
                          2,
                          'make name column unique'
                        ).then(resolve);
                      },
                      () => {
                        console.error('about to reject in Settings init');
                        reject();
                      }
                    );
                  },
                  reject,
                  resolve
                );
              }
            );
          },

          makeUnique: () => {
            return new Promise(
              (resolve, reject) => {
                console.log('starting make unique');
                helper_db.set_table_version('settings', 1, 'hardReset for broken dev tabs')
                  .then(
                    () => {
                      return helper_db.get_table_version('settings');
                    }
                  )
                  .then(
                    (version) => {
                      console.log('version:', version);
                      if (version === 1) {
                        dbExecute(
                          `DROP table IF EXISTS _settings_1_old`
                        )
                          .then(
                            () => {
                              return dbExecute(
                                `ALTER TABLE settings RENAME TO _settings_1_old`
                              );
                            }
                          )
                          .then(
                            () => {
                              console.log('after unique rename');
                            }
                          )
                          .then(Settings.init)
                          .then(
                            () => {
                              console.log('after unique init');
                            }
                          )
                          .then(() => {
                            return dbExecute(
                              `INSERT OR IGNORE INTO settings
                                (name, setting, edit_datetime)
                                SELECT name, setting, edit_datetime
                                  FROM _settings_1_old
                                  GROUP BY name
                                  ORDER BY edit_datetime DESC`,
                              []
                            );
                          })
                          .then(() => {
                            return dbExecute(`DROP TABLE _settings_1_old`);
                          })
                          .then(
                            () => {
                              return helper_db.set_table_version(
                                'settings',
                                2,
                                'make name column unique'
                              );
                            }
                          )
                          .then(resolve)
                          .catch(reject);
                      } else {
                        resolve();
                      }
                    }
                  )
                  .catch(
                    (err) => {
                      reject(err);
                    }
                  );
              }
            );
          },

          insert: (si_name, si_setting, si_callback) => {
            db.transaction(
              (tx) => {
                tx.executeSql(
                  `INSERT OR IGNORE INTO settings
                                (name,setting,edit_datetime) 
                                values(?, ?, ?)`,
                  [si_name, si_setting, get_datetime_now()],
                  (transaction, result) => {
                    if (v.d) {
                      console.log(
                        'inserted setting: ' +
                        si_name + ' => ' + si_setting);
                    }
                    si_callback(true);
                  },
                  errorHandler
                );
              }
            );
          },

          insertWithPromise: (si_name, si_setting) => {
            return new Promise(
              (resolve, reject) => {
                if (
                  typeof(si_setting === 'undefined')
                  || si_setting === 'undefined'
                ) {
                  resolve();
                }
                Settings.insert(si_name, si_setting, resolve);
              }
            );
          },
          insertReplaceWithPromise: (si_name, si_setting) => {
            return new Promise(
              (resolve) => {
                Settings.insert_replace(si_name, si_setting, resolve);
              }
            );
          },
          insert_replace: (si_name, si_setting, si_callback) => {
            let datetime_now = get_datetime_now();
            db.transaction(function(tx) {
              tx.executeSql(
                `INSERT OR REPLACE INTO settings
                  (setting, name, edit_datetime)
                  values (?, ?, ?)`,
                [si_setting, si_name, datetime_now ],
                (tx, result) => {
                  console.log(
                    'inserted setting: '
                    + si_name + ' => '
                    + si_setting);
                  if ($.isFunction(si_callback)) si_callback(true);
                }, errorHandler);
            });
          },

          selectPromise: (settingsName = '') => {
            return new Promise(
              (resolve, reject) => {
                Settings.select('videosSavedInternally', resolve, reject);
              }
            );
          },
          // err_cb later introduced, therefore ss_callback as fallback
          // 2018-03 mahe
          select: (ss_name, ss_callback, err_cb = ss_callback) => {
            return new Promise(
              (resolve, reject) => {
                if (typeof ss_callback !== 'function') {
                  ss_callback = resolve;
                  err_cb = reject;
                }

                db.transaction((tx) => {
                  tx.executeSql(
                    `select setting from settings
                        where name = ? 
                        order by edit_datetime desc
                        limit 1`,
                    [ss_name],
                    (tx, result) => {
                      if (result !== null && result.rows !== null) {
                        if (result.rows.length < 1) {
                          console.error(
                            'no results length for setting with name =',
                            ss_name);
                          if ($.isFunction(err_cb)) err_cb(false);
                        } else {
                          let row = result.rows.item(0);
                          if (v.d) {
                            console.log('select setting: '
                              + ss_name + ' => '
                              + row.setting);
                          }
                          ss_callback(row.setting);
                        }
                      } else {
                        let error =
                          'no results for setting with name = '
                          + ss_name;
                        console.log(error);
                        if (err_cb === reject) {
                          reject(error);
                        } else {
                          err_cb(false);
                        }
                      }
                    },
                    (error) => {
                      if (err_cb === reject) {
                        reject(error);
                      } else {
                        err_cb(false);
                      }
                    }
                  );
                });
              }
            );
          },

          update: (su_name, su_setting, su_callback = nullHandler) => {
            const datetime_now = get_datetime_now();
            db.transaction(
              (tx) => {
                tx.executeSql(
                  'UPDATE settings set setting=?, edit_datetime=? where name=?',
                  [su_setting.toString(), datetime_now, su_name.toString()],
                  (transaction, result) => {
                    if (result !== null && result.rows !== null) {
                      for (let i = 0; i < result.rows.length; i++) {
                        if(logging)
                          console.log('updated setting: ' + su_name + ' => ' + su_setting);
                      }
                    }
                    su_callback(true);
                  },
                  (su_name, su_setting, su_callback) => {
                    Settings.insert(su_name, su_setting, su_callback);
                  });
              });
          },

          // check if setting exists:
          exists: (setting_name) => {
            return new Promise(
              (resolve, reject) => {
                db.transaction(
                  (tx) => {
                    tx.executeSql(
                      `SELECT EXISTS(
                        SELECT 1 FROM settings WHERE name = ?
                      ) as does_exist`,
                      [setting_name],
                      (transaction, result) => {
                        if (result !== null && result.rows !== null) {
                          for (let i = 0; i < result.rows.length; i++) {
                            if (result.rows.item(0).does_exist === 1) {
                              resolve(
                                {
                                  setting: {
                                    name: setting_name,
                                    exists: true,
                                  },
                                }
                              );
                            } else {
                              // setting does not exist
                              resolve(
                                {
                                  setting: {
                                    name: setting_name,
                                    exists: false,
                                  },
                                }
                              );
                            }
                          }
                        }
                      },
                      reject
                    );
                  }
                );
              }
            );
          },

          delete: (settings_name, success_cb, error_cb) => {
            let this_error_cb = errorHandler;
            if ($.isFunction(error_cb)) {
              this_error_cb = error_cb;
            }

            db.transaction(
              function (tx) {
                tx.executeSql(
                  `delete from settings where name = ?`,
                  [settings_name],
                  function (tx, result) {
                    if (result !== null && result.rows !== null) {
                      if ($.isFunction(success_cb)) success_cb();
                    }
                  },
                  this_error_cb
                );
              }
            );
          },

          // moved from settings_profiles controller, cause unknown:
          // adding default settings for first-time start of
          // the application
          default: () => {
            return new Promise(
              (resolveDefault, rejectDefault) => {
                Promise.all(
                  DEFAULT_SETTINGS.map((thisSetting) => {
                    return Settings.insertWithPromise(
                      thisSetting.name,
                      thisSetting.value
                    );
                  })
                )
                  .then(resolveDefault)
                  .catch((err) => {
                    console.error(err);
                    rejectDefault(err);
                  });
              }
            );
          },
        };

      resolveCreate();
    }
  );
};
