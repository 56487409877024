/* jshint esversion: 6 */
const createInvitationsModel = () => {
  return new Promise(
    (resolve) => {
      window.Invite = {};
      Invite.api_url = url.relatives_api;
      /*
        Invite Status Column:
        1 = in queue (invitations added, api not yet informed)
        2 = invitation activated, api informed
        3 = invitation to deactivate, api not yet informed
        4 = invitation deactivated, api informed
      */
      Invite.validate_firstname = function(firstname) {
        if (firstname.length >= 3) {
          return true;
        } else {
          return false;
        }
      };

      Invite.validate_lastname = function(lastname) {
        if (lastname.length >= 3) {
          return true;
        } else {
          return false;
        }
      };

      Invite.validate_email = function(email) {
        if (email.length >= 5) {
          let re = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
          return re.test(email);
        } else {
          return false;
        }
      };

      Invite.get_all = function(callback) {
        db.transaction(function(tx) {
          tx.executeSql(
            'SELECT * from invitations_v3',
            [], function(
              tx, result) {
              if (result !== null && result.rows !== null) {
                let length = result.rows.length;
                let arr = [];
                for (let i = 0; i < length; i++) {
                  let row = result.rows.item(i);
                  arr[i] = row;
                }
                if ($.isFunction(callback)) callback(arr);
              }
            }, errorHandler);
        });
      };

      Invite.get_by_status = function(inv_status, callback) {
        db.transaction(function(tx) {
          tx.executeSql(
            'SELECT * from invitations_v3 where inv_status = ? ',
            [inv_status], function(
              tx, result) {
              if (result !== null && result.rows !== null) {
                let length = result.rows.length;
                let arr = [];
                for (let i = 0; i < length; i++) {
                  let row = result.rows.item(i);
                  arr[i] = {};
                  arr[i].user_id = parseInt(row.inv_userid) || 0;
                  arr[i].inv_mail = row.inv_mail;
                  arr[i].inv_status = row.inv_status;
                  arr[i].created = row.created;
                  // new according to new JSON
                  arr[i].prename = row.inv_firstname || '';
                  arr[i].lastname = row.inv_lastname || '';
                  arr[i].title = row.inv_title || '';
                  arr[i].phone_number = row.inv_phone || '';
                }
                if ($.isFunction(callback)) callback(arr);
              }
            }, errorHandler);
        });
      };

      Invite.get_by_status_userid = function(inv_status, this_userid, callback) {
        db.transaction(function(tx) {
          tx.executeSql(
            `SELECT * 
             FROM invitations_v3
             WHERE inv_status = ? 
             AND inv_userid = ?`,
            [inv_status, this_userid], function(
              tx, result) {
              if (result !== null && result.rows !== null) {
                let length = result.rows.length;
                let arr = [];
                for (let i = 0; i < length; i++) {
                  let row = result.rows.item(i);
                  arr[i] = {};
                  arr[i].user_id = parseInt(row.inv_userid) || 0;
                  arr[i].email = row.inv_mail;
                  arr[i].inv_status = row.inv_status;
                  arr[i].created = row.created;
                  // new according to new JSON
                  arr[i].prename = row.inv_firstname || '';
                  arr[i].lastname = row.inv_lastname || '';
                  arr[i].title = row.inv_title || '';
                  arr[i].phone_number = row.inv_phone || '';
                }

                if ($.isFunction(callback)) callback(arr);
              }
            }, errorHandler);
        });
      };

      Invite.get_by_userid = function(inv_userid, callback) {
        db.transaction(function(tx) {
          tx.executeSql(
            `SELECT *
              FROM invitations_v3
              WHERE inv_userid = ?
              AND inv_status IN (1,2)
              ORDER BY created DESC`,
            [inv_userid],
            function(
              tx, result) {
              if (result !== null && result.rows !== null) {
                let length = result.rows.length;
                let arr = [];
                for (let i = 0; i < length; i++) {
                  let row = result.rows.item(i);
                  // if(logging===true)console.log(row);
                  arr[i] = {};
                  arr[i].user_id = row.inv_userid;
                  arr[i].inv_mail = row.inv_mail;
                  arr[i].inv_status = row.inv_status;
                  arr[i].created = row.created;
                  arr[i].prename = row.inv_firstname || '';
                  arr[i].lastname = row.inv_lastname || '';
                  arr[i].title = row.inv_title || '';
                  arr[i].phone_number = row.inv_phone || '';
                }
                if ($.isFunction(callback)) callback(arr);
              }
            }, errorHandler);
        });
      };

      Invite.update_status = function(inv_status, old_status, callback) {
        db.transaction(function(tx) {
          tx.executeSql(
            `UPDATE invitations_v3 
            SET 
              inv_status = ? 
            WHERE inv_status = ?`,
            [inv_status, old_status],
            function(tx, result) {
              if ($.isFunction(callback)) callback();
            },
            errorHandler);
        });
      };

      Invite.sendPromise = () => {
        return new Promise(
          (resolve, reject) => {
            Invite.send(resolve, reject);
          }
        );
      };

      // TODO: better use chaining here:::::::
      Invite.send = function(success_cb, error_cb) {
        const inviteInterval = () => {
          if (Invite.interval === '') {
            Invite.interval = setInterval(
              function () {
                // using setTimeout to prevent GUI blocking
                // -> TODO: and why not using webworker here?
                Invite.send();
              },
              20 * 1000
            );
          }
        };
        Usync.send_users(
          // success callback:
          function() {
            Invite.get_by_status(1, function(arr) {
              if (arr.length === 0) {
                return success_cb();
              } else {
                Invite.upload(arr, '/v3/invitations')
                  .then(
                    () => {
                      clearInterval(Invite.interval);
                      Invite.interval = '';
                      Invite.update_status(
                        2,
                        1,
                        () => {
                          if (logging) console.log('alle invitations nun auf 2');
                          if ($.isFunction(success_cb)) success_cb();
                        }
                      );
                      Family_relations.pull();
                    }
                  )
                  .catch(
                    /* error_callback:*/
                    () => {
                      inviteInterval();
                      console.error(
                        `error uploading Invitations -
                        missing token or wrong syntax or data`);
                    }
                  );
              }
            });
          },
          // errorCallback: start Interval mhe =>
          function() {
            inviteInterval();
          }
        );
      };

      Invite.select_emailcnt_by_userid = function(in_userid, cb_success) {
        db.transaction(function (tx) {
          tx.executeSql(
            'SELECT count(*) as cnt from invitations_v3 where inv_userid = ?',
            [in_userid], function(
              tx, result) {
              if (result !== null && result.rows !== null) {
                if (v.d) console.log(result.rows.item(0).cnt);
                if ($.isFunction(cb_success)) cb_success(result.rows.item(0).cnt);
              }
            }, errorHandler);
        });
      };

      Invite.get_by_email = function(email) {
        return query('SELECT * FROM invitations_v3 WHERE inv_mail = ?', [email])
          .then(Array.from)
          .then((result) => result.length && result[0]);
      };

      Invite.create = function(email, user_id) {
        if (!Invite.validate_email(email)) {
          return Promise.reject('Email invalid');
        }
        const sql = `INSERT INTO invitations_v3
                      (inv_mail, inv_userid, inv_status, created) VALUES (?, ?, 1, ?)`;
        const now = get_datetime_now();
        return query(sql, [email, user_id, now]);
      };

      Invite.change_status = function(inv_id, status) {
        const sql = 'UPDATE invitations_v3 SET inv_status = ? WHERE inv_id = ?';
        return query(sql, [status, inv_id]);
      };

      Invite.new = function(email, user_id) {
        return Invite.get_by_email(email)
          .then((inv) => {
            return inv ? Invite.change_status(inv.inv_id, 1) : Invite
              .create(email, user_id);
          })
          .then(Invite.sendPromise);
      };

      Invite.interval = '';
      Invite.running = false;
      Invite.upload = function(arr, Invite_url, httpMethod = 'POST') {
        return new Promise((resolve, reject) => {
          if (!Invite.running && thisapp_online) {
            // array2json in controller/statistics.js
            Invite.running = true;
            let data_json = array2json(arr);
            if (logging) console.log(data_json);

            let request_url = Invite.api_url + Invite_url;
            let settings = {
              'type': httpMethod,
              'data': {'data_json': data_json, 'token': Auth.token},
              'url': request_url,
              'xhrFields': {
                'withCredentials': false,
              },
              'headers': {'Token': Auth.token},
              'cache': false,
            };
            $.ajax(settings).done(
              function(data) {
                Invite.running = false;
                resolve();
              }
            ).fail(function(jqXHR, textStatus) {
              Invite.running = false;
              console.log('API request failed - error uploading Invitations');
              console.log(jqXHR, textStatus);
              reject(jqXHR);
            });
          } else {
            reject('Invite is already running');
          }
        });
      };

      Invite.interval_disabled = '';
      Invite.send_disabled_users = function() {
        return new Promise(
          (resolve, reject) => {
            Invite.get_by_status(3, function(arr) {
              if (arr.length === 0) {
                resolve();
              } else {
                Invite.upload(arr, '/v1/relation/delete', 'DELETE')
                  .then(
                    /* success_callback:*/
                    () => {
                      clearInterval(Invite.interval_disabled);
                      Invite.interval_disabled = '';
                      console.log(
                        'deactivated invitations successfully sent 2 customer_cloud queue'
                      );
                      Invite.update_status(4, 3, function() {
                        resolve();
                      });
                    })
                  .catch(
                    (xhr) => {
                      if (xhr.status === 400) {
                        const response = JSON.parse(xhr.responseText);
                        if (response.error && (response.error.code === 106)) {
                          Invite.update_status(4, 3, function() {
                            resolve();
                          });
                          return xhr;
                        }
                      }
                    }
                  );
              }
            });
          }
        );
      };

      Invite.delete = function(inv_userid, inv_email) {
        return new Promise((resolve, reject) => {
          db.transaction(
            function(tx) {
              tx.executeSql(
                `UPDATE
                  invitations_v3
                SET
                  inv_status = ?
                WHERE
                  inv_userid = ?
                  AND inv_mail = ? `,
                [3, inv_userid, inv_email],
                function(tx, res) {
                  if (res.rowsAffected) {
                    Invite.send_disabled_users()
                    resolve(true);
                  } else {
                    resolve(false);
                  }
                },
                (err) => reject(err)
              );
            },
            (err) => reject(err),
            nullHandler
          );
        });
      };

      Invite.send();
      // do we still like to do this: ?
      Invite.send_disabled_users();
      resolve();
    }
  );
};
