const createAuthModel = () => {
  return new Promise(
    (resolve, reject) => {
      //TODO: introduce state for Auth if log in was successful and so on mahe
      window.Auth = {};
      Auth.authenticate_url = 'https://authd.media4care.com/device/authenticate';
      Auth.token = '';
      Auth.secret = '';
      v.auth = true;
      // make sure to refersh Auth.token on startup:
      // Auth.refreshed = new Date('1995-12-17T03:24:00');
      Auth.refreshed = 1435636576;
      Auth.try_conn = 0;
      Auth.checkPromise = () => {
        return new Promise(
          (resolve, reject) => {
            Auth.check(resolve, reject);
          }
        );
      }
      Auth.check = function(success_cb, error_cb) {
        // Notify MSG_SYNC_LOG-API that
        msg_sync_log.last_online(
          Auth.device_id.toString(),
          api_endpoints.msg_sync_last_online
        );
        let requestSecret = () => {
          Auth.register(
            () => {
              Auth.authenticate(
                () => {
                  if (v.auth) console.log('authentification successful');
                  if ($.isFunction(success_cb)) success_cb();
                },
                () => {
                  if ($.isFunction(error_cb)) error_cb();
                }
              );
            },
            error_cb,
            0
          );
        };
        // load secret from settings:
        Settings.select('secret')
          .then(
            (secret) => {
              console.log('da secret:');
              console.log(secret);
              if (secret.length > 0) {
                Auth.secret = secret;
                Auth.authenticate(
                  () => {
                    if (v.auth) console.log('authentification successful');
                    if ($.isFunction(success_cb)) success_cb();
                  },
                  () => {
                    if (v.auth) console.log('authentification failed');
                    if ($.isFunction(error_cb)) error_cb();
                  }
                );
              } else {
                requestSecret();
              }
            }
          )
          .catch( (err) => {
            console.error(err);
            requestSecret();
          });
      };

      Auth.register = function (success_cb, error_cb, this_try_conn) {
        if (typeof this_try_conn === 'undefined') this_try_conn = 0;
        $.ajax(
          {
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify({
              'device_id': device.uuid,
              'secret_key': conf.secret_key
            }),
            url: api_endpoints.auth_register_url,
            cache: false,
            xhrFields: {
              withCredentials: false
            },
            timeout: conf.get_timeout,
            headers: {},
            success: function (secret_ob, textStatus, xhr) {
              console.log("XHR");
              console.log(xhr);
              console.log(xhr.status);
              console.log(secret_ob);
              if (
                (secret_ob !== 'error' || secret_ob !== '')
                && secret_ob.secret.length > 3
              ) {
                console.log('updateing secret!!!!');
                if (v.auth) console.log(secret_ob);
                Auth.secret = secret_ob.secret;
                Settings.insert_replace('secret', Auth.secret, success_cb);
              }
            },
            error: function () {
              if (this_try_conn < 4) {
                ++this_try_conn;
                setTimeout(() => {
                  // check if tab has wifi issues (medion):
                  // reenable_wifi(request_token, gt_callback);
                  Auth.register(success_cb, error_cb, this_try_conn);
                }, 1500);
              } else {
                if ($.isFunction(error_cb)) error_cb();
                if (v.auth)
                  console.error('Auth.register request failed with 4 attempts');
              }
            }
          }
        );
      };
      Auth.authenticate = function (success_cb, error_cb, this_try_conn) {
        if (v.auth) console.log('AUTHENTICATING....');
        if (typeof this_try_conn == 'undefined') this_try_conn = 0;
        // check if token.hash needs to be refreshed at least every 5h
        // time difference in ms: 2017_05 mhe:
        if (Date.now() - Auth.refreshed > (5 * 3600 * 1000)) {
          $.ajax({
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify({
              'device_id': device.uuid,
              'secret': Auth.secret
            }),
            url: api_endpoints.auth_authenticate_url,
            cache: false,
            xhrFields: {
              withCredentials: false,
            },
            timeout: conf.get_timeout,
            headers: {},
            success: function (token_ob, textStatus, xhr) {
              if (token_ob !== 'error' || avent !== '') {
                Auth.token = token_ob.token;
                Auth.refreshed = Date.now();
                if ($.isFunction(success_cb)) success_cb();
              }
            },
            error: function (xhr, status, error) {
              if (xhr.status === 401) {
                Auth.register(function () {
                  Auth.authenticate(function() {
                    if (v.auth) console.log('authentification successful');
                    if ($.isFunction(success_cb)) success_cb();
                  },
                  function () {
                    if ($.isFunction(error_cb)) error_cb();
                  });
                },
                error_cb,
                0);
              } else {
                if (this_try_conn < 4) {
                  ++this_try_conn;
                  setTimeout(function () {
                    // check if tab has wifi issues (medion):
                    // reenable_wifi(request_token, gt_callback);
                    Auth.authenticate(success_cb, error_cb, this_try_conn);
                  }, 1500);
                } else {
                  if (v.auth)
                    console.error('Auth.authenticate request failed with 4 attempts');
                  if ($.isFunction(error_cb)) error_cb();
                }
              }
            },
          });
        } else {
          if ($.isFunction(success_cb)) success_cb();
        }
      };
      Auth.device_id = 0;
      Auth.verifyPromise = () => {
        return new Promise(
          (resolve, reject) => {
            Auth.verify(resolve, reject);
          }
        );
      };

      Auth.verify = function(success_cb, error_cb) {
        if (thisapp_online && Auth.token.length) {
          $.ajax(
            {
              type: 'POST',
              contentType: 'application/json',
              data: JSON.stringify({}),
              url: api_endpoints.auth_verify,
              cache: false,
              xhrFields: {
                withCredentials: false,
              },
              timeout: conf.get_timeout,
              headers: {'Token': Auth.token},
              success: function (resp, textStatus, xhr) {
                if (v.auth) console.log(resp);
                if (resp !== 'error' || resp !== '') {
                  var data = JSON.parse(resp);
                  if (v.d) console.warn(data);
                  Auth.device_id = data.id;
                  if ($.isFunction(success_cb)) {
                    success_cb();
                  }
                }
              },
              error: function () {
                if (try_conn < 4) {
                  ++try_conn;
                  setTimeout(
                    function () {
                      // check if tab has wifi issues (medion):
                      // reenable_wifi(request_token, gt_callback);
                      Auth.verify(success_cb, error_cb);
                    },
                    1500
                  );
                } else {
                  if (v.auth) {
                    console.error('Auth.verify request failed with 4 attempts');
                  }
                  if ($.isFunction(error_cb)) error_cb();
                }
              },
            }
          );
        } else {
          resolve();
        }
      };

      resolve();
    }
  );
};
