const MEDIATYPE_BOOK = 'book';
const MEDIATYPE_GAME = 'game';
const MEDIATYPE_QUIZ = 'quiz';
const MEDIATYPE_VIDEO = 'video';

const MEDIATYPEID_LIST62 = 'list62';

const MEDIA_CONSTANTS = Object.freeze(
  {
    ACCESS: {
      'PUBLIC': 'public',
      'PRIVATE': 'private',
    },
    FILE_CHECK: {
      'DONE': 3,
      'TODO': 1,
    },
  }
);

function Media() {
  this.get_by_mediaid = function(mediaid, cb) {
    db.transaction(function(tx) {
      tx.executeSql(
        'SELECT * FROM Media where mediaid = ?;',
        [ mediaid ],
        function(transaction, result) {
          if (result !== null && result.rows !== null) {
            if ($.isFunction(cb)) {
              cb(result.rows.item(0));
            }
          }
        },
        errorHandler);
    },
    errorHandler,
    nullHandler);
  };
  this.video = function() {
    return Object.freeze({
      enable: function(videoUrl, mediaId) {
        return new Promise(
          (resolve, reject) => {
            db.transaction(
              (tx) => {
                tx.executeSql(
                  `UPDATE Media SET 
                  name = ?, file_check = ?, access = ?
                  WHERE mediaid = ?`,
                  [
                    videoUrl,
                    MEDIA_CONSTANTS.FILE_CHECK.DONE,
                    MEDIA_CONSTANTS.ACCESS.PUBLIC,
                    mediaId
                  ],
                  resolve,
                  reject
                );
              }
            );
          }
        );
      },
      disable: function(mediaId) {
        return new Promise(
          (resolve, reject) => {
            db.transaction(
              (tx) => {
                tx.executeSql(
                  `UPDATE Media SET 
                  file_check = ?, access = ?
                  WHERE mediaid = ?`,
                  [
                    MEDIA_CONSTANTS.FILE_CHECK.TODO,
                    MEDIA_CONSTANTS.ACCESS.PRIVATE,
                    mediaId
                  ],
                  resolve,
                  reject
                );
              }
            );
          }
        );
      },
      getUncheckedUrls: function() {
        return new Promise(
          (resolve, reject) => {
            db.transaction(
              (tx) => {
                tx.executeSql(
                  `SELECT mediaid, name FROM Media
                    WHERE type = ? AND is_mother = 0 AND file_check != ?;`,
                  [MEDIATYPE_VIDEO, MEDIA_CONSTANTS.FILE_CHECK.DONE],
                  (transaction, result) => {
                    let res = [];
                    if (result !== null && result.rows !== null) {
                      for (let i = 0; i < result.rows.length; i++) {
                        res = [...res, ...[result.rows.item(i)]];
                      }
                      resolve(res);
                    }
                  },
                  reject
                );
              }
            );
          }
        );
      },
    });
  };
}

Media.getVideoPath = function(path) {
  return path.includes('file:') ? path : `${local_dir}/videos/${path}`;
}

Media.getListByMotherId = function getListByMotherId(mother_id) {
  const cache_target = `make-sublist-${mother_id}`;
  const cache_content = cache.get(cache_target);
  let m_status = '';

  if (!parseInt(sessionStorage.wascat)) {
    m_status = ' and m.status = \'enabled\' ';
  }

  const selector = `
      SELECT
          m.mediaid,
          m.name,
          m.duration,
          m.preview_image,
          m.has_mother,
          m.is_mother,
          m.type,
          m_t.title,
          m_t2.title AS title_native,
          m_t.sort_id,
          m_t2.sort_id AS sort_id_native
      FROM
          Media m
      LEFT JOIN media_trans m_t ON
          m_t.media_id = m.mediaid
          AND m_t.language = '${lang}'
      LEFT JOIN media_trans m_t2 ON
          m_t2.media_id = m.mediaid
          AND m_t2.language = '${nat_lang}'
      WHERE
          m.has_mother = ${mother_id}
          AND(
              (
                  m.access = 'public' ${m_status}
              )
              OR m.media_type_id = 80
              OR m.has_mother = 1813
              OR m.media_type_id = 81
          )
      ORDER BY
          CASE
              WHEN m_t.sort_id IS NOT NULL THEN m_t.sort_id
              ELSE m_t2.sort_id
          END,
          CASE
              WHEN m_t.title IS NOT NULL THEN LTRIM(m_t.title,'&')
              ELSE LTRIM(m_t2.title,'&')
          END ASC;
  `;

  if (cache_content) {
    return Promise.resolve(JSON.parse(cache_content));
  }

  return query(selector)
    .then((iterator) => {
      const items = [...iterator];
      cache.set(cache_target, JSON.stringify(items));
      return items;
    });
}

/* get_video_url returns the url which is stored in the name column */
function get_video_url(mediaid, cb) {
  db.transaction(
    (tx) => {
      tx.executeSql(
        'SELECT name FROM Media where mediaid = ?;',
        [mediaid],
        function(transaction, result) {
          if (result !== null && result.rows !== null) {
            if($.isFunction(cb)) {
              cb( result.rows.item(0).name );
            }
          }
        },
        errorHandler
      );
    },
    errorHandler,
    nullHandler
  );
}

function buildListItem(media, index) {
  let url;

  if (['book', 'quiz', 'calendar'].includes(media.type)) {
    url = media.mediaid;
  }

  if (media.type === 'game') {
    url = media.name;
  }

  if (media.type === 'video') {
    url = Media.getVideoPath(media.name);
  }

  return `<li 
              class="grid-wrapper sublistclick"
              data-mediaid="${media.mediaid}"
              data-is-mother="${media.is_mother}"
              data-has-mother="${media.has_mother}"
              data-media-type="${media.type}"
              data-video-url="${url}"
              data-role="button"
              >
              ${(media.type === 'video') ? buildVideoItem(item, index) : buildImageItem(item, index)}
           </li>`;
}

function buildImageItem(item, index) {
  const preview = `${local_dir}img/fotobuecher/prev/${preview_image}`;

  return `
          <img class="videoElement" src="${preview}" align="left" />
          <span class="media_title" id="media_title_${index}">
            ${item.title || item.title_native}
          </span>
          `;
}

function buildVideoItem(item, index) {
  const preview = `${local_dir}img/fotobuecher/prev/${preview_image}`;
  const mm = Math.floor(item.duration / 60);
  const ss = item.duration % 60;

  return `<div style="padding:15px;padding-bottom:0px;">
            <img class="videoElement" src="${preview}" align="left" />
            <img class="videoElement playElement"
                 src="./img/ico/play_300x300.png" align="left" />
            <span class="media_title" id="media_title_${index}">
              ${item.title || item.title_native}
            </span>
          </div>
          <div class="grid-wrapper-half-bottom">
            <span class="additional_info">
              ${mm}:${ss}
            </span>
          </div>`;
}
