window.updateApp;
window.avail_v = {};
const createUpdateApp = () => {
  return new Promise(
    (resolveCreate, rejectCreate) => {
      window.updateApp = new function() {
        const download_app = (
          resolve,
          reject,
          filename,
          cbMissingPermission = () => {}
        ) => {
          let ft_app = new FileTransfer();
          let starttime = new Date().getTime();
          let last_progress = 0;
          ft_app.onprogress = function(progressEvent) {
            if (progressEvent.lengthComputable) {
              let download_progress = Math.floor(
                progressEvent.loaded / progressEvent.total * 100
              );
              if (download_progress !== last_progress) {
                last_progress = download_progress;
                setTimeout(function() {
                  let istime = new Date().getTime();
                  let dtime = istime - starttime;

                  if (download_progress < 1) {
                    download_progress = 0.1;
                  }

                  let time_left = '';
                  if (istime === dtime) {
                    time_left = 'weniger als 1 Minute';
                  } else {
                    const time_needed = Math.floor(
                      (100 - download_progress) * dtime
                      /
                      (download_progress * 1000)
                    );
                    let time_n_min = Math.floor(time_needed / 60);
                    let time_n_sec = Math.floor(time_needed % 60);

                    if (time_n_min < 1) {
                      time_n_min = '0';
                    }

                    if (time_n_sec < 1) {
                      time_n_sec = '00';
                    } else if (time_n_sec < 10) {
                      time_n_sec = '0' + time_n_sec;
                    }

                    time_left = time_n_min + ':' + time_n_sec;
                  }
                  controllers.home.bootscreen.render(
                    `wird aktualisiert`,
                    {
                      show: true,
                      overallProgress: download_progress,
                      timeLeft: time_left,
                      fileDisplay: 'Media4Care Anwendung',
                    }
                  );
                }, 0);
              }
            }
          };

          // GET MODEL TO KNOW PLATFORM TYPE
          window.device_platform = '';
          window.app_dest_url = '';
          // TODO might be better to just use app's data directory,
          // since it is android version independent[no external opening
          // from within different app dir allowed!!!]:

          let update_filename = 'm4c_update.apk';

          if (device.version >= '6.0' || device.model === 'A3-A40') {
            app_dest_url = 'file:///sdcard/Download/' + update_filename;
          } else if (
            (device.model === 'Lenovo TB2-X30L' && device.version === '5.1.1')
            || device.model === 'Lenovo YT3-X50L'
          ) {
            app_dest_url = 'file:///sdcard/Download/' + update_filename;
          } else if (device.version < '5.1' || device.model === 'P1050X') {
            app_dest_url = 'file:///storage/sdcard0/Download/' + update_filename;
          } else {
            app_dest_url = 'file:///storage/sdcard1/Download/' + update_filename;
          }

          // change url.apk_update_url to cust_url (when saved in filenametings)
          if (filename && filename.indexOf('.apk') > 0) {
            // change URL to custom entry
            url.apk_update_url = 'http://www.mediadementia.de/' + filename;
          }
          ft_app.download(
            url.apk_update_url,
            app_dest_url,
            function(entry) {
              cordova.plugins.fileOpener2.open(
                app_dest_url,
                'application/vnd.android.package-archive',
                {
                  error: function(err) {
                    if (v.d) console.error('error opening update apk');
                    if (v.d) console.error(err);
                    download_app(filename);
                  },
                  success: function() {
                    Settings.update('update','0', navigator.app.exitApp);
                  },
                });
            },
            // added error handling for apk download 2016_10_17 MHe:
            function (err) {
              if (v.d) console.log('Error APK Download');
              if (v.d) console.dir(err);
              $('#home')
                .html(`APK kann nicht abgespeichert werden,
                bitte überprüfen Sie die Speicherberechtigungen
                unter Apps -> media4care -> Berechtigungen`);
              // no timeout let to no screen refresh /
              // proper loading of home.html...
              setTimeout(() => {
                cbMissingPermission();
              }, 4000);
            }
          );
        };

        const execCheckVersion = (
          resolve,
          reject,
          versions_conn_try
        ) => {
          $.ajax(
            {
              type: 'GET',
              data: {
                uuid: device.uuid,
              },
              url: api_endpoints.versions,
              xhrFields: {
                withCredentials: false,
              },
              headers: {},
              cache: false,
              success: (versions) => {
                avail_v = JSON.parse(versions);
                console.log('available versions', avail_v);
                if (avail_v.app >= app_core_version) {
                  // is set in controller settings_update
                  Settings.select('update')
                    .then(
                      (updateSetting) => {
                        if (
                          updateSetting !== '0'
                          && avail_v.app > app_core_version
                        ) {
                          if (is_app === -1) {
                            resolve(avail_v);
                          } else {
                            return download_app(resolve, reject, updateSetting);
                          }
                        } else {
                          // no newer version available,
                          // deactivate apk update checking:
                          Settings.update(
                            'update',
                            '0',
                            () => {
                              resolve(avail_v);
                            }
                          );
                        }
                      }
                    );
                } else {
                  resolve(avail_v);
                }
              },
              error: (err) => {
                console.log('error gettings versions', err);
                if (versions_conn_try < 4) {
                  setTimeout(
                    () => {
                      versions_conn_try++;
                      return execCheckVersion(
                        resolve,
                        reject,
                        versions_conn_try
                      );
                    },
                    1500
                  );
                } else {
                  avail_v.app = '-';
                  avail_v.content = '-';
                  resolve(avail_v);
                }
              },
            }
          );
        };

        this.check_version = () => {
          /* check available Application Versions: 2016-03-15 MHe*/
          /* GET AVAILABLE VERSIONS */
          return new Promise(
            (resolve, reject) => {
              if (!thisapp_online) {
                resolve();
              } else {
                execCheckVersion(resolve, reject, 0);
              }
            }
          );
        };
      };

      resolveCreate();
    }
  );
};
