const state = {
  Family_portal_register_direct:
    {
      running: false,
    },
  icon_strings:
    {
      wifi: `<img
        src="./img/ico/wifi_symbol_white.svg"
        style="height:15px;width:15px;margin-top:-4px;margin-left:8px;" />`,
    },
  connected:
    {
      generic: false,
      generic_comment: 'shows if any connection exists',
      wifi: false,
      mobile: false,
    },
};

const stateUpdate = (stateChanges) => {
  state = Object.assign(state, stateChanges);
};
