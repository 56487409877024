/*
 * FAST LANGUAGE SWITCHING BUTTONS for 24h utilization mode 24
 * 2017_09_13_mhe
 */
const fast_lang_switch_creator = () => {
  return new Promise(
    (resolve, reject) => {
      window.fast_lang_switch = {};

      fast_lang_switch.bind_fast_switch_lang_btns = function () {
        if (utilization === 24) {

          if (v.d) console.log("BIND FAST SWITCH LANG BTNS:\n" + this.activePage);
          $('.btn_lang_de').off().on(clickhandler_prev, function (e) {
            e.preventDefault();
            e.stopPropagation();
            lang = "de";
            fast_lang_switch.getLanguageArray(lang, function () {
              fast_lang_switch.show_btns();
              $('#' + $.mobile.activePage.attr('id')).trigger("pagebeforeshow");
            });
          });
          $('.btn_lang_pl').off().on(clickhandler_prev, function (e) {
            e.preventDefault();
            e.stopPropagation();
            lang = "pl";
            fast_lang_switch.getLanguageArray(lang, function () {
              fast_lang_switch.show_btns();
              $('#' + $.mobile.activePage.attr('id')).trigger("pagebeforeshow");
            });
          });
        }
      };

      fast_lang_switch.show_btns = function () {
        if (v.d) console.log("°º¤ø,¸¸,ø¤º°`°º¤ø,¸,ø¤°º¤ø,¸¸,ø¤º°`°º¤ø,¸ SHOW FAST_LANG_SWITCH BTNS");
        $('.hilfe_home').attr('style', 'display:none;');

        this.activePage = $.mobile.activePage.attr('id');

        if (v.d) console.log(`♪┏(°.°)┛┗(°.°)┓┗(°.°)┛┏(°.°)┓ ♪ ${this.activePage}`);
        //if(typeof $('#lang_btns_wrap') !== "undefined"){

        if ($(`#${this.activePage}`).find(`#lang_btns_wrap`).length > 0) {

          $(`#${this.activePage}`).find(`#lang_btns_wrap`).attr('style', 'display:block;');
          if (lang === "de") {
            $(`#${this.activePage}`).find('.btn_lang_pl').attr('style', '$margin-left:20px; border-color: rgba(218,218,217,1);display:block;');
            $(`#${this.activePage}`).find('.btn_lang_de').attr('style', '$margin-left:20px; border-color: rgba(218,218,217,1);display:none;');
          } else if (lang === "pl") {
            $(`#${this.activePage}`).find('.btn_lang_de').attr('style', '$margin-left:20px; border-color: rgba(218,218,217,1);display:block;');
            $(`#${this.activePage}`).find('.btn_lang_pl').attr('style', '$margin-left:20px; border-color: rgba(218,218,217,1);display:none;');
          }

        } else {

          $(`#${this.activePage}`).find(`.ui-header`)
            .append(`<div style="display:true;" id="lang_btns_wrap">
                            <div class="btn_lang_de" style="margin-left:20px; border-color: rgba(218,218,217,1);">&nbsp;</div>
                            <div class="btn_lang_pl" style="margin-left:20px; border-color: rgba(218,218,217,1);">&nbsp;</div>
                        </div>`);
          if (lang === "de") {
            $('.btn_lang_pl').attr('style', '$margin-left:20px; border-color: rgba(218,218,217,1);display:block;');
            $('.btn_lang_de').attr('style', '$margin-left:20px; border-color: rgba(218,218,217,1);display:none;');
          } else if (lang === "pl") {
            $('.btn_lang_de').attr('style', '$margin-left:20px; border-color: rgba(218,218,217,1);display:block;');
            $('.btn_lang_pl').attr('style', '$margin-left:20px; border-color: rgba(218,218,217,1);display:none;');
          }

        }
      };

      fast_lang_switch.getLanguageArray = function (new_lang, success_cb, error_cb) {
        db.transaction(function (transaction) {

          transaction.executeSql('select id, ' + new_lang + ' from translations;', [],

            function (transaction, result) {
              if (result !== null && result.rows !== null) {
                //if(logging)console.log(result.rows);
                for (i = 0; i < result.rows.length; i += 1) {
                  //if(logging)console.log(result.rows.item(i).id);
                  strings[parseInt(result.rows.item(i).id) - 1] = result.rows.item(i)[new_lang];
                }
                //emptyStorageCache();
                cache.reset();
                if (typeof success_cb === "function") success_cb();
              }
            });
        });
      };

      fast_lang_switch.init = () => {
        /* START
        fast switching language button insteead of help in header 2017_02_10 Mhe:*/
        if (utilization === 24) {
          $('.hilfe_home').attr('style', 'display:none;');

          fast_lang_switch.show_btns();
          fast_lang_switch.bind_fast_switch_lang_btns();
        }
        resolve();
        /*END
        fast switching language button
        insteead of help in header 2017_02_10 Mhe:*/
      };
      fast_lang_switch.init();
    }
  );
};
