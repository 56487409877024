/* jshint esversion: 6 */
const createFamilyRelationsModel = () => {
  return new Promise(
    (resolve, reject) => {
      window.Family_relations =
        {
          /**
           * This enumeration defines the possible states of an relation and
           * are used for the `status` column of `Family_relations` table.
           */
          StateEnum: Object.freeze({
            'invitationPending': 1, // currently not used
            'invitationSent': 2,
            'invitationRemovedPending': 3, // currently not used
            'invitationRemovedSent': 4, // currently not used
            'active': 5,
            'relationDeletedPending': 6, // currently not used
            'relationDeletedSent': 7, // currently not used
            'relationDeletionReceived': 8, // currently not used
            'relationDeleted': 9,
            'invitationFailed': 10,
          }),

          init: function() {
            helper_db.get_table_version('Family_relations').then(
              function(version) {
                let TARGET_VERSION = 3;
                if (version === 0) {
                  db.executeSql(
                    `CREATE TABLE IF NOT EXISTS Family_relations
                    (
                      id INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
                      cust_id INT NOT NULL,
                      prof_id INT NOT NULL,
                      email TEXT NOT NULL,
                      prename TEXT,
                      lastname TEXT,
                      last_edit TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
                      date_loadHistory_RC TIMESTAMP,
                      status INT,
                      unique(cust_id,prof_id),
                      FOREIGN KEY(prof_id) REFERENCES User(prof_id)
                    )`,
                    [],
                    function (tx, res) {
                      helper_db.set_table_version(
                        'Family_relations',
                        TARGET_VERSION,
                        'Added column for date_loadHistory_RC'
                      );
                      resolve();
                    },
                    reject
                  );
                } else if (version === 1) {
                  if (db.hasOwnProperty('sqlBatch')) {
                    db.sqlBatch(
                      [
                        'ALTER TABLE Family_relations ADD date_loadHistory_RC TIMESTAMP',
                        'ALTER TABLE Family_relations ADD status TEXT',
                        `UPDATE Family_relations SET status=${Family_relations.StateEnum.active}`,
                      ],
                      (res) => {
                        helper_db.set_table_version(
                          'Family_relations',
                          TARGET_VERSION,
                          'Added column for date_loadHistory_RC and status'
                        );
                        console.error("to resolve 2");
                        resolve();
                      },
                      reject
                    );
                  } else {
                    db.executeSql(
                      `ALTER TABLE Family_relations 
                        ADD date_loadHistory_RC TIMESTAMP;`,
                      [],
                      db.executeSql(
                        `ALTER TABLE Family_relations
                        ADD status TEXT;`,
                        [],
                        db.executeSql(
                          `UPDATE Family_relations
                            SET status=?;`,
                          [Family_relations.StateEnum.active],
                          helper_db.set_table_version(
                            'Family_relations',
                            TARGET_VERSION,
                            'Added column for date_loadHistory_RC and status'
                          ),
                          errorHandler
                        ),
                        errorHandler
                      ),
                      errorHandler
                    );
                  }
                } else if (version === 2) {
                  if (db.sqlBatch) {
                    db.sqlBatch(
                      [
                        'ALTER TABLE Family_relations ADD status TEXT',
                        `UPDATE Family_relations SET status=${Family_relations.StateEnum.active}`,
                      ],
                      (res) => {
                        helper_db.set_table_version(
                          'Family_relations',
                          TARGET_VERSION,
                          'Added column for date_loadHistory_RC and status'
                        );
                        console.error("to resolve 3");
                        resolve();
                      },
                      reject
                    );
                  } else {
                    db.executeSql(
                      `ALTER TABLE Family_relations
                        ADD status TEXT;`,
                      [],
                      db.executeSql(
                        `UPDATE Family_relations
                          SET status=?`,
                        [Family_relations.StateEnum.active],
                        helper_db.set_table_version(
                          'Family_relations',
                          TARGET_VERSION,
                          'Added column for date_loadHistory_RC and status'
                        ),
                        errorHandler
                      ),
                      errorHandler
                    );
                  }
                } else {
                  // for newer version you can add more else if cases.
                  // than please update the helper_db
                  resolve();
                }
              }
            );
            /**
             * FamilyRelation, which defines a relation between a senior and a customer.
             * @typedef {Object} FamilyRelation
             * @property {number} id - Id of the family relation in the local
             * Family_relations table.
             * @property {number} cust_id - The id of the customer, normally you can
             *  can think of this as the junior.
             * @property {number} prof_id - The id of the profile used on the tablet.
             * @property {string} email - The email of the customer.
             * @property (string} prename - First name of the customer.
             * @property {string} lastname - Last name of the customer.
             * @property {string} last_edit - Timestamp of the time at which the entry
             * was last changed.
             * @property {string} date_loadHistory_RC - Time stamp of the time at which
             * the conversation of this relation between customer and profile was
             * fetched from rocket.Chat.
             */
          },

          /**
           * Delete a family relation of the current user/profile by a given cust_id.
           *
           * This deletes just the row in the family_relations table, not the data
           * connected to this relation.
           * @param {number} cust_id - The id of the customer
           * @param {Function} cb_success - Function to be executed if execution was
           * successful.
           * @param {Function} cb_error - Function to be executed if error occurs.
           */
          delete_by_cust_id: function (
            cust_id,
            cb_success = helper_db.default_success,
            cb_error = errorHandler
          ) {
            if (parseInt(cust_id) > 0) {
              // 1. grab array:
              Family_relations.select_by_cust_id(
                cust_id,
                /* success callback of Family_relations.select_by_cust_id: */
                // 2. pass to Invite-upload:
                function (arr) {
                  let l_arr = {
                    cust_id: arr[0].cust_id,
                    inv_mail: arr[0].email,
                    prof_id: arr[0].prof_id,
                    user_id: userid,
                  };
                  console.log(l_arr);
                  Invite.upload(
                    [l_arr],
                    '/v1/relation/delete'
                  ).then(
                    /* success_callback of Invite.upload: */
                    function () {
                      clearInterval(Invite.interval_disabled);
                      Invite.interval_disabled = '';
                      console.log(
                        'removed Family_relation successfully ' +
                        'sent 2 customer_cloud queue'
                      );
                      // 3. delete from db
                      db.executeSql(
                        `DELETE
                      FROM
                        Family_relations
                      WHERE
                        cust_id = ?`,
                        [cust_id],
                        function (r) {
                          let res = [];
                          for (let i = 0; i < r.rows.length; i++) {
                            res[i] = r.rows.item(i);
                          }
                          if ($.isFunction(cb_success)) {
                            cb_success(res);
                          } else {
                            cb_error('success callback not valid func');
                          }
                        },
                        function (err) {
                          if ($.isFunction(cb_error)) {
                            cb_error(err);
                          }
                        }
                      );
                    }).catch(
                    /* error_callback of Invite.upload :*/
                    function () {
                      if (Invite.interval_disabled === '') {
                        Invite.interval_disabled = setInterval(
                          function () {
                            if (thisapp_online) {
                              Family_relations.delete_by_cust_id(cust_id);
                            }
                          }, 1 * 1000);
                      }
                    }
                  );
                }
              );
            } else {
              console.error('the passed cust_id must be a positive Integer');
              cb_error();
            }
          },

          /**
           * Delete family relation by given id.
           * @param {number} id - Id of the relation to be deleted.
           * @return {Promise<number>} - Returns the count
           */
          deleteById: function (id) {
            return new Promise((resolve, reject) => {
              db.transaction(
                function (tx) {
                  tx.executeSql(
                    `DELETE
                    FROM
                      Family_relations
                    WHERE
                      id = ?`,
                    [id],
                    function (tx, res) {
                      resolve(res.rowsAffected);
                    },
                    (err) => reject(err)
                  );
                },
                (err) => reject(err),
                nullHandler
              );
            });
          },

          /**
           * Deletes family relation with the given id, including all data connected
           * to this relation.
           *
           * This contains among other things photos and messages which were sent
           * to the profile.
           * @param {number} id - The Id of the profile relation to delete.
           * @param {boolean} [deleteRelation=true] - If true also the entry in
           * the `Family_relations` table will be removed.
           * @return {Promise<any>}
           */
          deleteConnectedData: function(id, deleteRelation = true) {
            return new Promise((resolve, reject) => {
              Family_relations.selectById(id).then(
                (familyRelation) => {
                  // delete all Messages from that account:
                  Messages.delete(familyRelation.cust_id);

                  if (deleteRelation) {
                    // delete Family_relation:
                    Family_relations.deleteById(familyRelation.id);
                  } else {
                    // Set status of relation to delete. This is used for the view
                    // of "Persönliche Einladungen"
                    Family_relations.setStatus(
                      id,
                      Family_relations.StateEnum.relationDeleted
                    );
                  }

                  // delete from Family_portal_register_direct (no cust_id):
                  let fprd = new Family_portal_register_direct();
                  fprd.delete_by_mail(familyRelation.email);
                  resolve();
                }
              );
            });
          },

          /**
           * Set status of family relation.
           * @param {number} id - The id of the relation.
           * @param {Family_relations.StateEnum} status - Status to set.
           * @return {Promise<any>}
           */
          setStatus: function (id, status) {
            return new Promise((resolve, reject) => {
              db.transaction(
                function (tx) {
                  tx.executeSql(
                    `UPDATE
                    Family_relations
                  SET
                    status = ?
                  where
                    id = ?`,
                    [status, id],
                    resolve,
                    reject
                  );
                },
                reject,
                nullHandler
              );
            });
          },

          /**
           * Return all family relations stored on the tablet
           * i.e. in the Family_relations table.
           * @return {Promise<FamilyRelation[]>} An array of all family relations.
           */
          selectAll: function () {
            return new Promise((resolve, reject) => {
              db.executeSql(
                `SELECT * FROM Family_relations`,
                [],
                (res) => {
                  let ret = [];
                  for (let i = 0; i < res.rows.length; i++) {
                    ret.push(res.rows.item(i));
                  }
                  resolve(ret);
                },
                (err) => reject(err)
              );
            });
          },

          /**
           * Return family relation of the given id.
           * @param {number} id - id of the family relation.
           * @return {Promise<FamilyRelation>} A family relations.
           */
          selectById: function (id) {
            return new Promise((resolve, reject) => {
              db.executeSql(
                `SELECT * FROM Family_relations where id = ?`,
                [id],
                (res) => {
                  resolve(res.rows.item(0));
                },
                (err) => reject(err)
              );
            });
          },

          /**
           * Return family relation between profile (with prof_id) and customer (with
           * cust_id).
           *
           * @param {number} prof_id - Profile ID ot the relation.
           * @param {number} cust_id - Customer ID ot the relation.
           * @return {Promise<FamilyRelation>} A family relations.
           */
          selectByProfIdCustID: function (prof_id, cust_id) {
            return new Promise((resolve, reject) => {
              db.executeSql(
                `SELECT * FROM Family_relations where prof_id = ? and cust_id = ?`,
                [prof_id, cust_id],
                (res) => {
                  resolve(res.rows.item(0));
                },
                (err) => reject(err)
              );
            });
          },

          select_by_cust_id: function (
            this_cust_id,
            cb_success = helper_db.default_success,
            cb_error = errorHandler
          ) {
            if (parseInt(this_cust_id) > 0) {
              db.executeSql(
                `SELECT
                *
              FROM
                Family_relations
              WHERE
                cust_id = ?`,
                [this_cust_id],
                function (r) {
                  let res = [];
                  for (let i = 0; i < r.rows.length; i++) {
                    res[i] = r.rows.item(i);
                  }
                  if ($.isFunction(cb_success)) {
                    cb_success(res);
                  } else {
                    cb_error('success callback not valid func');
                  }
                },
                function (err) {
                  if ($.isFunction(cb_error)) {
                    cb_error(err);
                  }
                }
              );
            } else {
              console.error('the passed cust_id must be a positive Integer');
              cb_error();
            }
          },

          selectByProfId : function(this_prof_id = User.prof_id) {
            return new Promise(
              (resolve, reject) => {
                db.executeSql(
                  `SELECT
                    fr.id id,
                    fr.prename prename,
                    fr.lastname lastname,
                    fr.email email,
                    fr.prof_id prof_id,
                    fr.cust_id cust_id,
                    fr.last_edit last_edit,
                    5 as inv_status,
                    COUNT(distinct m.id) amount_unread,
                    COUNT(distinct m2.id) missed_calls
                     from Family_relations fr 
                     LEFT JOIN
                    Messages m
                    ON
                      m.cust_id = fr.cust_id
                    AND
                      m.receipient_account_id = ?
                    AND
                      m.status != 3
                    AND
                      m.content NOT LIKE "#TwVideo:%"
                  LEFT JOIN
                    Messages m2
                    ON
                      m2.cust_id = fr.cust_id
                    AND
                      m2.receipient_account_id = ?
                    AND
                      m2.status != 3
                    AND
                      m2.content LIKE "#TwVideo:%"
                  WHERE
                    fr.prof_id = ?
                  GROUP BY
                    fr.cust_id
                  ORDER BY
                    prename COLLATE NOCASE asc`,
                  [this_prof_id, this_prof_id, this_prof_id],
                  (r) => {
                    let res = [];
                    for (let i = 0; i < r.rows.length; i++) {
                      res[i] = r.rows.item(i);
                    }
                    resolve(res);
                  },
                  reject
                );
              }
            );
          },

          getActiveRelationsPromise: (thisProfId) => {
            return new Promise(
              (resolve, reject) => {
                Family_relations.getActiveRelations(
                  thisProfId,
                  resolve,
                  reject
                );
              }
            );
          },

          // TODO(peter): Check if this func can just be void,
          // if yes rm return stmnt.
          getActiveRelations: (this_prof_id, cb_success, cb_error) => {
            db.executeSql(
              `SELECT
              fr.id id,
              fr.prename prename,
              fr.lastname lastname,
              fr.email email,
              fr.prof_id prof_id,
              fr.cust_id cust_id,
              fr.last_edit last_edit,
              COUNT(distinct m.id) amount_unread,
              COUNT(distinct m2.id) missed_calls,
              CASE
                WHEN fr.status IS NOT NULL
                  THEN fr.status
                ELSE i.inv_status
              END inv_status
            FROM
              Family_relations fr
            LEFT JOIN
              Messages m
              ON
                m.cust_id = fr.cust_id
              AND
                m.receipient_account_id = ?
              AND
                m.status != 3
              AND
                m.content NOT LIKE "#TwVideo:%"
            LEFT JOIN
              Messages m2
              ON
                m2.cust_id = fr.cust_id
              AND
                m2.receipient_account_id = ?
              AND
                m2.status != 3
              AND
                m2.content LIKE "#TwVideo:%"
            LEFT JOIN
              invitations_v3 i
            ON
              i.inv_mail = fr.email
            WHERE
              fr.prof_id = ?
            AND
              fr.status = ${Family_relations.StateEnum.active}
            GROUP BY
              fr.cust_id
            ORDER BY
              prename COLLATE NOCASE asc
            `,
              [this_prof_id, this_prof_id, this_prof_id],
              (r) => {
                let res = [];
                for (let i = 0; i < r.rows.length; i++) {
                  res[i] = r.rows.item(i);
                }
                if ($.isFunction(cb_success)) {
                  cb_success(res);
                } else {
                  cb_error('success callback not valid func');
                }
              },
              (err) => {
                if ($.isFunction(cb_err)) {
                  cb_error(err);
                }
              }
            );
          },

          /**
           * extended select function.
           * it queries additional entries from Family_portal_register_direct table
           * (it is needed for displaying all connected relations
           * (e.g. on settings invitations page)
           * @param {number} l_prof_id from which all relations should be selected
           * @param {function} cb_success success callback
           * @param {function} cb_error error callback
           */
          select_extended_by_prof_id: (
            l_prof_id,
            cb_success,
            cb_error,
            order_by_prename = true
          ) => {
            let sql = `Select * from
            (SELECT
               null id,
               fprd.firstname prename,
               fprd.lastname lastname,
               fprd.mail email,
               fprd.prof_id,
               0 cust_id,
               CURRENT_TIMESTAMP last_edit,
               1 status,
               0 amount_unread,
               0 missed_calls,
               1 inv_status
            FROM Family_portal_register_direct fprd
            WHERE fprd.prof_id = ?
            AND fprd.mail
              NOT IN (
                SELECT
                  email
                FROM
                  Family_relations
                WHERE prof_id = ?)
            UNION
            SELECT
              fr.id id,
              fr.prename prename,
              fr.lastname lastname,
              fr.email email,
              fr.prof_id prof_id,
              fr.cust_id cust_id,
              fr.last_edit last_edit,
              fr.status status,
              COUNT(distinct m.id) amount_unread,
              COUNT(distinct m2.id) missed_calls,
              CASE
                WHEN fr.status IS NOT NULL
                  THEN fr.status
                ELSE i.inv_status
              END inv_status
            FROM
              Family_relations fr
            LEFT JOIN
              Messages m
              ON
                m.cust_id = fr.cust_id
              AND
                m.receipient_account_id = ?
              AND
                m.status != 3
              AND
                m.content NOT LIKE "#TwVideo:%"
            LEFT JOIN
              Messages m2
              ON
                m2.cust_id = fr.cust_id
              AND
                m2.receipient_account_id = ?
              AND
                m2.status != 3
              AND
                m2.content LIKE "#TwVideo:%"
            LEFT JOIN
              invitations_v3 i
            ON
              i.inv_mail = fr.email
            WHERE
              fr.prof_id = ?
            GROUP BY
              fr.cust_id)`;
            if (order_by_prename === true) {
              sql += `ORDER BY prename COLLATE NOCASE asc`;
            } else {
              sql += `ORDER BY email COLLATE NOCASE asc`;
            }

            return db.executeSql(
              sql,
              [l_prof_id, l_prof_id, l_prof_id, l_prof_id, l_prof_id],
              (r) => {
                let res = [];
                for (let i = 0; i < r.rows.length; i++) {
                  res[i] = r.rows.item(i);
                }
                if ($.isFunction(cb_success)) {
                  cb_success(res);
                } else {
                  cb_error('success callback not valid func');
                }
              },
              (err) => {
                if ($.isFunction(cb_err)) {
                  cb_error(err);
                }
              }
            );
          },

          /**
           * This will set the current time as the date of the last fetch of messages
           * between the current user (tablet) and the customer `cust_id`.
           * @param {number} cust_id - id of the customer,
           *  from which you fetched the messages
           * @return {Promise<any>}
           */
          update_date_loadHistory_RC: function(cust_id) {
            return new Promise((resolve, reject) => {
              db.transaction(
                function (tx) {
                  tx.executeSql(
                    `UPDATE
                    Family_relations
                  SET
                    date_loadHistory_RC = datetime('now')
                  WHERE
                    cust_id =?
                    and prof_id =?`,
                    [cust_id, User.prof_id],
                    resolve,
                    reject
                  );
                },
                reject,
                nullHandler
              );
            });
          },

          /**
           * This will get the date of the last fetch of messages
           * between the current user (tablet) and the customer `cust_id`.
           * @param {number} cust_id - id of the customer.
           * @return {Promise<String>} Timestamp of the last fetch.
           */
          get_date_loadHistory_RC: function (cust_id) {
            return new Promise((resolve, reject) => {
              db.transaction(
                function (tx) {
                  tx.executeSql(
                    `SELECT
                    date_loadHistory_RC
                  FROM
                    Family_relations
                  WHERE
                    cust_id =?
                    and prof_id =?`,
                    [cust_id, User.prof_id],
                    function(tx, res) {
                      if (res.rows.length < 1) {
                        reject;
                      } else {
                        resolve(res.rows.item(0).date_loadHistory_RC);
                      }
                    },
                    reject
                  );
                },
                reject,
                nullHandler
              );
            });
          },

          /**
           * Insert the customers, or if the customer assigned to the same profile,
           * the existing customer will be UPDATE.
           * @param {RelativesApiCustomer[]} customers - Customers for update or insert.
           * @return {Promise<Any[]>}
           */
          upsert: function (customers) {
            return new Promise((resolve, reject) => {
              const CUSTOMER_STATUS_REGISTERED = 3;
              const CUSTOMER_STATUS_DELETED = 4;
              const CUSTOMER_STATUS_INVITATION_FAILED = 20;
              if (customers.length < 1) {
                resolve('customers is empty.');
              }
              // Define SQL statement.
              // The extensively use CAST keyword is due to an issue with
              // Cordova-sqlite-storage plugin, where cust_id is sometime not inserted
              // as the right type.
              let sqlUpsert = `
                            Insert or replace into Family_relations
                              (
                                id,
                                cust_id,
                                prof_id,
                                email,
                                prename,
                                lastname,
                                date_loadHistory_RC,
                                status
                              )
                              values`;
              let customerValuesTemplate = `(
                            (Select id from Family_relations
                              where
                                (cust_id = CAST (? as INTEGER)
                                or
                                email = ?)
                              and
                                CAST(prof_id as INTEGER) = CAST (? as INTEGER)),
                            CAST(? as INTEGER),
                            CAST(? as INTEGER),
                            ?,
                            ?,
                            ?,
                            (Select date_loadHistory_RC from Family_relations
                              where
                                (cust_id = CAST (? as INTEGER)
                                or
                                email = ?)
                              and
                                CAST(prof_id as INTEGER) = CAST (? as INTEGER)),
                            ?
                          )`;
              let valuesString = '';
              for (let i = 0; i < customers.length; i++) {
                valuesString += customerValuesTemplate
                  + ((i === customers.length - 1) ? ';' : ',');
              }

              let sqlStatement = sqlUpsert + valuesString;

              // Define arguments for sql statement.
              let argsSqlStatment = [];
              for (let i = 0; i < customers.length; i++) {
                let prename = customers[i].prename;
                let lastname = customers[i].lastname;
                if (
                  typeof prename === 'undefined'
                  || prename === null
                ) {
                  prename = '';
                }
                if (
                  typeof lastname === 'undefined'
                  || lastname === null
                ) {
                  lastname = '';
                }
                let customerStatus;
                switch (customers[i].status) {
                  case CUSTOMER_STATUS_REGISTERED:
                    customerStatus = Family_relations.StateEnum.active;
                    break;
                  case CUSTOMER_STATUS_DELETED:
                    customerStatus = Family_relations.StateEnum.relationDeleted;
                    break;
                  case CUSTOMER_STATUS_INVITATION_FAILED:
                    customerStatus = Family_relations.StateEnum.invitationFailed;
                    break;
                  default:
                    customerStatus = Family_relations.StateEnum.invitationSent;
                }
                let curr_customers_cust_id = parseInt(customers[i].cust_id, 10);
                let curr_customers_prof_id = parseInt(customers[i].prof_id, 10);
                let customers_value = [
                  curr_customers_cust_id,
                  customers[i].email,
                  curr_customers_prof_id,
                  curr_customers_cust_id,
                  curr_customers_prof_id,
                  customers[i].email,
                  prename,
                  lastname,
                  curr_customers_cust_id,
                  customers[i].email,
                  curr_customers_prof_id,
                  customerStatus,
                ];
                argsSqlStatment = argsSqlStatment.concat(customers_value);
              }

              console.log('[Family_relations.upsert]', sqlStatement);
              console.log('[Family_relations.upsert]', argsSqlStatment);

              // Execute the defined SQL query with
              // the defined arguments argsSqlStatment.
              db.transaction(
                function (tx) {
                  tx.executeSql(
                    sqlStatement,
                    argsSqlStatment,
                    (res) => resolve(res),
                    (err) => reject(err)
                  );
                },
                (err) => reject(err),
                nullHandler
              );
            });
          },

          /**
           * Compare the local database with the customers and return the id of
           * the family relations in table `Family_relations`, which are not part of
           * `customers`.
           *
           * @param {RelativesApiCustomer[]} customers
           * @return {Promise<number[]>} Array of ids of Family_relations.
           */
          getObsoleteRelations: function (customers) {
            return new Promise(((resolve, reject) => {
              Family_relations.selectAll().then(
                (relations) => {
                  let removed_relations = [];

                  loop_relations:
                    for (let r = 0; r < relations.length; r++) {
                      for (let c = 0; c < customers.length; c++) {
                        if (
                          relations[r].cust_id === customers[c].cust_id &&
                          relations[r].prof_id === customers[c].prof_id
                        ) {
                          // Whe there is a customer equivalent to an relation than
                          // the push to removed_relations array will be omitted.
                          continue loop_relations;
                        }
                      }
                      removed_relations.push(relations[r].id);
                    }
                  resolve(removed_relations);
                }
              ).catch((err) => reject(err));
            }));
          },

          /**
           * Pulls the Family_relations of all profiles on the tablet from the
           * relatives_api.
           *
           * This function downloads all family relations from the relatives_api,
           * and aligns the local data of family relations to this. This means
           * this function can add new relations or if relation was removed in api
           * delete the data of this relation on tablet.
           * @return {Promise<void>}
           */
          pull: function() {
            if (thisapp_online) {
              return User.get_all_prof_ids()
                .then((prof_ids) =>
                  api_relatives.get_customers_by_prof_ids(prof_ids)
                )
                .then((customers) => Family_relations.upsert(customers)
                  .then(() => customers)
                )
                .then((customers) =>
                  Family_relations.getObsoleteRelations(customers)
                )
                .then(
                  (ids) => {
                    const delPromises = [];
                    ids.forEach((id) => {
                      const delPromise = Family_relations
                        .deleteConnectedData(id, false);
                      delPromises.push(delPromise);
                    });
                    return Promise.all(delPromises);
                  }
                );
            }
            return Promise.resolve();
          },

          /**
           *  Return the relation which is between the customer with the email
           *  `email_cust` and the profile with prof_id.
           * @param {string} email_cust -The email of the customer.
           * @param {number} [prof_id=User.prof_id] - The Profile id of the relation.
           * @return {Promise<RelativesApiCustomer>}
           */
          getRelationByEmail: function (email_cust, prof_id = User.prof_id) {
            return new Promise((resolve, reject) => {
              db.transaction(
                function (tx) {
                  tx.executeSql(
                    `SELECT *
                    FROM Family_relations
                    WHERE email =? and prof_id = ?`,
                    [email_cust, prof_id],
                    function (tx, res) {
                      resolve(res.rows.item(0));
                    },
                    (err) => reject(err)
                  );
                },
                (err) => reject(err),
                nullHandler
              );
            });
          },
          /**
           *  Return the relation which has the given status
           * @param {number} status - the status of the Family_relation.
           * @return {Promise<RelativesApiCustomer>}
           */
          getRelationsByStatus: function(status) {
            return new Promise((resolve, reject) => {
              db.transaction(
                function(tx) {
                  tx.executeSql(
                    `SELECT *
                    FROM Family_relations
                    WHERE status = ?`,
                    [status],
                    function(tx, r) {
                      let res = [];
                      for (let i = 0; i < r.rows.length; i++) {
                        res[i] = r.rows.item(i);
                      }
                      resolve(res);
                    },
                    reject
                  );
                },
                reject,
                nullHandler
              );
            });
          },
        };
      // resolve create Func in init:
      // init table:
      Family_relations.init();
    }
  );
};
