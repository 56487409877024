const createFamily_portal_register_direct = () => {
  return new Promise(
    (resolveCreate) => {
      window.Family_portal_register_direct = function() {
        let self = this;

        // init state start

        // starting states in config are not overwritten!!

        if (typeof state.Family_portal_register_direct === 'undefined') {
          state.Family_portal_register_direct = {};
        }

        if (typeof state.Family_portal_register_direct.running === 'undefined') {
          state.Family_portal_register_direct.running = false;
        }

        if (typeof state.Family_portal_register_direct.interval === 'undefined') {
          state.Family_portal_register_direct.interval = '';
        }

        if (typeof state.Family_portal_register_direct.last_email === 'undefined') {
          state.Family_portal_register_direct.last_email = '';
        }

        // init state end

        this.get_by_status = function (this_status, cb) {
          db.transaction(
            function (tx) {
              tx.executeSql(
                `SELECT * 
                FROM Family_portal_register_direct
                WHERE status = ? ORDER BY created ASC`,
                [this_status],
                function (tx, result) {
                  if (result !== null && result.rows !== null) {
                    let length = result.rows.length;
                    let arr = [];
                    for (let i = 0; i < length; i++) {
                      let row = result.rows.item(i);

                      arr[i] = {};
                      arr[i].id = row.id;
                      arr[i].user_id = parseInt(row.userid) || 0;
                      arr[i].mail = row.mail;
                      arr[i].status = row.status;
                      arr[i].created = row.created;
                      arr[i].prename = row.firstname || '';
                      arr[i].lastname = row.lastname || '';
                      arr[i].title = row.title || '';
                      arr[i].phone_number = row.phone || '';
                      arr[i].password = row.password || '';
                      arr[i].profile_id = row.prof_id || 0;
                    }
                    if ($.isFunction(cb) && arr.length > 0) cb(arr);
                  }
                },
                errorHandler
              );
            }
          );
        };

        this.delete_by_mail = function (
          l_mail,
          cb_success = helper_db.default_success,
          cb_error = errorHandler
        ) {
          db.transaction(
            function (tx) {
              tx.executeSql(
                `DELETE
                FROM
                  Family_portal_register_direct
                 WHERE
                  mail = ?`,
                [l_mail],
                cb_success,
                cb_error
              );
            }
          );
        };

        this.update_status = function (
          new_status,
          old_status,
          this_id,
          cb
        ) {
          db.transaction(
            function (tx) {
              tx.executeSql(
                `UPDATE Family_portal_register_direct SET status = ?
                              WHERE status = ? AND id = ?`,
                [new_status, old_status, this_id],
                function (tx, result) {
                  if ($.isFunction(cb)) cb();
                },
                errorHandler
              );
            }
          );
        };

        this.update_prof_id = function (
          this_prof_id,
          this_user_id,
          cb
        ) {
          db.transaction(
            function (tx) {
              tx.executeSql(
                `UPDATE
                  Family_portal_register_direct 
                SET 
                  prof_id = ?
                WHERE 
                  prof_id = 0
                AND 
                  userid = ?`,
                [this_prof_id, this_user_id],
                function (tx, result) {
                  if ($.isFunction(cb)) cb();
                },
                errorHandler
              );
            }
          );
        };

        this.add = function (
          new_entry
          // email,
          // userid,
          // success_cb,
          // error_cb,
          // progressing_cb,
          // title,
          // firstname,
          // lastname,
          // password
          // phone,
          // pass
        ) {
          // check if email is valid:
          if (Invite.validate_email(new_entry.email)) {
            let now = get_datetime_now();

            if (new_entry.userid < 2) new_entry.userid = userid;

            db.transaction(
              function (tx) {
                tx.executeSql(
                  // maybe switch inv_title to sex
                  `INSERT OR REPLACE INTO Family_portal_register_direct
                  (
                    userid,
                    prof_id,
                    mail,
                    title,
                    firstname,
                    lastname,
                    phone,
                    password,
                    status,
                    created
                  )
                  values(?, ?, ?, ?, ?, ?, ?, ?, ?, ?)`,
                  [
                    new_entry.userid,
                    User.prof_id || 0,
                    new_entry.email,
                    new_entry.title,
                    new_entry.firstname,
                    new_entry.lastname,
                    '',
                    btoa(new_entry.password),
                    2,
                    now,
                  ],
                  function (tx, result) {
                    // update state -
                    // so we have email in update_password
                    // to finish adding in onboarding util6.
                    state.Family_portal_register_direct.last_email = new_entry.email;
                    if ($.isFunction(new_entry.success_cb)) {
                      self.send(new_entry.success_cb);
                    } else {
                      self.send();
                    }
                    if ($.isFunction(new_entry.progressing_cb)) {
                      console.log('executing progress callback');
                      new_entry.progressing_cb();
                    }
                  },
                  errorHandler
                );
              }
            );
          } else {
            if ($.isFunction(new_entry.error_cb)) new_entry.error_cb();
          }
        };

        this.update_password = function (
          new_entry
          // email,
          // password,
          // userid,
          // success_cb,
          // error_cb,
          // progressing_cb,
          // title,
          // firstname,
          // lastname,
          // phone,
          // pass
        ) {
          // check required state:
          if (state.Family_portal_register_direct.last_email.length <= 1) {
            console.error(`no valid email set in state, check add function`);
            return;
          }

          if (new_entry.userid < 2) new_entry.userid = userid;

          db.transaction(
            function (tx) {
              tx.executeSql(
                // maybe switch inv_title to sex
                `UPDATE Family_portal_register_direct
                              SET password = ?, status = ?
                              WHERE mail = ?`,
                [
                  btoa(new_entry.password),
                  2,
                  state.Family_portal_register_direct.last_email,
                ],
                function (tx, result) {
                  if ($.isFunction(new_entry.success_cb)) {
                    self.send(new_entry.success_cb);
                  } else {
                    self.send();
                  }
                  if ($.isFunction(new_entry.progressing_cb)) {
                    console.log('executing progress callback');
                    new_entry.progressing_cb();
                  }
                },
                errorHandler
              );
            }
          );
        };

        this.send = function (success_cb, error_cb) {
          if (thisapp_online) {
            Auth.check(
              function () {
                Usync.send_users(
                  // success callback:
                  function () {
                    self.get_by_status(2, function (arr) {
                      if (arr.length === 1) {
                        clearInterval(state.Family_portal_register_direct.interval);
                        state.Family_portal_register_direct.interval = '';
                      }
                      arr.forEach(function (reg) {
                        // update state:
                        state.Family_portal_register_direct.last_email = reg.mail;

                        self.upload(
                          reg,
                          /* success_callback*/
                          function () {
                            if (v.d) {
                              console.log(
                                `register requests successfully added`
                              );
                              console.log(
                                `register id: ${reg.id}`
                              );
                            }
                            self.update_status(
                              3,
                              2,
                              reg.id,
                              function () {
                                if (v.d) {
                                  console.log(
                                    `all registrations = 3`
                                  );
                                }

                                if ($.isFunction(success_cb)) {
                                  success_cb(reg.mail);
                                }
                              }
                            );
                          },

                          /* error_callback:*/
                          () => {
                            if (state.Family_portal_register_direct.interval === '') {
                              state.Family_portal_register_direct.interval =
                                setInterval(
                                  function () {
                                    if (thisapp_online) {
                                      let fpr = new Family_portal_register_direct();
                                      if ($.isFunction(success_cb)) {
                                        fpr.send(success_cb);
                                      } else {
                                        fpr.send();
                                      }
                                    }
                                  },
                                  5 * 1000
                                );
                            }
                            if (v.d) {
                              console.error(
                                `error uploading Registrations
                                  missing token or wrong data?
                                  or running = true`
                              );
                            }
                          }
                        );
                      });
                    });
                  },
                  // errorCallback: start Interval mhe =>
                  () => {
                    if (state.Family_portal_register_direct.interval === '') {
                      state.Family_portal_register_direct.interval =
                        setInterval(
                          function () {
                            if (thisapp_online) {
                              let fpr = new Family_portal_register_direct();
                              if ($.isFunction(success_cb)) {
                                fpr.send(success_cb);
                              } else {
                                fpr.send();
                              }
                            }
                          },
                          60 * 1000
                        );
                    }
                  }
                );
              },
              // error handling for token request failed 2017_04_07 mhe:
              () => {
                if (state.Family_portal_register_direct.interval === '') {
                  state.Family_portal_register_direct.interval =
                    setInterval(
                      function () {
                        if (thisapp_online) {
                          let fpr = new Family_portal_register_direct();
                          if ($.isFunction(success_cb)) {
                            fpr.send(success_cb);
                          } else {
                            fpr.send();
                          }
                        }
                      },
                      60 * 1000
                    );
                }
              }
            );
          } else {
            if (state.Family_portal_register_direct.interval === '') {
              state
                .Family_portal_register_direct
                .interval = setInterval(
                  () => {
                    if (thisapp_online) {
                      // TODO: use return this instead of :
                      let fpr = new Family_portal_register_direct();
                      if ($.isFunction(success_cb)) {
                        fpr.send(success_cb);
                      } else {
                        fpr.send();
                      }
                    }
                  },
                  60 * 1000
                );
            }
          }
        };

        this.upload = function(register_data, success_cb, error_cb) {
          if (!state.Family_portal_register_direct.running) {
            state.Family_portal_register_direct.running = true;
            let data_json = JSON.stringify(register_data);

            $.ajax({
              type: 'POST',
              contentType: 'application/json; charset=utf-8',
              data: data_json,
              url: api_endpoints.family_portal_register_direct,
              xhrFields: {
                withCredentials: false,
              },
              headers: {
                'Token': Auth.token,
              },
              cache: false,
              success: function (data) {
                state
                  .Family_portal_register_direct
                  .running = false;
                if (data === 'success') {
                  if ($.isFunction(success_cb)) success_cb();
                } else {
                  if ($.isFunction(error_cb)) error_cb();
                }
              },
              error: function (error) {
                state.Family_portal_register_direct
                  .running = false;
                if ($.isFunction(error_cb)) error_cb();

                if (v.d) {
                  console.error(`API request failed -
                    error uploading direct Registrations`);
                }
                if (v.d) {
                  console.error(error);
                }
              },
            });
          } else {
            if ($.isFunction(error_cb)) error_cb();
          }
        };
      };

      resolveCreate();
    }
  );
}
