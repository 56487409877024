function layout(){
    
this.build_header = function(this_page, header_title, show_icon_bar, show_home_btn, show_back_btn, lang_fast_switch){
    var home_btn = '';
    var back_btn = '';
    var icon_bar = '';
    var lang_fast_switch_btns = '';
    var header_style = '';
    var settings_style = '';
    
    if(lang_fast_switch) lang_fast_switch_btns = 
            `<div style="display:none;" id="lang_btns_wrap">
                <div class="btn_lang_de" style="margin-left:20px; border-color: rgba(218,218,217,1);">&nbsp;</div>
                <div class="btn_lang_pl" style="margin-left:20px; border-color: rgba(218,218,217,1);">&nbsp;</div>
            </div>`;
    
    if(show_home_btn) home_btn = '<div class="homebtn" data-role="button" role="button"></div>';
    if(show_back_btn) back_btn = '<div class="backbtn"></div>';
    if(show_icon_bar) icon_bar = `<div class="iconbar">
                                <div id="imprint_btn" class="iconbar--imprint" class="iconbar--imprint">&copy; media4care</div>
                                <div id="autotest"></div>
                                <div class="uhrzeit iconbar--clock"></div>
                                <div class="battery"></div>
                            </div>`;        
    
    
    if(utilization===5 || utilization===6){             
                
        if(this_page === "home")
        {
            header_style = `height: 150px; background-size: cover !important;`;
            settings_style = `top: 62px !important; right: 25px;`;
        }
        else
        {
            header_style = `line-height: 92px !important;`; 
            settings_style = ``;
        }
    
        $('.ui-header').attr('style', header_style)
        .html(`
            ${icon_bar}
            ${home_btn}
            ${back_btn}     
            <h2 class="ui-title header--title" style="font-size: 48px !important; margin: 25px auto 0 auto !important;">${header_title}</h2>
            <div class="settings header--settings util6--homeheader-settings" class="ui-btn-right" style="${settings_style}">&nbsp;</div>
        `); 
    } 
    /*
    else if([2,8].includes(utilization)){
        
    }
    else if(utilization===24){
        
    }
    */
    else 
    { 
                                
        $('.ui-header').html(`<div class="iconbar">
                <div id="imprint_btn" class="iconbar--imprint" class="iconbar--imprint">&copy; media4care</div>
                <div id="autotest"></div>
                <div class="uhrzeit iconbar--clock"></div>
                <div class="battery"></div>
            </div>
            ${home_btn}
            ${back_btn} 
            <h2 class="ui-title header--title" style="margin-top: 9px !important;">${header_title}</h2>
            <div class="settings header--settings" class="ui-btn-right">&nbsp;</div>
            <div class="hilfe_home header--help">&nbsp;</div>
            
        `);
    }
    bind_headerbuttons(); 
};    
  
this.build = function(){
    //get current page:    
    var this_page = $.mobile.activePage.attr('id');  
      
    switch(this_page){
        case "home":
            switch(utilization){
                case 5:
                case 6:
                    this.build_header(this_page, "Drücken Sie auf ein Feld", true, false, false);
                    break;
                case 2:
                case 8:
                    this.build_header(this_page, strings[0]/*main menu*/, true, false, false);
                    break;
                case 24:
                    this.build_header(this_page, strings[0]/*main menu*/, true, false, false);
                    break;
                
            }
            break;
        default:
            this.build_header(this_page, "Menu", true, true, true);
            break;
    }
};    
    
}



var template = new layout();
console.warn("LAYOUT - tempalte loaded");