const addNewLanguage = () => {
  return new Promise(
    (resolve, reject) => {
      Settings.select(
        'language',
        (new_lang) => {
          if (
            !new_lang ||
            new_lang === 'undefined'
          ) {
            new_lang = nat_lang || CONST_LANGUAGES.GERMAN;
          }
          getLanguageArray(new_lang);
          resolve();
        },
        () => {
          Settings.insert('language', nat_lang, nullHandler);
          getLanguageArray(nat_lang);
          resolve();
        });
    }
  );
};
