function add_user_stats(addus_mediaid, addus_action_id, addus_action_env, addus_categoryid) {
/*
  addus_action_id:
  1 = opening media
  2 = close media => action_env with feedback like
      quiz_score, time&state for video, pages for books, etc.
  3 = youtube search (term as env)
  5 = feeling answers (date, question, rating, answ as json in env)
*/
  const timestamp_now = get_datetime_now();
  db.transaction(
    function(tx) {
      tx.executeSql(
        `INSERT OR IGNORE INTO user_stats
        (
          userid,
          mediaid,
          action_id,
          action_env,
          action_datetime,
          us_status,
          categoryid
        ) 
        values(?, ?, ?, ?, ?, ?, ?)`,
        [
          userid,
          addus_mediaid,
          addus_action_id,
          addus_action_env,
          timestamp_now,
          1,
          addus_categoryid,
        ],
        () => {
          if (v.d) {
            console.log('user_stat added');
          }
        },
        errorHandler
      );
    }
  );
}

let stats = {};
stats.select_cnt_by_actionid_datetime = (sel_action_id, action_date, cb) => {
  db.transaction(
    function(tx) {
      tx.executeSql(
        `select count(*) as cnt from user_stats
          where userid = ?
            and action_datetime >= ?
            and action_id = ?`,
        [userid, action_date, sel_action_id],
        function(transaction, result) {
          if (result !== null && result.rows !== null) {
            if ($.isFunction(cb))cb(result.rows.item(0).cnt);
          }
        },
        errorHandler
      );
    }
  );
};

/* START transfer stats MHe */
let stat_ostream = setInterval(
  function() {
    // using setTimeout to prevent GUI blocking
    setTimeout(
      () => {
        request_token(get_user_stats);
      },
      0
    );
  },
  19*60*1000
);
/*
//remove Interval:
clearInterval(stat_ostream);
*/

const last_token = {};
last_token.value = '';
last_token.refreshed = new Date('1995-12-17T03:24:00');

function refresh_token(success_cb, error_cb) {
  request_token(success_cb, error_cb);
}


function request_token(success_cb, error_cb) {
  // check if token.hash needs to be refreshed
  // at least every 15h - time difference in ms: 2017_05 mhe:
  if (Date.now()-last_token.refreshed > (15*3600*1000) && thisapp_online) {
    const token_url = url.content_be+'/get_token.php';
    $.ajax({
      type: 'POST',
      data: {
        manufacturer: device.manufacturer,
        platform: device.platform,
        version: device.version,
        uuid: device.uuid,
        cordova: device.cordova,
        model: device.model,
        app_version: app_core_version,
      },
      url: token_url,
      cache: false,
      xhrFields: {
        withCredentials: false,
      },
      timeout: conf.get_timeout,
      headers: {},
      success: function(res) {
        if (res !== 'error' || res !== '') {
          last_token.value = res;
          last_token.refreshed = Date.now();
          if ($.isFunction(success_cb)) {
            success_cb();
          }
        }
      },
      error: () => {
        if (try_conn < 4) {
          ++try_conn;
          setTimeout(function() {
            // check if tab has wifi issues (medion):
            // reenable_wifi(request_token, gt_callback);
            request_token(success_cb, error_cb);
          }, 1500);
        } else {
          console.error('token request failed with 4 attempts');
          if ($.isFunction(error_cb)) error_cb();
        }
      },
    });
  } else {
    if ($.isFunction(success_cb)) {
      success_cb();
    }
  }
}

function get_user_stats() {
  db.transaction(
    function(tx) {
      tx.executeSql(
      /*
      only api_userstats upload timing,get_token ~300ms, lenovo2, php api:
        10k=1.3s, 5k=604ms, 4k=488ms, 2k=338ms, 1k=220ms, 500=191ms
      MHe*/
        `SELECT * FROM user_stats
          where us_status = ?
          order by us_id asc limit 1000`,
        [1],
        function(transaction, result) {
          if (result !== null && result.rows !== null) {
            let first_element = '';
            let last_element = '';
            let stats_arr = [];
            if (result.rows.length < 1) {
              return false;
            }
            for (let i = 0; i < result.rows.length; i++) {
              const row = result.rows.item(i);

              // save first and last id for updating table
              // after successful upload:
              if (i === 0) {
                first_element = row.us_id;
              }
              if (i + 1 === result.rows.length) {
                last_element = row.us_id;
              }

              if (row.last_edit === null) {
                row.last_edit = '';
              }

              if (row.action_env === null) {
                row.action_env = '';
              }

              row.action_env = row.action_env.replace(/NaN/g, '0')
                .replace(/null/g, '""');

              if (
                row.action_env.length>1
                && row.action_env.indexOf('{') !== -1
              ) {
                row.action_env = JSON.parse(row.action_env);
              }
              // customizing columns to speed up upload:
              stats_arr[i] = {
                a: row.action_id,
                // s :row.us_status,
                u: row.userid,
                e: row.action_env,
                m: row.mediaid,
                c: row.categoryid,
                // i : row.us_id,
                d: row.action_datetime, // ,
                // l :row.last_edit
              };
            }

            let stat_son = array2json(stats_arr);
            if (v.d) {
              console.log(stat_son);
            }
            upload_user_stats(stat_son, first_element, last_element);
          }
        },
        errorHandler
      );
    }
  );
}

function upload_user_stats(user_stats_json_enc, first_element, last_element) {
  const stats_url = url.content_be+'/api_userstats.php';

  $.ajax({
    type: 'POST',
    data: {user_stats: user_stats_json_enc, token: last_token.value},
    url: stats_url,
    xhrFields: {
      withCredentials: false,
    },
    headers: {},
    cache: false,
    success: function(data) {
      if (data === 'success') {
        // set transfered entries to status 2 ⁼ transfered:
        db.transaction(
          function(tx) {
            tx.executeSql(
              `UPDATE user_stats
                SET us_status = ?
                WHERE us_status = ? AND us_id >= ? AND us_id <= ?`,
              [2, 1, first_element, last_element],
              function(transaction, result) {
                console.log('user stats updated');
              },
              errorHandler
            );
          }
        );

        // if sum(transfered == 2000) do it again:
        if (last_element-first_element >= 1000) {
          get_user_stats();
        }
      }
    },
    error: function(error) {
      console.error('error uploading stats', error);
    },
  });
}

function array2json(arr) {
  let parts = [];
  let is_list = (Object.prototype.toString.apply(arr) === '[object Array]');

  for (let key in arr) {
    let value = arr[key];
    if (typeof value == 'object') { // Custom handling for arrays
      if (is_list) parts.push(array2json(value)); /* :RECURSION: */
      else parts.push('"' + key + '":' + array2json(value)); /* :RECURSION: */
      // else parts[key] = array2json(value); /* :RECURSION: */
    } else {
      let str = '';
      if (!is_list) str = '"' + key + '":';

      // Custom handling for multiple data types
      if (typeof value == 'number') str += value; // Numbers
      else if (value === false) str += 'false'; // The booleans
      else if (value === true) str += 'true';
      else str += '"' + value + '"'; // All other things
      // :TODO: Is there any more datatype we should be in the lookout for? (Functions?)

      parts.push(str);
    }
  }
  let json = parts.join(',');
  if (is_list) {
    let num_son = '[' + json + ']';// Return numerical JSON
    if (num_son.indexOf('function')>0) {
      num_son = num_son.substring(0, num_son.lastIndexOf(',"'))+']';
    }
    return num_son;
  } else {
    return '{' + json + '}';// Return associative JSON
  }
}
/* END transfer stats  MHe 16-03-01 */
/* type depending stats if media consuming finished: 2017_02_01 MHe:*/
/* for stats: introduced quiz_finsihed, so we know,
  if auswertung stat insert was already used or not
  so we could skip onpagebeforehide stat saving
    2017_01_31 MHe */
stats.finished_inserted = false;
stats.video_add_finished_to_stats = function() {
  /*
    add finished or cancled vid to stats: 2017_02_01 MHe
    stats entry envelope:
  */
  if (v.d) {
    console.log('video stats:');
    console.log(video_currentTime);
    console.log(video_duration);
  }
  let stat_video = {};
  /* stat_video.duration =  vid.duration;
      stat_video.currentTime = vid.currentTime;
      stat_video.time_used = vid.currentTime;
      */
  stat_video.duration = video_duration.toFixed(2);
  stat_video.currentTime = video_currentTime.toFixed(2);
  stat_video.time_used = video_currentTime.toFixed(2);
  if (stat_video.currentTime >= stat_video.duration) {
    stat_video.finished = true;
  } else {
    stat_video.finished = false;
  }
  if (v.d)console.log(array2json(stat_video));
  add_user_stats(parseInt(sessionStorage.mediaid), 2, array2json(stat_video), '');
  stats.finished_inserted = true;
};
stats.quiz_add_finished_to_stats = function() {
  /*
    add finished quiz to stats: 2017_01_31 MHe
    quiz stats entry envelope:
  */
  const now = new Date().getTime();
  const stat_quiz = {};
  stat_quiz.wrong_cnt = parseInt(sessionStorage.wrong_cnt)||0;
  stat_quiz.right_cnt = parseInt(sessionStorage.right_cnt)||0;
  stat_quiz.total_cnt =
    parseInt(sessionStorage.wrong_cnt)+parseInt(sessionStorage.right_cnt)||0;
  stat_quiz.difficulty = parseInt(sessionStorage.difficulty)||0;
  stat_quiz.time_used = parseFloat((now-starttime)/1000);
  if (stat_quiz.wrong_cnt !== 0 || stat_quiz.right_cnt !== 0) {
    stat_quiz.percent =
      100*stat_quiz.right_cnt/(stat_quiz.right_cnt+stat_quiz.wrong_cnt);
    stat_quiz.percent = stat_quiz.percent.toFixed(2);
  } else {
    stat_quiz.percent = 0;
  }
  if (stat_quiz.total_cnt >= 10) {
    stat_quiz.finished = true;
  } else {
    stat_quiz.finished = false;
  }

  add_user_stats(parseInt(sessionStorage.quizid), 2, array2json(stat_quiz), '');
  stats.finished_inserted = true;
};
stats.general_add_finished_to_stats = function(g_mediaid) {
  if (typeof(g_mediaid) !== 'undefined') {
    g_mediaid = parseInt(g_mediaid)||0;
    if (g_mediaid>0) {
      let now = new Date().getTime();
      let stat_gen = {};
      stat_gen.time_used = ((now-starttime)/1000).toFixed(2);
      add_user_stats(g_mediaid, 2, array2json(stat_gen), '');
    }
  }
};
