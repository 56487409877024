const SD_CARD_INSTALL_CONSTANTS = Object.freeze({
  INTERNAL_MEMORY_BUFFER_MB: 3000,
  MEGA_BYTE: 1000000,
  SKIP_COPY: Object.freeze([
    'videos/TVAdapter.jpeg',
    'tab_im_einsatz_video_vorschau.jpg',
  ]),
  IMAGE_PATH: 'Android/data/com.media4care.dementia/files/',
  CONTENT_PATH: 'Android/data/com.media4care.dementia/files/m4c_content/',
  DEFAULT_PROPS: Object.freeze({
    totalSize: 0,
    processedSize: 0,
    videosInternally: false,
    lastProcessedFile: '',
  }),
});

class SdCardInstall {
  constructor() {
    this._props = {
      values: {},
      update: (propsChanges) => {
        this._props.values = Object.assign(
          this._props.values, propsChanges
        );
        return this.refreshView(this._props.values);
      },
    };
  };

  refreshView(val) {
    val.totalSize > 0 ? val.showBar = true : val.showBar = false;
    return controllers.home.bootscreen.render(
      `wird installiert`,
      {
        show: val.showBar,
        overallProgress:
          (100 * val.processedSize / val.totalSize).toFixed(2),
        timeLeft: '0:00',
        fileDisplay: (val.processedSize / SD_CARD_INSTALL_CONSTANTS.MEGA_BYTE)
          .toFixed(3) +
          ' von ' +
          (val.totalSize / SD_CARD_INSTALL_CONSTANTS.MEGA_BYTE).toFixed(3) +
          ' MB<br>' +
          val.lastProcessedFile,
      }
    );
  };

  run() {
    return new Promise(
      (resolve, reject) => {
        this._props.update(SD_CARD_INSTALL_CONSTANTS.DEFAULT_PROPS);
        return Files.resolvePromise(sd_card_url)
          .then(() => {
            return Files.resolvePromise(
              `${sd_card_url}${SD_CARD_INSTALL_CONSTANTS.CONTENT_PATH}`
            );
          })
          .then(() => {
            return this.processQueue();
          })
          .then(resolve)
          .catch(reject);
      }
    );
  }

  sumUpSizeFromFileList(files = []) {
    return files.reduce(
      (fileSize, currentFile) => {
        return fileSize + currentFile.size;
      },
      0
    );
  }

  fillFilesQueue() {
    return files.selectAll()
      .then(
        (elements) => {
          let filesToCopy = [];
          elements.map(
            (el) => {
              this.sd_install_total += el.size;
              el.copy_errors = 0;
              filesToCopy = [
                ...filesToCopy,
                ...[
                  {
                    'id': el.id,
                    'path': el.path,
                    'name': Files.getFilenameFromPath(el.path),
                    'size': el.size,
                    'md5': el.md5,
                    'copy_errors': 0,
                  },
                ],
              ];
            }
          );
          return filesToCopy;
        }
      )
      .catch(console.error);
  }

  processQueue() {
    return this.fillFilesQueue()
      .then((filesToProcess) => {
        this._props.update({
          totalSize: this.sumUpSizeFromFileList(filesToProcess),
        });

        if (
          available_space >= this._props.values.totalSize +
            SD_CARD_INSTALL_CONSTANTS.INTERNAL_MEMORY_BUFFER_MB *
            SD_CARD_INSTALL_CONSTANTS.MEGA_BYTE
        ) {
          Settings.insertReplaceWithPromise('videosSavedInternally', 'true')
            .then(() => {
              this._props.update({
                videosInternally: true,
              });
            });
        }
        return filesToProcess;
      })
      .then(
        (filesToProcess) => {
          if (filesToProcess.length <= 0) {
            return true;
          }

          let count = 0;
          const promiseProducer = () => {
            const file = filesToProcess[count];
            if (count < filesToProcess.length && typeof(file) !== 'undefined') {
              count++;
              return this.processFile(file)
                .then(() => {
                  this._props.update(
                    {
                      processedSize: this._props.values.processedSize +
                      file.size,
                      lastProcessedFile: file.name,
                    }
                  );
                });
            } else {
              return null;
            }
          };
          return new PromisePool(promiseProducer, 4).start();
        }
      )
      .then(
        () => {
          console.info(`COPYING MEDIA FILES FINSIHED`);
        }
      )
      .catch((err) => {
        console.error('in processQueue', err);
      });
  }

  processFile(file) {
    return new Promise(
      (resolve, reject) => {
        const sourceURL =
          sd_card_url +
          (
            (file.path.indexOf('videos/') >= 0)
              ?
              SD_CARD_INSTALL_CONSTANTS.IMAGE_PATH
              :
              SD_CARD_INSTALL_CONSTANTS.CONTENT_PATH
          ) +
          file.path;

        const subPath = Files.getFolderPathFromUrl(file.path);
        Files.resolvePromise(sourceURL)
          .then((sourceFileEntry) => {
            return Files.path.make(
              local_dir, subPath
            )
              .then(() => {
                return sourceFileEntry;
              });
          })
          .then((sourceFileEntry) => {
            return (
              (
                this._props.values.videosInternally === true
                && file.name.indexOf('.mp4') > 0
              )
              ||
              file.name.indexOf('.mp4') === -1
            )
              ?
              Files.copy.to(
                sourceFileEntry,
                local_dir + subPath,
                file.name
              )
              :
              Files.copy.to(
                sourceFileEntry,
                Files.getFolderPathFromUrl(sourceURL),
                file.name
              );
          })
          .then(
            (res) => {
              return Files.resolvePromise(res.destDir + res.destFilename);
            }
          )
          .then(
            (destFileEntry) => {
              return Files.getMd5(destFileEntry);
            }
          )
          .then(
            (md5Local) => {
              return (md5Local === file.md5 || file.path.indexOf('.js') >= 0)
                ?
                files.setStatus(files.constants().STATUS.LOCALLY_AVAILABLE, file.id)
                  .then(resolve)
                :
                reject();
            }
          )
          .catch((err) => {
            console.error('catch in processFile', sourceURL);
            console.error(file);
            console.error(err);
            reject(err);
          });
      }
    );
  }
}
