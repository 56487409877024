const createApiDeviceUser = () => {
  return new Promise(
    (resolveCreate) => {
      window.api_device_user = {};
      api_device_user.getProfIds = () => {
        return new Promise(
          (resolve, reject) => {
            api_device_user.get_prof_ids(resolve, reject);
          }
        );
      };

      // get User prof_ids:
      api_device_user.get_prof_ids = function(SUCCESS_CB, ERROR_CB) {
        User.select_all_local_userids(
          function (Userids) {
            let userids_json = array2json(Userids);
            $.ajax({
              type: 'POST',
              data: {
                device_id: Auth.device_id.toString(),
                lusers_json: userids_json,
              },
              url: api_endpoints.dua_get_profids,
              xhrFields: {
                withCredentials: false,
              },
              headers: {'Token': Auth.token},
              cache: false,
              timeout: 10000,
              success: function (resp) {
                if (resp.length > 0) {
                  let data = JSON.parse(resp);
                  if (v.d) console.log(data);
                  // TODO: update User prof ids here:
                  if (data !== null) {
                    data.forEach(
                      function (id_entry) {
                        console.log(id_entry);
                        User.update_profid_by_luserid(
                          id_entry.prof_id,
                          id_entry.l_user_id,
                          // potential success callback:
                          nullHandler
                        );
                      }
                    );
                  }
                  if ($.isFunction(SUCCESS_CB)) {
                    SUCCESS_CB();
                  }
                }
              },
              error: function (error, ERROR_CB) {
                core.ajax_error(
                  error,
                  function () {
                    if ($.isFunction(ERROR_CB)) {
                      ERROR_CB();
                    }
                  }
                );
              },
            });
          }
        );
      };
      resolveCreate();
    }
  );
};
