/**
 * db related helpers
 * @type {{eraseTable: helper_db.eraseTable,
 *  default_success: helper_db.default_success
  *  build_where_from_array: (function(*=): string),
  *  is_table: (function(string): Promise<any>),
  *  get_table_version: (function(string): Promise<T>),
  *  set_table_version: (function(string, number, string): Promise<T>)}}
 */
const createHelperDb = () => {
  return new Promise(
    (resolve, reject) => {
      window.dbExecute = function() {
        return (sqlStatement, params) => {
          return new Promise(
            (resolve, reject) => {
              db.transaction(
                (tx) => {
                  tx.executeSql(
                    sqlStatement,
                    params,
                    (tx, res) => {
                      console.log('executing succsesCB/resolve');
                      console.log(tx);
                      console.log(res);
                      resolve(res);
                    },
                    () => {
                      if (is_app >= 0) {
                        reject();
                      } else {
                        resolve();
                      }
                    }
                  );
                },
                errorHandler,
                resolve
              );
            }
          );
        };
      }();

      window.helper_db = {
        checkIfColumnExists: (table, column) => {
          return new Promise((resolve, reject) => {
            // no PRAGMA support in browser
            if (is_app >= 0) {
              db.transaction((tx) => {
                tx.executeSql(
                  `PRAGMA table_info(${table})`,
                  [],
                  (tx, res) => {
                    let exists = false;
                    for (let i = 0; i < res.rows.length; i++) {
                      if (res.rows.item(i).name === column) {
                        exists = true;
                      }
                    }
                    resolve(exists);
                  },
                  () => {
                    reject();
                  }
                );
              });
            } else {
              resolve(false);
            }
          });
        },

        eraseTable: (currentTable) => {
          if (v.d) console.log('Inside erase table function');
          if (v.d) console.log(`Table to erase is table : ${currentTable}`);
          db
            .transaction(
              function(tx) {
                tx
                  .executeSql(
                    `DELETE FROM ${currentTable};`,
                    [],
                    nullHandler);

                tx
                  .executeSql(
                    `DELETE FROM SQLITE_SEQUENCE WHERE name=?;`,
                    [currentTable],
                    nullHandler);
              }
            );
        },

        default_success: function (res) {
          console.log(res);
          console.table(res);
        },
        build_where_from_array: (filter = []) => {
          let q = '';
          if (filter.length > 0) {
            q += ' WHERE ';
            filter.forEach(
              function (item) {
                q += `${item.filter} ${item.operator} ${item.value} AND `;
              }
            );
            q = q.slice(0, q.length - 4);
          }
          if (v.d) console.log(q);
          return q;
        },

        /**
         * Check if table with the name "`name`" exists.
         * @param {string} name - Name to check.
         * @return {Promise<boolean>} - returns true if table exists.
         */
        is_table: function (name) {
          return new Promise((resolve, reject) => {
            db.transaction(
              function (tx) {
                tx.executeSql(
                  `Select tbl_name 
                    FROM sqlite_master WHERE type='table' and tbl_name = ?`,
                  [name],
                  function (tx, res) {
                    console.log(res);
                    if (res.rows.length > 0) {
                      resolve(true);
                    } else {
                      resolve(false);
                    }
                  },
                  reject
                );
              },
              reject,
              nullHandler
            );
          });
        },

        /**
         * Return the version of the table with the name `table_name`.
         * If there is no entry which holds the version of the table,
         * the function returns `1`. If the table doesn't exists the function
         * returns 0.
         * @param {string} table_name - name of the table to get the version from.
         * @return {Promise<number>} - returns the version of the table,
         * if 0 the table does not exists.
         */
        get_table_version: function (table_name) {
          return helper_db.is_table(table_name).then(
            function(res) {
              return new Promise((resolve, reject) => {
                if (res) {
                  db.transaction(
                    function (tx) {
                      tx.executeSql(
                        `Select version from table_version where table_name = ?`,
                        [table_name],
                        function(tx, res) {
                          if (res.rows.length < 1) {
                            resolve(1);
                          } else {
                            resolve(res.rows.item(0).version);
                          }
                        },
                        reject
                      );
                    },
                    reject,
                    nullHandler
                  );
                } else {
                  resolve(0);
                }
              });
            });
        },

        /**
         * Updates the version of the table.
         * This function overwrites the version-number of a table.
         * @param {string} table_name - The name of the table,
         *  where the version should be updated.
         * @param {number} version - The version to be set.
         * @param {string} comment - Small description what
         * has changed in this version.
         * @return {Promise<any>}
         */
        set_table_version: function (table_name, version, comment) {
          return helper_db.is_table(table_name).then(
            function (res) {
              if (res) {
                return new Promise((resolve, reject) => {
                  db.transaction(
                    function (tx) {
                      tx.executeSql(
                        `INSERT
                            OR REPLACE INTO
                              table_version(
                                id,
                                table_name,
                                version,
                                comment,
                                last_updated,
                                created
                              )
                            values(
                              (
                                Select
                                  id
                                from
                                  table_version
                                where
                                  table_name =?
                              ),
                              ?,
                              ?,
                              ?,
                              datetime('now'),
                              (
                                Select
                                  created
                                from
                                  table_version
                                where
                                  table_name =?
                              )
                            )`,
                        [table_name, table_name, version, comment, table_name],
                        function(tx, res) {
                          resolve();
                        },
                        reject
                      );
                    },
                    reject,
                    nullHandler
                  );
                });
              }
            });
        },
      };

      // rewrite sqlite db.executeSql to be executable in web:
      if (is_app < 0) {
        if (typeof db === "undefined") {
          db = window.openDatabase(
            shortName,
            "1.0",
            shortName,
            120 * 1024 * 1024
          );
        }
        db.executeSql = dbExecute;
      }
      resolve();
    }
  );
};

function query(sql, params = []) {
  return new Promise((resolve, reject) => {
    function onSuccess(tx, {rows}) {
      if (rows.length === 0) {
        resolve([]);
      } else {
        resolve({
          * [Symbol.iterator]() {
            for (let i = 0; i < rows.length; i++) {
              yield rows.item(i);
            }
          },
        });
      }
    }
    db.transaction((tx) => tx.executeSql(sql, params, onSuccess, reject), reject);
  });
}

function insertRecord(sql, records) {
  return new Promise((resolve, reject) => {
    function onSuccess(tx, result) {
      resolve(result);
    }

    db.transaction((tx) => tx.executeSql(
      sql, records, onSuccess, reject), reject);
  });
}
