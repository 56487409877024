const ANDROID_ASSETS = Object.freeze({
  NOT_FOUND: -1,
});
const is_app = window.location.pathname.indexOf("android_asset");
let clickhandler = "vclick";
let clickhandler_prev = "mousedown";
if (is_app >= 0) {
  clickhandler = "touchend";
  clickhandler_prev = "touchstart";
}
var db;
/*to provide a testing apk to i.e. test.io and keep app simple, we provide a version with online media sources: 2017_07_19 mhe: */
let online_media_sources = false;
let online_media_token_refresh = false;
let lastsync_dl = '';
let lastsync_dl_cache = '';
// var db;
// web debugger only:
var version = '1.0';
//shortName leads to databases' filename
var shortName = 'm4cd2';


window.Connection = {
  UNKNOWN: 'unknown',
  ETHERNET: 'ethernet',
  WIFI: 'wifi',
  CELL_2G: '2g',
  CELL_3G: '3g',
  CELL_4G: '4g',
  CELL: 'cellular',
  NONE: 'none',
};

// dirty adding core:
var core = {};
core.load_js = function(js_file_path, js_id) {
  return new Promise(
    (resolve, reject) => {
      if (!document.getElementById(js_id)) {
        let script = document.createElement('script');
        script.id = js_id;
        script.src = js_file_path + '?v=' + core.loading_ts(get_datetime_now());
        script.onload = function() {
          console.log(js_file_path + ' loaded and ready');
          resolve();
        };
        script.async = true;
        script.defer = true;
        script.type = 'text/javascript';
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        reject(
          {
            error: `script tag id ${js_id} already used,
              ${js_file_path} not loaded!`,
          }
        );
      }
    }
  );
};
core.loading_ts = function(now){
  now = now.replace('-','').replace('-','').replace(' ','').replace(' ','').replace(':','').replace(':','');
  return now;
};

core.load_css = function (css_file_path, css_id) {
  if (!document.getElementById(css_id)) {
    let head = document.getElementsByTagName('head')[0];
    let link = document.createElement('link');
    link.id = css_id;
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = css_file_path + '?v=' + core.loading_ts(get_datetime_now());
    link.media = 'all';
    head.appendChild(link);
  }
};

core.load_styles = function () {
  core.load_css('./css/style.css', 'css_style');
  core.load_css('./css/base.css', 'css_base');
  core.load_css('./css/inputs.css', 'css-input');
  core.load_css('./css/swipe.css', 'css_swipe');
};


const init = () => {
  return new Promise(
    (resolve, reject) => {
      checkConnection();
      core.load_styles();
      $(document).disableSelection();
      $.getJSON("./app_settings/config.json", function(json) {
        console.log('reading out JSON config!!!!');
        console.log(json);
        utilization = json.startup_utilization;
        sessionStorage.userid = json.startup_user;
        userid = json.startup_user;
        cur_userid = json.startup_user;
        testing = json.testing;
        developer = json.developer;
        logging = developer;
        v.usync = json.v.usync;
        v.d = json.v.d;
        v.benchmark = json.v.benchmark;

        stage = json.stage;
        use_medialist_cache = json.use_medialist_cache;
        conf.secret_key = json.secret_key;

        url.content_be = json.url.content_be;
        url.relatives_api = json.url.relatives_api;
        url.device_user_api = json.url.device_user_api;
        url.msg_sync_log_api = json.url.msg_sync_log_api;
        url.apk_update_url = json.url.apk_update_url;
        url.media_base_path = json.url.media_base_path;
        url.storage_api = json.url.storage_api;

        api_endpoints = json.api_endpoints;
        online_media_sources = json.online_media_sources;
        // expand config:
        conf.get_timeout = 10000;

        // after reading in config, start app:
        if (localStorage.getItem("db_break") === "true") {
          core.db_broken_msg();
        } else {
          controllers.home.bootscreen.render(
            strings['Application is started'] || 'Anwendung wird gestartet'
          );
        }

        $.support.cors = true;

        Sentry.init({dsn: json.sentry_dsn});

        if (is_app >= 0) {
          onDeviceReady()
            .then(
              openDbDevice
            )
            .then(resolve)
            .catch(
              (r) => {
                console.error('ERROR in onDeviceReady');
                console.error(r);
                reject(r);
              }
            );
        } else {
          browser_plugins_replacements()
            .then(openDbBrowser)
            .then(resolve)
            .catch(
              (r) => {
                console.error(r);
                reject(r);
              }
            );
        }
      });
    }
  );
};

const openDbDevice = () => {
  return new Promise(
    (resolve, reject) => {
      db = window.sqlitePlugin.openDatabase(
        {
          name: shortName,
          // added to fix android 64bit issues:
          location: 'default',
          androidDatabaseImplementation: 2,
        },
        (s) => {
          console.log('DB successfully opened',s);
          // setTimeout(resolve, 1000);
          resolve();
        },
        function(err) {
          console.error(err);
          reject(err);
        }
      );
    }
  );
};

// TODO: make Promise: and modify init() afterwards:
const openDbBrowser = () => {
  return new Promise(
    (resolve, reject) => {
      db = window.openDatabase(shortName, "1.0", shortName, 120 * 1024 * 1024);
      setDeviceProperties();
      resolve();
    }
  );
};

// get_token END
const getlastsync_dl = () => {
  return new Promise(
    (resolve, reject) => {
      Settings.select(
        'sync_action_file_create',
        (datetime) => {
          lastsync_dl = datetime;
          resolve();
        },
        // previoiusly build_settings here and setting lastsync_dl to
        () => {
          Settings.insert_replace(
            'sync_action_file_create',
            get_datetime_now(),
            resolve
          );
        }
      );
    }
  );
};

const setDeviceProperties = () => {
  device = {};
  if (online_media_sources){
    local_dir = url.media_base_path;
  } else {
    local_dir = '';
  }
  console.log('is not app' + is_app);
  lastsync_dl_cache = get_datetime_now();
  home_dir = '';
  device.manufacturer = window.navigator.vendor;
  device.platform = window.navigator.platform;
  device.version = window.navigator.productSub;
  // device.uuid = window.navigator.appVersion;
  localStorage.removeItem('browserId');
  if (localStorage.getItem("browserId") === null) {
    // browser751455e1ea25f50f1cf529e3c3f029e05fee405190a3662886f9e23e1c50f4b0
    localStorage.setItem(
      "browserId",
      'browser'+sha256(get_datetime_now()
      ).slice(0,15)
    );
  }
  device.uuid = localStorage.getItem("browserId");
  device.cordova = window.navigator.product;
  device.model = window.navigator.appCodeName;
};

function reset_fullscreen_mode() {
  /*
      * force fullscreen Android immersive Mode
      * using
      * https://github.com/mesmotronic/cordova-plugin-fullscreen
      * 2017_04_12 mhe:
      */
  AndroidFullScreen.immersiveMode(
    function(s) {
      if (v.d) {
        console.log('fullscreen immersive activated');
      }
    },
    function(err) {
      if (v.d) {
        console.log('fullscreen immersive error!');
        console.error(err);
      }
    });
}

function onDeviceReady() {
  return new Promise(
    (resolve, reject) => {
      // system is android:
      reset_fullscreen_mode();
      document.addEventListener(
        'focusout',
        function(event) {
          if (v.d) console.log(event.target.id);
          reset_fullscreen_mode();
        }
      );
      if (online_media_sources) {
        home_dir = cordova.file.applicationDirectory + 'www/';
        local_dir = url.media_base_path;
        // refreshing of local_dir in core.load_models to renew token in
        // local_dir path for auth-checking in storage_api!! 2017_07_21_mhe
      } else {
        // mediadementia sample tab v.2
        if (device.model === "rk312x") {
          home_dir = cordova.file.applicationDirectory + 'www/';
          local_dir = cordova.file.externalDataDirectory;
        } else {
          home_dir = cordova.file.applicationDirectory + 'www/';
          local_dir = cordova.file.dataDirectory;
        }
      }

      // get internal free storage:
      cordova.exec(
        function(result) {
          available_space = result * 1000;
        },
        reject,
        'File',
        'getFreeDiskSpace',
        []
      );

      if (typeof cordova.getAppVersion !== 'undefined') {
        cordova.getAppVersion.getVersionNumber(
          function (version) {
            app_core_version = version;
          }
        );
      }

      // define brightness to stop standby while installing/updating:
      if (typeof cordova.plugins.brightness !== 'undefined') {
        window.brightness = cordova
          .require("cordova.plugin.Brightness.Brightness");
        brightness.setKeepScreenOn(true);
      }
      resolve();
    }
  );
}
